"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.regexp.constructor.js");

require("core-js/modules/es.regexp.dot-all.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.sticky.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.regexp.test.js");

require("core-js/modules/es.array.some.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var behandlerTeamsBehandler_1 = require("@/components/columns/settings/behandlerTeamsBehandler");

var AutoCompleteBehandler_vue_1 = require("@/components/AutoCompleteBehandler.vue");

var lodash_1 = require("lodash");

var settings_1 = require("@/helpers/settings");

var behandlerStore_1 = require("@/state/behandlerStore");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    AutoCompleteBehandler: AutoCompleteBehandler_vue_1["default"]
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: (0, behandlerTeamsBehandler_1.settingsBehandlerTeamsBehandlerColumnsDefs)(),
      showDeleteAllSelected: false,
      nichtZugeordnetAktivAdded: [],
      nichtZugeordnetVeraltetAdded: [],
      filter: ''
    };
  },
  computed: {
    behandler: function behandler() {
      var rgx = new RegExp(this.filter, 'i');
      var behandlerMap = behandlerStore_1.behandlerStore.getters.map;
      var configurableBehandler = editSettingsStore_1.editSettingsStore.getters.terminBehandler;

      if (!this.filter) {
        return configurableBehandler;
      }

      return (0, lodash_1.chain)(configurableBehandler).map(function (extid) {
        return behandlerMap[extid];
      }).filter(function (b) {
        return rgx.test(b.displayText || '') || rgx.test(b.extid || '') || (0, lodash_1.chain)((0, settings_1.getTerminBehandler)(editSettingsStore_1.editSettingsStore.state.editSettings, b.extid)).map(function (tb) {
          return behandlerMap[tb.extid];
        }).compact().some(function (t) {
          return rgx.test((t === null || t === void 0 ? void 0 : t.extid) || '') || rgx.test((t === null || t === void 0 ? void 0 : t.displayText) || '');
        }).value();
      }).map(function (b) {
        return b.extid;
      }).value();
    },
    behandlerNichtZugeordnetAktiv: function behandlerNichtZugeordnetAktiv() {
      return editSettingsStore_1.editSettingsStore.getters.behandlerNichtZugeordnetAktiv;
    },
    behandlerNichtZugeordnetVeraltet: function behandlerNichtZugeordnetVeraltet() {
      return editSettingsStore_1.editSettingsStore.getters.behandlerNichtZugeordnetVeraltet;
    }
  },
  created: function created() {
    this.gridOptions = {
      rowHeight: 50,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
    this.$log.debug('table data', this.behandler);
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    },
    onSelectionChanged: function onSelectionChanged() {
      this.showDeleteAllSelected = !!this.gridApi.getSelectedRows().length;
    },
    deleteItems: function deleteItems() {
      var selected = this.gridApi.getSelectedRows();
      var items = selected.map(function (v) {
        return v.extid;
      });
      editSettingsStore_1.editSettingsStore.commit.deleteBehandlerFromZuordnung(items);
      this.showDeleteAllSelected = false;
      this.gridApi.deselectAll();
    },
    addBehandlerToZuordnung: function addBehandlerToZuordnung(data) {
      editSettingsStore_1.editSettingsStore.commit.addBehandlerToZuordnung(data);
    },
    getRowId: function getRowId(params) {
      return params.data;
    }
  }
});