"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var HeuteAlarmAusschluesse_vue_1 = require("@/components/settings/Heute/HeuteAlarmAusschluesse.vue");

var vue_1 = require("vue");

var HeuteAlarmTable_vue_1 = require("@/components/tables/settings/Heute/HeuteAlarmTable.vue");

var HeuteTerminzeiten_vue_1 = require("@/components/settings/Heute/HeuteTerminzeiten.vue");

var HeuteGruppenFilter_vue_1 = require("@/components/settings/Heute/HeuteGruppenFilter.vue");

var Helptext_vue_1 = require("@/components/Helptext.vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

var HeuteSonstiges_vue_1 = require("@/components/settings/Heute/HeuteSonstiges.vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    Helptext: Helptext_vue_1["default"],
    HeuteAlarmAusschluesse: HeuteAlarmAusschluesse_vue_1["default"],
    HeuteAlarmTable: HeuteAlarmTable_vue_1["default"],
    HeuteTerminzeiten: HeuteTerminzeiten_vue_1["default"],
    HeuteGruppenFilter: HeuteGruppenFilter_vue_1["default"],
    HeuteSonstiges: HeuteSonstiges_vue_1["default"]
  },
  data: function data() {
    return {
      heuteField: [],
      helptext: "Hier kannst Du beeinflu\xDFen wie stark ein Fehler in den Feldern auf den Score wirkt.\n    Eine h\xF6here Zahl bedeutet dabei einen gr\xF6\xDFeren Score-Abzug. Wenn Du den Score-Abzug auf 0 stellst, wird das Feld nur noch rot dargestellt, aber kein Abzug mehr berechnet. W\xE4hlst Du - aus, so wird auch kein Hinweis mehr dargestellt. Mit Klick auf das Auge wird das Feld inklusive Wert gar nicht dargestellt und auch kein Score-Abzug vorgenommen.",
      pvs: null
    };
  },
  created: function created() {
    if (editSettingsStore_1.editSettingsStore.getters.heuteField) {
      this.heuteField = editSettingsStore_1.editSettingsStore.getters.heuteField;
      this.pvs = editSettingsStore_1.editSettingsStore.getters.pvs;
    }
  }
});