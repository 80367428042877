import { IDoctosyncUnmatchedPatients } from '../../../types';
import { RoseApi } from './apiTypes';

export type DoctosyncApi = ReturnType<typeof createDoctosyncApi>;

export function createDoctosyncApi(roseApi: RoseApi) {
  return {
    getUnmatchedPatients: async () => roseApi.doctosync.get<IDoctosyncUnmatchedPatients[]>(`/api/unmatchedpatients`),
    addPatid: async (extid: string, patid: string) => roseApi.doctosync.post(`/api/addpatid`, { extid, patid }),
    reset: async () => roseApi.doctosync.post(`/api/reset`),
  };
}
