// times
export enum TimeInMilliseconds {
  seconds_1 = 1000,
  seconds_2 = 2 * 1000,
  seconds_3 = 3 * 1000,
  seconds_5 = 5 * 1000,
  seconds_10 = 10 * 1000,
  seconds_15 = 15 * 1000,
  seconds_20 = 20 * 1000,
  seconds_30 = 30 * 1000,
  seconds_45 = 45 * 1000,
  seconds_90 = 90 * 1000,
  minutes_1 = 60 * 1000,
  minutes_2 = 2 * 60 * 1000,
  minutes_5 = 5 * 60 * 1000,
  minutes_10 = 10 * 60 * 1000,
  minutes_15 = 15 * 60 * 1000,
  minutes_20 = 20 * 60 * 1000,
  minutes_30 = 30 * 60 * 1000,
  minutes_45 = 45 * 60 * 1000,
  hours_1 = 1 * 60 * 60 * 1000,
  hours_2 = 2 * 60 * 60 * 1000,
  hours_3 = 3 * 60 * 60 * 1000,
  hours_6 = 6 * 60 * 60 * 1000,
  days_1 = 24 * 60 * 60 * 1000,
}

export enum TimeInSeconds {
  seconds_10 = 10,
  seconds_30 = 30,
  minutes_1 = 60,
  minutes_5 = 5 * 60,
  minutes_10 = 10 * 60,
  minutes_20 = 20 * 60,
  minutes_30 = 30 * 60,
  hours_1 = 1 * 60 * 60,
  hours_5 = 5 * 60 * 60,
  hours_10 = 10 * 60 * 60,
  day_1 = 24 * 60 * 60,
}
