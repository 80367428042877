"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var BehandlerChip_vue_1 = require("@/components/BehandlerChip.vue");

var behandlerStore_1 = require("@/state/behandlerStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    BehandlerChip: BehandlerChip_vue_1["default"]
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    behandler: function behandler() {
      return behandlerStore_1.behandlerStore.getters.map[this.params.value];
    },
    isAutoConfigured: function isAutoConfigured() {
      return false;
    }
  }
});