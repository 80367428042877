"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    checked: {
      get: function get() {
        var _this$params$value;

        return !!((_this$params$value = this.params.value) !== null && _this$params$value !== void 0 && _this$params$value.value);
      },
      set: function set(value) {
        this.params.context.clicked({
          key: this.params.value.key,
          value: !!value
        });
      }
    },
    label: function label() {
      return this.params.context.label;
    }
  },
  methods: {
    clicked: function clicked(event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
});