"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    condition: {
      get: function get() {
        var _editSettingsStore_1$, _this$params$data;

        return (_editSettingsStore_1$ = editSettingsStore_1.editSettingsStore.getters.alarme[(_this$params$data = this.params.data) === null || _this$params$data === void 0 ? void 0 : _this$params$data.feldKey]) === null || _editSettingsStore_1$ === void 0 ? void 0 : _editSettingsStore_1$.conditions;
      },
      set: function set(value) {
        var _this$params$data2;

        if ((_this$params$data2 = this.params.data) !== null && _this$params$data2 !== void 0 && _this$params$data2.feldKey) {
          editSettingsStore_1.editSettingsStore.commit.setAlarmCondition({
            alarmField: this.params.data.feldKey,
            condition: value
          });
        }
      }
    },
    conditions: function conditions() {
      return this.params.value;
    }
  }
});