import { IDBModel } from './db';
import { RECHNUNGSEMPFAENGER_TYPE } from './factoring';
import { IProfile } from './profile';
import { IBaseSettings, PvsType } from './settings';

export interface IBotImportStartResult {
  message: string;
  started: boolean;
}

export interface IPvsInfo {
  pvs: PvsType;
  host: string;
  port: number;
  database: string;
  user: string;
  password: string;
  version: string;
  dbversion: string;
  praxis: string;
  anzahlLizenzen?: number;
  anzahlTermine?: number;
  lastchange?: Date;
  praxisInfo?: IRoseBotPraxisInfo;
  uuidFromDB?: string;
  selected?: boolean; // which was selected by the bot
}

export interface IHostInfo {
  hostname: string;
  ip: string;
  ports: number[];
  ctx?: string;
}

export enum IBotMode {
  UNKNOWN = 'UNKNOWN',
  ROSE_BOT = 'ROSE_BOT',
  CHARLY_ANALYTICS_BOT = 'CHARLY_ANALYTICS_BOT',
  CHARLY_ANALYTICS_SERVICE = 'CHARLY_ANALYTICS_SERVICE',
}

export interface IBotInfo {
  botid: string;
  cid: string;
  alias: string;
  mode: IBotMode;
  version: string;
  pvsInfos: IPvsInfo[];
  uptime: Date;
  ipInternal: string;
  ipExternal: string;
  subnetMask: string;
  macAddress: string;
  sshPort: number;
  sshServerPort?: number;
  tenantId?: string; // only suitable for charly analytics
  linuxVersion?: string;
  nodeVersion?: string;
  portScanResults?: IHostInfo[];
}

export interface IConnectedClientInfo {
  cid: string;
  sparkId?: string;
  spark?: any;
  connectionTime: Date;
  reconnects: number;
}

export interface ICompleteBotInfo extends IBotInfo, IConnectedClientInfo {}

export interface BotLogs {
  logs: string[];
}

export interface IActionResult<T> {
  success: boolean;
  error?: string;
  result?: T;
}

export interface IRequestResult<T> {
  status: number;
  statusText: string;
  success: boolean;
  data?: T;
  error?: any;
}

export interface IRequestResultWithDetails<T> extends IRequestResult<T> {
  sizeUncompressed?: number;
  sizeCompressed?: number;
  timeCompress?: number;
}

export interface SaveInfo {
  type: 'APPEND' | 'REPLACE' | 'FAIL' | 'QUEUED' | 'SAVED';
  insertCount: number;
  modifiedCount: number;
}

export interface IBot extends IDBModel {
  cid: string;
  name: string;
  botId: string;
  mode: IBotMode;
  active: boolean;
  ipInternal: string;
  ipExternal: string;
  subnetMask: string;
  macaddress: string;
  sshPort: number;
  postgresScan: IPvsInfo[];
  pvsscan: IPvsInfo[];
  uptime?: Date;
  version: string;
  databases: string[];
  monitor: boolean;
  alias: string;
}

export interface IBotExtended extends IBot, IBaseSettings {
  profile: IProfile;
  sshonline?: boolean;
  online?: boolean;
}

export interface IImportModels {
  [modelname: string]:
    | {
        [fieldname: string]: string | any;
      }
    | string[];
}

export enum PoolMode {
  single = 'single',
  multi = 'multi',
}

export enum Z1PoolType {
  admin = 'admin',
  z1 = 'z1',
  timer = 'timer',
}

export interface IBotPatientDetailsPatient {
  extid: string;
  geb_datum: Date;
  name: string;
  vorname: string;
  privat: boolean;
  veraltet: boolean;
}

export interface IBotPatientDetailsKontakt {
  art: string;
  nummer: string;
}

export interface IBotPatientDetailsDaten {
  privat: boolean;
  vers_name: string;
  vers_vorname: string;
  vers_geb_datum: string;
  vers_ort: string;
  vers_plz: string;
  vers_strasse: string;
  rgan: RECHNUNGSEMPFAENGER_TYPE;
}

export interface IBotPatientDetailsRechnungsempfang {
  extid: string;
  name: string;
  vorname: string;
  geburtstag: Date;
  strasse: string;
  ort: string;
  plz: string;
  geschlecht: number;
}

export interface IBotPatientDetails {
  patient?: IBotPatientDetailsPatient;
  contact?: IBotPatientDetailsKontakt[];
  daten?: IBotPatientDetailsDaten;
  rechnungsempfang?: IBotPatientDetailsRechnungsempfang;
}

export interface IBotPatientSearchResult {
  extid: string;
  anschriftzusatz: string;
  geb_datum: Date;
  geb_datum_string: string;
  geschlecht: 'M' | 'W' | 'D' | 'U' | 'X';
  veraltet: boolean;
  privat: boolean;
  name: string;
  ort: string;
  plz: string;
  strasse: string;
  vorname: string;
  day: number;
  year: number;
  month: number;
}

export enum RoseStatusType {
  DOWN = 'DOWN',
  UP = 'UP',
  DISABLED = 'DISABLED',
  UNKNOWN = 'UNKNOWN',
}

export interface IRoseStatus {
  status: RoseStatusType;
  components: {
    db: {
      status: RoseStatusType;
      details: {
        host: string;
        port: string;
        db: string;
      };
    };
    incrementalcollect: {
      status: RoseStatusType;
      details: {
        id: string;
        started: Date;
      };
    };
    fullcollect: {
      status: RoseStatusType;
      details: {
        id: string;
        started: Date;
      };
    };
  };
  details: {
    started: Date;
    pid: number;
  };
}

export interface IRoseBotManifest {
  version: string;
  name: string;
  timestamp: string;
  buildId?: string;
}

export interface IRoseBotManifestExecutables {
  checksum: {
    type: 'md5';
    value: string;
    signature: string;
  };
  sources: [
    {
      url: string;
    },
  ];
  filter: {
    platform: 'win' | 'linux' | 'macos';
    arch: 'x64';
    version: string;
  };
  manifest: IRoseBotManifest;
}

export interface IRoseBotManifestComplete {
  executables: IRoseBotManifestExecutables[];
}

export interface IRoseBotPraxisInfo {
  stempelname1: string;
  stempelname2: string;
  praxis1: string; // fallback
  praxis2: string; // fallback
  strasse: string;
  plz: string;
  ort: string;
  bank: string;
  iban: string;
  bic: string;
  kzvnummer: string;
  fax: string;
  email: string;
  telefon: string;
  license: string;
  serialnr: string;
}

export interface IRoseBotPatientHashEncrypted {
  patid: string;
  hash?: string;
  patientdata?: string;
}

export interface IRoseBotPatientHashDecrypted {
  patid: string;
  hash?: string;
  patientdata?: IRoseBotPatientHashPatientData;
}

export interface IRoseBotPatientHashPatientData {
  name: string;
  firstname: string;
  birthdate: Date;
}

export interface IRoseBotPatientHashResult {
  lastchange: string;
  patients: IRoseBotPatientHashEncrypted[];
}

export interface IPatientHashParameters {
  url: string;
  token: string;
  lastchange?: string;
}

export enum BotExitCodes {
  OK = 0,
  UNHANDLED_EXCEPTION = 2,
  UNHANDLED_REJECTION = 3,
  NOSERIALNUMBER = 10,
  NOLICENCE = 20,
  DELETELICENCE = 30,
  LICENCEREQUESTFAIL = 40,
  RESTARTFORCLIENTIDCHANGE = 50,
  DBCONNECTIONUNRECOVERABLE = 60,
  TEARDOWNUNRECOVERABLE = 70,
  TOOMANYFAILEDIMPORTSTARTS = 71,
  WAITEDTTOOLONGFORSETTINGS = 72,
  SOCKETUNRECOVERABLE = 80,
  MANUALRESTART = 90,
  NOBOTINFO = 91,
}

export enum CompressionType {
  BROTLI = 'br',
  GZIP = 'gzip',
  NONE = 'none',
}

export enum EncryptionType {
  AES_256_CTR = 'aes-256-ctr',
  NONE = 'none',
}

export interface IBotAccess {
  cid: string;
  keyname: string;
  publickey: string;
  sshpublickey: string;
  privatekey: string;
  password: string;
}
