import { IAbwesenheit, ISchicht, ITerminvorgabe } from './terminbuch';

export interface IDBDoctolib {
  cid: string;
  lastsync: Date;
  lastid: string;
  error: boolean;
  errormsg: string | null;
  errorcount: number;
}

export interface IConfigDoctolib {
  doctolib: {
    hostUrl: string;
    testHostUrl: string; // test environment
    fetchInterval: number;
    runnerInterval: number;
    parallelFetchers: number;
    hasherInterval: number;
    salt: string;
  };
}

export interface IDoctolibAuthConfig {
  cid: string;
  key: string;
  secretKey: string;
  test: boolean; // test environment
}

export interface IDoctolibHeaders {
  Accept: string;
  Authorization: string;
  'Content-Type': string;
  Date: string;
}

export interface IDoctolibConfigResponse {
  // specialities: IDoctolibSpeciality[]; // UNUSED
  agendas: IDoctolibAgenda[];
  visit_motives: IDoctolibVisitMotive[];
  visit_motive_categories: IDoctolibVisitMotiveCategory[];
  practices: IDoctolibPractice[];
}

export interface IDoctolibSpeciality {
  id: string;
  label: string;
}

export interface IDoctolibAgenda {
  id: string;
  label: string;
  speciality_id?: string;
  type: string;
  visit_motive_ids: string[];
  practice_id?: string;
}

/**
 * terminarten
 */
export interface IDoctolibVisitMotive {
  id: string;
  label: string;
  speciality_id: string;
  duration: number;
  color: string;
  visit_motive_category_id: string;
}

/**
 * terminarten kategorien
 */
export interface IDoctolibVisitMotiveCategory {
  id: string;
  label: string;
  speciality_id: string;
}

export interface IDoctolibPractice {
  id: string;
  name: string;
  address: string;
  zipcode: string;
  city: string;
}

export interface IDoctolibSyncResponse {
  events: IDoctolibEvent[];
}

export interface IDoctolibEvent {
  id: string;
  appointment?: IDoctolibAppointment;
  patient?: IDoctolibPatient;
  schedule_time_slot?: IDoctolibScheduleTimeSlot;
}

export interface IDoctolibScheduleTimeSlot {
  id: string;
  agenda_id: string;
  kind: 'open' | 'blck';
  active: boolean;
  start_date: string;
  duration: number;
  recurrence?: IDoctolibRecurrence; // Made optional as it is not always present
  visit_motive_ids: string[];
  all_visit_motives: boolean;
  color?: string | null; // Optional property
  exceptions?: string[]; // Optional property
  label?: string | null; // Optional property
  comment?: string; // Optional property for absences
}

export interface IDoctolibRecurrence {
  end_date?: string | null; // Optional and nullable
  week_frequency?: number; // Optional
  week_days?: string[]; // Optional
}

export interface IDoctosyncTerminvorgabe extends ITerminvorgabe {
  lastchange: number;
  aktiv: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IDoctosyncAbwesenheit extends IAbwesenheit {
  lastchange: number;
  aktiv: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IDoctosyncSchicht extends ISchicht {
  lastchange: number;
  exceptions: string[];
  aktiv: boolean;
  start_datum: Date;
  end_datum: Date | null;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum DoctolibAppointmentStatus {
  CONFIRMED = 'confirmed',
  WAITING = 'waiting',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  DELETED = 'deleted',
  NO_SHOW_BUT_OK = 'no_show_but_ok',
  NO_SHOW = 'no_show',
  SUSPENDED = 'suspended',
}

export interface IDoctolibAppointment {
  id: string;
  start_date: string;
  end_date: string;
  duration: number;
  status: DoctolibAppointmentStatus;
  notes: string;
  agenda_id: string;
  visit_motive_id: string;
  visit_motive_label?: string;
  medical_records?: IDoctolibMedicalRecord[];
  patient: IDoctolibPatient;
}

export interface IDoctolibMedicalRecord {
  id: number;
  question_title: string;
  question_body: string;
  answer_type: string;
  answer_body: string;
}

export interface IDoctolibPatient {
  last_name: string;
  first_name: string;
  maiden_name: string;
  gender: string;
  birthdate: string;
  phone_number: string;
  secondary_phone_number: string;
  email: string;
  address: string;
  zipcode: string;
  city: string;
  id: string;
}

export interface IDoctosyncUnmatchedPatients {
  extid: string;
  patientData: IDoctolibPatient;
}
