"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    checkColor: function checkColor() {
      var _this$params$context;

      return (_this$params$context = this.params.context) !== null && _this$params$context !== void 0 && _this$params$context.colorized ? 'success' : 'primary';
    },
    timesColor: function timesColor() {
      var _this$params$context2;

      return (_this$params$context2 = this.params.context) !== null && _this$params$context2 !== void 0 && _this$params$context2.colorized ? 'error' : 'primary';
    }
  }
});