export enum HEADERS {
  ROSEVERSION = 'rose-version',
  ROSECLIENTIP = 'rose-client-ip',
  ROSECLIENTID = 'rose-client-id',
  ROSETRACEID = 'rose-trace-id',
  ROSETASKID = 'rose-task-id',
  ROSER4CUUID = 'rose-r4c-uuid',
  ROSEREQUESTID = 'rose-request-id',
  ROSEPREFFEREDR4CINSTANCEUUID = 'rose-preffered-r4c-instance-uuid',
  ROSECACHECONTROL = 'rose-cache-control',
}

export enum HEADERVALUES {
  NOCACHE = 'no-cache',
}

export enum QUERYSTRINGS {
  NONOTIFY = 'nonotify',
}

export enum PARAMS {
  PATEXTRAPAGEFIELDS = 'patextrapagefields',
  STICHWORTE = 'stichworte',
  STERNWERTE = 'sternwerte',
  SCHNITTSTELLEN = 'schnittstellen',
  PVSVERSION = 'pvsversion',
  DBVERSION = 'dbversion',
  ARBEITSPLAETZE = 'arbeitsplaetze',
  PRAXISNAME = 'praxisname',
  LIZENZ = 'lizenz',
  LABORBEL = 'laborbel',
  KZV = 'kzv',
  MULTIRECALL = 'multirecall',
}

export let CACHEBLOCKERID = 'CACHEBLOCKER';
export let ERRORBLOCKERID = 'ERRORBLOCKER';

export const demoUserEmail = 'demo@rose.dental';

export const STRIPE_CHARLY_ANALYTICS_KUNDENID_KEY = 'Kunden-ID';
export const STRIPE_CHARLY_ANALYTICS_LIZENZNR_KEY = 'Lizenz-Nr.';
export const STRIPE_CHARLY_ANALYTICS_SERIALNR_KEY = 'Serien-Nr.';
export const STRIPE_CHARLY_ANALYTICS_NAME_KEY = 'Name';

export const STRIPE_KUNDENID_KEY = 'cid';
export const STRIPE_KUNDENNAME_KEY = 'praxisname';
