"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var StichwortChip_vue_1 = require("@/components/StichwortChip.vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

var staticDataMetricsStore_1 = require("@/state/staticDataMetricsStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    StichwortChip: StichwortChip_vue_1["default"]
  },
  data: function data() {
    return {
      params: {},
      id: ''
    };
  },
  computed: {
    regelSetting: {
      get: function get() {
        var _this$params$data, _this$params$data$set;

        return (_this$params$data = this.params.data) === null || _this$params$data === void 0 ? void 0 : (_this$params$data$set = _this$params$data.settings) === null || _this$params$data$set === void 0 ? void 0 : _this$params$data$set.ignorierteStichworte;
      },
      set: function set(items) {
        if (this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              ignorierteStichworte: items
            }
          });
        }
      }
    },
    stichworte: function stichworte() {
      return staticDataMetricsStore_1.staticDataMetricsStore.state.params.stichworte;
    }
  }
});