import { IFactoringRechenzentrum, CREDENTIALS_TYPE, rzkeys } from '../../types';

export const rechenzentren: { [key in rzkeys]: IFactoringRechenzentrum } = {
  bfs: {
    key: rzkeys.bfs,
    backendkey: rzkeys.bfs,
    name: 'BFS',
    langname: 'BFS health finance GmbH',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.CLIENT_WITH_CERT,
    standardLaufzeitEweInMonaten: 12,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EVE',
      ava: 'AVA',
      mandant: 'Mandant',
    },
    features: {
      restbetrag: true,
      letztesRechnungsDatum: true,
      ausfallschutzBeiBlindankauf: true,
      fristLetzteLeistung: true,
      nachrichten: false,
      landMandatory: false,
      vertragsDatenUpdate: true,
    },
    binaryCredentialFields: ['zertifikat'],
  },
  dzr: {
    key: rzkeys.dzr,
    backendkey: rzkeys.dzr,
    name: 'DZR',
    langname: 'DZR',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EE',
      ava: 'AA',
      mandant: 'Mandant',
    },
    features: {
      restbetrag: true,
      letztesRechnungsDatum: false,
      landMandatory: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      vertragsDatenUpdate: false,
    },
    numberCredentialFields: ['org_unit', 'contract_number'],
    credentialsFieldsForMeta: ['org_unit'],
  },
  abz: {
    key: rzkeys.abz,
    backendkey: rzkeys.abz,
    name: 'ABZ',
    langname: 'ABZ',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EE',
      ava: 'AA',
      mandant: 'Mandant',
    },
    features: {
      restbetrag: true,
      letztesRechnungsDatum: false,
      landMandatory: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      vertragsDatenUpdate: false,
    },
    numberCredentialFields: ['org_unit', 'contract_number'],
    credentialsFieldsForMeta: ['org_unit'],
  },
  za: {
    key: rzkeys.za,
    backendkey: rzkeys.za,
    name: 'ZA',
    langname: 'ZA',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EE',
      ava: 'AA',
      mandant: 'Mandant',
    },
    features: {
      restbetrag: true,
      letztesRechnungsDatum: true,
      nachrichten: true,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      landMandatory: false,
      vertragsDatenUpdate: true,
    },
  },
  health: {
    key: rzkeys.health,
    backendkey: rzkeys.health,
    name: 'Health',
    langname: 'Health',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Mandant',
    },
    features: {
      restbetrag: true,
      letztesRechnungsDatum: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      landMandatory: false,
      vertragsDatenUpdate: true,
    },
  },
  pvsdental: {
    key: rzkeys.pvsdental,
    backendkey: rzkeys.pvsdental,
    name: 'PVS Dental',
    langname: 'PVS Dental',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Mandant',
    },
    features: {
      restbetrag: true,
      letztesRechnungsDatum: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      landMandatory: false,
      vertragsDatenUpdate: true,
    },
  },
  pvsreiss: {
    key: rzkeys.pvsreiss,
    backendkey: rzkeys.pvsreiss,
    name: 'PVS Reiss',
    langname: 'PVS Reiss',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Mandant',
    },
    features: {
      restbetrag: false,
      letztesRechnungsDatum: false,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      landMandatory: false,
      vertragsDatenUpdate: true,
    },
  },
  arc: {
    key: rzkeys.arc,
    backendkey: rzkeys.arc,
    name: 'ARC',
    langname: 'ARC',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Kunde',
    },
    features: {
      letztesRechnungsDatum: true,
      restbetrag: true,
      landMandatory: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      vertragsDatenUpdate: true,
    },
  },
  teamfaktor: {
    key: rzkeys.teamfaktor,
    backendkey: rzkeys.teamfaktor,
    name: 'Teamfaktor',
    langname: 'Teamfaktor',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Kunde',
    },
    features: {
      letztesRechnungsDatum: true,
      restbetrag: true,
      landMandatory: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      vertragsDatenUpdate: true,
    },
  },
  carecapital: {
    key: rzkeys.carecapital,
    backendkey: rzkeys.carecapital,
    name: 'CareCapital',
    langname: 'CareCapital',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    numberCredentialFields: ['kunde'],
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Kunde',
    },
    features: {
      letztesRechnungsDatum: true,
      restbetrag: true,
      landMandatory: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      vertragsDatenUpdate: true,
    },
  },
  mediserv: {
    key: rzkeys.mediserv,
    backendkey: rzkeys.mediserv,
    name: 'mediserv',
    langname: 'mediserv',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Kunde',
    },
    features: {
      letztesRechnungsDatum: true,
      restbetrag: false,
      landMandatory: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      vertragsDatenUpdate: true,
    },
  },
  zab: {
    key: rzkeys.zab,
    backendkey: rzkeys.zab,
    name: 'ZAB',
    langname: 'ZAB',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Kunde',
    },
    features: {
      letztesRechnungsDatum: true,
      restbetrag: true,
      landMandatory: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      vertragsDatenUpdate: true,
    },
  },
  nelly: {
    key: rzkeys.nelly,
    backendkey: rzkeys.nelly,
    name: 'nelly',
    langname: 'nelly',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Kunde',
    },
    features: {
      letztesRechnungsDatum: false,
      restbetrag: true,
      landMandatory: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      vertragsDatenUpdate: true,
    },
  },
  fabius: {
    key: rzkeys.fabius,
    backendkey: rzkeys.fabius,
    name: 'fabius',
    langname: 'fabius dental',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 18,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Kunde',
    },
    features: {
      letztesRechnungsDatum: false,
      restbetrag: true,
      landMandatory: true,
      nachrichten: false,
      ausfallschutzBeiBlindankauf: false,
      fristLetzteLeistung: false,
      vertragsDatenUpdate: true,
    },
  },
  // for testing, tes and demo are basically the same but different names
  test: {
    key: rzkeys.test,
    backendkey: rzkeys.test,
    name: 'TESTRZ',
    langname: 'TESTRZ',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 12,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Mandant',
    },
    features: {
      restbetrag: true,
      letztesRechnungsDatum: true,
      nachrichten: true,
      ausfallschutzBeiBlindankauf: true,
      fristLetzteLeistung: true,
      landMandatory: true,
      vertragsDatenUpdate: true,
    },
  },
  demo: {
    key: rzkeys.demo,
    backendkey: rzkeys.demo,
    name: 'DEMORZ',
    langname: 'DEMORZ',
    patreffieldEWE: 'ref_re_simple',
    patreffieldAVA: 'ref_re_simple',
    credentialstype: CREDENTIALS_TYPE.ROSETOKEN,
    standardLaufzeitEweInMonaten: 12,
    standardanfragebetrag: 5000,
    maximalbetrag: 10000,
    multipleanfragen: false,
    localisation: {
      ewe: 'EWE',
      ava: 'AVA',
      mandant: 'Mandant',
    },
    features: {
      restbetrag: true,
      letztesRechnungsDatum: true,
      nachrichten: true,
      ausfallschutzBeiBlindankauf: true,
      fristLetzteLeistung: true,
      landMandatory: true,
      vertragsDatenUpdate: true,
    },
    credentialsFieldsForMeta: ['metainfo'],
  },
};
