"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var BehandlerChip_vue_1 = require("@/components/BehandlerChip.vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    BehandlerChip: BehandlerChip_vue_1["default"]
  },
  data: function data() {
    return {
      params: {},
      event: '',
      terminToAdd: null
    };
  },
  computed: {
    terminBehandlerNotAssignedAktiv: function terminBehandlerNotAssignedAktiv() {
      return editSettingsStore_1.editSettingsStore.getters.terminBehandlerNichtZugeordnetAktiv;
    },
    terminBehandlerNotAssignedVeraltet: function terminBehandlerNotAssignedVeraltet() {
      return editSettingsStore_1.editSettingsStore.getters.terminBehandlerNichtZugeordnetVeraltet;
    },
    terminBehandlerAssigned: function terminBehandlerAssigned() {
      if (!this.params.data) {
        return [];
      }

      return editSettingsStore_1.editSettingsStore.getters.terminBehandlerMap[this.params.data];
    }
  },
  methods: {
    addTerminbehandlerZuordnung: function addTerminbehandlerZuordnung(terminBehandler) {
      if (this.params.data) {
        editSettingsStore_1.editSettingsStore.commit.assignTerminBehandlerToBehandler({
          behandlerId: this.params.data,
          terminBehandlerId: terminBehandler.extid
        });
      }
    },
    deleteTerminBehandlerZuordnung: function deleteTerminBehandlerZuordnung(terminBehandler) {
      if (this.params.data) {
        editSettingsStore_1.editSettingsStore.commit.removeTerminBehandlerAssignment({
          behandlerId: this.params.data,
          terminBehandlerId: terminBehandler.extid
        });
      }
    },
    getIsAutoConfigured: function getIsAutoConfigured(t) {
      var _editSettingsStore_1$, _editSettingsStore_1$2, _editSettingsStore_1$3;

      var grund = (_editSettingsStore_1$ = editSettingsStore_1.editSettingsStore.getters.terminBehandlerZuordnungMeta) === null || _editSettingsStore_1$ === void 0 ? void 0 : (_editSettingsStore_1$2 = _editSettingsStore_1$.auto) === null || _editSettingsStore_1$2 === void 0 ? void 0 : (_editSettingsStore_1$3 = _editSettingsStore_1$2[t.extid]) === null || _editSettingsStore_1$3 === void 0 ? void 0 : _editSettingsStore_1$3.grund;
      return !!grund;
    }
  }
});