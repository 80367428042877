export const roseWebinare = [
  {
    thema: 'Schwerpunkt Empfang',
    datum: '2024-09-05',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/7e82c697-9e5a-46f6-a207-fef62c34cf8d@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Empfang',
    datum: '2024-09-04',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/7e82c697-9e5a-46f6-a207-fef62c34cf8d@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Empfang',
    datum: '2024-10-02',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/dd847808-31b3-48c7-9a8e-fcd8382b53c5@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Empfang',
    datum: '2024-11-06',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/cf72403d-7159-4980-8c5f-83a889511ff7@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Empfang',
    datum: '2024-12-04',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/aee21072-32a8-4172-8361-f52fdf7a955b@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Behandlungszimmer',
    datum: '2024-9-11',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/9f79b844-d21d-4657-88cd-8bdc159d78d2@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Behandlungszimmer',
    datum: '2024-10-09',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/b902b2da-70d7-47bd-b61e-ecee5b073707@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Behandlungszimmer',
    datum: '2024-11-13',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/1569e244-4a3e-47b4-accc-f67f38e9cbac@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Behandlungszimmer',
    datum: '2024-12-11',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/89a895ba-76f7-414f-8942-f1942a5d7ad1@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Abrechnung',
    datum: '2024-9-18',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/bd04512d-e233-461f-800f-aee6835b5935@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Abrechnung',
    datum: '2024-10-16',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/c36789b1-467b-4789-ab20-955a9170b2a2@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Abrechnung',
    datum: '2024-11-20',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/bb645a9b-0742-4e9c-aa14-94b9d6ec39ab@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Abrechnung',
    datum: '2024-12-18',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/c89b8117-2c6f-4276-ab5d-7caf4538919e@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt rose4charly',
    datum: '2024-9-25',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/79f7466b-5fe3-4962-aa61-7bc6067ccfdd@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt rose4charly',
    datum: '2024-10-30',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/e233f16e-9197-4149-9104-a8ee2e04c879@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt rose4charly',
    datum: '2024-11-27',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/fd4b701b-93b2-4b3c-9147-a4fde0cc6d33@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'Schwerpunkt Controlling und Benchmarking',
    datum: '2024-10-23',
    zeit: '14 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/28b99b1a-96ee-4dcb-b31b-690c965d409d@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'rose-Community Meeting',
    datum: '2024-10-12',
    zeit: '13 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/a27bb365-e517-4273-a182-78f0a4a7b141@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
  {
    thema: 'rose-Community Meeting',
    datum: '2024-11-16',
    zeit: '13 Uhr',
    registerurl:
      'https://events.teams.microsoft.com/event/4158c20a-6b2a-489b-9643-f6738fc8415d@dae51c82-4e5b-44e1-87c4-1c51393670e3',
  },
];
