export interface ICharlyPatient {
  extraseitendaten: any;
  klassifizierung: number;
  lastchange: number;
  veraltet: boolean;
  stichworte: string[];
  [key: string]: any;
}

export interface ICharlyTerminBase {
  extid: string;
  patid: string;
  tag: Date;
  art: string;
  terminstart: Date;
  termindauer: number;
  nichterschienen: number;
  terminBehandler: string;
  angelegt: Date;
  angelegtvon: string;
  mode: number; // mode=0: offen, =1:normaler termin, =2: allg. termin ohne patientenbezug
  lastchange: number;
}

export interface ICharlyTermin extends ICharlyTerminBase {
  alter: number;
  alterinmonaten: number;
  zimmer: string;
  vorlauf: number;
  wartezimmerzeit: Date;
  behandlungszimmerzeit: Date;
  behandlungsstartzeit: Date;
  behandlungsendzeit: Date;
  wtime: number;
}

export interface ICharlyTerminart {
  extid: string;
  bezeichnung: string;
  recalltermin: boolean;
  veraltet: boolean;
  gruppenid: string;
  gruppenbezeichnung: string;
  gruppencolorr: number;
  gruppencolorg: number;
  gruppencolorb: number;
  honorar_privat: number;
  zus_honorar_privat: number;
  honorar_kasse: number;
  zus_honorar_kasse: number;
  vorgabe: number;
  gruppe?: string; // for now serialized gruppe
  lastchange: number;
}

export interface ICharlyMahnung {
  extid: string;
  rgid: string;
  rgnr: string;
  patid: string;
  behandler: string;
  tag: Date;
  erstemahnung: Date;
  zweitemahnung: Date;
  drittemahnung: Date;
  anwalt: string;
  rest: number;
  faelligseit: Date;
  rechnungsbetrag: number;
  behandlerdetails: { [behandlerid: string]: number }; // beträge pro behandler
  lastchange: number;
}

export interface ICharlyParam {
  daten: any;
  extid: string;
}

export interface ICharlySchichten {
  jahr: number;
  wochen: string | number[]; // json number[]
  wochentage: string | number[]; // json number[];
  behandler: string;
  schicht: string;
  veraltet: boolean;
  bezeichnung: string;
  von1: number;
  bis1: number;
  von2: number;
  bis2: number;
  vonbis: string;
}

export interface ICharlyTerminvorgaben {
  extid: string;
  behandlerid: string;
  tag: Date;
  von: number;
  bis: number;
  terminart: number;
  bezeichnung: string;
  terminsperre: boolean;
  terminanbieten: boolean;
  termingruppeid: string;
  wochentag: number;
  schichtid: string;
  wiederkehrend: boolean;
  lastchange: number;
}

export interface ICharlyTermineMeta {
  extid: string;
  tag: Date;
  patid: string;
  extids: string[];
  assi1ids: string[];
  assi2ids: string[];
  lastchange: number;
}

export interface ICharlyArbeitsZeit {
  behandler: string;
  wochentage: number[];
  von1: number;
  bis1: number;
  von2: number;
  bis2: number;
  vonbis: string;
  lastchange: number;
}

export interface ICharlyBefund01Pa {
  extid: string;
  patid: string;
  alter: number;
  alterinmonaten: number;
  tag: Date;
  zahn11: string;
  zahn12: string;
  zahn13: string;
  zahn14: string;
  zahn15: string;
  zahn16: string;
  zahn17: string;
  zahn18: string;
  zahn21: string;
  zahn22: string;
  zahn23: string;
  zahn24: string;
  zahn25: string;
  zahn26: string;
  zahn27: string;
  zahn28: string;
  zahn31: string;
  zahn32: string;
  zahn33: string;
  zahn34: string;
  zahn35: string;
  zahn36: string;
  zahn37: string;
  zahn38: string;
  zahn41: string;
  zahn42: string;
  zahn43: string;
  zahn44: string;
  zahn45: string;
  zahn46: string;
  zahn47: string;
  zahn48: string;
  lastchange: number;
}

export type ICharlyFileListing = { ablage: AblageType; files: string[] }[];

export interface ICharlyDocSettingsMargins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface ICharlyDocSettingsBackground {
  image?: string;
  x?: number;
  y?: number;
}

export interface ICharlyDocSettings {
  margins: ICharlyDocSettingsMargins;
  background?: ICharlyDocSettingsBackground;
}

export interface ICharlyFileInfo {
  files: ICharlyFileListing;
  documentSettings: { file: string; settings: ICharlyDocSettings }[];
  fileTimestamps?: { file: string; ablage: string; timestamp: string }[];
}

export enum AblageType {
  Ablage_material_praxis = 'Ablage_material_praxis',
  AU = 'AU',
  Bestellungen = 'Bestellungen',
  /**
   * "Eingang" is a special case, it is a shared folder for all patients
   */
  Eingang = 'Eingang',
  Fremdlabor = 'Fremdlabor',
  Fremdrechnungen = 'Fremdrechnungen',
  Krankenkassen = 'Krankenkassen',
  KV = 'KV',
  KVBriefe = 'KVBriefe',
  PatEingang = 'PatEingang',
  Patient = 'Patient',
  Pdf = 'Pdf',
  PraxisAdressen = 'PraxisAdressen',
  PrivatAdressen = 'PrivatAdressen',
  Rechenzentren = 'Rechenzentren',
  Rechnungen = 'Rechnungen',
  TeamviewerEinverstaendis = 'TeamviewerEinverstaendis',
}

export interface IR4CFileInfo {
  fileName: string;
  data: any;
}
