import { IBotMode } from './bot';
import { IDBModel } from './db';
import { PvsType } from './settings';

// ===================== activity

export interface IUserActivity {
  user: string;
  cid: string;
  time: Date;
  token: string;
  info: string;
  visible: boolean;
  url: string;
}

export interface ITask {
  type: 'IMPORT' | 'TRIGGER';
  id: string;
  cid: string;
  createdAt: Date;
  startedAt?: Date;
  notify?: { email: string; slack?: string };
  info?: IImportInfo;
  protocol?: IImportProtocolComplete;
  aborted?: boolean;
  rawData?: boolean;
  lastProgress?: number;
}

export interface IImportInfo {
  id: string;
  cid: string;
  runId: string;
  incremental: boolean;
  lastchange: number;
  lastchangeDoctosync?: Date;
  started: Date;
  ended?: Date;
  duration?: number;
  pvs: PvsType;
  workerHost?: string;
  workerId?: string;
}

export interface IImportProtocolModelDetails {
  patids: { [patid: string]: string[] };
  started?: Date;
  size: number; // bytes transferred
  count: number; // how many rows
  ended?: Date;
  duration?: number; // duration in seconds
}

export interface IImportProtocolDetails {
  [model: string]: IImportProtocolModelDetails;
}

// use this only for db model and ui
export interface IImportProtocolComplete {
  readonly info: IImportInfo;
  readonly botProtocol: IImportProtocol;
  readonly collectorProtocol: IImportProtocol;
  readonly bot: IImportProtocolDetails;
  readonly collector: IImportProtocolDetails;
  readonly stepTimes: any;
  readonly errors: any;
  readonly errorCount: number;
  setBotProtocol(botProtocol: IBotImportProtocol): void;
  getRohPatIds(): string[];
  getAllPatIds(): string[];
  end(): void;
}

export interface IImportProtocol {
  readonly errors: string[];
  readonly patidsCount: number;
  modelInfo: IImportProtocolDetails;
  stepTimes: { [step: string]: Date };
  addNonModelPatids(patids: string[]): void;
  getPatIds(): string[];
  getModelExtIds(model: string): string[];
  addError(msg: string): void;
  setMeta(key: string, data: any): void;
  getMeta(key: string): any;
  modelStart(protocolTableName: string): void;
  addPatid(protocolTableName: string, patid?: string, extids?: any[]): void;
  addPatids(model: string, patids: string[]): void;
  addSize(protocolTableName: string, size: number): void;
  modelEnd(protocolTableName: string): void;
  getSize: () => number;
}

export interface IBotImportProtocol extends IImportProtocol {
  readonly info: IImportInfo;
  readonly patids: string[];
  readonly importStartTime: number;
}

export interface IClientImport {
  cid: string;
  importid: string;
  incremental: boolean;
  started: Date;
  finished: Date | undefined;
  duration: number | undefined;
  errors: string[];
  errorcount: number;
  patcount: number;
  sizeinbytes: number;
  workerhost: string | undefined;
  workerid: string | undefined;
}

export interface IClientImportExtended extends IClientImport {
  ageinseconds: number;
  praxisName: string;
  praxisKurzName: string;
  tags: string[];
  package: string; // from lizenzinfo
  pvs: PvsType;
  pvsversion: string;
  dbhost: string;
  veraltet: boolean;
  lastchange: number;
  botmode: IBotMode;
  queueIdx: number;
}

export interface IClientImportExtendedUI extends IClientImportExtended {
  running: IImportState;
}

export interface IClientImportInfosDetail {
  incremental?: IClientImportExtended;
  full?: IClientImportExtended;
  pvs: PvsType;
  dbhost: string;
  lastchange: number;
}

export enum WorkerStates {
  IDLE = 'IDLE',
  WORKING = 'WORKING',
}

export interface IWorkerStatus {
  id: string;
  host: string;
  pid: number;
  created: Date;
  state: WorkerStates;
  task?: ITask;
  progress?: number;
  taskCount?: number;
  rss?: number;
  lastContact: Date;
}

export interface IClientsImportInfos {
  [cid: string]: IClientImportInfosDetail;
}

export interface IClientImportErrors {
  cid: string;
  importid: string;
  incremental: boolean;
  errors: string[];
  started: Date;
}

export interface ICurrentImportStateResult {
  importe: IClientsImportInfos;
  lasterrors: IClientImportErrors[];
  workers: { worker: IWorkerStatus[] };
  stats: {
    inc: { stunde: Date; anzahl: number }[];
    full: { stunde: Date; anzahl: number }[];
  };
}

/**
 * SYNC ORDER WITH ImportSteps array AND DERIVEDDATACALCULATOR !!!!!!!!!!
 */
export enum IMPORT_STEPS {
  NOT_RUNNING = 0,
  STARTED = 1,
  RAW_DATA_RETRIVAL = 2,
  DOCTOSYNC_DATA_RETRIVAL = 3,
  RAW_DATA_INDEXING = 4,
  RAW_DATA_TRANSFORMATION = 5,
  DELETIONS = 6,
  LEISTUNGSBLOECKE = 7,
  KONTROLLIERT = 8,
  PSI = 9,
  TERMINE = 10,
  HKP = 11,
  PATIENTEN = 12,
  RECHNUNGEN = 13,
  UMSATZ = 14,
  ABRECHNUNG = 15,
  KZVABRECHNUNG = 16,
  PA = 17,
  HEUTE = 18,
  TERMINBUCH = 19,
  BENCHMARK = 20,
  FINISHED = 21,
}

export interface IImportStep {
  step: IMPORT_STEPS;
  key: string;
  name: string;
}

export interface IImportLockInfo extends IImportInfo {
  name: string;
  retryCount: number;
  step: IMPORT_STEPS;
  stepTimes: { [step: string]: Date };
}

export interface ILastImportProgress {
  lastFullImport?: IImportCollectionInfo;
  lastFullImportFailed?: IImportCollectionInfo;
  lastIncrementalImport?: IImportCollectionInfo;
  lastIncrementalImportFailed?: IImportCollectionInfo;
}

export interface IImportProgress extends ILastImportProgress {
  cid: string;
  incrementalInfo: IImportState;
  fullInfo: IImportState;
  lastFullImportStats?: IImportStats;
  lastIncrementalImportStats?: IImportStats;
}

export interface IImportCounts {
  count: number;
  all: number;
  selected: number;
  rawData: number;
  derivedData: number;
  unfinished: number;
  running: number;
}

export interface IImportTypeStat {
  running: boolean;
  lastFinish: Date;
  counts: IImportCounts;
}

export interface IAllImportInfo {
  imports: IImportProgress[];
  fullQueue: IImportQueueInfo[];
  incQueue: IImportQueueInfo[];
}

export interface IImportStats {
  count: number;
  avg: number;
  stddev: number;
  median: number;
}

export interface IImportCollectionInfo {
  importid: string;
  duration: number;
  patcount: number;
  errorcount: number;
  createdAt: string;
  incremental: boolean;
  importinfo: IImportInfo;
}

export enum CLIENT_IMPORT_STATE {
  SELECTED = 'SELECTED', // import is selected to start, this lasts from selction until bot has started
  ASSIGNING = 'ASSIGNING', // import is wwaiting to be assigned to worker
  RAWDATA = 'RAWDATA', // import is assigned to worker and collecting rawdata
  DERIVEDDATA = 'DERIVEDDATA', // import raw data is finished and is doing derived data calc
  FINISHING = 'FINISHING', // worker has ended, but we need to finish some db stuff in collector
}

export interface IImportState {
  cid: string;
  state: CLIENT_IMPORT_STATE;
  lastStateChange: Date;
  iinfo?: IImportInfo;
  error?: string;
  step: IMPORT_STEPS;
  stepTimes: { [step: string]: Date };
  scripts: { [script: string]: Date | undefined };
}

export interface IImportQueueInfo {
  cid: string;
  dbhost: string | undefined;
  lastFinished?: Date;
  ageInSeconds: number;
  lastchange: number;
  lastDuration: number | undefined;
}

export interface IPercentage {
  count: number;
  percentage: number;
}

export interface ITagesInfo {
  arbeitszeit: number; // minuten
  pausenzeit: number; // minuten
  ausfallzeit: number; // minuten
}

export interface ITerminDauerInfoDetail {
  durchschnittProTermin: number;
  durchschnittProPatient: number;
  anzahlPatienten: number;
  anzahlTermine: number;
  summe: number;
}

export interface IInternalTerminZeitInfo {
  date: any; // cannot be moment as moment should not be in types
  patid: string;
  warteZimmer: number;
  behandlungsZimmer: number;
  behandlung: number;
  termin: number;
}

export interface IExcelExport {
  fileName: string;
  sheetName: string;
  data: any[];
}

export interface ISort {
  field: string;
  dir: string;
}

export type DB_FUNCTION_SCOPE_TYPE = 'root' | 'client';

export const PRIOR14DBS = ['9', '10', '11', '12', '13'];
export const AFTER14DBS = ['14', '15', '16'];
export const ALLDBS = [...PRIOR14DBS, ...AFTER14DBS];

export interface IDBFunction {
  name: string | undefined;
  compatibleDbVersions: string[];
  drop: (schema?: string) => string | undefined;
  create: (schema?: string) => string | undefined;
  execute: (db: any, schema?: string) => Promise<void>;
  scope?: DB_FUNCTION_SCOPE_TYPE;
}

export interface IDBView extends IDBFunction {}

export interface IDBAggregate extends IDBFunction {
  aggregateFunction: (schema?: string) => string | undefined;
}

export interface IDBTrigger extends IDBFunction {
  triggerFunction: (schema?: string) => string | undefined;
}

// compatible to sequelize query
export interface IDBConnection4Queries {
  query(sql: string): Promise<any>;
}

export enum NewsTags {
  BLOG = 'blog', // show on website blog
  METRICS = 'metrics',
  R4C = 'r4c',
}

export interface INews {
  name: string;
  nameCaOverride?: string;
  description: string;
  teaser?: string; // used in r4c, stay short!!
  fileName: string;
  fileNameCaOverride?: string;
  date: Date;
  draft?: boolean;
  tags: NewsTags[];
}

export interface IUserNews extends IDBModel {
  key: string;
  username: string;
  cid: string;
  wann?: Date;
}

// sync with rohDatenTypMap in enums.ts
export type RohdatenColumnType = 'text' | 'float' | 'integer' | 'date' | 'time' | 'timetz' | 'blob' | 'bigint' | 'json';

export interface IRohDatenColumnConfig {
  type: RohdatenColumnType;
  expression: string;
}

export interface IRohDatenColumnsConfig<T = IRohDatenColumnConfig> {
  [column: string]: T;
}

export interface IColumnConfig {
  name: string;
  order?: 'ASC' | 'DESC';
}

export interface IRohDatenIndexConfig {
  [idxname: string]: IColumnConfig[];
}

export interface IRohDatenTableConfig<T = IRohDatenColumnConfig> {
  columns: IRohDatenColumnsConfig<T>;
  extid: string; // which field to use for extid
  lastchange?: string; // which field to use for lastchange
  indices: IRohDatenIndexConfig;
  chunkSize: number;
  transitory?: IRohDatenColumnsConfig<T>; // fields that dont exist on the clients db but we create those fields for local converter
  filter?: string; // additional where statement
  filterBy?: string; // field that is used to filter the data for unlocked praxis/mandanten
  filterTimeBy?: string; // additional from statement
  join?: string; // additional join statement
}

export interface IRohDatenTablesConfig<T = IRohDatenColumnConfig> {
  [table: string]: IRohDatenTableConfig<T>;
}

export interface IRohDatenDBConfig<T = IRohDatenColumnConfig> {
  [db: string]: IRohDatenTablesConfig<T>;
}

export interface IFeedback {
  date: Date;
  email: string;
  cid: string;
  url: string;
  navigator: string;
  rosehelper: string;
  text: string;
  meta: string;
}

export interface IAbrechnungsvorschlaegeQueryParams {
  from?: string;
  to?: string;
  seit?: string;
}
