"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var types_1 = require("@rose/types");

var behandlerStore_1 = require("@/state/behandlerStore");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  name: 'ZaPzrCell',
  data: function data() {
    return {
      params: {},
      values: {},
      id: null,
      behType: null,
      zaOptions: [{
        value: types_1.ZAType.partner,
        name: 'Inhaber/Partner'
      }, {
        value: types_1.ZAType.angestellt,
        name: 'Angestellter Zahnarzt'
      }, {
        value: types_1.ZAType.assistent,
        name: 'Vorbereitungsassistent'
      }],
      pzrOptions: [{
        value: types_1.PZRType.zfa,
        name: 'ZMF/ZFA'
      }, {
        value: types_1.PZRType.zmpzmf,
        name: 'ZMP'
      }, {
        value: types_1.PZRType.dh,
        name: 'DH'
      }]
    };
  },
  computed: {
    topLeistungen: function topLeistungen() {
      var _behandlerStore_1$beh;

      return this.params.data && ((_behandlerStore_1$beh = behandlerStore_1.behandlerStore.getters.map[this.params.data]) === null || _behandlerStore_1$beh === void 0 ? void 0 : _behandlerStore_1$beh.topLeistungen);
    },
    behandlerType: {
      get: function get() {
        var _editSettingsStore_1$, _editSettingsStore_1$2, _editSettingsStore_1$3, _editSettingsStore_1$4, _editSettingsStore_1$5, _editSettingsStore_1$6, _editSettingsStore_1$7;

        if (this.params.data && (_editSettingsStore_1$ = editSettingsStore_1.editSettingsStore.getters.behandlerSettings) !== null && _editSettingsStore_1$ !== void 0 && (_editSettingsStore_1$2 = _editSettingsStore_1$[this.params.data]) !== null && _editSettingsStore_1$2 !== void 0 && _editSettingsStore_1$2.zahnarzt && !((_editSettingsStore_1$3 = editSettingsStore_1.editSettingsStore.getters.behandlerSettings[this.params.data]) !== null && _editSettingsStore_1$3 !== void 0 && _editSettingsStore_1$3.pzr)) {
          return types_1.BehandlerTyp.ZA;
        }

        if (this.params.data && !((_editSettingsStore_1$4 = editSettingsStore_1.editSettingsStore.getters.behandlerSettings) !== null && _editSettingsStore_1$4 !== void 0 && (_editSettingsStore_1$5 = _editSettingsStore_1$4[this.params.data]) !== null && _editSettingsStore_1$5 !== void 0 && _editSettingsStore_1$5.zahnarzt) && (_editSettingsStore_1$6 = editSettingsStore_1.editSettingsStore.getters.behandlerSettings) !== null && _editSettingsStore_1$6 !== void 0 && (_editSettingsStore_1$7 = _editSettingsStore_1$6[this.params.data]) !== null && _editSettingsStore_1$7 !== void 0 && _editSettingsStore_1$7.pzr) {
          return types_1.BehandlerTyp.PZR;
        }

        return null;
      },
      set: function set(value) {
        if (value === types_1.BehandlerTyp.ZA && this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.updateBehandlerInfo({
            id: this.params.data,
            value: {
              zahnarzt: true,
              pzr: false
            }
          });
        }

        if (value === types_1.BehandlerTyp.PZR && this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.updateBehandlerInfo({
            id: this.params.data,
            value: {
              zahnarzt: false,
              pzr: true
            }
          });
        }
      }
    },
    selectedPosition: {
      get: function get() {
        if (this.behandlerType === types_1.BehandlerTyp.ZA) {
          var _editSettingsStore_1$8, _editSettingsStore_1$9;

          return this.params.data && ((_editSettingsStore_1$8 = editSettingsStore_1.editSettingsStore.getters.behandlerSettings) === null || _editSettingsStore_1$8 === void 0 ? void 0 : (_editSettingsStore_1$9 = _editSettingsStore_1$8[this.params.data]) === null || _editSettingsStore_1$9 === void 0 ? void 0 : _editSettingsStore_1$9.zahnarztTyp);
        }

        if (this.behandlerType === types_1.BehandlerTyp.PZR) {
          var _editSettingsStore_1$10, _editSettingsStore_1$11;

          return this.params.data && ((_editSettingsStore_1$10 = editSettingsStore_1.editSettingsStore.getters.behandlerSettings) === null || _editSettingsStore_1$10 === void 0 ? void 0 : (_editSettingsStore_1$11 = _editSettingsStore_1$10[this.params.data]) === null || _editSettingsStore_1$11 === void 0 ? void 0 : _editSettingsStore_1$11.pzrTyp);
        }

        return undefined;
      },
      set: function set(value) {
        if (this.behandlerType === types_1.BehandlerTyp.ZA && this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.updateBehandlerInfoTyp({
            id: this.params.data,
            value: {
              zahnarztTyp: value,
              pzrTyp: undefined
            }
          });
        }

        if (this.behandlerType === types_1.BehandlerTyp.PZR && this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.updateBehandlerInfoTyp({
            id: this.params.data,
            value: {
              zahnarztTyp: undefined,
              pzrTyp: value
            }
          });
        }
      }
    }
  },
  methods: {
    getAutoConfiguredClass: function getAutoConfiguredClass() {
      var _editSettingsStore_1$12, _editSettingsStore_1$13;

      return this.params.data && (_editSettingsStore_1$12 = editSettingsStore_1.editSettingsStore.getters.behandlerSettings) !== null && _editSettingsStore_1$12 !== void 0 && (_editSettingsStore_1$13 = _editSettingsStore_1$12[this.params.data]) !== null && _editSettingsStore_1$13 !== void 0 && _editSettingsStore_1$13.grund ? 'auto-configured' : '';
    }
  }
});