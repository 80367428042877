import { IRoseMemoryCache } from './diverse';
import { IFactoringPatientKomplett } from './factoring';
import { ITabletExtended } from './tablet';
import { IZimmerbelegung } from './zimmerbelegung';

export interface EventSetupConfig<DataType, Params extends readonly string[]> {
  config: EventStreamConfig<DataType, Params>;
  cache?: IRoseMemoryCache<DataType>;
  getInitialData?: (cid: string, params?: StringArrayToObject<Params>) => Promise<DataType>;
}

export type StringArrayToObject<T extends readonly string[]> = {
  [K in T[number]]: string;
};

export interface EventStreamConfig<DataType, Params extends readonly string[]> {
  path: string;
  params: Params; // cid is always included
  authType: 'apikey' | 'jwt';
}

const tablets: EventStreamConfig<ITabletExtended[], []> = {
  path: '/tablets',
  params: [],
  authType: 'apikey',
};

const anamneseResponses: EventStreamConfig<number, []> = {
  path: '/anamneseResponses',
  params: [],
  authType: 'apikey',
};

const r4cActivePatient: EventStreamConfig<IFactoringPatientKomplett, ['r4cUuid']> = {
  path: '/r4cActivePatient',
  params: ['r4cUuid'],
  authType: 'apikey',
};

export const r4cServerEvents = {
  tablets,
  r4cActivePatient,
  anamneseResponses,
};

const zimmerBelegung: EventStreamConfig<IZimmerbelegung[], []> = {
  path: '/zimmerbelegung',
  params: [],
  authType: 'jwt',
};

export const uiServerEvents = {
  zimmerBelegung,
};
