"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.index-of.js");

require("core-js/modules/es.array.splice.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var BehandlerChip_vue_1 = require("@/components/BehandlerChip.vue");

exports["default"] = (0, vue_1.defineComponent)({
  name: 'AutoCompleteBehandler',
  components: {
    BehandlerChip: BehandlerChip_vue_1["default"]
  },
  props: {
    behandlerList: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      "default": 'Behandler'
    },
    disabled: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      behandlerSelection: []
    };
  },
  methods: {
    remove: function remove(item) {
      var index = this.behandlerSelection.indexOf(item.extid);

      if (index >= 0) {
        this.behandlerSelection.splice(index, 1);
      }
    },
    save: function save() {
      this.$emit('save', this.behandlerSelection);
      this.behandlerSelection = [];
    }
  }
});