"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var HeuteGruppenFilterPopup_vue_1 = require("@/components/settings/Heute/HeuteGruppenFilterPopup.vue");

var behandlerStore_1 = require("@/state/behandlerStore");

var staticDataMetricsStore_1 = require("@/state/staticDataMetricsStore");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    HeuteGruppenFilterPopup: HeuteGruppenFilterPopup_vue_1["default"]
  },
  data: function data() {
    return {};
  },
  computed: {
    termingruppenNamen: function termingruppenNamen() {
      return staticDataMetricsStore_1.staticDataMetricsStore.getters.termingruppenNamen;
    },
    zahnaerzteNamen: function zahnaerzteNamen() {
      return behandlerStore_1.behandlerStore.getters.zahnaerzte.map(function (v) {
        return v.displayText;
      });
    },
    pzrNamen: function pzrNamen() {
      return behandlerStore_1.behandlerStore.getters.pzr.map(function (v) {
        return v.displayText;
      });
    },
    pzrNoTerminBehandler: function pzrNoTerminBehandler() {
      var _editSettingsStore_1$;

      return (_editSettingsStore_1$ = editSettingsStore_1.editSettingsStore.getters.pzrNoTerminBehandler) === null || _editSettingsStore_1$ === void 0 ? void 0 : _editSettingsStore_1$.map(function (v) {
        return v.displayText;
      });
    },
    zaNoTerminBehandler: function zaNoTerminBehandler() {
      var _editSettingsStore_1$2;

      return (_editSettingsStore_1$2 = editSettingsStore_1.editSettingsStore.getters.zaNoTerminBehandler) === null || _editSettingsStore_1$2 === void 0 ? void 0 : _editSettingsStore_1$2.map(function (v) {
        return v.displayText;
      });
    },
    gfempfangtg: {
      get: function get() {
        var _editSettingsStore_1$3;

        return (_editSettingsStore_1$3 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$3 === void 0 ? void 0 : _editSettingsStore_1$3.empfang.termingruppen.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'empfang',
          filter: 'termingruppen',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gfempfangza: {
      get: function get() {
        var _editSettingsStore_1$4;

        return (_editSettingsStore_1$4 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$4 === void 0 ? void 0 : _editSettingsStore_1$4.empfang.za.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'empfang',
          filter: 'za',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gfempfangpzr: {
      get: function get() {
        var _editSettingsStore_1$5;

        return (_editSettingsStore_1$5 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$5 === void 0 ? void 0 : _editSettingsStore_1$5.empfang.pzr.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'empfang',
          filter: 'pzr',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gprophylaxetg: {
      get: function get() {
        var _editSettingsStore_1$6;

        return (_editSettingsStore_1$6 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$6 === void 0 ? void 0 : _editSettingsStore_1$6.prophylaxe.termingruppen.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'prophylaxe',
          filter: 'termingruppen',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gprophylaxeza: {
      get: function get() {
        var _editSettingsStore_1$7;

        return (_editSettingsStore_1$7 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$7 === void 0 ? void 0 : _editSettingsStore_1$7.prophylaxe.za.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'prophylaxe',
          filter: 'za',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gprophylaxepzr: {
      get: function get() {
        var _editSettingsStore_1$8;

        return (_editSettingsStore_1$8 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$8 === void 0 ? void 0 : _editSettingsStore_1$8.prophylaxe.pzr.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'prophylaxe',
          filter: 'pzr',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpzahnarzttg: {
      get: function get() {
        var _editSettingsStore_1$9;

        return (_editSettingsStore_1$9 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$9 === void 0 ? void 0 : _editSettingsStore_1$9.zahnarzt.termingruppen.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'zahnarzt',
          filter: 'termingruppen',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpzahnarztza: {
      get: function get() {
        var _editSettingsStore_1$10;

        return (_editSettingsStore_1$10 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$10 === void 0 ? void 0 : _editSettingsStore_1$10.zahnarzt.za.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'zahnarzt',
          filter: 'za',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpzahnarztpzr: {
      get: function get() {
        var _editSettingsStore_1$11;

        return (_editSettingsStore_1$11 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$11 === void 0 ? void 0 : _editSettingsStore_1$11.zahnarzt.pzr.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'zahnarzt',
          filter: 'pzr',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpabrechnungtg: {
      get: function get() {
        var _editSettingsStore_1$12;

        return (_editSettingsStore_1$12 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$12 === void 0 ? void 0 : _editSettingsStore_1$12.abrechnung.termingruppen.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'abrechnung',
          filter: 'termingruppen',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpabrechnungza: {
      get: function get() {
        var _editSettingsStore_1$13;

        return (_editSettingsStore_1$13 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$13 === void 0 ? void 0 : _editSettingsStore_1$13.abrechnung.za.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'abrechnung',
          filter: 'za',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpabrechnungpzr: {
      get: function get() {
        var _editSettingsStore_1$14;

        return (_editSettingsStore_1$14 = editSettingsStore_1.editSettingsStore.getters.gruppenfilter) === null || _editSettingsStore_1$14 === void 0 ? void 0 : _editSettingsStore_1$14.abrechnung.pzr.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'abrechnung',
          filter: 'pzr',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    }
  },
  methods: {
    updateGruppenFilter: function updateGruppenFilter(data) {
      editSettingsStore_1.editSettingsStore.commit.updateGruppenFilter(data);
    }
  }
});