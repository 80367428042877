import { PACKAGES } from './features';
import { PvsType } from './settings';

export enum MultiClientLoginType {
  NONE = 'NONE',
  PAID = 'PAID',
  FREE = 'FREE',
}

export enum OnboardingStatusType {
  NEW = 'NEW', // neuer eintrag
  PENDING = 'PENDING', // eintrag in bearbeitung
  ACCEPTED = 'ACCEPTED', // eintrag akzeptiert, kundeen daten etc angelegt
  ACTIVATED = 'ACTIVATED', // kunde wird beim zahlungsdienstleister angelegt
  IGNORE = 'IGNORE', // kunde ignorieren
}

export enum OnboardingProcessStatusType {
  UNBEARBEITET = 'unbearbeitet',
  VERSANDT = 'versandt',
  ANGEKOMMEN = 'angekommen',
  VERBINDUNG_OK = 'verbindungok',
  ERSTSCHULUNG = 'erstschulung',
}

export interface MetricsOnboardingStripeInfo {
  anzahlbehandler?: number;
  anzahlprophylaxe?: number;
  multiclientlogin: MultiClientLoginType;
  abrechnungsstart: Date;
  savesetupfee: boolean;
  gutscheincode?: string;
}

export interface IOnboardingEntry extends MetricsOnboardingStripeInfo {
  // lizenzInfo
  id?: string;
  paket: PACKAGES;
  abrechnungsstart: Date;
  pvs: PvsType;

  // praxisDaten
  auftraggebername: string;
  auftraggebervorname: string;
  auftraggeberemail: string;
  auftraggeberstraße: string;
  auftraggeberstadt: string;
  auftraggeberplz: string;

  praxisname: string;
  praxisnamekurz: string;
  praxistel: string;
  praxisstraße: string;
  praxisstadt: string;
  praxisplz: string;

  // adminBenutzer
  adminbenutzername: string;
  adminbenutzervorname: string;
  adminbenutzeremail: string;

  // anprechpartner
  ansprechpartnername: string;
  ansprechpartnervorname: string;
  ansprechpartneremail: string;
  ansprechpartnertel: string;

  // zahlungsdaten
  kontoinhaber: string;
  kontoinhaberEmail: string;
  payment_method_id: string;

  // kommentar
  kommentar: string;

  empfehlung: string;
  uebergabetermin: Date;
  prozessstatus: OnboardingProcessStatusType;
  prozessstatushistory?: IOnboardingProcessStatusHistory;
  helpdeskid: string;
  group: number | null;

  status: OnboardingStatusType;
  tags: string[];
  cid: string;
}

export interface IOnboardingEntryExtended extends IOnboardingEntry {
  stripe?: {
    id: string;
    match: 'profile' | 'email';
  };
}

export interface IOnboardingProcessStatusHistory {
  [key: string]: {
    timestamp: Date;
    user: string;
  };
}

export interface IOnboardingDaten {
  paket?: string;
  lizenzInfo?: IOnboardingLizenzInfo | null;
  rechenzentrenData?: any;
  praxisDaten?: IOnboardingPraxisdaten | null;
  adminBenutzer?: IOnboardingAdminBenutzer | null;
  ansprechpartner?: IOnboardingTechnischerAnprechpartner | null;
  zahlungsdaten?: IOnboardingZahlungsdaten | null;
  kommentar?: string | null;
}

export interface IOnboardingDatenWithCid extends IOnboardingDaten {
  cid: string;
}

export interface IOnboardingPraxisdaten {
  auftraggeber:
    | {
        name?: string;
        vorname?: string;
        email?: string;
        straße?: string;
        stadt?: string;
        plz?: string;
      }
    | undefined;
  praxis: {
    name: string;
    nameKurz: string;
    tel: string;
    straße?: string;
    stadt?: string;
    plz?: string;
    licence?: string;
  };
  empfehlung: string;
}

export interface IOnboardingLizenzInfo {
  paket: IMetricsPaket;
  behandlerCount: number;
  prophylaxeCount: number;
  saveSetupFee: boolean;
  gutscheinCode: string;
  pvs: PvsType;
}

export interface IMetricsPaket {
  id: PACKAGES;
  text1: string;
  text2: string;
  name: string;
  preis: number;
  zusatzLizenzPreis: number;
  promonat: string;
  class: string;
  min: number;
  max: number;
}

export interface IR4cFactoringPaket {
  id: string;
  price: number;
  name: string;
  nameSpecial?: string;
}

export interface IR4cDocumentsPaket {
  id: string;
  pricePerMonth: number;
  pricePerDocument: number;
  name: string;
}

export interface IOnboardingAdminBenutzer {
  name: string;
  vorname: string;
  email: string;
}

export interface IOnboardingZahlungsdaten {
  kontoinhaber: string;
  kontoinhaberEmail: string;
  payment_method_id: string;
}

export interface IOnboardingTechnischerAnprechpartner {
  name: string;
  vorname: string;
  email: string;
  tel: string;
}

export const metricsPakete: IMetricsPaket[] = [
  {
    text1: 'Der',
    text2: 'Einstieg.',
    name: 'White',
    id: PACKAGES.WHITE,
    preis: 69,
    zusatzLizenzPreis: 59,
    promonat: 'zzgl. MwSt. / Monat',
    class: 'rosePaketWhite',
    min: 1,
    max: 3,
  },
  {
    text1: 'Um mehr ',
    text2: 'zu entdecken.',
    name: 'Red',
    id: PACKAGES.RED,
    preis: 189,
    zusatzLizenzPreis: 59,
    promonat: 'zzgl. MwSt. / Monat',
    class: 'rosePaketRed',
    min: 3,
    max: 5,
  },
  {
    text1: 'Der 360°  ',
    text2: 'Rundumblick.',
    name: 'Black',
    id: PACKAGES.BLACK,
    preis: 299,
    zusatzLizenzPreis: 49,
    promonat: 'zzgl. MwSt. / Monat',
    class: 'rosePaketBlack',
    min: 5,
    max: 9,
  },
  {
    text1: 'Für die,',
    text2: 'die alles wollen!',
    name: 'Diamond',
    id: PACKAGES.DIAMOND,
    preis: 499,
    zusatzLizenzPreis: 0,
    promonat: 'zzgl. MwSt. / Monat',
    class: 'rosePaketDiamond',
    min: 9,
    max: 100,
  },
];
