export enum BemaGozType {
  UNKNOWN = -1,
  GOZ = 0,
  BEMA = 1,
  VERLANGEN = 2,
}

export enum KassePrivatType {
  UNKNOWN,
  PRIVAT,
  KASSE,
}

export enum UIKassePrivatType {
  ALLE = 'ALLE',
  PRIVAT = 'PRIVAT',
  KASSE = 'KASSE',
}

export enum GeschlechtType {
  UNKNOWN = 0,
  MALE = 1,
  FEMALE = 2,
  NEUTRAL = 3,
}

export enum BerechnungsType {
  UNBEKANNT = 0,
  KASSE = 1,
  PRIVAT = 2,
  SELBSTZAHLER = 3,
}

export enum AbrechnungsArt {
  UNBEKANNT = 0,
  // GOZ
  GOZ_ALLG = 100,
  GOZ_KONS = 101,
  GOZ_CHIR = 102,
  GOZ_IMPL = 103,
  GOZ_PA = 104,
  GOZ_FA = 105,
  GOZ_ZE = 106,
  GOZ_KFO = 107,
  GOZ_PZR = 108,

  // BEMA
  BEMA_ZE = 200,
  BEMA_PA = 201,
  BEMA_KFO = 202,
  BEMA_KONSCHIR = 203,
  BEMA_KIEFERB = 204,
  BEMA_IP = 205,
  BEMA_BESUCH = 206,
}

export enum PAType {
  OHNE = 0,
  PROPHYLAXE = 1,
  PAV = 2,
  PA = 3,
  PAN = 4,
  UPT = 5,
}

export enum HKPTerminType {
  OHNE = 0,
  ZE = 1,
  PA = 2,
  IMPLA = 3,
  PRIVAT = 4,
  ALLE = 5,
  KFO = 6,
  KBR = 7,
}

export enum HKPPARPhaseType {
  UNBEKANNT = 0,
  BEANTRAGT = 1, // Antrag gestellt, einzige Leistung auf Antrag 4
  GENEHMIGT = 2, // Antrag genehmigt
  PAR_BEGONNEN = 3, // Leistung MHU, ATG, <90% AIT
  PAR_ABGESCHLOSSEN = 4, // Leistung AIT > 90%
  PAR_BEFUNDEVALUATION = 5, // Leistung BEVa
  PAR_CHIRURGIE = 6, // Leistung CPT
  PAR_CHIRURGIE_BEFUNDEVALUATION = 7, // Leistung BEVb
  UPT = 8, // UPT Leistungen
  BEENDET = 9, // abgeschlossen, durhcgeführt oder verworfen oder so
}

export enum TerminStatusTypeAlt {
  OK = 0,
  NICHTERSCHIENEN = 1,
  KURZFRISTIG_ABGESAGT = 2,
  FRISTGERECHT_ABGESAGT = 3,
  VIRTUELL = 99,
}

export enum TerminStatusType {
  UNKNOWN = -1000,
  OFFEN = -1,
  OHNE = 0,

  OK = 1,
  NICHTERSCHIENEN = 2,
  KURZFRISTIG_ABGESAGT = 3,
  FRISTGERECHT_ABGESAGT = 4,
  PRAXIS_HAT_ABGESAGT = 5,

  KONTROLLIERT_BEHANDLER_OK = 11,
  KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN = 12,
  KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT = 13,
  KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT = 14,
  KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT = 15,

  KONTROLLIERT_ABRECHNUNG_OK = 21,
  KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN = 22,
  KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT = 23,
  KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT = 24,
  KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT = 25,

  KONTROLLIERT_ALLE_OK = 31,
  KONTROLLIERT_ALLE_NICHTERSCHIENEN = 32,
  KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT = 33,
  KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT = 34,
  KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT = 35,

  VIRTUELL = 999,
}

export enum ZahlungsArtType {
  NONE = 0,
  BAR = 1,
  SCHECK = 2,
  EC = 4,
  ERSTATTUNG = 8,
  BANK1 = 16,
  BANK2 = 32,
}

export enum RecallType {
  KEIN_RECALL = 0,
  NAECHSTER_RECALLTERMIN = 1,
  RECALLTERMIN_ERINNERT = 2,
  RECALLTERMIN_VEREINBART = 3,
  RECALLTERMIN_ERSCHIENEN = 4,
}

export enum HKPAbzurechnenFlag {
  NICHT_ABRECHNEN = 0,
  EINGESETZT = 1 << 0,
  LEISTUNGEN_ERBRACHT = 1 << 1,
  PAR_ABGESCHLOSSEN = 1 << 2,
  PAR_HAENGT = 1 << 3,
}

export enum HKPStatusType {
  UNKNOWN = -1,
  EINGABE = 0,
  ALTERNATIV = 1,
  ÜBERARBEITEN = 2,
  ABGELEHNT = 3,
  TROTZABLEHNUNGANGENOMMEN = 4,
  GENEHMIGT = 5,
  DURCHGEFUEHRT = 6,
  VERWORFEN = 7,
  SOFORTABRECHNUNG = 12,
}

export enum HKPSubStatusType {
  UNKNOWN = -1,
  EINGABE_IN_ARBEIT = 101,
  EINGABE_ZU_PRUEFEN = 102,
  EINGABE_GEPRUEFT = 103,
  EINGABE_BEI_PATIENT = 104,
  EINGABE_BEI_KASSE = 105,
  EINGABE_BEI_GUTACHTER = 106,

  ABGELEHNT_KASSE = 301,
  ABGELEHNT_PATIENT = 302,
  ABGELEHNT_GUTACHTER = 303,

  GENEHMIGT_PATIENT_OHNE_TERMIN = 501,
  GENEHMIGT_PATIENT_HAT_TERMIN = 502,
  GENEHMIGT_IN_ARBEIT = 503,
  GENEHMIGT_EINGEGLIEDERT_ERBRACHT = 504,

  VERWORFEN_ALTERNATIVE_BEHANDLUNG_DURCHGEFUEHRT = 701,
  VERWORFEN_ZU_TEUER = 702,
  VERWORFEN_NICHT_VERSTANDEN = 703,
  VERWORFEN_PATIENT_WARTET_AUF_ANRUF = 704,
  VERWORFEN_FALSCHE_LOESUNG = 705,
  VERWORFEN_PATIENT_FUEHLT_SICH_BEVORMUNDET = 706,
  VERWORFEN_BEHANDLER_UNSYMPATISCH = 707,
  VERWORFEN_UNBEHAGLICHES_PRAXISAMBIENTE = 708,
  VERWORFEN_ZAHNARZTWECHSEL_OHNE_ANGABE = 709,
  VERWORFEN_KEINE_ANGABE = 710,
}

export enum HKPArtType {
  UNBEKANNT = -1,
  PRIVAT = 0,
  PRIVAT_FA = 1,
  PRIVAT_PA = 2,
  PRIVAT_IMPL = 3,
  PRIVAT_GTR = 4,
  PRIVAT_ZE = 5,
  KASSE_ZE_SPEZIAL = 6, // unfall? KASSE ZE???
  ___KVART_PA = 7,
  ___KVART_KIEFERBRUCH = 8,
  ___KVART_KFO_VERL1 = 10,
  ___KVART_KFO_VERL2 = 11,
  ___KVART_KFO_RETENTION = 12,
  KASSE_KFO2 = 9,
  PRIVAT_KFO = 20, // v1 ??
  PRIVAT_KFO2 = 21, // v2
  ___KVART_ZE98 = 50,
  ___KVART_ZE_ALTERNATIV = 51,
  ___KVART_ZE_UNFALL = 52,
  KASSE_ZE = 53,
  ___KVART_ZE_2005BGS = 54,
  KASSE_KFO = 60,
  KASSE_PA = 70,
  KASSE_PA_ERGAENZUNG = 71,
  KASSE_KIEFERBRUCH = 80,
  KASSE_PAR = 85,
  PASTATUS_P22A = 86,
  EBZ_KBR = 100,
  EBZ_KGL = 101,
  EBZ_KFO = 102,
  EBZ_PAR = 103,
  EBZ_PMB = 104,
  EBZ_ZER = 105,
  KASSE_KFO_NACHTRAG = 10000,
}

export const HKPArtTypeAlle = [
  HKPArtType.PRIVAT,
  HKPArtType.PRIVAT_FA,
  HKPArtType.PRIVAT_PA,
  HKPArtType.PRIVAT_IMPL,
  HKPArtType.PRIVAT_GTR,
  HKPArtType.PRIVAT_ZE,
  HKPArtType.KASSE_ZE_SPEZIAL,
  // HKPArtType.___KVART_PA,
  // HKPArtType.___KVART_KIEFERBRUCH,
  // HKPArtType.___KVART_KFO_VERL1,
  // HKPArtType.___KVART_KFO_VERL2,
  // HKPArtType.___KVART_KFO_RETENTION,
  HKPArtType.KASSE_KFO2,
  HKPArtType.PRIVAT_KFO,
  HKPArtType.PRIVAT_KFO2,
  // HKPArtType.___KVART_ZE98,
  // HKPArtType.___KVART_ZE_ALTERNATIV,
  // HKPArtType.___KVART_ZE_UNFALL,
  HKPArtType.KASSE_ZE,
  // HKPArtType.___KVART_ZE_2005BGS,
  HKPArtType.KASSE_KFO,
  HKPArtType.KASSE_PA,
  HKPArtType.KASSE_PA_ERGAENZUNG,
  HKPArtType.KASSE_KIEFERBRUCH,
  HKPArtType.KASSE_PAR,
  HKPArtType.PASTATUS_P22A,
  HKPArtType.EBZ_KBR,
  HKPArtType.EBZ_KGL,
  HKPArtType.EBZ_KFO,
  HKPArtType.EBZ_PAR,
  HKPArtType.EBZ_PMB,
  HKPArtType.EBZ_ZER,
  HKPArtType.KASSE_KFO_NACHTRAG,
];

export enum GozArtType {
  ALTEGOZ = 1,
  AERZTLICHELEISTUNGEN = 2, // alle mit ä, aus der goä
  GOZ = 3,
}

export enum LaborArtType {
  PRIVAT_BEB = 0, // Bundeseinheitliche Benennungsliste
  KASSE_BEL = 1, // Bundeseinheitliches Leistungsverzeichnis
}

export enum TerminbuchTerminStatusType {
  KEIN_TERMIN, // 0 kein Termin
  TERMIN_OHNE_STATUS, // 1 Termin ohne Status
  TERMIN_AUSFALL, // 2 Termin Ausfall
  TERMIN_OK, // 3 Termin OK
}

export enum TerminbuchVerfuegbarkeitStatusType {
  KEINE_DATEN, // 0 keine Daten
  ABWESENHEIT, // 1 Urlaub / Fortbildung / Krank => ABWESENHEIT
  TERMIN_NICHT_ANBIETEN, // 2 Termin nicht anbieten
  TERMIN_ANBIETEN, // 3 Termin anbieten
}

export enum RoentgenQuelleType {
  ROENTGENBUCH = 'R',
  LEISTUNG = 'L',
}

export enum EingabeKontrollArtType {
  BEHANDLER = 1,
  ABRECHNUNG = 2,
}

export enum RechnungsArtType {
  OHNE_KV = 0, // Rechnung ohne Kassen KV Bezug
  ZA_KASSE_KV = 1, // Rechnung auf Basis eines zahnärztlichen Kassen HKP
  KFO_KASSE_KV = 3, // Rechnung auf Basis eines KFO Kassen HKP
  UNFALL_KASSE_KV = 4, // Rechnung an Unfallkasse
}

export enum PendingFileUploadStatus {
  NEW = 'new',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
}

// from our data definition to postgres types
export enum RoseDBType {
  SERIAL = 'serial',
  BIGSERIAL = 'bigserial',
  STRING = 'text',
  STRINGARRAY = 'text[]',
  TIME_TZ = 'timestamp with time zone',
  TIME_NO_TZ = 'timestamp without time zone',
  DATE = 'date',
  DATEARRAY = 'date[]',
  BOOLEAN = 'boolean',
  NUMBER = 'double precision',
  NUMBERARRAY = 'double precision[]',
  JSON = 'jsonb',
  HOURBITMASKTYPE = 'bit varying(60)',
  BLOB = 'bytea',
  BLOBARRAY = 'bytea[]',
  BIGINT = 'bigint',
  INT = 'int',
}

export interface RohDatenTypMapType {
  [key: string]: RoseDBType;
}

export const rohDatenTypMap: RohDatenTypMapType = {
  text: RoseDBType.STRING,
  float: RoseDBType.NUMBER,
  integer: RoseDBType.INT,
  bigint: RoseDBType.BIGINT,
  date: RoseDBType.DATE,
  time: RoseDBType.TIME_NO_TZ,
  timetz: RoseDBType.TIME_TZ,
  bool: RoseDBType.BOOLEAN,
  blob: RoseDBType.STRING,
  json: RoseDBType.JSON,
};

export enum LeistungsZurechnungType {
  GOZ_UNKNOWN = 'GU',
  GOZ_V = 'V',
  GOZ_Ä = 'GAE',
  GOZ_L = 'L',
  GOZ_A = 'A',
  GOZ_B = 'B',
  GOZ_C = 'C',
  GOZ_D = 'D',
  GOZ_E = 'E',
  GOZ_F = 'F',
  GOZ_G = 'G',
  GOZ_H = 'H',
  GOZ_J = 'J',
  GOZ_K = 'K',
  BEMA_UNKNOWN = 'BU',
  BEMA_1 = '1',
  BEMA_2 = '2',
  BEMA_3 = '3',
  BEMA_4 = '4',
  BEMA_5 = '5',
  BEMA_Ä = 'BAE',
  BEMA_PAUSCHAL = 'BP',
  BEMA_UNFALLKASSEBG = 'BUKBG',
}

export enum KartenStatus {
  PRIVAT = 1,
  KASSE_AKTUELL = 2,
  KASSE_ABGELAUFEN = 3,
  KASSE_INDEFINIT = 4,
}

export enum Units {
  EURO = '€',
  EUROROUNDED = 'eur_rounded',
  PROZENTE = '%',
  PROZENTEROUNDED = '%_rounded',
  TERMINE = 'T',
  PATIENTEN = 'P',
  IMPLANTATE = 'I',
  STUNDEN = 'H',
  STUNDENROUNDED = 'h',
  DAYS = 'D',
  DAYSROUNDED = 'D_rounded',
  MINUTEN = 'min',
  EURO_PRO_STUNDE = '€/h',
  EURO_PRO_TERMIN = '€/T',
  EURO_PRO_ZIMMER = '€/Z',
  FLOAT = 'n',
  INTEGER = 'int',
  ENTFERNUNG = 'km',
  TEXT = 's',
  DATUM = 'd',
  TIME = 't',
  NONE = '',
}

export enum VerrechnungsArtType {
  NORMAL = 0,
  ERSTATTUNG = 1,
  GUTSCHRIFT = 2,
}

export enum EGKEingabeArtType {
  UNKNOWN = 0,
  KARTENLESEGERAET = 1,
  MANUELL = 2,
  ANDERERVERSICHERUNGSNACHWEIS1 = 4,
  ANDERERVERSICHERUNGSNACHWEIS2 = 5,
  ANDERERVERSICHERUNGSNACHWEIS3 = 7,
}

export enum PAGradType {
  UNKNOWN = '-',
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum ZAType {
  partner = 'partner',
  angestellt = 'angestellt',
  assistent = 'assistent',
}

export enum BehandlerTyp {
  ZA = 'za',
  PZR = 'pzr',
}

export enum PZRType {
  zfa = 'zfa',
  zmpzmf = 'zmpzmf',
  dh = 'dh',
}

export enum FeiertageTyp {
  OSTERN_DIFF = 'OSTERN_DIFF',
  FESTES_DATUM = 'FESTES_DATUM',
  WOCHENTAG_NACH_FESTEM_DATUM = 'WOCHENTAG_NACH_FESTEM_DATUM',
  EINMALIG = 'EINMALIG',
}

export enum ImportTyp {
  FULL = 'full',
  INCREMENTAL = 'incremental',
}

export enum WoAnbietenTyp {
  GKV_PKV = 'GKV_PKV',
  PKV = 'PKV',
  GKV = 'GKV',
}

export enum CharlyChargeFlag {
  MAT_VERBRAUCHART_UNBEKANNT = 0x00000000,
  MAT_VERBRAUCH_CHARGE = 0x00000001,
  MAT_VERBRAUCH_HALTBAR_BIS = 0x00000002,
  MAT_VERBRAUCH_PORTIONEN = 0x00000004,
  MAT_LAGER_PRO_EINHEIT = 0x00000008,
  MAT_CHARGE_RG_DRUCK = 0x00000010,
  MAT_HALTBARKEIT_RG_DRUCK = 0x00000020,
  MAT_BESTANDTEILE_RG_DRUCK = 0x00000040,
  MAT_BESTANDFUEHRUNG_LAGER = 0x00000080,
  MAT_KONFORMITAET = 0x00000100,
  MAT_GOLDBUCH = 0x00000200,
  MAT_TAGESPREIS = 0x00000400,
  MAT_HERSTELLER_RG_DRUCK = 0x00000800,
  MAT_NICHTBERECHNEN = 0x00001000,
  MAT_TROTZNICHTBERDRUCK = 0x00002000,
  MAT_STUECKVERBRAUCH = 0x00004000,
  MAT_PORTIONSVERBRAUCH = 0x00008000,
  MAT_MENGENVERBRAUCH = 0x00010000,
  MAT_BC_DRUCK_MATEINGANG = 0x00020000,
}

export enum TOKEN_PURPOSE {
  NEW_USER = 'new-user',
  USER_INVITATION = 'user-invitation',
  USER_TOKEN_FOR_ADMIN = 'user-token-for-admin',
  LOGIN = 'login',
  PAPO_LOGIN = 'papo-login',
  RESET_PASSWORD = 'reset-password',
  INTERNAL_COMMUNICATION = 'internal-communication',
}

export enum PVS_PORT {
  CHARLY = 5432,
  CHARLY_NEW = 5433,
  Z1 = 1433,
  EVIDENT = 6430,
}

export enum TabletTaskType {
  EWE_TASK = 'ITabletTaskInfoEwe',
  DOCUMENT_TASK = 'ITabletTaskInfoDocument',
  QR_CODE_TASK = 'ITabletTaskInfoQrCode',
  ANAMNESE_TASK = 'ITabletTaskInfoAnamnese',
  UPLOAD_TASK = 'ITabletTaskInfoUpload',
}

export enum PAREreignisTyp {
  UNKNOWN = 'UNKNOWN',
  TERMIN_OHNE_LEISTUNGEN = 'TERMIN_OHNE_LEISTUNGEN',
  TERMIN_MIT_LEISTUNGEN = 'TERMIN_MIT_LEISTUNGEN',
  HKPSTATUS = 'HKPSTATUS',
  ENDEPANBEHANDLUNGSZEITMARKER = 'ENDEPANBEHANDLUNGSZEITMARKER',
  ENDECPTBEHANDLUNGSZEITMARKER = 'ENDECPTBEHANDLUNGSZEITMARKER',
  UPTTERMINZEITMARKERSTART = 'UPTTERMINZEITMARKERSTART',
  UPTTERMINZEITMARKERENDE = 'UPTTERMINZEITMARKERENDE',
  UPTGMARKER = 'UPTGMARKER',
  ENDMARKER = 'ENDMARKER',
  MOREUPTS = 'MOREUPTS',
  MISSINGPA = 'MISSINGPA',
  MISSINGBEV = 'MISSINGBEV',
  MISSINGUPT = 'MISSINGUPT',
  TODAYMARKER = 'TODAYMARKER',
  CPTNURANTRAG = 'CPTNURANTRAG',
}

export enum UPTEventType {
  NONE = 'NONE', // no upt event
  PRIVAT = 'PRIVAT', // private upt, zukunft & vergangenheit: upt-termin außerhalb des upt intervalls, vergangenheit: upt-termin ohne upt-leistungen
  TERMIN = 'TERMIN', // ordentlich geplanter upt-termin
  TERMINNACHENDE = 'TERMINNACHENDE', // ordentlich geplanter upt-termin nach ende des letzten upt intervalls
  LEISTUNGEN = 'LEISTUNGEN', // ordentlich geplanter termin (muss nicht upt termin sein!!) mit upt-leistungen
}

export enum PAREreignisPhaseTyp {
  PAR = 'PAR',
  CPT = 'CPT',
  UPT = 'UPT',
}

export enum PAREreignisFehlerLevel {
  NONE = 'NONE',
  WARNUNG = 'WARNUNG',
  FEHLER = 'FEHLER',
}

export enum PAREreignisFehlerTyp {
  NONE = 'NONE',
  ABRECHNUNG = 'ABRECHNUNG',
  KEIN_GENEHMIGTER_HKP = 'TERMINIERUNG_ZUKUNFT',
  KEIN_ROENTGEN = 'KEIN_ROENTGEN',
  KEINE_UPT_GEPLANT = 'KEINE_UPT_GEPLANT',
  UPT_FRISTVERLETZUNG = 'UPT_FRISTVERLETZUNG',
  UPT_ENTFALLEN = 'UPT_ENTFALLEN',
  KEIN_BEV_TERMIN = 'KEIN_BEV_TERMIN',
  BEV_FRISTVERLETZUNG = 'BEV_FRISTVERLETZUNG',
  KEINE_BEV_GEPLANT = 'KEINE_BEV_GEPLANT',
  KEIN_PA_TERMIN_GEPLANT = 'KEIN_PA_TERMIN_GEPLANT',
  TERMIN_OHNE_PA_KONFIG = 'TERMIN_OHNE_PA_KONFIG',
  CPT_PLANUNG = 'CPT_PLANUNG',
}

export enum PAREreignisGruppeTyp {
  UNKNOWN = 'UNKNOWN',
  VORBEHANDLUNG = 'VORBEHANDLUNG',
  HAUPTBEHANDLUNG = 'HAUPTBEHANDLUNG',
  NACHBEHANDLUNG_BEVA = 'NACHBEHANDLUNG_BEVA',
  REEVALUATION_AIT = 'REEVALUATION_AIT', // reev ait
  CPT = 'CPT',
  REEVALUATION_CPT = 'REEVALUATION_CPT', // reev cpt
  UPT_I = 'UPT_I',
  UPT_II = 'UPT_II',
  UPT_III = 'UPT_III',
  UPT_IV = 'UPT_IV',
  UPT_V = 'UPT_V',
  UPT_VI = 'UPT_VI',
  UPT_VII = 'UPT_VII',
  UPT_VIII = 'UPT_VIII',
  UPT_IX = 'UPT_IX',
  UPT_X = 'UPT_X',
}
