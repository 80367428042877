import { RoseDBType } from './enums';

export interface IDBModelBase {
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IDBModel extends IDBModelBase {
  id?: number;
}

export interface IPoJo {
  extid: string;
}

export interface ICreateInfo extends IPoJo {
  erstelltAm: Date;
  erstelltVon: string;
}

export interface IChangeInfo extends ICreateInfo {
  geaendertAm: Date;
  geaendertVon: string;
}

export interface IPostgresCollectionIndexConfig {
  name: string;
  unique: boolean;
  fields: string[];
  indextype?: 'GIN';
}

export interface IPatsAndDaysAndBehandler {
  patid: string;
  tage: string[];
  behandler: string[];
}

export enum QueryTypes {
  SELECT = 'SELECT',
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
  BULKUPDATE = 'BULKUPDATE',
  BULKDELETE = 'BULKDELETE',
  DELETE = 'DELETE',
  UPSERT = 'UPSERT',
  VERSION = 'VERSION',
  SHOWTABLES = 'SHOWTABLES',
  SHOWINDEXES = 'SHOWINDEXES',
  DESCRIBE = 'DESCRIBE',
  RAW = 'RAW',
  FOREIGNKEYS = 'FOREIGNKEYS',
}

export interface IBaseDBService {
  defineAndSyncModel(cid: string, modelinfo: IDBModelsMeta, tableName: string, addIndices: boolean): Promise<void>;
  doesClientTableExist(cid: string, tablename: string): Promise<boolean>;
  dropTable(cid: string, tablename: string): Promise<void>;
  dropTableSqlRaw(tableName: string): string;
  switchTables(cid: string, dbModels: IDBModels): Promise<string[]>;
  createIndices(cid: string, dbModels: IDBModels, prefixModels: boolean, concurrent?: boolean): Promise<void>;
  getIndices(cid: string): Promise<IDBIndex[]>;
  removeIndices(cid: string, indices: IDBIndex[]): Promise<void>;
  removeModelTables(cid: string, dbModels: IDBModels, prefixModels: boolean): Promise<string[]>;
  removeTables(cid: string, tableNames: string[], prefixModels: boolean): Promise<string[]>;
  // DO NOT USE
  runSQL(cid: string, sql: string, queryType: QueryTypes, replacements?: any, transaction?: any): Promise<any>;
}

export type DemoModeType =
  | 'hide'
  | 'randomfirstname'
  | 'randomlastname'
  | 'randomstring'
  | 'randomstringarray'
  | 'randomnumber'
  | 'randomcurrency'
  | 'empty'
  | 'randomhkp'
  | 'randomnotiz';

export interface IColumnOptions {
  type: RoseDBType;
  comment?: string;
  primaryKey?: boolean;
  notNull?: boolean;
  autoIncrement?: boolean;
  defaultValue?: any;
  demoMode?: DemoModeType;
  roseReferencePath?: string; // model.field of reference
  enum?: any;
  unique?: boolean | string;
}

export interface IModelAttributes {
  [name: string]: IColumnOptions;
}

export interface IDBModelsMetaMixins {
  name?: DBModelName;
  comment?: string;
  features?: string[];
  indices?: ICollectionIndexConfig[];
  attributes: IModelAttributes;
  importOptions?: IImportOptions;
  priority?: 'mandatory' | 'semimandatory' | 'optional'; // mandatory: ohna da geht es nicht, semimandatory: zweitranging umszusetzen, optional: kann ggf wegfallen ohne große probleme
}

export interface IDBModelsMeta extends IDBModelsMetaMixins {
  name: DBModelName;
  comment: string;
}

export interface IImportOptions {
  queryChunkSize: {
    charly?: number;
    z1?: number;
    z1v2?: number;
    evident?: number;
  };
  live?: boolean;
  full?: boolean;
}

export interface IDBModels {
  [modelname: string]: IDBModelsMeta;
}

export interface IDBModelsMixins {
  [modelname: string]: IDBModelsMetaMixins;
}

export interface ICollectionIndexConfig {
  fields: { [name: string]: number };
  options?: {
    unique?: boolean;
    indextype?: 'GIN';
  };
}

export type DBModelName = string;

export interface IPostgresVersionBase {
  major: string;
  minor: string;
  patch: string;
}

export interface IPostgresVersion extends IPostgresVersionBase {
  raw: string;
  short: string; // major.minor.patch
}

export interface IDBLocksInfo {
  blocked_pid: number;
  blocked_user: string;
  blocking_pid: number;
  blocking_user: string;
  blocked_statement: string;
  blocking_statement: string;
  db: string;
  blocked_started: Date;
  // waitstart: Date;
}

export interface IDBIndex {
  table: string;
  index: string;
}

export interface ILoeschungen extends IPoJo {
  model: string;
  loeschid: string;
  patid: string;
  loeschdatum: Date;
  lastchange: number;
}
