export let paRoentgenLeistungen = ['ä925c', 'ä925d', 'ä935d', 'ä5004'];
export let sixPackRoentgenLeistungen = ['ä5000'];
export let opgRoentgenLeistungen = ['Ä935d', 'Ä5004'];
export let paRoentgenLeistungenSet = new Set(paRoentgenLeistungen);
export let sixPackRoentgenLeistungenSet = new Set(sixPackRoentgenLeistungen);
export let opgRoentgenLeistungenSet = new Set(opgRoentgenLeistungen);

export function isPaRoentgen(nummer: string, anzahl: number): boolean {
  if (!nummer) {
    return false;
  }
  const ln = nummer.toLocaleLowerCase();
  if (paRoentgenLeistungenSet.has(ln)) {
    return true;
  }
  if (sixPackRoentgenLeistungenSet.has(ln) && anzahl >= 6) {
    return true;
  }
  return false;
}
