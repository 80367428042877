"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var types_1 = require("@rose/types");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {},
      IMfaType: types_1.IMfaType
    };
  },
  computed: {
    mfaType: function mfaType() {
      var _this$params$data;

      return (_this$params$data = this.params.data) === null || _this$params$data === void 0 ? void 0 : _this$params$data.mfatype;
    },
    mfaEnforced: function mfaEnforced() {
      var _this$params$data2;

      return (_this$params$data2 = this.params.data) === null || _this$params$data2 === void 0 ? void 0 : _this$params$data2.mfaenforced;
    }
  },
  moorunted: function moorunted() {},
  methods: {}
});