"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var HelpTooltip_vue_1 = require("@/components/HelpTooltip.vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    HelpTooltip: HelpTooltip_vue_1["default"]
  },
  props: {
    name: {
      type: String,
      "default": null
    },
    propname: String,
    sortfield: String,
    direction: String,
    help: String
  },
  methods: {
    sort: function sort() {
      console.log('sort', this.propname, this.sortfield, this.direction, this.sortfield === this.propname);
      var dir = 'desc';

      if (this.sortfield === this.propname) {
        switch (this.direction) {
          case 'asc':
            dir = undefined;
            break;

          case 'desc':
            dir = 'asc';
            break;

          case undefined:
            dir = 'desc';
            break;
        }
      }

      this.$emit('sort-changed', {
        field: this.propname,
        direction: dir
      });
    }
  }
});