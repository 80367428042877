import {
  BemaGozType,
  RoentgenQuelleType,
  GozArtType,
  RecallType,
  KartenStatus,
  PAType,
  HKPTerminType,
  GeschlechtType,
  TerminStatusType,
} from './enums';
import { IPoJo, IChangeInfo, ICreateInfo, IDBModel } from './db';
import { ITablet } from './tablet';
import { IImportInfo } from './interfaces';
import { IBotMode } from './bot';
import { IPARCaseInfo } from './pa';
import {
  IRechnungBezahlt,
  IRechnungenHonorarDetails,
  IRechnungenLaborDetails,
  IRechnungenMaterialDetails,
} from './rechnung';

export interface IRoseMemoryCache<V> {
  set: (key: string, value: V, ttlInSeconds?: number) => void;
  get: (key: string) => V;
  has: (key: string) => boolean;
  del: (key: string) => number;
  getTtl: (key: string) => number;
  keys: () => string[];
  flushAll: () => void;
}

/**
 * entweder es gibt keinen eintrag, dann ist der status ok oder im feld status steht OK
 */
export interface IStatus {
  id: string;
  message: string;
  solutionmessage: string;
  start: Date;
  end: Date;
  status: string;
  type: string;
  data: any;
}

export interface ILizenzen extends IPoJo {
  extid: string;
  computername: string;
  loginname: string;
  loginid: string;
  lizenzhash: string;
  logindate: Date;
  tomanyuser: boolean;
  lastchange: number;
}

export interface IBemaGozStammdaten extends IPoJo {
  kuerzel: string;
  nummer: string;
  abrechnungsnummer: string;
  ref: string;
  text: string;
  typ: BemaGozType | string;
  gozart: GozArtType;
  veraltet: boolean;
}

export interface IBemaGozStammdatenWithHeaders extends IBemaGozStammdaten {
  header: string;
}

export interface IAnamnese {
  patid: string;
  daten: Date[];
}

export interface IRoentgen extends IPoJo {
  patid: string;
  tag: Date | undefined;
  kuerzel: string;
  nummer: string;
  anzahl: number;
  quelle: RoentgenQuelleType;
}

export interface IBehandlerTerminGruppe extends IPoJo {
  name: string;
  veraltet: boolean;
  pzr: boolean;
  behandler: string[];
}

export interface IKarte extends IPoJo {
  patid: string;
  lesedatum: Date;
  ungueltig: boolean;
  versicherungGueltigAb: Date;
  versicherungGueltigBis: Date;
}

export interface INotiz extends IChangeInfo, IPoJo {
  extid: string;
  patid: string;
  tag: Date;
  termin: boolean;
  behblatt: boolean;
  patblatt: boolean;
  dauerhaft: boolean;
  notiz: string;
  erledigt: boolean;
  erstelltVonName?: string;
}

export interface IToDos extends IPoJo, ICreateInfo {
  text: string;
  patid: string;
  tag: Date;
  empfaengerId: string;
  erledigt: boolean;
}

export interface IZimmer extends IPoJo {
  name: string;
  position?: number;
  veraltet: boolean;
}

export interface IEingabekontrolle extends IPoJo {
  // extid is terminid
  patid?: string;
  tag: Date;
  behandler: string;
  behandlertag: Date;
  behandlerdauer: number;
  abrechnung: string;
  abrechnungtag: Date;
  abrechnungdauer: number;
}

export interface IParams<T> extends IPoJo {
  extid: string;
  daten: T[];
}

export interface IStichwortParam {
  name: string;
  farbe: string;
  kuerzel?: string; // z1
  index?: number; // z1
  bgfarbe?: string; // set dynamically in ui
}

export interface IStichwortParamMap {
  [name: string]: IStichwortParam; // map name to farbe
}

export interface IStichwortParamMapZ1V2 {
  [index: number]: IStichwortParam;
}

export interface IParamsKZV {
  id: string;
  name: string;
}

export interface IAllParams {
  patextrapagefields?: string[];
  stichworte?: IStichwortParam[];
  stichworteMap?: IStichwortParamMap;
  sternwerte?: string[];
  schnittstellen?: string[];
  pvsversion?: string;
  dbversion?: string;
  lizenz?: string;
  arbeitsplaetze?: number;
  praxisname?: string;
  kzv?: IParamsKZV;
  laborbel?: number;
  multirecall?: boolean;
}

export interface IPatExtraPage extends IPoJo {
  patid: string;
  daten: any;
}

export interface ITermineMeta extends IPoJo {
  extid: string;
  tag: Date;
  patid: string;
  extids: string[];
  assistenzids: string[];
  lastchange: number;
}

export interface IPsi {
  checks: IRohPsi[];
  abgerechnet: (Date | undefined)[];
}

export interface IServerStatus {
  ok: boolean;
  dbok: boolean;
  message: string;
}

export interface IRohPsi extends IPoJo {
  extid: string;
  patid: string;
  tag: Date | undefined;
  s1: string;
  s2: string;
  s3: string;
  s4: string;
  s5: string;
  s6: string;
  bad: boolean;
  worst: number;
}

export interface ITermingruppe extends IPoJo {
  farbe: string; // r,g,b
  bezeichnung: string;
}

export interface ITerminart extends IPoJo {
  bezeichnung: string;
  recalltermin: boolean;
  veraltet: boolean;
  gruppe: ITermingruppe;
  honorar_privat: number;
  zus_honorar_privat: number;
  honorar_kasse: number;
  zus_honorar_kasse: number;
  vorgabe: number;
  lastchange: number;
}

export interface ITerminartPa extends ITerminart {
  paTyp: PAType;
}

export interface ITerminartHkp extends ITerminart {
  hkpTyp: HKPTerminType;
}

export interface IBema extends IPoJo {
  extid: string;
  anzahl: number;
  behandler: string;
  jahr: number;
  monat: number;
  teilmonat: number;
  abrechnungsdatum: Date;
  gesamtbetrag: number;
}

export interface IPatientResolvedInfo {
  extid: string;
  titel?: string;
  name: string;
  vorname: string;
  veraltet: boolean;
  privat: boolean;
  geburtstag: Date;
  geschlecht: GeschlechtType;
  par?: IPARCaseInfo[];
  telefon?: string[];
  email?: string;
}

export interface IRezept {
  extid: string;
  patid: string;
  behandler: string;
  tag: Date;
  befreit: boolean;
}

export interface IArbeitsUnfaehigkeitsBescheinigung {
  extid: string;
  patid: string;
  behandler: string;
  tag: Date;
}

export interface IRecallInfo {
  multirecall: boolean;

  recallpzrwochen: number;
  recallpzrmodus: RecallType;
  naechsterpzrrecall: Date;

  recall01wochen: number;
  recall01modus: RecallType;
  naechster01recall: Date;

  recallimplwochen: number;
  recallimplmodus: RecallType;
  naechsterimplrecall: Date;

  recallzewochen: number;
  recallzemodus: RecallType;
  naechsterzerecall: Date;

  recallpawochen: number;
  recallpamodus: RecallType;
  naechsterparecall: Date;

  recallendowochen: number;
  recallendomodus: RecallType;
  naechsterendorecall: Date;
}

export interface IVersionsInfo {
  root: string;
  build: string;
  commitSha: string;
  datum: string;
  charly?: string;
}

export interface R4CSocketMeta {
  sparkType: 'r4c';
  cid: string;
  computer: string;
  ctxid: string;
  user: string;
  uuid: string;
  versions: IVersionsInfo;
  userAgent: string;
  started: Date;
  isAdminHost: boolean;
  upgraded: boolean;
}

export interface ISocketMetaStats {
  r4cUpgradedCount: number;
  r4cUpgradedInfo: { [cid: string]: { upgraded: number; notUpgraded: number } };
  tabletUpgradedCount: number;
  r4cCount: number;
  tabletCount: number;
  socketCount: number;
  r4cVersions: { [sha: string]: { datum?: string; clients: string[] } };
  charlyVersions: { [charlyVersion: string]: { clients: string[] } };
  tabletVersions: { [sha: string]: { datum?: string; clients: string[] } };
  r4cUserAgents: { [ua: string]: string[] };
  r4cOS: {
    mac: string[];
    win: string[];
  };
  oldestR4c?: { client: string; started: Date };
  tabletUserAgents: { [ua: string]: string[] };
}

export interface IR4CClientSocketMeta {
  [cid: string]: R4CSocketMeta[];
}

export interface IR4CStats {
  r4cs: IR4CClientSocketMeta;
  r4cCount: number;
  r4cUpgradedCount: number;
}

export interface ITabletClientSocketMeta {
  [cid: string]: TabletSocketMeta[];
}

export interface ITabletStats {
  tabletSocketMetas: TabletSocketMeta[];
  tabletCount: number;
  tabletUpgradedCount: number;
}

export interface ISocketsMeta {
  r4cs: IR4CClientSocketMeta;
  tablets: ITabletClientSocketMeta;
  stats: ISocketMetaStats;
}

export interface TabletSocketMeta {
  sparkType: 'tablet';
  name: string;
  cid: string;
  tablet: ITablet;
  versions: IVersionsInfo;
  userAgent: string;
}

export interface IKarteInfo {
  status: KartenStatus;
  letzteLesung?: Date;
}

export interface IContact {
  cid: string;
  email: string;
  vorname: string;
  nachname: string;
  rolle: string;
  telefon: string;
}

export interface IRohStempel {
  extid: string | number;
  name1: string;
  name2: string;
  strasse: string;
  plz: string;
  ort: string;
  iban: string;
  aktiv: boolean;
  lastchange: number;
}

export interface IStatsDClient {
  increment: (counterName: string) => void;
  decrement: (counterName: string) => void;
  gauge: (counterName: string, value: number) => void;
  histogram: (counterName: string, durationInMs: number) => void;
}

export interface IBehandlerService {
  getProperty: (idOderKuerzel: string, field?: string, inputAsFallback?: boolean) => any;
}

export interface INewClientInfo {
  cid: string;
  praxisName: string;
  db: {
    host: string;
    port: number;
    database: string;
  };
  bot: {
    id: string;
    mode: IBotMode;
    ipInternal: string;
    ipExternal: string;
    subnetMask: string;
    sshTunnelPort: number;
    socketOnline: boolean;
    sshOnline: boolean;
  };
  import: {
    lastFullImport: IImportInfo | undefined;
    lastIncImport: IImportInfo | undefined;
    currentFullImport: IImportInfo | undefined;
    currentIncImport: IImportInfo | undefined;
  };
  user: {
    adminCount: number;
    count: number;
  };
}

export interface IMwStReportRechnungenRaw {
  tag: Date;
  honorar: number;
  eigenlabor: number;
  eigenlabormaterial: number;
  fremdlabor: number;
  material: number;
  bezahltdetails: IRechnungBezahlt[];
  honorardetails: IRechnungenHonorarDetails;
  labordetails: IRechnungenLaborDetails;
  materialdetails: IRechnungenMaterialDetails;
}

export interface IMwStReportInfo {
  mwstsatz: number;
  netto: number;
  mwst: number;
  brutto: number;
}

export enum MwStQuelleType {
  H = 'H',
  EL = 'EL',
  ELM = 'ELM',
  M = 'M',
  FL = 'FL',
}

export interface IMwStReportInfoExtended extends IMwStReportInfo {
  quelle: MwStQuelleType;
}

export interface IMwStReportGroupInfo {
  honorar: IMwStReportInfo[];
  eigenlabor: IMwStReportInfo[];
  eigenlabormaterial: IMwStReportInfo[];
  material: IMwStReportInfo[];
  fremdlabor?: IMwStReportInfo[];
}

export interface IMwStReportPartialResult {
  leistungen: IMwStReportGroupInfo | undefined;
  rechnungen: IMwStReportGroupInfo | undefined;
  eingang: any;
}

export interface IMwStReportResult {
  cid: string;
  referenzMonat: { jahr: number; monat: number };
  monat: {
    referenz: IMwStReportPartialResult | undefined;
    vorjahr: IMwStReportPartialResult | undefined;
  };
  jahr: {
    referenz: IMwStReportPartialResult | undefined;
    vorjahr: IMwStReportPartialResult | undefined;
  };
}

export interface IFaktorenReportResult {
  cid: string;
  entries: IFaktorenReportResultEntry[];
}

export interface IFaktorenReportResultEntry {
  nummer: string;
  avg: number;
  stddev: number;
  min: number;
  max: number;
  percentile25: number;
  percentile50: number;
  percentile75: number;
  anzahl: number;
  umsatz: number;
}

export interface IPzrPatsWithMissingFollowUps {
  patid: string;
  stammbehandlerid: string;
  prophylaxebehandlerid?: string;
  tag: Date;
  tage_seit_pzr_leistung: number;
  letzterpzrtermin_tag?: Date;
  letzterpzrtermin_bezeichnung?: string;
  letzterpzrtermin_art?: string;
  folgetermine: {
    extid: string;
    tag: Date;
    art: string;
    bezeichnung: string;
    status: TerminStatusType;
    patyp: PAType;
  }[];
}

export enum CharlyAnalyticsLicenceStatus {
  OK = 'ok',
  NOK = 'nok',
  DELETE = 'delete',
}

export interface ICharlyAnalyticsLicenceInfo {
  serialnumber: string;
  tenant: string;
  status: CharlyAnalyticsLicenceStatus;
}

export interface ITabletRegisterUrl {
  expiresAt: Date;
  ok: boolean;
  registerUrl: string;
}

export interface IR2CsvLicenceInfo {
  serialnumber: string;
  praxis: string;
  tenant: string;
  valid: boolean;
  counter: number;
  subscription?: string;
  validuntil?: Date;
}

export interface IR2CsvLicenceCheck {
  info: IR2CsvLicenceInfo;
  signature?: string;
}

export interface ISettingsPatchError {
  patchValidationFailed: true;
  settingsLastUpdate: string;
  operation: any;
}

export interface IR2CsvLicenceUsage {
  id?: number;
  serialnumber: string;
  praxis: string;
  ip: string;
  created: boolean;
  subscription?: string;
  licence: IR2CsvLicenceCheck;
}

// keep this a subset of AxiosRequestConfig
export interface ICharlySerializedRequest {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
  contentType?: string;
  data?: any;
}

export interface ICharlySerializedResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: ICharlySerializedRequest;
}

export type IProxiedCharlyRequestResult =
  | { success: true; response: ICharlySerializedResponse }
  | { success: false; error: any };

// VOTING
// VOTING

export interface IVotingFeature extends IDBModel {
  title: string;
  description: string;
  active: boolean;
}

export interface ITranslation extends IDBModel {
  text: string;
  translation: string;
  targetLanguage: string;
}

export interface IVotingVote extends IDBModel {
  email: string;
  feature: number;
  points: number;
}

export interface IMailSuppression {
  email: string;
  reason: 'HardBounce' | 'SpamComplaint' | 'ManualSuppression';
  origin: 'Recipient' | 'Customer' | 'Admin';
  created: Date;
}

export interface IDeletedMailSuppression {
  email: string;
  status: 'Failed' | 'Deleted';
  message: string;
}

export interface IPatientNameResolverOptions {
  limit?: number;
  withPAR?: boolean;
  withAddress?: boolean;
}

export interface IPatientNameResolverParams extends IPatientNameResolverOptions {
  ids: string[];
}

export type SortOrderType = 'asc' | 'desc' | undefined;

export interface IMetricsUsage {
  monat: Date;
  usage: number;
  behandlerinfo: {
    behandler: string;
    honorar: number;
    za: boolean;
    pzr: boolean;
    lcount: number;
    ltyp: string;
  }[];
}
