export * from './abrechnung';
export * from './constants';
export * from './featureConfig';
export * from './leistungen';
export * from './news';
export * from './onboarding';
export * from './rechenzentren';
export * from './timeItem';
export * from './utils/hkpUtils';
export * from './utils/paUtils';
export * from './utils/r4cUtils';
export * from './utils/util';
export * from './utils/xlsUtils';
export * from './validations';
export * from './vertragsstrings_from_md';
export * from './views';
export * from './windows1252';
export * from './benchmark.config.old/benchmark.config';
export * from './benchmark.config/benchmark.config';
export * from './i18n/i18n';
export * from './rose-webinare';

import * as versionsIn from './versions.json';

// @ts-ignore
export const versions = versionsIn.default as {
  root: string;
  build: string;
  commitSha: string;
  shortCommitSha: string;
  datum: string;
};
