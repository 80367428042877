import { IBenchmarkConfigItemRaw, ISettings, IStichwortParam } from '../../../types';
import { benchmarkConfigHKP } from './benchmark.config.hkp';
import { benchmarkConfigLeistungen } from './benchmark.config.leistungen';
import { benchmarkConfigPaPzr } from './benchmark.config.papzr';
import { benchmarkConfigPatienten } from './benchmark.config.patienten';
import { benchmarkConfigTermine } from './benchmark.config.termine';
import { benchmarkConfigTerminzeiten } from './benchmark.config.tz';
import { benchmarkConfigUmsatz } from './benchmark.config.umsatz';
import { benchmarkConfigRezepteHmvAuKt } from './benchmark.config.rezepteHmvAuKt';
import { benchmarkConfigBehandlungen } from './benchmark.config.behandlungen';
import { benchmarkConfigTBv2 } from './benchmark.config.tbv2';
import { benchmarkConfigStichworte } from './benchmark.config.stichworte';

export function benchmarkConfigRawOld(settings: ISettings, topStichworte: IStichwortParam[]): IBenchmarkConfigItemRaw {
  return {
    name: 'KPI',
    isHeader: true,
    isRootNode: true,
    childs: {
      behandeltePatienten: benchmarkConfigPatienten(),
      papzr: benchmarkConfigPaPzr(),
      _termine: benchmarkConfigTermine(),
      umsatz: benchmarkConfigUmsatz(),
      leistungen: benchmarkConfigLeistungen(),
      behandlungen: benchmarkConfigBehandlungen(settings.leistungen.implantat),
      hkp: benchmarkConfigHKP(),
      terminbuchv2: benchmarkConfigTBv2(),
      terminzeiten: benchmarkConfigTerminzeiten(),
      rezepteHmvAuKt: benchmarkConfigRezepteHmvAuKt(),
      stichworte: benchmarkConfigStichworte(topStichworte),
    },
  };
}
