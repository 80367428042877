import { IHKP } from './hkp';
import { IZeitSlotInfos, IZeitSlots } from './termin';
import {
  RoentgenQuelleType,
  GeschlechtType,
  TerminStatusType,
  RecallType,
  PAType,
  HKPStatusType,
  HKPAbzurechnenFlag,
  HKPTerminType,
  PAGradType,
} from './enums';
import {
  IRoentgen,
  INotiz,
  IKarte,
  IRohPsi,
  IPsi,
  IEingabekontrolle,
  IRecallInfo,
  IKarteInfo,
  IStichwortParam,
} from './diverse';
import { IPercentage } from './interfaces';
import { IFactoringPatientMetrics, IFactoringPatientRefHashes } from './factoring';
import { ILabelConfig, ISettings } from './settings';
import { IAbrechnungsVorschlag } from './abrechnung';
import { IPatientNameContainer, IPatientRisikoAnamnese } from './patient';
import { ILeistungsBlock } from './leistungsblock';
import { IPARCaseInfo } from './pa';

export interface IComplianceComplete {
  recent: ICompliance;
  all: ICompliance;
}

export interface ICompliance {
  termine: number;
  ok: number;
  abgesagtKurzfristig: number;
  abgesagtFristgerecht: number;
  abgesagtFristgerechtPraxis: number;
  nichtErschienen: number;
  ohne: number;
  percentage?: number;
  verspaetet: number;
}

export interface IOffeneRechnungsInfo {
  extid: string;
  tag: Date;
  patid: string;
  betrag: number;
  offenerBetrag: number;
  wochenueberfaellig: number;
}

export interface ILeistungenInfo {
  anzahl: number;
  betrag: number;
  ids: string[];
}

export enum HeuteStatusType {
  NONE = 0,
  V = 1,
  W = 2,
  Z = 3,
  B = 4,
  OK = 5,
  OK_KONTROLLIERT_BEHANDLER = 6,
  OK_KONTROLLIERT_ABRECHNUNG = 7,
  OK_KONTROLLIERT_ALLE = 8,
  NICHTERSCHIENEN = 9,
  KURZFRISTIG_ABGESAGT = 10,
  FRISTGERECHT_ABGESAGT = 11,
}

export type IconStatusType = 'eingabe' | 'abgelaufen' | 'genehmigt' | 'abzurechnen';

export interface IHeuteHKP {
  extid: string;
  tag: Date;
  artString: string;
  status: HKPStatusType;
  statusString: string;
  statusDatum: Date;
  behandler: string;
  ersteller: string;
  abgelaufen: boolean;
  iconStatus: IconStatusType;
  gesamt: number;
  gueltigkeitInMonaten: number;
  abzurechnen: HKPAbzurechnenFlag;
  abschlussderbehandlung: Date;
  kommentare: string[];
  verlaengertbis: Date;
  verlaengertam: Date;
  par: PAGradType;
  stadium: number;
  labelConfig?: ILabelConfig[];
  bemerkung?: string; // kommt über resolver
  kurztext?: string; // kommt über resolver
  kzvintern?: string; // kommt über resolver
}

export interface IHeuteLeistungInfo {
  id: string; // extid
  b: number; // betrag
  n: string; // nummer
  a: boolean; // abgerechnet
  bg: string; // bema/goz
}

export interface IHeuteTerminInfo {
  extid: string;
  tag: Date;
  behandler: string;
  angelegt: Date | undefined;
  terminstart: Date;
  termindauer: number;
  terminart: string | undefined;
  patyp: PAType | undefined;
}

export interface IHeuteRoentgen extends IRoentgen {
  quelle: RoentgenQuelleType;
}

export interface IHeuteRoentgenInfoAggregated {
  tag: Date;
  kuerzel: string;
  nummer: string;
  anzahlAusLeistungen: number;
  anzahlAusRoentgenbuch: number;
  ispa: boolean;
  error?: string;
}

export interface IHeuteRoentgenInfo {
  raw: IRoentgen[];
  aggregated: IHeuteRoentgenInfoAggregated[];
  hasErrors: boolean;
}

export enum IHeuteRechenzentrumInfoType {
  MINDERJAEHRIG,
  EWE_FEHLT,
  EWE_ABGELAUFEN,
  EWE_UNGUELTIG,
  EWE_MITGEGEBEN,
  EWE_ABGELEHNT,
  EWE_KEIN_RZ,
  AVA_FEHLT,
  AVA_UNGUELTIG, // special case if we dont know it better
  AVA_ABGELEHNT,
  AVA_ABGELAUFEN,
  AVA_VERBRAUCHT,
  AVA_OFFEN,
  AVA_GUELTIG,
  AVA_GUELTIG_KEIN_AUSFALLSCHUTZ,
  AVA_GUELTIG_50_PROZENT_AUSFALLSCHUTZ,
}

export function isRZStatusAlarm(status: IHeuteRechenzentrumInfoType, settings: ISettings): boolean {
  switch (status) {
    case IHeuteRechenzentrumInfoType.EWE_FEHLT:
    case IHeuteRechenzentrumInfoType.EWE_MITGEGEBEN:
    case IHeuteRechenzentrumInfoType.EWE_ABGELAUFEN:
    case IHeuteRechenzentrumInfoType.AVA_ABGELAUFEN:
    case IHeuteRechenzentrumInfoType.AVA_VERBRAUCHT:
    case IHeuteRechenzentrumInfoType.EWE_UNGUELTIG:
      return true;
    case IHeuteRechenzentrumInfoType.AVA_FEHLT:
      return settings.alarme.rechenzentrum?.conditions === 'AVA_FEHLT_ALARM';
    case IHeuteRechenzentrumInfoType.AVA_UNGUELTIG:
    case IHeuteRechenzentrumInfoType.MINDERJAEHRIG:
    case IHeuteRechenzentrumInfoType.EWE_ABGELEHNT:
    case IHeuteRechenzentrumInfoType.EWE_KEIN_RZ:
    case IHeuteRechenzentrumInfoType.AVA_ABGELEHNT:
    case IHeuteRechenzentrumInfoType.AVA_GUELTIG:
      return false;
  }
  return false;
}

export interface IHeuteAnamneseInfo {
  tag: Date;
  ok: boolean;
}

export enum HeuteRechenzentrumUIInfoResultType {
  NOHIGHLIGHT = 0,
  OK = 1,
  WARN = 2,
  MOREWARN = 3,
  NOK = 4,
}

export interface IHeuteRechenzentrumUIInfos {
  [rz: string]: IHeuteRechenzentrumUIInfo;
}

export interface IHeuteRechenzentrumUIInfo {
  text: string; // used as text
  title: string; // used as title
  result: HeuteRechenzentrumUIInfoResultType; // used for coloring
  typ: string; // just for debugging
  source: string; // just for debugging
  status: IHeuteRechenzentrumInfoType | undefined;
}

export interface IHeuteRechenzentrumInfo {
  eweDatum: Date | undefined;
  avaDatumGueltig: Date | undefined;
  avaDatumAnfrage: Date | undefined;
  status: IHeuteRechenzentrumInfoType | undefined;
  spezialFall?: string;
  spezialFallOK?: boolean;
  eweid?: number;
  avaid?: number;
  rzmeta?: string;
}

export interface IHeuteRechenzentrumInfosExtraPagesValues {
  [name: string]: IHeuteRechenzentrumInfo;
}

export interface IHeuteRechenzentrumInfosExtraPages {
  extraseiten?: IHeuteRechenzentrumInfosExtraPagesValues;
}

export interface IHeuteRechenzentrumInfosStichworte {
  stichworte?: IHeuteRechenzentrumInfo;
}

export interface IHeuteRechenzentrumInfosFactoringValues {
  [clientrz: string]: IHeuteRechenzentrumInfo | undefined;
}

export interface IHeuteRechenzentrumInfosFactoring {
  factoring?: IHeuteRechenzentrumInfosFactoringValues;
}

export type IHeuteRechenzentrumInfos = IHeuteRechenzentrumInfosStichworte &
  IHeuteRechenzentrumInfosExtraPages &
  IHeuteRechenzentrumInfosFactoring;

export interface IHeuteAlarmScoresBool {
  empfang?: boolean;
  prophylaxe?: boolean;
  zahnarzt?: boolean;
  abrechnung?: boolean;
}

export interface IHeuteAlarmScores {
  empfang: IPercentage;
  prophylaxe: IPercentage;
  zahnarzt: IPercentage;
  abrechnung: IPercentage;
}

export interface IHeuteAlarmConfig {
  empfang: number;
  prophylaxe: number;
  zahnarzt: number;
  abrechnung: number;
  conditions?: any;
  calculationType?: any;
}

export interface IHeuteAlarmData {
  scores: IHeuteAlarmScores;
  message: string;
  data?: any;
}

export interface IHeuteAlarme<T> {
  alter?: T;
  assistenz?: T;
  behandlerLeistungen?: T;
  email?: T;
  folgeTermine?: T;
  geschlecht?: T;
  heuteStatus?: T;
  hkp?: T;
  karte?: T;
  kontrolliert?: T;
  leistungen?: T;
  letzteAnamnese?: T;
  letzteNullEins?: T;
  letztePA?: T;
  letztePSI?: T;
  letztePZR?: T;
  naechstePZR?: T;
  nichtAbgerechneteLeistungen?: T;
  offeneRechnungen?: T;
  offeneTermine?: T;
  paRoentgen?: T;
  psiBefund?: T;
  naechsterpzrrecall?: T;
  rechenzentrum?: T;
  roentgen?: T;
  telefon?: T;
  versicherung?: T;
  vorschlaege?: T;
}

export interface IHeuteNichtAbgrechneteLeistungenDetail {
  honorar: number;
  material: number;
  eigenlabor: number;
  fremdlabor: number;
  gesamt: number;
}

export interface IHeuteNichtAbgrechneteLeistungen {
  gesamt: IHeuteNichtAbgrechneteLeistungenDetail;
  aktuell: IHeuteNichtAbgrechneteLeistungenDetail;
  ueberfaellig: IHeuteNichtAbgrechneteLeistungenDetail;
  verjaehrt: IHeuteNichtAbgrechneteLeistungenDetail;
}

export interface IHeuteLabelExtension {
  terminLabels: ILabelConfig[];
}

export interface IHeuteWithLabels extends IHeute, IHeuteLabelExtension {}

export interface IHeuteDatenSchutzInfo {
  vorhanden: boolean;
  tag: Date | undefined;
}

export interface IHeuteR4cAnamneseInfoEntryPending {
  id: string;
  taskType: string;
  shareId: string;
  emailSendId: string;
  recipientEmail: string;
  expiresAt: Date;
  createdAt: Date | undefined;
}

export interface IHeuteR4cAnamneseInfoEntryReviewable {
  id: number;
  createdAt: Date;
  questionnaireTitle: string;
}

export interface IHeuteR4cAnamneseInfoEntry {
  pending: IHeuteR4cAnamneseInfoEntryPending[];
  reviewable: IHeuteR4cAnamneseInfoEntryReviewable[];
}

export interface IHeuteR4cAnamneseInfo {
  [patid: string]: IHeuteR4cAnamneseInfoEntry;
}

export interface IHeute extends IZeitSlotInfos, IFactoringPatientRefHashes, IPatientNameContainer {
  abrechnungsvorschlaege: IAbrechnungsVorschlag[];
  alarme?: IHeuteAlarme<IHeuteAlarmData> & { ignoriert?: string };
  alarmScores?: IHeuteAlarmScores;
  alleLeistungenAbgerechnet: boolean;
  alleLeistungenBezahlt: boolean;
  alter: number;
  alterinmonaten?: number;
  anamnesen: Date[];
  risikoanamnesen: IPatientRisikoAnamnese[];
  assistenzen: string[];
  behandler: string;
  bema: ILeistungenInfo;
  bewertung: string;
  bezahltesHonorar: number;
  compliance: IComplianceComplete;
  datenschutzerklaerung: IHeuteDatenSchutzInfo | undefined;
  diagnosen?: string[];
  email: boolean;
  ersterTermin: boolean;
  folgeTermine: IHeuteTerminInfo[];
  ueberweiser: string;
  geschlecht: GeschlechtType;
  goz: ILeistungenInfo;
  parInfo: IPARCaseInfo; // coming from db
  par?: IPARCaseInfo[]; // coming through resolver
  hauptBehandler: string;
  heuteStatus: HeuteStatusType;
  hkp: IHeuteHKP[];
  hkptyp: HKPTerminType;
  honorar: number;
  honorarErstattung: number;
  karte: IKarteInfo;
  klassifizierung: string;
  kommentar?: string;
  kontrolliert: IEingabekontrolle;
  kontostand: number;
  lbohnetermin: ILeistungsBlock[];
  leistungsbloecke: IHeutePatientTerminLeistungsblock[];
  letzteAnamnese: IHeuteAnamneseInfo | undefined;
  letzteNullEins: IHeuteTerminInfo | undefined;
  letztePA: IHeuteTerminInfo | undefined;
  letztePSI: Date | undefined;
  letztePZR: IHeuteTerminInfo | undefined;
  letztesOPG: IHeuteRoentgen | undefined;
  letztesRoentgen: IHeuteRoentgen | undefined;
  mobil: boolean;
  naechstePA: IHeuteTerminInfo | undefined;
  naechstePZR: IHeuteTerminInfo | undefined;
  nichtAbgerechneteLeistungen: IHeuteNichtAbgrechneteLeistungen;
  notizen: INotiz[];
  offeneRechnungen: IOffeneRechnungsInfo[];
  offeneTermine: IHeuteTerminInfo[];
  patid: string;
  patyp: PAType;
  privat: boolean;
  prophylaxeBehandler: string;
  psiBefund: IRohPsi | undefined;
  recallInfo: IRecallInfo;
  rechenzentrum:
    | { raw: IHeuteRechenzentrumInfos; ui: IHeuteRechenzentrumUIInfos; mainui: IHeuteRechenzentrumUIInfo | undefined }
    | undefined;
  roentgen: IHeuteRoentgenInfo;
  stichworte: IStichwortParam[];
  tag: Date; // theoretischer start des termins, auf tag gerundet, zum gruppieren
  telefon: boolean;
  terminArt: string;
  terminartBezeichnung?: string;
  terminBehandler: string;
  terminid: string;
  terminstatus: TerminStatusType;
  updatedAt?: Date;
  zahnlos: boolean;
  zusatzversicherung: string[];
  distanz: number; // distanz zur praxis (luftlinie) in km
  r4canamnesen: IHeuteR4cAnamneseInfoEntry;
}

export interface IHeuteResult {
  entries: IHeuteWithLabels[];
  deletedEntries: string[];
  history: IHeuteHistory[];
  maxScores: IHeuteAlarmConfig;
}

// remember to convert new dateonly fields from string in HeuteHelper

export interface IHeutePatient extends IFactoringPatientMetrics {
  extid: string;
  extraseitendaten: any;
  stichworte: IStichwortParam[];
  notizen: INotiz[];
  hkp: IHKP[];
  psi: IPsi;
  parInfo: IPARCaseInfo;
  kartenlesungen: IKarte[];
  recallpzrmodus: RecallType;
  naechsterpzrrecall: Date | undefined;
  recallpzrwochen: number;
  roentgen: IRoentgen[];
  email: boolean;
  geschlecht: GeschlechtType;
  alter: number; // nur als fallback genutzt
  hauptbehandler: string;
  klassifizierung: string;
  anamnesen: Date[];
  risikoanamnesen: IPatientRisikoAnamnese[];
  mobil: boolean;
  sms: boolean;
  privat: boolean;
  telefon: boolean;
  prophylaxebehandler: string;
  offenerechnungen?: IOffeneRechnungsInfo[];
  nichtAbgerechneteLeistungen: IHeuteNichtAbgrechneteLeistungen;
  termine: IHeutePatientTermin[];
  abgesagtetermine: IHeuteAbgesagterTermin[];
  abrechnungsvorschlaege: IAbrechnungsVorschlag[];
  zahnlos: boolean;
  lbohnetermin: ILeistungsBlock[];
  ueberweiser: string;
  kontostand: number;
  locationhash: string;
}

// remember to convert new dateonly fields from string in HeuteHelper

export interface IHeutePatientTermin {
  alter: number;
  angelegt: Date | undefined;
  art: string;
  behandler: string;
  behandlungsendzeit: Date | undefined;
  behandlungsstartzeit: Date | undefined;
  behandlungszimmerzeit: Date | undefined;
  erster: boolean;
  extid: string;
  hkptyp: HKPTerminType;
  kommentar: string;
  kontrolliert: IEingabekontrolle;
  leistungsbloecke: IHeutePatientTerminLeistungsblock[];
  meta: IHeuteTerminMeta;
  patid: string;
  patyp: PAType;
  status: TerminStatusType;
  tag: Date;
  terminBehandler: string;
  termindauer: number;
  terminstart: Date | undefined;
  wartezimmerzeit: Date | undefined;
  zeitslots: IZeitSlots;
}

export interface IHeuteAbgesagterTermin {
  tag: Date | undefined;
  rohstatus: TerminStatusType;
}

export interface IHeutePatientTerminLeistungsblock {
  tag: Date | undefined;
  patid: string;
  behandler: string;
  hatpaleistungen: boolean;
  letztepsiabrechnung?: Date;
  hatpzrleistungen: boolean;
  inpzrbehandlung: boolean;
  alleabgerechnet: boolean;
  leistungsids: string[];
  leistungsidsbema: string[];
  leistungsidsgoz: string[];
  diagnosen: string[];
  assistenzids: string[];
  anzahlleistungenbema: number;
  anzahlleistungengoz: number;
  honorar: number;
  honorarbema: number;
  honorargoz: number;
  honorarerstattung: number;
}

export interface IHeuteTerminMeta {
  assistenzids: string[];
  diagnosen: string[];
}

export interface IHeuteHistory {
  monat: Date;
  empfang: number;
  prophylaxe: number;
  zahnarzt: number;
  abrechnung: number;
}
