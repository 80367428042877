import { IPoJo } from './db';
import { AbrechnungsArt, BemaGozType, GozArtType, VerrechnungsArtType } from './enums';
import { IMaterial } from './labor';
import { IPatientNameContainer } from './patient';
import { ITerminInfoContainer, ITerminMitKommentar } from './termin';

export interface IRohLeistungBase {
  art: BemaGozType;
  gozart: GozArtType;
  zurechnung: string;
  nummer: string;
  abrechnungsnummer: string;
  fiktiv: boolean;
}

export interface IRohLeistung extends IPoJo, IRohLeistungBase {
  extid: string; // krblattdatenid
  patid: string;
  hkpid: string;
  tag: Date;
  anzahl: number;
  faktor: number;
  kuerzel: string;
  punktzahl: number; // notwendig?
  punktwert: number; // notwendig?
  bewertungszahl: number;
  fuellungszahn: number;
  fuellungslage: number; // TODO enum fuellungslage
  betrag: number;
  behandler: string; // leistungserbringer
  abrechnungsart: AbrechnungsArt;
  sitzung: string; // sitzungsmodus
  abgerechnet: boolean;
  bezeichnung: string;
  verrechnungsart: VerrechnungsArtType;
}

export interface ILeistung extends IRohLeistung {
  material: IMaterial[];
}

export interface ILeistungTerminKommentar {
  cid: string;
  patid: string;
  tag: Date;
  kommentar: string;
  ersteller: string;
}

export interface IRechnungsLeistung extends IPoJo {
  extid: string; // nummer
  anzahl: number;
  art: BemaGozType;
  faktor: number;
  nummer: string;
  betrag: number;
  behandler: string;
  abrechnungsart: AbrechnungsArt;
}

export interface ILeistungsErbringerVerteilung {
  behandler: string;
  gesamt: number;
  gesamtohnehkp: number;
  honorar: number;
  honorarohnehkp: number;
  eigenlabor: number;
  eigenlaborohnehkp: number;
  eigenlabormaterial: number;
  eigenlabormaterialohnehkp: number;
  fremdlabor: number;
  fremdlaborohnehkp: number;
  material: number;
  materialohnehkp: number;
}

export interface IJahresVerteilungDetail {
  honorarerstattung: number;
  honorar: number;
  material: number;
  eigenlabor: number;
  eigenlabormaterial: number;
  fremdlabor: number;
  gesamt: number;
}

export interface IJahresVerteilungDetailExtended extends IJahresVerteilungDetail {
  honoraroffen: number;
  fremdlaboroffen: number;
  gesamt: number;
}

export interface IJahresVerteilung {
  yearCurrent: IJahresVerteilungDetail;
  year1Prev: IJahresVerteilungDetail;
  year2Prev: IJahresVerteilungDetail;
  year3Prev: IJahresVerteilungDetail;
  yearOlder3: IJahresVerteilungDetail;
}

export interface IOffeneGOZLeistungen {
  patid: string;
  privat: boolean;
  hauptbehandler: string;
  leistungstage: Date[];
  leistungsids: string[];
  leistungsErbringerVerteilung: ILeistungsErbringerVerteilung[];
  jahresVerteilung: IJahresVerteilung;
  tagMin: Date;
  tagMax: Date;
  gesamt: number;
  honorarerstattung: number;
  honorar: number;
  material: number;
  eigenlabor: number;
  fremdlabor: number;
  honorarmwst: number;
  materialmwst: number;
  fremdlabormwst: number;
  eigenlabormwst: number;
  eigenlabormaterialmwst: number;
  abrechnungszeitraumoffengoz: number;
  hasComments?: boolean;
}

export interface IOffeneGOZLeistungenExtended
  extends IOffeneGOZLeistungen,
    ITerminInfoContainer,
    IPatientNameContainer {
  wiedervorlage: Date;
  wiedervorlageKommentar: string;
  wiedervorlageErsteller: string;
}

export interface IOffeneleistungenResultVerlaufAZItem {
  tag: Date;
  abrechnungszeitraum: number;
}

export interface IOffeneleistungenResultVerlaufOFItem {
  tag: Date;
  honoraroffen: number;
  patienten: number;
}

export interface IOffeneleistungenResultVerlaufItem
  extends IOffeneleistungenResultVerlaufAZItem,
    IOffeneleistungenResultVerlaufOFItem {
  tag: Date;
}

export interface IOffeneLeistungenResult {
  offeneLeistungen: IOffeneGOZLeistungenExtended[];
}

export interface IOffeneLeistungenVerlaufResult {
  verlauf: IOffeneleistungenResultVerlaufItem[];
}

export interface ILeistungenQueryParams {
  [key: string]: string | boolean | undefined;
  leistungsbehandler?: string | undefined;
  stammbehandler?: string | undefined;
}

export interface ILeistungenTermineKommentarParams {
  patid: string;
  tag: string;
  kommentar: string;
}
export interface ILeistungenTermine {
  termine: Termine[];
}

export interface Termine {
  tag: string;
  tagtermine: Tagtermine[];
  tagLeistung: TagLeistung;
  kommentar?: Partial<Kommentar>;
}

interface TagLeistung {
  tag: string;
  sumHonorargozoffen: number;
  sumHonorargoz: number;
}

interface Tagtermine {
  tag: string;
  extid: string;
  behandler: string;
  terminstart: string;
}

interface Kommentar {
  cid: string;
  createdAt: string;
  ersteller: string;
  kommentar: string;
  patid: string;
  tag: string;
  updatedAt: string;
}

export interface ILeistungenWiedervorlageInfo {
  patid: string;
  kommentar: string;
  datum: string;
  ersteller: string;
}

export interface ILeistungenSummaryRow {
  doFilter?: {
    from: any;
    to: any;
  };
  spezialFilter?: string[];
  filterCount?: number;
  title: string | number;
  isSummary?: boolean;
  isExtended?: boolean;
  count: number;
  abrechnungszeitraumoffengoz: number;
  honorarerstattung: number;
  honorar: number;
  material: number;
  eigenlabor: number;
  eigenlabormaterial: number;
  fremdlabor: number;
  gesamt: number;
  leistungsErbringerVerteilung?: unknown[];
  honorarLeistungserbringer?: number;
}

export interface ILeistungsErbringerVerteilungPatientenUmsaetze {
  behandler: string;
  betrag: number;
  honorar: number;
  material: number;
  eigenlabor: number;
  eigenlabormaterial: number;
  fremdlabor: number;
}

export interface IPatientenUmsaetze {
  patid: string;
  /* patient */
  privat: boolean;
  stammbehandler: string;

  leistungvon: Date;
  leistungbis: Date;
  leistungserbringer: string;

  /* beträge lb */
  honorar: number;
  honorarbema: number;
  honorargoz: number;
  honorarmwst: number;
  honorarmwstoffen: number;
  honorarbemamwst: number;
  honorargozmwst: number;
  honorargozkasse: number;
  honorargozprivat: number;
  honorarerstattung: number;
  honorarerstattungoffen: number;
  honorarhkp: number;
  honorarhkpoffen: number;
  material: number;
  materialmwst: number;
  materialbema: number;
  materialgoz: number;
  eigenlabor: number;
  eigenlabormwst: number;
  eigenlaborleistungen: number;
  eigenlabormaterial: number;
  eigenlaborleistungenmwst: number;
  eigenlabormaterialmwst: number;
  fremdlabor: number;
  fremdlabormwst: number;
  honoraroffen: number;
  honorargozoffen: number;
  honorarbemaoffen: number;
  materialoffen: number;
  materialmwstoffen: number;
  materialhkpoffen: number;
  materialbemaoffen: number;
  materialgozoffen: number;
  eigenlaboroffen: number;
  eigenlabormwstoffen: number;
  eigenlaborhkpoffen: number;
  eigenlabormaterialoffen: number;
  eigenlabormaterialmwstoffen: number;
  eigenlabormaterialhkpoffen: number;
  fremdlaboroffen: number;
  fremdlabormwstoffen: number;
  fremdlaborhkpoffen: number;
  gesamt: number;
  gesamtoffen: number;
  huel: number;

  reanzahl: number;
  renummern: string[];
  rebezahlt: number;
  rebezahltanteil: number;
  renichtbezahlt: number;
  renichtbezahltanteil: number;
  reerlassen: number;
  reerlassenanteil: number;
  reerstattung: number;

  folgetermine?: ITerminMitKommentar[];

  leistungszurechnung?: {
    GOZA: number;
    GOZB: number;
    GOZC: number;
    GOZD: number;
    GOZE: number;
    GOZF: number;
    GOZG: number;
    GOZH: number;
    GOZJ: number;
    GOZK: number;
    GOZL: number;
    GOZV: number;
    GOZGU: number;
    GOZGAE: number;
    BEMA1: number;
    BEMA2: number;
    BEMA3: number;
    BEMA4: number;
    BEMA5: number;
    BEMABAE: number;
    BEMABU: number;
    OhneZurechnung: number;
    GOZGesamt: number;
    BEMAGesamt: number;
  };

  leistungsErbringerVerteilung: ILeistungsErbringerVerteilungPatientenUmsaetze[];
}

export interface IPatientenUmsaetzeExtended extends IPatientenUmsaetze, ITerminInfoContainer, IPatientNameContainer {}

export interface IPatientenUmsaetzeQueryParams {
  [key: string]: string | boolean | undefined;
  leistungsbehandler?: string | undefined;
  stammbehandler?: string | undefined;
}

export enum PrivatFilterTypeUI {
  Alle = 'Alle',
  Kasse = 'Kasse',
  Privat = 'Privat',
}

export enum PatientenUmsaetzeGesamtoffenFilter {
  Alle = 'Alle',
  Offen = 'Offen',
  Abgerechnet = 'Abgerechnet',
}

export enum PatientenUmsaetzeBezahltFilter {
  Alle = 'Alle',
  NichtBezahlt = 'NichtBezahlt',
  Bezahlt = 'Bezahlt',
  Nachlass = 'Nachlass',
}

export enum PatientenUmsaetzeMwstFilter {
  Alle = 'Alle',
  MitMwSt = 'MitMwSt',
  OhneMwSt = 'OhneMwSt',
}
