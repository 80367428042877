"use strict";

var _objectSpread = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js")["default"];

var _defineProperty = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/defineProperty.js")["default"];

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/web.timers.js");

require("core-js/modules/es.error.cause.js");

require("core-js/modules/es.error.to-string.js");

require("core-js/modules/es.json.stringify.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.overrideSignatureCityInjectToken = void 0;

var signature_pad_1 = require("signature_pad");

var vue_demi_1 = require("vue-demi");

var filters_1 = require("../filters");

var canvasUtil_1 = require("../helpers/canvasUtil");

var DrawInDocument_vue_1 = require("./DrawInDocument.vue");

exports.overrideSignatureCityInjectToken = 'overrideSignatureCityInjectToken';
exports["default"] = (0, vue_demi_1.defineComponent)({
  inject: _defineProperty({}, exports.overrideSignatureCityInjectToken, {
    "default": null
  }),
  props: {
    documentPreviewUrls: {
      type: Array,
      required: false
    },
    documentPreviewUrl: {
      type: String,
      required: false
    },
    documentHtml: {
      type: String,
      required: false
    },
    patientFirstName: {
      type: String,
      required: true
    },
    patientName: {
      type: String,
      required: true
    },
    patientDOB: {
      type: String,
      required: true
    },
    signatureName: {
      type: String,
      required: true
    },
    signatureCity: {
      type: String,
      required: true
    },
    prefillPatientEmail: {
      type: String,
      "default": ''
    },
    sending: {
      type: Boolean,
      "default": false
    },
    enableDrawInDocument: {
      type: Boolean,
      "default": false
    },
    hideCopyMailDialog: {
      type: Boolean,
      "default": false
    },
    noSignatureMode: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      signaturePad: null,
      signatureDirty: false,
      imageLoaded: false,
      patientEmail: undefined,
      sendEmail: null,
      emailInputValid: false,
      emailInputValidationMessage: '',
      observer: null,
      _signatureName: '',
      _signatureCity: ''
    };
  },
  computed: {
    previewUrls: function previewUrls() {
      var _this$documentPreview;

      if (this.documentPreviewUrl) {
        return [this.documentPreviewUrl];
      }

      return (_this$documentPreview = this.documentPreviewUrls) !== null && _this$documentPreview !== void 0 ? _this$documentPreview : null;
    },
    previewUrlsCombinedString: function previewUrlsCombinedString() {
      if (!this.previewUrls) {
        return '';
      }

      return this.previewUrls.join('\n');
    },
    isValid: function isValid() {
      // MUST CHOOSE sendEmail true or false
      if (!this.hideCopyMailDialog && this.sendEmail === null) {
        return false;
      } // IF SENDMAIL MUST HAVE VALID EMAIL


      if (this.sendEmail && (!this.patientEmail || !this.emailInputValid)) {
        return false;
      } // IF SIGNATUE MODE MUST HAVE SIGNATURE


      if (!this.noSignatureMode && !this.signatureDirty) {
        return false;
      }

      return true;
    }
  },
  components: {
    DrawInDocument: DrawInDocument_vue_1["default"]
  },
  mounted: function mounted() {
    if (!this.noSignatureMode) {
      this.initCanvas();
    }

    this.$emit('valid', this.isValid);
  },
  unmounted: function unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  destroyed: function destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  watch: {
    signatureName: {
      immediate: true,
      handler: function handler() {
        this._signatureName = this.signatureName;
      }
    },
    signatureCity: {
      immediate: true,
      handler: function handler() {
        this._signatureCity = this.signatureCity;

        if (this[exports.overrideSignatureCityInjectToken]) {
          this._signatureCity = this[exports.overrideSignatureCityInjectToken];
        }
      }
    },
    prefillPatientEmail: {
      immediate: true,
      handler: function handler() {
        this.patientEmail = this.prefillPatientEmail;
      }
    },
    sendEmail: function sendEmail() {
      // trigger validation once, when field gets active
      if (this.sendEmail) {
        this.checkEmailInputValidation();
      }
    },
    isValid: function isValid() {
      this.$emit('valid', this.isValid);
    },
    previewUrlsCombinedString: function previewUrlsCombinedString() {
      this.imageLoaded = false;
    }
  },
  methods: _objectSpread({
    initCanvas: function initCanvas() {
      var _this = this;

      var canvasRef = this.$refs.signatureCanvas;
      this.signaturePad = new signature_pad_1["default"](canvasRef);
      this.observer = new ResizeObserver(function () {
        _this.rescaleCanvas();
      });
      this.observer.observe(canvasRef); // this.rescaleCanvas();
    },
    rescaleCanvas: function rescaleCanvas() {
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      var canvasRef = this.$refs.signatureCanvas;

      if (canvasRef) {
        canvasRef.width = canvasRef.clientWidth * ratio;
        canvasRef.height = canvasRef.clientHeight * ratio; // @ts-ignore

        canvasRef.getContext('2d').scale(ratio, ratio);
        this.signaturePad.clear();
        this.signatureDirty = false;
      }
    },
    checkEmailInputValidation: function checkEmailInputValidation() {
      var _this2 = this;

      // if disabled attribute changed in same tick validation does not work
      setTimeout(function () {
        var emailInputElement = _this2.$refs.emailInput;
        _this2.emailInputValid = emailInputElement.validity.valid;
        _this2.emailInputValidationMessage = emailInputElement.validationMessage;
      });
    },
    scrollDown: function scrollDown() {
      var el = this.$refs.actions;
      el.scrollIntoView();
    },
    clearSignaturePad: function clearSignaturePad() {
      if (this.signaturePad) {
        this.signaturePad.clear();
      }

      this.signatureDirty = false;
    },
    getOverlayDrawPngs: function getOverlayDrawPngs() {
      var _this$$refs$drawInDoc;

      return (_this$$refs$drawInDoc = this.$refs.drawInDocument) === null || _this$$refs$drawInDoc === void 0 ? void 0 : _this$$refs$drawInDoc.getOverlayDrawPngs();
    },
    getDocumentCopyMail: function getDocumentCopyMail() {
      if (!this.sendEmail) {
        return undefined;
      }

      return this.patientEmail;
    },
    getSignatureDetails: function getSignatureDetails() {
      if (this.noSignatureMode) {
        throw new Error('getSignatureDetails should not be called when noSignatureMode is set');
      } // raw png data


      var canvasRef = this.$refs.signatureCanvas;
      var pngString = (0, canvasUtil_1.getCroppedCanvasImage)(canvasRef, 15, 15); // stroke data with timestamps

      var data = this.signaturePad.toData(); // convert stroke data to string (remove color)

      var strokeDataString = JSON.stringify(data.map(function (stroke) {
        return stroke.points;
      }));
      return {
        pngString: pngString,
        strokeDataString: strokeDataString,
        signatureName: this._signatureName,
        signatureCity: this._signatureCity
      };
    },
    markSignatureDirty: function markSignatureDirty() {
      this.signatureDirty = true;
    },
    cancel: function cancel() {
      this.$emit('cancel');
    }
  }, filters_1.tzFilter.filters)
});