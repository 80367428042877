"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var dayjs_1 = require("dayjs");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      rules: [function (v) {
        return (0, dayjs_1["default"])(v).isValid();
      }],
      params: {}
    };
  },
  computed: {
    date: function date() {
      return this.params.value.value;
    },
    label: function label() {
      return this.params.context.label;
    },
    key: function key() {
      return this.params.value.key;
    }
  },
  methods: {
    commit: function commit(event) {
      var target = event.target;
      event.preventDefault();
      event.stopPropagation();

      if ((0, dayjs_1["default"])(target.value).isValid()) {
        this.params.context.clicked({
          key: this.params.value.key,
          value: target.value
        });
      }
    }
  }
});