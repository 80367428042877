"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var base_1 = require("@rose/base");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {},
      skipHideOption: false,
      skipAlarm: false,
      reiter: null,
      feld: null
    };
  },
  computed: {
    hiddenField: function hiddenField() {
      if (editSettingsStore_1.editSettingsStore.getters.heuteHideFields && this.feld && this.reiter) {
        var _editSettingsStore_1$;

        return (_editSettingsStore_1$ = editSettingsStore_1.editSettingsStore.getters.heuteHideFields[this.reiter]) === null || _editSettingsStore_1$ === void 0 ? void 0 : _editSettingsStore_1$[this.feld];
      }

      return false;
    },
    counterValue: function counterValue() {
      var _this$params$data, _this$params$colDef;

      if ((_this$params$data = this.params.data) !== null && _this$params$data !== void 0 && _this$params$data.feldKey && (_this$params$colDef = this.params.colDef) !== null && _this$params$colDef !== void 0 && _this$params$colDef.field) {
        var _editSettingsStore_1$2;

        var counter = (_editSettingsStore_1$2 = editSettingsStore_1.editSettingsStore.getters.alarme[this.params.data.feldKey]) === null || _editSettingsStore_1$2 === void 0 ? void 0 : _editSettingsStore_1$2[this.params.colDef.field];
        return counter <= -1 ? '-' : counter;
      }

      return undefined;
    },
    counterColor: function counterColor() {
      if (this.counterValue > 0) {
        return 'primary lighten-1';
      }

      if (this.counterValue === 0) {
        return 'warning';
      }

      if (this.counterValue === '-' && !this.hiddenField) {
        return 'error';
      }

      if (this.counterValue === '-' && this.hiddenField) {
        return 'none';
      }

      return 'primary';
    }
  },
  mounted: function mounted() {
    var _this$params$data2, _this$params$colDef2;

    if ((_this$params$data2 = this.params.data) !== null && _this$params$data2 !== void 0 && _this$params$data2.feldKey && (_this$params$colDef2 = this.params.colDef) !== null && _this$params$colDef2 !== void 0 && _this$params$colDef2.field) {
      var _this$params$colDef3, _this$params$data3;

      this.reiter = (_this$params$colDef3 = this.params.colDef) === null || _this$params$colDef3 === void 0 ? void 0 : _this$params$colDef3.field;
      this.feld = (_this$params$data3 = this.params.data) === null || _this$params$data3 === void 0 ? void 0 : _this$params$data3.feldKey;
      this.skipHideOption = common_ui_1.skipHideOptionList.includes(this.feld);
      this.skipAlarm = !(0, base_1.hasAlarm)(this.reiter, this.feld); // console.log(
      //   `countercell (reiter,field,skiphide,skipalarm)`,
      //   this.reiter,
      //   this.feld,
      //   this.skipHideOption,
      //   this.skipAlarm,
      // );
    }
  },
  methods: {
    itemClicked: function itemClicked() {
      if (this.feld) {
        editSettingsStore_1.editSettingsStore.commit.toggleHeuteHideField({
          alarmField: this.reiter,
          alarmType: this.feld
        });
      }
    },
    decrease: function decrease() {
      if (this.counterValue >= -1) {
        editSettingsStore_1.editSettingsStore.commit.decreaseAlarm({
          alarmField: this.feld,
          alarmType: this.reiter
        });
      }
    },
    increase: function increase() {
      editSettingsStore_1.editSettingsStore.commit.increaseAlarm({
        alarmField: this.feld,
        alarmType: this.reiter
      });
    }
  }
});