"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var HelpTooltip_vue_1 = require("@/components/HelpTooltip.vue");

var behandlerStore_1 = require("@/state/behandlerStore");

var Helptext_vue_1 = require("@/components/Helptext.vue");

var staticDataMetricsStore_1 = require("@/state/staticDataMetricsStore");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    HelpTooltip: HelpTooltip_vue_1["default"],
    Helptext: Helptext_vue_1["default"]
  },
  data: function data() {
    return {};
  },
  computed: {
    pvs: function pvs() {
      return editSettingsStore_1.editSettingsStore.state.editSettings.pvs;
    },
    stichworte: function stichworte() {
      return staticDataMetricsStore_1.staticDataMetricsStore.state.params.stichworte;
    },
    terminbehandler: function terminbehandler() {
      return behandlerStore_1.behandlerStore.getters.terminBehandler;
    },
    terminarten: function terminarten() {
      return staticDataMetricsStore_1.staticDataMetricsStore.state.terminarten;
    },
    disabeldAlarmsStichworte: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.disabledAlarmsStichworte;
      },
      set: function set(items) {
        editSettingsStore_1.editSettingsStore.commit.updateDisabledAlarmsStichworte(items);
      }
    },
    disabledAlarmsTerminbehandler: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.disabledAlarmsTerminbehandler;
      },
      set: function set(items) {
        editSettingsStore_1.editSettingsStore.commit.updateDisabledAlarmsTerminbehandler(items);
      }
    },
    disabledAlarmsTerminarten: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.disabledAlarmsTerminarten;
      },
      set: function set(items) {
        editSettingsStore_1.editSettingsStore.commit.updateDisabledAlarmsTerminarten(items);
      }
    },
    disabledAlarmsUeberweiser: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.disabledAlarmsUeberweiser;
      },
      set: function set(active) {
        editSettingsStore_1.editSettingsStore.commit.updateDisabledAlarmsUeberweiser(active);
      }
    },
    disabledAlarmsTerminstatusAbgesagt: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.disabledAlarmsTerminstatusAbgesagt;
      },
      set: function set(active) {
        editSettingsStore_1.editSettingsStore.commit.updateDisabledAlarmsTerminstatusAbgesagt(active);
      }
    },
    disabledAlarmsTerminstatusNichtErschienen: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.disabledAlarmsTerminstatusNichtErschienen;
      },
      set: function set(active) {
        editSettingsStore_1.editSettingsStore.commit.updateDisabledAlarmsTerminstatusNichtErschienen(active);
      }
    }
  }
});