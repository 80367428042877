"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

var lodash_1 = require("lodash");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {};
  },
  computed: {
    warteZimmerMin: {
      get: function get() {
        var _editSettingsStore_1$;

        return (_editSettingsStore_1$ = editSettingsStore_1.editSettingsStore.getters.wartezeiten) === null || _editSettingsStore_1$ === void 0 ? void 0 : _editSettingsStore_1$.warnung.warteZimmer;
      },
      set: function set(value) {
        this.updateWartezeitenWarnung({
          warteZimmer: (0, lodash_1.toInteger)(value)
        });
      }
    },
    warteZimmerMax: {
      get: function get() {
        var _editSettingsStore_1$2;

        return (_editSettingsStore_1$2 = editSettingsStore_1.editSettingsStore.getters.wartezeiten) === null || _editSettingsStore_1$2 === void 0 ? void 0 : _editSettingsStore_1$2.maximum.warteZimmer;
      },
      set: function set(value) {
        this.updateWartezeitenMaximum({
          warteZimmer: (0, lodash_1.toInteger)(value)
        });
      }
    },
    behandlungsZimmerMin: {
      get: function get() {
        var _editSettingsStore_1$3;

        return (_editSettingsStore_1$3 = editSettingsStore_1.editSettingsStore.getters.wartezeiten) === null || _editSettingsStore_1$3 === void 0 ? void 0 : _editSettingsStore_1$3.warnung.behandlungsZimmer;
      },
      set: function set(value) {
        this.updateWartezeitenWarnung({
          behandlungsZimmer: (0, lodash_1.toInteger)(value)
        });
      }
    },
    behandlungsZimmerMax: {
      get: function get() {
        var _editSettingsStore_1$4;

        return (_editSettingsStore_1$4 = editSettingsStore_1.editSettingsStore.getters.wartezeiten) === null || _editSettingsStore_1$4 === void 0 ? void 0 : _editSettingsStore_1$4.maximum.behandlungsZimmer;
      },
      set: function set(value) {
        this.updateWartezeitenMaximum({
          behandlungsZimmer: (0, lodash_1.toInteger)(value)
        });
      }
    },
    terminMin: {
      get: function get() {
        var _editSettingsStore_1$5;

        return (_editSettingsStore_1$5 = editSettingsStore_1.editSettingsStore.getters.wartezeiten) === null || _editSettingsStore_1$5 === void 0 ? void 0 : _editSettingsStore_1$5.warnung.termin;
      },
      set: function set(value) {
        this.updateWartezeitenWarnung({
          termin: (0, lodash_1.toInteger)(value)
        });
      }
    },
    terminMax: {
      get: function get() {
        var _editSettingsStore_1$6;

        return (_editSettingsStore_1$6 = editSettingsStore_1.editSettingsStore.getters.wartezeiten) === null || _editSettingsStore_1$6 === void 0 ? void 0 : _editSettingsStore_1$6.maximum.termin;
      },
      set: function set(value) {
        this.updateWartezeitenMaximum({
          termin: (0, lodash_1.toInteger)(value)
        });
      }
    },
    terminVerspaetet: {
      get: function get() {
        var _editSettingsStore_1$7;

        return (_editSettingsStore_1$7 = editSettingsStore_1.editSettingsStore.getters.wartezeiten) === null || _editSettingsStore_1$7 === void 0 ? void 0 : _editSettingsStore_1$7.minimumVerspaetungInMinuten;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.updateWarteZeitenVerspaetung((0, lodash_1.toInteger)(value));
      }
    }
  },
  methods: {
    updateWartezeitenWarnung: function updateWartezeitenWarnung(data) {
      editSettingsStore_1.editSettingsStore.commit.updateWartezeitenWarnung(data);
    },
    updateWartezeitenMaximum: function updateWartezeitenMaximum(data) {
      editSettingsStore_1.editSettingsStore.commit.updateWartezeitenMaximum(data);
    }
  }
});