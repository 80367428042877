"use strict";

var _defineProperty = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/defineProperty.js")["default"];

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.index-of.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var types_1 = require("@rose/types");

var lodash_1 = require("lodash");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    var _woAnbietenMap;

    return {
      params: {},
      woAnbietenMap: (_woAnbietenMap = {}, _defineProperty(_woAnbietenMap, types_1.WoAnbietenTyp.GKV_PKV, 'Bei GKV und PKV'), _defineProperty(_woAnbietenMap, types_1.WoAnbietenTyp.PKV, 'Nur bei PKV'), _defineProperty(_woAnbietenMap, types_1.WoAnbietenTyp.GKV, 'Nur bei GKV'), _woAnbietenMap)
    };
  },
  computed: {
    id: function id() {
      var _this$params$data;

      return (_this$params$data = this.params.data) === null || _this$params$data === void 0 ? void 0 : _this$params$data.id;
    },
    availableLeistungen: function availableLeistungen() {
      return editSettingsStore_1.editSettingsStore.getters.availableLeistungen;
    },
    availableWoAnbieten: function availableWoAnbieten() {
      var _this$params$data2,
          _this$params$data2$ko,
          _this$params$data2$ko2,
          _this = this;

      return (0, lodash_1.map)((_this$params$data2 = this.params.data) === null || _this$params$data2 === void 0 ? void 0 : (_this$params$data2$ko = _this$params$data2.konfiguration) === null || _this$params$data2$ko === void 0 ? void 0 : (_this$params$data2$ko2 = _this$params$data2$ko.woAnbieten) === null || _this$params$data2$ko2 === void 0 ? void 0 : _this$params$data2$ko2.auswahl, function (value) {
        return {
          name: _this.woAnbietenMap[value],
          value: value
        };
      });
    },
    availableTriggerleistungen: function availableTriggerleistungen() {
      var _this$params$data3, _this$params$data3$ko, _this$params$data3$ko2;

      return (0, lodash_1.map)((_this$params$data3 = this.params.data) === null || _this$params$data3 === void 0 ? void 0 : (_this$params$data3$ko = _this$params$data3.konfiguration) === null || _this$params$data3$ko === void 0 ? void 0 : (_this$params$data3$ko2 = _this$params$data3$ko.triggerLeistungen) === null || _this$params$data3$ko2 === void 0 ? void 0 : _this$params$data3$ko2["default"], function (value) {
        return {
          name: value,
          value: value
        };
      });
    },
    konfiguration: function konfiguration() {
      var _this$params$data4;

      return (_this$params$data4 = this.params.data) === null || _this$params$data4 === void 0 ? void 0 : _this$params$data4.konfiguration;
    },
    fehlendeLeistung: {
      get: function get() {
        var _this$params$data5, _this$params$data5$se;

        return (_this$params$data5 = this.params.data) === null || _this$params$data5 === void 0 ? void 0 : (_this$params$data5$se = _this$params$data5.settings) === null || _this$params$data5$se === void 0 ? void 0 : _this$params$data5$se.fehlendeLeistung;
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              fehlendeLeistung: value
            }
          });
        }
      }
    },
    woAnbieten: {
      get: function get() {
        var _this$params$data6, _this$params$data6$se, _this$params$data7, _this$params$data7$ko, _this$params$data7$ko2;

        return ((_this$params$data6 = this.params.data) === null || _this$params$data6 === void 0 ? void 0 : (_this$params$data6$se = _this$params$data6.settings) === null || _this$params$data6$se === void 0 ? void 0 : _this$params$data6$se.woAnbieten) || ((_this$params$data7 = this.params.data) === null || _this$params$data7 === void 0 ? void 0 : (_this$params$data7$ko = _this$params$data7.konfiguration) === null || _this$params$data7$ko === void 0 ? void 0 : (_this$params$data7$ko2 = _this$params$data7$ko.woAnbieten) === null || _this$params$data7$ko2 === void 0 ? void 0 : _this$params$data7$ko2["default"]);
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              woAnbieten: value
            }
          });
        }
      }
    },
    triggerLeistungen: {
      get: function get() {
        var _this$params$data8, _this$params$data8$se, _this$params$data9, _this$params$data9$ko, _this$params$data9$ko2;

        return ((_this$params$data8 = this.params.data) === null || _this$params$data8 === void 0 ? void 0 : (_this$params$data8$se = _this$params$data8.settings) === null || _this$params$data8$se === void 0 ? void 0 : _this$params$data8$se.triggerLeistungen) || ((_this$params$data9 = this.params.data) === null || _this$params$data9 === void 0 ? void 0 : (_this$params$data9$ko = _this$params$data9.konfiguration) === null || _this$params$data9$ko === void 0 ? void 0 : (_this$params$data9$ko2 = _this$params$data9$ko.triggerLeistungen) === null || _this$params$data9$ko2 === void 0 ? void 0 : _this$params$data9$ko2["default"]);
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              triggerLeistungen: value
            }
          });
        }
      }
    },
    minAlter: {
      get: function get() {
        var _this$params$data10, _this$params$data10$s, _this$params$data11, _this$params$data11$k;

        return ((_this$params$data10 = this.params.data) === null || _this$params$data10 === void 0 ? void 0 : (_this$params$data10$s = _this$params$data10.settings) === null || _this$params$data10$s === void 0 ? void 0 : _this$params$data10$s.minAlter) || ((_this$params$data11 = this.params.data) === null || _this$params$data11 === void 0 ? void 0 : (_this$params$data11$k = _this$params$data11.konfiguration) === null || _this$params$data11$k === void 0 ? void 0 : _this$params$data11$k.minAlter);
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              minAlter: value
            }
          });
        }
      }
    }
  },
  methods: {
    filterWithGroups: function filterWithGroups(item, queryText, itemText) {
      return Object.prototype.hasOwnProperty.call(item, 'header') || itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    deleteMinAlter: function deleteMinAlter() {
      if (this.params.data) {
        editSettingsStore_1.editSettingsStore.commit.deleteMinAlter({
          kategorie: this.params.data.kategorie,
          id: this.params.data.id
        });
      }
    }
  }
});