"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var leistungenStore_1 = require("@/state/leistungenStore");

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: null
    };
  },
  computed: {
    hasComments: function hasComments() {
      var _this = this;

      var leistung = leistungenStore_1.leistungenStore.getters.leistungen.find(function (l) {
        return l.patid === _this.params.data.patid;
      });
      return !!(leistung !== null && leistung !== void 0 && leistung.hasComments);
    }
  },
  methods: {}
});