"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var moment_1 = require("moment");

var lodash_1 = require("lodash");

var core_1 = require("@vueuse/core");

var common_ui_1 = require("@rose/common-ui");

function createMetricsChart(chartdata) {
  if (!chartdata) {
    return {
      points: [],
      options: {}
    };
  }

  var points = (0, lodash_1.map)(chartdata, function (d) {
    return {
      x: (0, moment_1["default"])(d.time).toDate(),
      y: (0, lodash_1.toInteger)(d.count),
      color: d.weekday === 6 || d.weekday === 7 ? '#1f75aa' : '#319ee1'
    };
  });

  var tooltipFormatterInterval = function tooltipFormatterInterval(point) {
    return "ca. ".concat(common_ui_1.dauerFilter.filters.dauer(point.y, true), " Minuten Nutzung am ").concat((0, moment_1["default"])(point.x).format('dd DD.MM.YYYY'));
  };

  var highchartsChartOptions = (0, vue_1.reactive)({
    title: {
      text: '' // Set an empty string to remove the title

    },
    series: [{
      data: points,
      type: 'column'
    }],
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function formatter() {
        return tooltipFormatterInterval(this);
      },
      shared: true,
      useHTML: true
    },
    yAxis: {
      title: {
        text: 'Nutzung pro Tag'
      },
      tickInterval: 60,
      labels: {
        formatter: function formatter() {
          return this.value / 60 + 'h'; // Convert minutes to hours for y-axis labels
        }
      },
      plotLines: [{
        value: 300,
        width: 2,
        dashStyle: 'Dash',
        zIndex: 4,
        label: {
          text: '5 Stunden',
          align: 'left',
          style: {
            color: 'gray'
          }
        }
      }]
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'letzter Monat'
      }
    },
    plotOptions: {
      column: {
        borderRadius: 3,
        pointPadding: 0.0,
        groupPadding: 0.0 // Adjust the padding between groups of columns

      }
    }
  });
  return {
    points: points,
    highchartsChartOptions: highchartsChartOptions
  };
}

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    chartData: {
      type: Object,
      "default": function _default() {
        return null;
      }
    }
  },
  setup: function setup(props) {
    var _props$chartData, _props$chartData$metr, _props$chartData2, _props$chartData2$met;

    // month chart
    var _createMetricsChart = createMetricsChart((_props$chartData = props.chartData) === null || _props$chartData === void 0 ? void 0 : (_props$chartData$metr = _props$chartData.metrics) === null || _props$chartData$metr === void 0 ? void 0 : _props$chartData$metr.lastmonth.chart),
        metricsMonthPoints = _createMetricsChart.points,
        metricsMonthChartOptionsNew = _createMetricsChart.highchartsChartOptions;

    var _createMetricsChart2 = createMetricsChart((_props$chartData2 = props.chartData) === null || _props$chartData2 === void 0 ? void 0 : (_props$chartData2$met = _props$chartData2.metrics) === null || _props$chartData2$met === void 0 ? void 0 : _props$chartData2$met.lastyear.chart),
        metricsYearPoints = _createMetricsChart2.points,
        metricsYearChartOptionsNew = _createMetricsChart2.highchartsChartOptions;

    var target = (0, vue_1.ref)(null);
    var targetIsVisible = (0, core_1.useElementVisibility)(target);
    return {
      target: target,
      targetIsVisible: targetIsVisible,
      hasMetricsChartData: (0, lodash_1.chain)(metricsYearPoints).map(function (p) {
        return p.y;
      }).filter(function (val) {
        return val !== 0;
      }).size().value() > 0,
      metricsMonthPoints: metricsMonthPoints,
      metricsYearPoints: metricsYearPoints,
      metricsMonthChartOptionsNew: metricsMonthChartOptionsNew,
      metricsYearChartOptionsNew: metricsYearChartOptionsNew
    };
  },
  data: function data() {
    return {
      chartDataType: 'metrics'
    };
  },
  methods: {
    log: function log() {
      console.log('chartinfos', this.metricsMonthPoints, this.metricsYearPoints);
    }
  }
});