"use strict";

var _objectSpread = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js")["default"];

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("@/../../types");

var anamneseCharlyConfigStore_1 = require("@rose/common-ui/src/anamnese/state/anamneseCharlyConfigStore");

var lodash_1 = require("lodash");

var vue_1 = require("vue");

var AnamneseQuestionEditorDialog_vue_1 = require("./AnamneseQuestionEditorDialog.vue");

var anamneseContextStore_1 = require("@rose/common-ui/src/anamnese/state/anamneseContextStore");

var anamneseSettingsEventBus_1 = require("@/views/Settings/Anamnese/anamneseSettingsEventBus");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    AnamneseQuestionEditorDialog: AnamneseQuestionEditorDialog_vue_1["default"]
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    charlyAnamneseConfig: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      showQuestionEditor: false,
      questionTypeDisplayString: types_1.questionTypeDisplayString,
      questionTypeIcon: types_1.questionTypeIcon
    };
  },
  computed: {
    questionFull: function questionFull() {
      if (!this.question.isCharlyQuestion) {
        return this.question;
      } else {
        var charlyQuestion = anamneseCharlyConfigStore_1.anamneseCharlyConfigStore.getCharlyQuestionById(this.question.questionID);
        return _objectSpread(_objectSpread({}, this.question), charlyQuestion);
      }
    },
    charlyQuestionNotFound: function charlyQuestionNotFound() {
      return !!this.question.isCharlyQuestion && !anamneseCharlyConfigStore_1.anamneseCharlyConfigStore.getCharlyQuestionById(this.question.questionID);
    },
    isPracticeQuestion: function isPracticeQuestion() {
      return (0, types_1.isPracticeQuestion)(this.question);
    }
  },
  methods: {
    saveNewQuestion: function saveNewQuestion(question) {
      anamneseSettingsEventBus_1.anamneseSettingsEventBus.$emit('saveNewQuestion', question);
    },
    // QUESTION CRUD
    editQuestion: function editQuestion() {
      this.showQuestionEditor = true; // this.editingQuestion = question;
    },
    cancelEditQuestion: function cancelEditQuestion() {
      this.showQuestionEditor = false; // this.editingQuestion = null;
    },
    saveEditedQuestion: function saveEditedQuestion(question) {
      this.showQuestionEditor = false; // omit charly specific properties

      this.$emit('edited', (0, lodash_1.omit)(question, ['alert', 'visibleForPatient', 'visibleForPatient', '_links', 'position']));
    },
    deleteEditedQuestion: function deleteEditedQuestion(question) {
      this.showQuestionEditor = false;
      this.$emit('deleted', question);
    },
    getTranslatedString: anamneseContextStore_1.anamneseContextStore.getTranslatedString,
    setTranslatedString: anamneseContextStore_1.anamneseContextStore.setTranslatedString
  }
});