import { HKPArtType, HKPStatusType, IHKPBase, HKPSubStatusType, HKPArtTypeAlle } from '../../../types';

export const hkpArtIds: {
  [key: string]: { name: string; gruppe: string; artIds: HKPArtType[]; benchmarkKey?: string };
} = {
  ['-1']: { name: 'Alle Arten', gruppe: 'Privat / Kasse', artIds: HKPArtTypeAlle, benchmarkKey: 'hkp' },
  ZE: {
    name: 'ZE',
    gruppe: 'Privat / Kasse',
    artIds: [HKPArtType.PRIVAT_ZE, HKPArtType.KASSE_ZE, HKPArtType.KASSE_ZE_SPEZIAL, HKPArtType.EBZ_ZER],
    benchmarkKey: 'hkpZE',
  },
  KBr: {
    name: 'KBr / FAL',
    gruppe: 'Privat / Kasse',
    artIds: [HKPArtType.PRIVAT_FA, HKPArtType.KASSE_KIEFERBRUCH, HKPArtType.EBZ_KGL, HKPArtType.EBZ_KBR],
    benchmarkKey: 'hkpKBrFAL',
  },
  PA: {
    name: 'PAR / GTR',
    gruppe: 'Privat / Kasse',
    artIds: [
      HKPArtType.PRIVAT_PA,
      HKPArtType.PRIVAT_GTR,
      HKPArtType.KASSE_PA,
      HKPArtType.KASSE_PAR,
      HKPArtType.KASSE_PA_ERGAENZUNG,
      HKPArtType.EBZ_PAR,
      HKPArtType.EBZ_PMB,
      HKPArtType.PASTATUS_P22A,
    ],
    benchmarkKey: 'hkpPAGTR',
  },
  KFO: {
    name: 'KFO',
    gruppe: 'Privat / Kasse',
    artIds: [HKPArtType.PRIVAT_KFO, HKPArtType.PRIVAT_KFO2, HKPArtType.KASSE_KFO, HKPArtType.KASSE_KFO2],
    benchmarkKey: 'hkpKFO',
  },
  NONKFPPAKBR: {
    name: 'ZE, Impla. & Private',
    gruppe: 'Privat / Kasse',
    artIds: [
      HKPArtType.PRIVAT_ZE,
      HKPArtType.KASSE_ZE,
      HKPArtType.KASSE_ZE_SPEZIAL,
      HKPArtType.EBZ_ZER,
      HKPArtType.PRIVAT,
      HKPArtType.PRIVAT_IMPL,
      HKPArtType.PRIVAT_ZE,
    ],
  },
  ZEIMPLA: {
    name: 'ZE & Impla.',
    gruppe: 'Privat / Kasse',
    artIds: [
      HKPArtType.PRIVAT_ZE,
      HKPArtType.KASSE_ZE,
      HKPArtType.KASSE_ZE_SPEZIAL,
      HKPArtType.EBZ_ZER,
      HKPArtType.PRIVAT_IMPL,
      HKPArtType.PRIVAT_ZE,
    ],
  },

  PrivatAlle: {
    name: 'Alle Privat',
    gruppe: 'Privat',
    artIds: [
      HKPArtType.PRIVAT,
      HKPArtType.PRIVAT_FA,
      HKPArtType.PRIVAT_PA,
      HKPArtType.PRIVAT_IMPL,
      HKPArtType.PRIVAT_GTR,
      HKPArtType.PRIVAT_ZE,
      HKPArtType.PRIVAT_KFO,
      HKPArtType.PRIVAT_KFO2,
    ],
    benchmarkKey: 'hkpPrivat',
  },
  PrivatZE: { name: 'ZE', gruppe: 'Privat', artIds: [HKPArtType.PRIVAT_ZE], benchmarkKey: 'hkpPrivatZE' },
  PrivatKBr: { name: 'KBr / FAL', gruppe: 'Privat', artIds: [HKPArtType.PRIVAT_FA], benchmarkKey: 'hkpPrivatKBrFAL' },
  PrivatPA: {
    name: 'PAR / GTR',
    gruppe: 'Privat',
    artIds: [HKPArtType.PRIVAT_PA, HKPArtType.PRIVAT_GTR],
    benchmarkKey: 'hkpPrivatPA',
  },
  PrivatKFO: {
    name: 'KFO',
    gruppe: 'Privat',
    artIds: [HKPArtType.PRIVAT_KFO, HKPArtType.PRIVAT_KFO2],
    benchmarkKey: 'hkpPrivatKFO',
  },
  PrivatImpla: { name: 'Impla', gruppe: 'Privat', artIds: [HKPArtType.PRIVAT_IMPL], benchmarkKey: 'hkpPrivatImpla' },
  PrivatAllgemein: {
    name: 'Privat allgemein',
    gruppe: 'Privat',
    artIds: [HKPArtType.PRIVAT],
    benchmarkKey: 'hkpPrivatAllgemein',
  },

  KasseAlle: {
    name: 'Alle Kasse',
    gruppe: 'Kasse',
    artIds: [
      HKPArtType.KASSE_ZE,
      HKPArtType.KASSE_ZE_SPEZIAL,
      HKPArtType.KASSE_KIEFERBRUCH,
      HKPArtType.KASSE_PA,
      HKPArtType.KASSE_PAR,
      HKPArtType.KASSE_PA_ERGAENZUNG,
      HKPArtType.KASSE_KFO,
      HKPArtType.KASSE_KFO2,
      HKPArtType.EBZ_KBR,
      HKPArtType.EBZ_KFO,
      HKPArtType.EBZ_KGL,
      HKPArtType.EBZ_PAR,
      HKPArtType.EBZ_PMB,
      HKPArtType.EBZ_ZER,
      HKPArtType.PASTATUS_P22A,
    ],
    benchmarkKey: 'hkpKasse',
  },
  KasseZE: {
    name: 'ZE',
    gruppe: 'Kasse',
    artIds: [HKPArtType.KASSE_ZE, HKPArtType.KASSE_ZE_SPEZIAL, HKPArtType.EBZ_ZER],
    benchmarkKey: 'hkpKasseZE',
  },
  KasseKBr: {
    name: 'KBr / FAL',
    gruppe: 'Kasse',
    artIds: [HKPArtType.KASSE_KIEFERBRUCH, HKPArtType.EBZ_KBR, HKPArtType.EBZ_KGL],
    benchmarkKey: 'hkpKasseKBrFAL',
  },
  KassePA: {
    name: 'PAR / GTR',
    gruppe: 'Kasse',
    artIds: [
      HKPArtType.KASSE_PA,
      HKPArtType.KASSE_PAR,
      HKPArtType.KASSE_PA_ERGAENZUNG,
      HKPArtType.EBZ_PAR,
      HKPArtType.EBZ_PMB,
      HKPArtType.PASTATUS_P22A,
    ],
    benchmarkKey: 'hkpKassePA',
  },
  KasseKFO: {
    name: 'KFO',
    gruppe: 'Kasse',
    artIds: [HKPArtType.KASSE_KFO, HKPArtType.KASSE_KFO2, HKPArtType.KASSE_KFO_NACHTRAG, HKPArtType.EBZ_KFO],
    benchmarkKey: 'hkpKasseKFO',
  },
};

export function isHkpPrivat(artEnum: HKPArtType): boolean {
  switch (artEnum) {
    case HKPArtType.PRIVAT:
    case HKPArtType.PRIVAT_FA:
    case HKPArtType.PRIVAT_PA:
    case HKPArtType.PRIVAT_IMPL:
    case HKPArtType.PRIVAT_GTR:
    case HKPArtType.PRIVAT_ZE:
    case HKPArtType.PRIVAT_KFO:
    case HKPArtType.PRIVAT_KFO2:
      return true;
    case HKPArtType.KASSE_ZE_SPEZIAL:
    case HKPArtType.KASSE_ZE:
    case HKPArtType.KASSE_KFO:
    case HKPArtType.KASSE_KFO2:
    case HKPArtType.KASSE_PA:
    case HKPArtType.KASSE_PAR:
    case HKPArtType.KASSE_PA_ERGAENZUNG:
    case HKPArtType.KASSE_KIEFERBRUCH:
    case HKPArtType.PASTATUS_P22A:
    case HKPArtType.EBZ_KBR:
    case HKPArtType.EBZ_KGL:
    case HKPArtType.EBZ_KFO:
    case HKPArtType.EBZ_PAR:
    case HKPArtType.EBZ_PMB:
    case HKPArtType.EBZ_ZER:
      return false;
  }
  return false;
}

export const hkpTerminCheckArten = [
  ...hkpArtIds.ZE.artIds,
  ...hkpArtIds.PrivatZE.artIds,
  ...hkpArtIds.PrivatImpla.artIds,
];

export function isHkpOffen(status: HKPStatusType): boolean {
  switch (status) {
    case HKPStatusType.ABGELEHNT:
    case HKPStatusType.DURCHGEFUEHRT:
    case HKPStatusType.VERWORFEN:
    case HKPStatusType.SOFORTABRECHNUNG:
      return false;
    default:
      return true;
  }
}

export function hkpGueltigkeit(hkp: IHKPBase, fallbackMonths: number, verlaengert: boolean) {
  let monate = fallbackMonths;
  if (!isHkpPrivat(hkp.art)) {
    if (hkp.art === HKPArtType.KASSE_KIEFERBRUCH || hkp.art === HKPArtType.KASSE_PA_ERGAENZUNG) {
      monate = 12;
    } else if (hkpArtIds.KFO.artIds.includes(hkp.art)) {
      monate = 4 * 12;
    } else if (hkpArtIds.PA.artIds.includes(hkp.art)) {
      // TODO haben wir eine bessere idee?
      monate = 3 * 12;
    } else if (hkpArtIds.ZE.artIds.includes(hkp.art)) {
      monate = 6;
    } else if (hkp.paragraph28) {
      // use fallback
    } else {
      monate = 12;
    }
  }
  // TODO: check if we can add 6 months for verlaengert
  if (verlaengert) {
    monate += 6;
  }
  return monate;
}

export function translateHKPArt(artEnum: HKPArtType): string {
  switch (artEnum) {
    case HKPArtType.PRIVAT:
      return 'P';
    case HKPArtType.PRIVAT_FA:
      return 'P FA';
    case HKPArtType.PRIVAT_PA:
      return 'P PA';
    case HKPArtType.PRIVAT_IMPL:
      return 'P Impl';
    case HKPArtType.PRIVAT_GTR:
      return 'P GTR';
    case HKPArtType.PRIVAT_ZE:
      return 'P ZE';
    case HKPArtType.PRIVAT_KFO:
    case HKPArtType.PRIVAT_KFO2:
      return 'P KFO';
    case HKPArtType.KASSE_ZE_SPEZIAL:
    case HKPArtType.KASSE_ZE:
    case HKPArtType.EBZ_ZER:
      return 'K ZE';
    case HKPArtType.KASSE_KFO:
    case HKPArtType.KASSE_KFO2:
    case HKPArtType.EBZ_KFO:
      return 'K KFO';
    case HKPArtType.KASSE_KFO_NACHTRAG:
      return 'K KFO Nachtrag';
    case HKPArtType.KASSE_PA:
      return 'K PA';
    case HKPArtType.KASSE_PAR:
    case HKPArtType.PASTATUS_P22A:
    case HKPArtType.EBZ_PAR:
      return 'K PAR';
    case HKPArtType.KASSE_PA_ERGAENZUNG:
      return 'K PA Erg';
    case HKPArtType.KASSE_KIEFERBRUCH:
    case HKPArtType.EBZ_KBR:
      return 'K KBr';
    case HKPArtType.EBZ_KGL:
      return 'K KGL';
    case HKPArtType.EBZ_PMB:
      return 'K KPMB';
    default:
      return `${artEnum}`;
  }
}

export function translateHKPStatus(status: HKPStatusType): string {
  switch (status) {
    case HKPStatusType.EINGABE:
      return 'Eingabe';
    case HKPStatusType.ALTERNATIV:
      return 'Alternativ';
    case HKPStatusType.ÜBERARBEITEN:
      return 'Überarbeiten';
    case HKPStatusType.ABGELEHNT:
      return 'Abgelehnt';
    case HKPStatusType.TROTZABLEHNUNGANGENOMMEN:
      return 'Trotz Ablehnung angenommen';
    case HKPStatusType.GENEHMIGT:
      return 'Genehmigt';
    case HKPStatusType.DURCHGEFUEHRT:
      return 'Durchgeführt';
    case HKPStatusType.VERWORFEN:
      return 'Verworfen';
    case HKPStatusType.SOFORTABRECHNUNG:
      return 'Sofortabrechnung';
    default:
      return `${status}`;
  }
}

export function translateHKPSubStatus(substatus: HKPSubStatusType): string {
  switch (substatus) {
    case HKPSubStatusType.EINGABE_IN_ARBEIT:
      return 'In Arbeit';
    case HKPSubStatusType.EINGABE_ZU_PRUEFEN:
      return 'Zu Prüfen';
    case HKPSubStatusType.EINGABE_GEPRUEFT:
      return 'Geprüft';
    case HKPSubStatusType.EINGABE_BEI_PATIENT:
      return 'Bei Patient';
    case HKPSubStatusType.EINGABE_BEI_KASSE:
      return 'Bei Kasse';
    case HKPSubStatusType.EINGABE_BEI_GUTACHTER:
      return 'Bei Gutachter';
    case HKPSubStatusType.ABGELEHNT_KASSE:
      return 'Kasse';
    case HKPSubStatusType.ABGELEHNT_PATIENT:
      return 'Patient';
    case HKPSubStatusType.ABGELEHNT_GUTACHTER:
      return 'Gutachter';
    case HKPSubStatusType.GENEHMIGT_PATIENT_OHNE_TERMIN:
      return 'Patient ohne Termin';
    case HKPSubStatusType.GENEHMIGT_PATIENT_HAT_TERMIN:
      return 'Patient mit Termin';
    case HKPSubStatusType.GENEHMIGT_IN_ARBEIT:
      return 'In Arbeit';
    case HKPSubStatusType.GENEHMIGT_EINGEGLIEDERT_ERBRACHT:
      return 'Eingegliedert';
    case HKPSubStatusType.VERWORFEN_ALTERNATIVE_BEHANDLUNG_DURCHGEFUEHRT:
      return 'Alternative Behandlung durchgeführt';
    case HKPSubStatusType.VERWORFEN_ZU_TEUER:
      return 'Zu teuer';
    case HKPSubStatusType.VERWORFEN_NICHT_VERSTANDEN:
      return 'Nicht verstanden';
    case HKPSubStatusType.VERWORFEN_PATIENT_WARTET_AUF_ANRUF:
      return 'Patient wartet auf Anruf';
    case HKPSubStatusType.VERWORFEN_FALSCHE_LOESUNG:
      return 'Falsche Lösung';
    case HKPSubStatusType.VERWORFEN_PATIENT_FUEHLT_SICH_BEVORMUNDET:
      return 'Patient fühlt sich bevormundet';
    case HKPSubStatusType.VERWORFEN_BEHANDLER_UNSYMPATISCH:
      return 'Behandler unsympatisch';
    case HKPSubStatusType.VERWORFEN_UNBEHAGLICHES_PRAXISAMBIENTE:
      return 'Unbehagliches Praxisambiente';
    case HKPSubStatusType.VERWORFEN_ZAHNARZTWECHSEL_OHNE_ANGABE:
      return 'Zahnarztwechsel ohne Angabe';
    case HKPSubStatusType.VERWORFEN_KEINE_ANGABE:
      return 'Keine Angabe';
    default:
      return ``;
  }
}

export enum SimpleHkpStatus {
  UNBEKANNT = -1,
  OFFEN = 0,
  DURCHGEFUEHRT = 1,
  VERWORFEN = 2,
}

export function transformHkpStatusToSimplerStatus(hkpStatus: HKPStatusType): SimpleHkpStatus {
  if (
    [
      HKPStatusType.EINGABE,
      HKPStatusType.GENEHMIGT,
      HKPStatusType.ALTERNATIV,
      HKPStatusType.ÜBERARBEITEN,
      HKPStatusType.TROTZABLEHNUNGANGENOMMEN,
    ].includes(hkpStatus)
  ) {
    return SimpleHkpStatus.OFFEN;
  }

  if ([HKPStatusType.DURCHGEFUEHRT, HKPStatusType.SOFORTABRECHNUNG].includes(hkpStatus)) {
    return SimpleHkpStatus.DURCHGEFUEHRT;
  }

  if ([HKPStatusType.VERWORFEN, HKPStatusType.ABGELEHNT].includes(hkpStatus)) {
    return SimpleHkpStatus.VERWORFEN;
  }

  return SimpleHkpStatus.UNBEKANNT;
}
