"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var DeleteButtonCell_vue_1 = require("@/components/cells/DeleteButtonCell.vue");

var common_ui_1 = require("@rose/common-ui");

var BehandlerChip_vue_1 = require("@/components/BehandlerChip.vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    BehandlerChip: BehandlerChip_vue_1["default"]
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    itemsAvailable: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      rowClass: 'row-class-even',
      itemsAdded: [],
      itemsSelected: [],
      components: {
        actionButtonsRenderer: DeleteButtonCell_vue_1["default"]
      },
      showDeleteAllSelected: false
    };
  },
  created: function created() {
    this.gridOptions = {
      rowHeight: 43,
      defaultColDef: {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      suppressCellFocus: true,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true
    };
  },
  methods: {
    getRowClass: function getRowClass(params) {
      if (params.node.rowIndex % 2 === 0) {
        return 'row-class-odd';
      }
    },
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('cell clicked:', event);
    },
    addItems: function addItems() {
      var items = this.itemsAdded.map(function (v) {
        return v.toLowerCase();
      });
      this.$emit('addItems', items);
      this.itemsAdded = [];
      this.showDeleteAllSelected = false;
    },
    deleteItems: function deleteItems() {
      var selected = this.getSelectedRows();
      this.$emit('deleteItems', selected);
      this.showDeleteAllSelected = false;
      this.gridApi.deselectAll();
    },
    getSelectedRows: function getSelectedRows() {
      return this.gridApi.getSelectedRows();
    },
    onSelectionChanged: function onSelectionChanged() {
      this.showDeleteAllSelected = !!this.getSelectedRows().length;
    }
  }
});