import { IBenchmarkConfigItemRaw, ISettings, IStichwortParam, BenchmarkParts } from '../../../types';
import { benchmarkConfigHKP } from './benchmark.config.hkp';
import { benchmarkConfigLeistungen } from './benchmark.config.leistungen';
import { benchmarkConfigPaPzr } from './benchmark.config.papzr';
import { benchmarkConfigPatienten } from './benchmark.config.patienten';
import { benchmarkConfigTermine } from './benchmark.config.termine';
import { benchmarkConfigTerminzeiten } from './benchmark.config.tz';
import { benchmarkConfigUmsatz } from './benchmark.config.umsatz';
import { benchmarkConfigBehandlungen } from './benchmark.config.behandlungen';
import { benchmarkConfigTBv2 } from './benchmark.config.tbv2';
import { benchmarkConfigStichworte } from './benchmark.config.stichworte';

let endi = 0;
export const benchmarkPatientSeitIntervalle = [
  { name: '< 1 Monat', key: '1monat', von: 0, bis: (endi += 30) }, // 1 monat
  { name: '1 - 6 Monate', key: '6monate', von: endi, bis: (endi += 5 * 30.5) }, // 1-6 Monate
  { name: '6 - 12 Monate', key: '1jahr', von: endi, bis: (endi += 6 * 30.5) }, // 6-12 Monate
  { name: '1 - 2 Jahre', key: '2jahre', von: endi, bis: (endi += 12 * 30.5) }, // 1-2 Jahre
  { name: '2 - 5 Jahre', key: '5jahre', von: endi, bis: (endi += 3 * 12 * 30.5) }, // 2-5 Jahre
  { name: '5 - 10 Jahre', key: '10jahre', von: endi, bis: (endi += 5 * 12 * 30.5) }, // 5-10 Jahre
  { name: '> 10 Jahre', key: 'mehrAls10jahre', von: endi, bis: (endi += 50 * 12 * 30.5) }, // >10 Jahre
];

export function benchmarkConfigRaw(settings: ISettings, topStichworte: IStichwortParam[]): IBenchmarkConfigItemRaw {
  return {
    name: 'KPI',
    isHeader: true,
    isRootNode: true,
    childs: {
      [BenchmarkParts.PATIENTEN]: {
        name: 'Patienten',
        isHeader: true,
        isRootNode: true,
        visible: false,
        collapsed: false,
        isTab: true,
        childs: {
          behandeltePatienten: benchmarkConfigPatienten(),
          papzr: benchmarkConfigPaPzr(),
          stichworte: benchmarkConfigStichworte(topStichworte),
        },
      },
      [BenchmarkParts.TERMINE]: {
        name: 'Termine',
        isHeader: true,
        isRootNode: true,
        visible: false,
        collapsed: false,
        isTab: true,
        childs: {
          _termine: benchmarkConfigTermine(),
          terminbuchv2: benchmarkConfigTBv2(),
          terminzeiten: benchmarkConfigTerminzeiten(),
        },
      },
      [BenchmarkParts.UMSATZ]: {
        name: 'Umsatz',
        isHeader: true,
        isRootNode: true,
        visible: false,
        collapsed: false,
        isTab: true,
        childs: {
          _umsatz: benchmarkConfigUmsatz(),
          leistungen: benchmarkConfigLeistungen(),
        },
      },
      [BenchmarkParts.HKPS]: { ...benchmarkConfigHKP(), isTab: true },
      [BenchmarkParts.BEHANDLUNGEN]: { ...benchmarkConfigBehandlungen(settings.leistungen.implantat), isTab: true },
    },
  };
}
