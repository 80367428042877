"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var rose_o_svg_1 = require("@/assets/rose_o.svg");

var types_1 = require("@/../../types/");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    RoseLogo: rose_o_svg_1["default"]
  },
  data: function data() {
    return {
      params: {},
      icon: 'fas fa-user',
      text: 'Admin ohne Benutzerverwaltung'
    };
  },
  computed: {
    initials: function initials() {
      if (this.params.data) {
        return this.params.data.firstName.replace(/Dr\.\s/g, '').charAt(0) + this.params.data.lastName.charAt(0);
      }

      return '';
    },
    isTeam: function isTeam() {
      var _this$params$data;

      return ((_this$params$data = this.params.data) === null || _this$params$data === void 0 ? void 0 : _this$params$data.adminLevel) === types_1.ADMIN_LEVEL.ROSE_TEAM;
    },
    isAdmin: function isAdmin() {
      var _this$params$data2, _this$params$data3;

      return ((_this$params$data2 = this.params.data) === null || _this$params$data2 === void 0 ? void 0 : _this$params$data2.adminLevel) === types_1.ADMIN_LEVEL.PRAXISMANAGER || ((_this$params$data3 = this.params.data) === null || _this$params$data3 === void 0 ? void 0 : _this$params$data3.adminLevel) === types_1.ADMIN_LEVEL.CLIENT_ADMIN;
    },
    badgeColor: function badgeColor() {
      var _this$params$data4, _this$params$data5;

      if (((_this$params$data4 = this.params.data) === null || _this$params$data4 === void 0 ? void 0 : _this$params$data4.adminLevel) === types_1.ADMIN_LEVEL.PRAXISMANAGER) {
        return 'orange';
      }

      if (((_this$params$data5 = this.params.data) === null || _this$params$data5 === void 0 ? void 0 : _this$params$data5.adminLevel) === types_1.ADMIN_LEVEL.CLIENT_ADMIN) {
        return 'red';
      }

      return 'grey lighten-1';
    }
  }
});