"use strict";

var _objectSpread = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js")["default"];

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var DeleteButtonCell_vue_1 = require("@/components/cells/DeleteButtonCell.vue");

var common_ui_1 = require("@rose/common-ui");

var diversZusatzversicherung_1 = require("@/components/columns/settings/diversZusatzversicherung");

var StichwortChip_vue_1 = require("@/components/StichwortChip.vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

var staticDataMetricsStore_1 = require("@/state/staticDataMetricsStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    StichwortChip: StichwortChip_vue_1["default"]
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      overlayLoadingTemplate: null,
      overlayNoRowsTemplate: null,
      columnDefs: (0, diversZusatzversicherung_1.settingsDiversZusatzversicherungColumnsDefs)(),
      components: {
        actionButtonsRenderer: DeleteButtonCell_vue_1["default"]
      },
      stichwortToAdd: {
        stichwort: '',
        text: ''
      },
      showDeleteAllSelected: false
    };
  },
  computed: {
    stichwortToAddStichwort: {
      get: function get() {
        return this.stichwortToAdd.stichwort;
      },
      set: function set(value) {
        this.stichwortToAdd.stichwort = value;
      }
    },
    stichwortToAddText: {
      get: function get() {
        return this.stichwortToAdd.text;
      },
      set: function set(value) {
        this.stichwortToAdd.text = value;
      }
    },
    zvStichworteKeys: function zvStichworteKeys() {
      return editSettingsStore_1.editSettingsStore.getters.zvStichworteKeys;
    },
    zvStichworteAvailable: function zvStichworteAvailable() {
      return editSettingsStore_1.editSettingsStore.getters.zvStichworteAvailable;
    }
  },
  beforeMount: function beforeMount() {
    this.gridOptions = {
      rowHeight: 48,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        resizable: true
      },
      rowSelection: 'single',
      suppressCellFocus: true
    };
  },
  created: function created() {
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        autoHeight: true
      },
      suppressCellFocus: true,
      suppressRowHoverHighlight: true
    };
  },
  methods: {
    onCellClicked: function onCellClicked(event) {
      this.$logger.debug('onCellClicked', event);
    },
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    addZVStichwort: function addZVStichwort() {
      if (staticDataMetricsStore_1.staticDataMetricsStore.state.params.stichworteMap) {
        var zvStichwort = _objectSpread(_objectSpread({}, staticDataMetricsStore_1.staticDataMetricsStore.state.params.stichworteMap[this.stichwortToAddStichwort]), {}, {
          text: this.stichwortToAddText
        });

        editSettingsStore_1.editSettingsStore.commit.addZvStichworte(zvStichwort);
      }

      this.stichwortToAdd.stichwort = '';
      this.stichwortToAdd.text = '';
    }
  }
});