"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var ProphyPatients_vue_1 = require("@/components/extended-queries/ProphyPatients.vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    ProphyPatients: ProphyPatients_vue_1["default"]
  }
});