"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var AbrechnungTable_vue_1 = require("@/components/tables/settings/Abrechnung/AbrechnungTable.vue");

var AbrechnungTableTerminArten_vue_1 = require("@/components/tables/settings/Abrechnung/AbrechnungTableTerminArten.vue");

var AbrechnungTableTerminBehandler_vue_1 = require("@/components/tables/settings/Abrechnung/AbrechnungTableTerminBehandler.vue");

var abrechnungTerminArten_1 = require("@/components/columns/settings/abrechnungTerminArten");

var abrechnungTerminBehandler_1 = require("@/components/columns/settings/abrechnungTerminBehandler");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    AbrechnungTable: AbrechnungTable_vue_1["default"],
    AbrechnungTableTerminArten: AbrechnungTableTerminArten_vue_1["default"],
    AbrechnungTableTerminBehandler: AbrechnungTableTerminBehandler_vue_1["default"]
  },
  data: function data() {
    return {
      columnArtenDefs: (0, abrechnungTerminArten_1.settingsAbrechnungTerminArtenColumnsDefs)(),
      columnBehandlerDefs: (0, abrechnungTerminBehandler_1.settingsAbrechnungTerminBehandlerColumnsDefs)()
    };
  },
  computed: {
    regelGroupedByCategory: function regelGroupedByCategory() {
      return editSettingsStore_1.editSettingsStore.getters.regelGroupedByCategory;
    },
    ignoriereTerminArten: function ignoriereTerminArten() {
      return editSettingsStore_1.editSettingsStore.getters.ignoriereTerminArten;
    },
    ignoriereTerminBehandler: function ignoriereTerminBehandler() {
      return editSettingsStore_1.editSettingsStore.getters.ignoriereTerminBehandler;
    },
    availableTerminArten: function availableTerminArten() {
      return editSettingsStore_1.editSettingsStore.getters.availableTerminArten;
    },
    availableTerminBehandler: function availableTerminBehandler() {
      return editSettingsStore_1.editSettingsStore.getters.availableTerminBehandler;
    }
  },
  methods: {
    addTerminArten: function addTerminArten(items) {
      editSettingsStore_1.editSettingsStore.commit.addAbrechnungIgnoriereTerminArten(items);
    },
    removeTerminArten: function removeTerminArten(selected) {
      var items = selected.map(function (v) {
        return v.extid.toLowerCase();
      });
      editSettingsStore_1.editSettingsStore.commit.removeAbrechnungIgnoriereTerminArten(items);
    },
    addTerminBehandler: function addTerminBehandler(items) {
      editSettingsStore_1.editSettingsStore.commit.addAbrechnungIgnoriereTerminBehandler(items);
    },
    deleteTerminBehandler: function deleteTerminBehandler(selected) {
      var items = selected.map(function (v) {
        return v.extid.toLowerCase();
      });
      editSettingsStore_1.editSettingsStore.commit.removeAbrechnungIgnoriereTerminBehandler(items);
    }
  }
});