"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var authStore_1 = require("./state/authStore");

var clientStateStore_1 = require("./state/clientStateStore");

exports["default"] = (0, vue_1.defineComponent)({
  name: 'App',
  data: function data() {
    return {};
  },
  computed: {
    cid: function cid() {
      return authStore_1.authStore.getters.cid;
    },
    initialized: function initialized() {
      return authStore_1.authStore.getters.initialized;
    },
    clientDataInitialized: function clientDataInitialized() {
      return clientStateStore_1.clientStateStore.getters.clientDataInitialized;
    }
  }
});