"use strict";

var _objectSpread = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js")["default"];

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.error.to-string.js");

require("core-js/modules/es.date.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.date.now.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var behandlerTeamsCustomTeams_1 = require("@/components/columns/settings/behandlerTeamsCustomTeams");

var behandlerTeamsAutoTeams_1 = require("@/components/columns/settings/behandlerTeamsAutoTeams");

var types_1 = require("@rose/types");

var behandlerStore_1 = require("@/state/behandlerStore");

var lodash_1 = require("lodash");

var AutoCompleteBehandler_vue_1 = require("@/components/AutoCompleteBehandler.vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

var teamFormInitial = {
  name: '',
  typ: '',
  farbe: '',
  mitglieder: []
};
exports["default"] = (0, vue_1.defineComponent)({
  components: {
    AutoCompleteBehandler: AutoCompleteBehandler_vue_1["default"]
  },
  props: {
    isCustom: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: null,
      showDeleteAllSelected: false,
      teamForm: teamFormInitial,
      teamFormValid: false,
      teamTypOptions: [{
        name: 'Team',
        value: types_1.TeamTypes.TEAM
      }, {
        name: 'Bündel',
        value: types_1.TeamTypes.BUNDLE
      }],
      noColor: true,
      menu: false
    };
  },
  computed: {
    teams: function teams() {
      return this.isCustom ? editSettingsStore_1.editSettingsStore.getters.customTeams : editSettingsStore_1.editSettingsStore.getters.autoTeams;
    },
    mitglieder: function mitglieder() {
      return behandlerStore_1.behandlerStore.getters.values;
    }
  },
  watch: {
    teamForm: {
      handler: function handler(newValue, oldValue) {
        this.checkValidTeam();
      },
      deep: true
    }
  },
  beforeMount: function beforeMount() {
    this.columnDefs = this.isCustom ? (0, behandlerTeamsCustomTeams_1.settingsBehandlerTeamsCustomTeamsColumnsDefs)() : (0, behandlerTeamsAutoTeams_1.settingsBehandlerTeamsAutoTeamsColumnsDefs)();
    this.gridOptions = {
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      rowHeight: 50,
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    },
    deleteItems: function deleteItems() {
      var selected = this.getSelectedRows();
      var items = selected.map(function (v) {
        return v.id;
      });
      editSettingsStore_1.editSettingsStore.commit.deleteTeams({
        teamIds: items
      });
      this.showDeleteAllSelected = false;
      this.gridApi.deselectAll();
    },
    getSelectedRows: function getSelectedRows() {
      return this.gridApi.getSelectedRows();
    },
    onSelectionChanged: function onSelectionChanged() {
      this.showDeleteAllSelected = !!this.getSelectedRows().length;
    },
    addTeam: function addTeam(mitglieder) {
      editSettingsStore_1.editSettingsStore.commit.addTeam({
        team: _objectSpread(_objectSpread({}, this.teamForm), {}, {
          mitglieder: mitglieder,
          size: mitglieder.length,
          id: Date.now().toString()
        })
      });
      this.teamForm.name = '';
      this.teamForm.typ = '';
      this.teamForm.farbe = '';
      this.teamForm.mitglieder = [];
      this.noColor = true;
    },
    checkValidTeam: function checkValidTeam() {
      this.teamFormValid = !(0, lodash_1.isEmpty)(this.teamForm.name) && !(0, lodash_1.isEmpty)(this.teamForm.typ);
    },
    setNewValues: function setNewValues() {
      this.noColor = false;
      this.menu = false;
    },
    resetColor: function resetColor() {
      this.menu = false;
      this.noColor = true;
      this.teamForm.farbe = '';
    }
  }
});