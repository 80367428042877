"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var OpenInCharly_vue_1 = require("@/components/OpenInCharly.vue");

var common_ui_1 = require("../../../../common-ui");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    OpenInCharly: OpenInCharly_vue_1["default"]
  },
  filters: {
    date: function date(d) {
      return d && (0, common_ui_1.roseDayjs)(d).format('DD.MM.YYYY');
    }
  },
  data: function data() {
    return {
      params: null,
      mahnungen: null,
      mahnstufe: null
    };
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // this.cellValue = this.getValueToDisplay(this.params);
    this.setValues(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues(params);
      return true;
    },
    setValues: function setValues(params) {
      var _params$data;

      this.mahnstufe = params.value;
      this.mahnungen = (_params$data = params.data) === null || _params$data === void 0 ? void 0 : _params$data.mahnungen;
    }
  }
});