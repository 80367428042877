"use strict";

var _createForOfIteratorHelper = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js")["default"];

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.error.to-string.js");

require("core-js/modules/es.date.to-string.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.array.sort.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("@rose/types");

var vue_1 = require("vue");

var OpenInCharly_vue_1 = require("@/components/OpenInCharly.vue");

var ZimmerbelegungFilter_vue_1 = require("@/components/zimmerbelegung/ZimmerbelegungFilter.vue");

var lodash_1 = require("lodash");

var BehandlerChip_vue_1 = require("@/components/BehandlerChip.vue");

var PatientName_vue_1 = require("@/components/PatientName.vue");

var StatusFilter;

(function (StatusFilter) {
  StatusFilter["COMING"] = "coming";
  StatusFilter["COMPLETED"] = "completed";
  StatusFilter["ALL"] = "all";
})(StatusFilter || (StatusFilter = {}));

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    PatientName: PatientName_vue_1["default"],
    BehandlerChip: BehandlerChip_vue_1["default"],
    ZimmerbelegungFilter: ZimmerbelegungFilter_vue_1["default"],
    OpenInCharly: OpenInCharly_vue_1["default"]
  },
  props: {
    termine: {
      type: Array,
      required: true
    },
    filter: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      GeschlechtType: types_1.GeschlechtType,
      StatusFilter: StatusFilter,
      searchInput: '',
      statusFilter: StatusFilter.ALL,
      activeSortGroup: undefined,
      filterConfig: [{
        name: 'Beginn',
        field: 'terminstart'
      }, {
        name: 'Zimmer',
        field: 'zimmer',
        filterGenerator: function filterGenerator(termine) {
          var zimmer = (0, lodash_1.chain)(termine).map(function (t) {
            return t.zimmer;
          }).uniq().value();
          return (0, lodash_1.map)(zimmer, function (z) {
            return {
              name: z.toString(),
              count: termine.filter(function (t) {
                return t.zimmer === z;
              }).length,
              filter: function filter(termin) {
                return termin.zimmer === z;
              }
            };
          });
        }
      }, {
        name: 'Patient',
        field: 'name'
      }, {
        name: 'Status',
        field: 'status',
        filters: [{
          name: 'Alle',
          value: 'all',
          count: 0,
          filter: function filter(termin) {
            return true;
          }
        }, {
          name: 'Offen',
          value: 'open',
          count: 0,
          filter: function filter(termin) {
            return !termin.wartezimmerzeit && !termin.behandlungsendzeit && termin.status < 2;
          }
        }, {
          name: 'Erledigt',
          value: 'completed',
          count: 0,
          filter: function filter(termin) {
            return termin.behandlungsendzeit || termin.status > 2;
          }
        }]
      }, {
        name: 'Terminart',
        field: 'terminartbezeichnung',
        filterGenerator: function filterGenerator(termine) {
          var terminart = (0, lodash_1.chain)(termine).map(function (t) {
            return t.terminartbezeichnung;
          }).uniq().value();
          return (0, lodash_1.map)(terminart, function (ta) {
            return {
              name: ta,
              count: termine.filter(function (t) {
                return t.terminartbezeichnung === ta;
              }).length,
              filter: function filter(termin) {
                return termin.terminartbezeichnung === ta;
              }
            };
          });
        }
      }, {
        name: 'Behandler',
        field: 'terminBehandler',
        filterGenerator: function filterGenerator(termine) {
          var behandler = (0, lodash_1.chain)(termine).map(function (t) {
            return t.terminBehandler;
          }).uniq().value();
          return (0, lodash_1.map)(behandler, function (b) {
            return {
              name: b,
              count: termine.filter(function (t) {
                return t.terminBehandler === b;
              }).length,
              filter: function filter(termin) {
                return termin.terminBehandler === b;
              }
            };
          });
        }
      }]
    };
  },
  computed: {
    filteredTermine: function filteredTermine() {
      var _this = this;

      var searchOutput = [];

      if (this.searchInput) {
        searchOutput = this.termine.filter(function (termin) {
          var _termin$name, _termin$vorname;

          var search = (0, lodash_1.toLower)(_this.searchInput);
          return termin.status !== types_1.TerminStatusType.PRAXIS_HAT_ABGESAGT && (((_termin$name = termin.name) === null || _termin$name === void 0 ? void 0 : _termin$name.toLowerCase().includes(search)) || ((_termin$vorname = termin.vorname) === null || _termin$vorname === void 0 ? void 0 : _termin$vorname.toLowerCase().includes(search)) || termin.patid.toString().includes(search) || termin.terminartbezeichnung.toLowerCase().includes(search) || termin.terminBehandler.toLowerCase().includes(search) || termin.zimmer.toString().includes(search));
        });
      } else {
        searchOutput = this.termine.filter(function (termin) {
          return termin.status !== types_1.TerminStatusType.PRAXIS_HAT_ABGESAGT;
        });
      }

      var _iterator = _createForOfIteratorHelper(this.filterConfig),
          _step;

      try {
        var _loop = function _loop() {
          var filterGroup = _step.value;

          if (filterGroup.activeFilter) {
            searchOutput = (0, lodash_1.filter)(searchOutput, filterGroup.activeFilter.filter);
          }

          if (filterGroup.sortDirection === 'asc') {
            searchOutput = searchOutput.sort(function (a, b) {
              var aField = a[filterGroup.field];
              var bField = b[filterGroup.field];
              if (!aField || !bField) return 0;

              if (aField < bField) {
                return -1;
              }

              if (aField > bField) {
                return 1;
              }

              return 0;
            });
          } else if (filterGroup.sortDirection === 'desc') {
            searchOutput = searchOutput.sort(function (a, b) {
              var aField = a[filterGroup.field];
              var bField = b[filterGroup.field];
              if (!aField || !bField) return 0;

              if (aField > bField) {
                return -1;
              }

              if (aField < bField) {
                return 1;
              }

              return 0;
            });
          }
        };

        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        } // console.log('filteredTermine', searchOutput);

      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return searchOutput;
    }
  },
  methods: {
    logEntry: function logEntry(entry) {
      console.log('entry', entry);
    },
    activeFilterChanged: function activeFilterChanged(activeFilter, group) {
      var _iterator2 = _createForOfIteratorHelper(this.filterConfig),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var filterElement = _step2.value;

          if (filterElement.name === group.name) {
            vue_1["default"].set(filterElement, 'activeFilter', activeFilter);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    sortDirectionChanged: function sortDirectionChanged(sortDirection, group) {
      var _iterator3 = _createForOfIteratorHelper(this.filterConfig),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var filterElement = _step3.value;

          if (this.activeSortGroup && filterElement.name === this.activeSortGroup) {
            vue_1["default"].set(filterElement, 'sortDirection', undefined);
          }

          if (filterElement.name === group.name) {
            vue_1["default"].set(filterElement, 'sortDirection', sortDirection);
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      this.activeSortGroup = group.name;
    },
    updateDynamicData: function updateDynamicData(group) {
      var _this2 = this;

      // create filters
      if (group.filterGenerator) {
        vue_1["default"].set(group, 'filters', group.filterGenerator(this.termine));
      } // update counts


      if (group.filters) {
        var _iterator4 = _createForOfIteratorHelper(group.filters),
            _step4;

        try {
          var _loop2 = function _loop2() {
            var fc = _step4.value;
            fc.count = (0, lodash_1.filter)(_this2.termine, function (t) {
              return fc.filter(t);
            }).length;
          };

          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            _loop2();
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
      }
    }
  }
});