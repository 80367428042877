import { IDocumentPredefinedSignature, IPraxisBranding } from '.';
import { IDBModel } from './db';
import { FactoringGeschlechtToText, IFactoringPatientKomplett, IFactoringPraxisInfo } from './factoring';

export interface IAnamneseSettings {
  customQuestionnaireElements: IQuestionnaireElement[];
  questionnaireGroups: IQuestionnaireGroup[];
}

export interface IQuestionnaireGroup {
  name: string;
  questionnaires: IQuestionnaire[];
}

export interface IQuestionnaire {
  id: string;
  title: string;
  displayTitle: string;
  recallMonths: number;
  questionnairePages: IQuestionnairePage[];
  signaturePage: IQuestionnairePage;
  deactivated: boolean;
  prefillPatientDetails: boolean;
  prefillQuestions: boolean;
  skipReviewProcess: boolean;
  allowExternalAccess: boolean;
  updatedAt: number;
  updatedBy: string;
  // for license checks
  initialGeneratedHash: string;
}

export interface ICharlyContactInfo {
  id: number;
  value: string;
  type: CharlyContactInfoType;
  state: CharlyContactInfoState;
}

export type CharlyContactInfoType = 'EMAIL' | 'FAX' | 'PHONE' | 'PAGER' | 'SMS' | 'MOBILE' | 'TELEX';

export type CharlyContactInfoState = 'PRIVATE' | 'BUSINESS';

export interface ICharlyPatientDetails {
  birthday: string;
  birthplace: string;
  city: string;
  contacts?: ICharlyContactInfo[];
  country: string;
  firstName: string;
  gender: CharlyGender;
  lastName: string;
  patientId: number;
  street: string;
  title?: string;
  peerage?: string;
  zip: string;
}

export interface IAnamneseCharlyQuestionResponse {
  questionID: number;
  answer?: boolean;
  answerText?: string;
}

export interface IAnamneseCharlyQuestion {
  alert: boolean;
  position: number;
  question: string;
  questionID: number;
  questionLong: string;
  visibleForPatient: boolean;
  answer?: boolean;
  answerText?: string;
}

export interface IQuestionnaireQuestion {
  questionID: number;
  question: string;
  questionLong: string;
  valueType: CustomAnamneseQuestionTypes;
  optional: boolean;
  fullWidth: boolean;
  isCharlyQuestion?: boolean;
  valueTypeConfig?: any;
  isPracticeQuestion?: boolean;
}

export enum QuestionnaireElementType {
  PATIENT_INFO_FORM = 'PATIENT_INFO_FORM',
  QUESTION = 'QUESTION',
  RICH_TEXT = 'RICH_TEXT',
  DOCUMENT_PREVIEW = 'DOCUMENT_PREVIEW',
  DOCUMENT_SIGNATURE = 'DOCUMENT_SIGNATURE',
}

export interface IQuestionnaireElementBase {
  type: QuestionnaireElementType;
  id: string;
  initialGenerated?: boolean;
}

export interface IQuestionnairePatientInfoFieldConfig {
  [fieldId: string]: {
    hideField: boolean;
    optional: boolean;
  };
}

export interface IQuestionnairePatientInfoFormElement extends IQuestionnaireElementBase {
  type: QuestionnaireElementType.PATIENT_INFO_FORM;
  // singleton element with unique id
  id: 'PATIENT_INFO_FORM';
  fieldConfig?: IQuestionnairePatientInfoFieldConfig;
}
export interface IQuestionnaireDocumentPreviewElement extends IQuestionnaireElementBase {
  type: QuestionnaireElementType.DOCUMENT_PREVIEW;
  enableDrawInDocument?: boolean;
}
export interface IQuestionnaireDocumentSignatureElement extends IQuestionnaireElementBase {
  type: QuestionnaireElementType.DOCUMENT_SIGNATURE;
}

export interface IQuestionnaireRichTextElement extends IQuestionnaireElementBase {
  type: QuestionnaireElementType.RICH_TEXT;
  richText: string;
}
export interface IQuestionnaireQuestionElement extends IQuestionnaireElementBase {
  type: QuestionnaireElementType.QUESTION;
  question: IQuestionnaireQuestion;
}

export type IQuestionnaireElement =
  | IQuestionnairePatientInfoFormElement
  | IQuestionnaireRichTextElement
  | IQuestionnaireDocumentPreviewElement
  | IQuestionnaireDocumentSignatureElement
  | IQuestionnaireQuestionElement;

export function filterQuestionnaireElementsForType<T extends IQuestionnaireElement = IQuestionnaireElement>(
  elements: IQuestionnaireElement[],
  ...types: QuestionnaireElementType[]
) {
  return elements.filter(e => types.includes(e.type)) as T[];
}
export function filterQuestionnaireElementsForQuestions(elements: IQuestionnaireElement[]) {
  return filterQuestionnaireElementsForType<IQuestionnaireQuestionElement>(elements, QuestionnaireElementType.QUESTION);
}

export interface IQuestionnairePage {
  title: string;
  hidden: boolean;
  pageElements: IQuestionnaireElement[];
  pagebreak: boolean;
  id: string;
}

export interface ICharlyAnamneseConfig {
  importTimestamp: number;
  questions: IAnamneseCharlyQuestion[];
  countries: { country: string }[];
  affixes: { nameAffix: string }[];
  peerages: { peerage: string }[];
  titles: { title: string }[];
}

export enum CustomAnamneseQuestionTypes {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  RANGE = 'RANGE',
  RATING = 'RATING',
  INVOICE_LIST = 'INVOICE_LIST',
  INVOICE_LIST_SUMMARY = 'INVOICE_LIST_SUMMARY',
  EXISTING_SIGNATURE = 'EXISTING_SIGNATURE',
}

export const questionairePrefillQuestionTypes: CustomAnamneseQuestionTypes[] = [
  CustomAnamneseQuestionTypes.INVOICE_LIST,
  CustomAnamneseQuestionTypes.EXISTING_SIGNATURE,
];

export const isPracticeQuestion = (question: IQuestionnaireQuestion): boolean =>
  !!question.isPracticeQuestion || questionairePrefillQuestionTypes.includes(question.valueType);

export const getPrefillElementsFromQuestionnaire = (
  questionnaire: IQuestionnaire,
): { pageIndex: number; prefillElements: { elementIndex: number; element: IQuestionnaireQuestionElement }[] }[] =>
  [...questionnaire.questionnairePages, questionnaire.signaturePage]
    .map((page, pageIndex) => ({
      pageIndex,

      prefillElements: page.pageElements
        .map((element, elementIndex) => ({
          elementIndex,
          element,
        }))
        .filter(
          ({ element }) => element.type === QuestionnaireElementType.QUESTION && isPracticeQuestion(element.question),
        ) as { elementIndex: number; element: IQuestionnaireQuestionElement }[],
    }))
    .filter(page => page.prefillElements.length > 0);

export const questionTypeDisplayString: Record<CustomAnamneseQuestionTypes, string> = {
  [CustomAnamneseQuestionTypes.BOOLEAN]: 'Ja/Nein',
  [CustomAnamneseQuestionTypes.SELECT]: 'Auswahl',
  [CustomAnamneseQuestionTypes.TEXT]: 'Text',
  [CustomAnamneseQuestionTypes.NUMBER]: 'Zahl',
  [CustomAnamneseQuestionTypes.DATE]: 'Datum',
  [CustomAnamneseQuestionTypes.RATING]: 'Bewertung',
  [CustomAnamneseQuestionTypes.RANGE]: 'Schieber',
  [CustomAnamneseQuestionTypes.INVOICE_LIST]: 'Leistungen',
  [CustomAnamneseQuestionTypes.INVOICE_LIST_SUMMARY]: 'Leistungen Zusammenfassung',
  [CustomAnamneseQuestionTypes.EXISTING_SIGNATURE]: 'Arzt Unterschrift',

  // Ideen:
  // [CustomAnamneseQuestionTypes.TIME]: 'Zeit',
  // [CustomAnamneseQuestionTypes.DATETIME]: 'Datum und Zeit',
};

export const questionTypeIcon: Record<CustomAnamneseQuestionTypes, string> = {
  [CustomAnamneseQuestionTypes.BOOLEAN]: 'far fa-toggle-on',
  [CustomAnamneseQuestionTypes.RATING]: 'far fa-star',
  [CustomAnamneseQuestionTypes.SELECT]: 'far fa-square-check',
  [CustomAnamneseQuestionTypes.NUMBER]: 'far fa-input-numeric',
  [CustomAnamneseQuestionTypes.TEXT]: 'far fa-input-text',
  [CustomAnamneseQuestionTypes.RANGE]: 'far fa-slider',
  [CustomAnamneseQuestionTypes.DATE]: 'far fa-calendar-days',
  [CustomAnamneseQuestionTypes.INVOICE_LIST]: 'far fa-list-ol',
  [CustomAnamneseQuestionTypes.INVOICE_LIST_SUMMARY]: 'far fa-list-ol',
  [CustomAnamneseQuestionTypes.EXISTING_SIGNATURE]: 'far fa-signature',
};

export interface IAnamneseInfos {
  anamneseAnswers: IAnamneseCharlyQuestion[];
  patientDetails: ICharlyPatientDetails;
  patient: IFactoringPatientKomplett;
  charlyUser: string;
}

// TASK
export interface IAnamneseTaskInfo {
  // charly stammdaten
  charlyAnameseConfig: ICharlyAnamneseConfig;
  // task specific
  questionnaire: IQuestionnaire;
  // charly patient details
  patientDetails?: ICharlyPatientDetails;
  anamneseAnswers?: IAnamneseCharlyQuestion[];
  predefinedSignatures: IDocumentPredefinedSignature[];
  // other infos
  interpolationMap: QuestionnaireInterpolationMap;
  branding: IPraxisBranding;
  prefillAnswers?: Record<string, IQuestionnaireQuestionAnswer>;
}

// RESPONSE
export const AnamneseResponseMetaDataKeys = [
  'id',
  'cid',
  'patid',
  'languageKey',
  'questionnaireId',
  'source',
  'taskId',
  'charlyError',
  'responseTimestamp',
];
export interface IAnamneseResponseMetaData extends IDBModel {
  cid: string;
  patid?: string;
  languageKey: string;
  questionnaireId: string;
  source: AnamneseResponseSource;
  taskId: string;
  charlyError?: string;
  responseTimestamp: Date;
  /** if patid was reassigned, we store the original patid here */
  sourcePatid?: string;
}
export interface IAnamneseResponse extends IAnamneseResponseMetaData {
  response: IAnamneseResponseDetails;
  taskInfos: IAnamneseTaskInfo;
  taskCreated: Date;
  anamneseDocument: any; // Buffer
  documentName: string;
}
export interface IAnamnesePartialResponse {
  id: number;
  cid: string;
  questionnaireId: string;
  patid?: string;
  source: AnamneseResponseSource;
  createdAt: Date;
  questionnaireTitle: string;
  questionnaireDisplayTitle: string;
  patientName?: string;
  responsePatientName?: string;
}

export interface IQuestionnaireQuestionAnswer {
  primaryAnswer: any;
  comment: any;
}

export interface IQuestionnaireQuestionResponse {
  question: IQuestionnaireQuestion;
  answer: IQuestionnaireQuestionAnswer;
}

export type IQuestionnaireQuestionResponses = IQuestionnaireQuestionResponse[];
export interface IAnamneseResponseDetails {
  updatedPatientDetails: ICharlyPatientDetails | null;
  reviewModifiedPatientDetails?: ICharlyPatientDetails;
  questionResponses: IQuestionnaireQuestionResponses;
}
export enum AnamneseResponseSource {
  TABLET = 'TABLET',
  PATIENT_MAIL_SHARED = 'PATIENT_MAIL_SHARED',
  PATIENT_PUBLIC = 'PATIENT_PUBLIC',
  PATIENT_TAKEOVER = 'PATIENT_TAKEOVER',
  PATIENT_TAKEOVER_DEMO = 'PATIENT_TAKEOVER_DEMO',
  MANUAL = 'MANUAL',
}

export function formatAnamneseResponseSource(source: AnamneseResponseSource) {
  switch (source) {
    case AnamneseResponseSource.TABLET:
      return 'Praxis Tablet';
    case AnamneseResponseSource.PATIENT_MAIL_SHARED:
      return 'Patient per Mailfreigabe';
    case AnamneseResponseSource.PATIENT_TAKEOVER:
      return 'Patient per QR-Code';
    case AnamneseResponseSource.PATIENT_PUBLIC:
      return 'Patient per Link';
    default:
      return source;
  }
}

// Response Archive
export interface IAnamneseArchivedResponse extends IAnamneseResponseMetaData {
  archivedResponse: IAnamneseArchivedResponseDetails;
  sourceResponseId: string;
  responseTimestamp: Date;
  status: 'deleted' | 'accepted';
}
export interface IAnamneseArchivedResponseDetails {
  updatedPatientDetailsKeys: string[];
  reviewModifiedPatientDetailsKeys: string[];
  questionResponses: IQuestionnaireQuestionResponses;
}

export interface ISignatureDetails {
  pngString: string;
  strokeDataString: string;
  signatureName: string;
  signatureCity: string;
}

export const charlyGenderValueSet = [
  { key: 'MALE', value: 'männlich' },
  { key: 'FEMALE', value: 'weiblich' },
  { key: 'DIVERS', value: 'divers' },
  { key: 'UNKNOWN', value: 'unbekannt' },
] as const;

export type CharlyGender = typeof charlyGenderValueSet[number]['key'];

export const fullSpanQuestionnaireElementTypes = [
  QuestionnaireElementType.PATIENT_INFO_FORM,
  QuestionnaireElementType.RICH_TEXT,
  QuestionnaireElementType.DOCUMENT_PREVIEW,
  QuestionnaireElementType.DOCUMENT_SIGNATURE,
];
export function questionnaireElementIsFullWidth(pageElement: IQuestionnaireElement) {
  return (
    fullSpanQuestionnaireElementTypes.includes(pageElement.type) ||
    (pageElement.type === QuestionnaireElementType.QUESTION && pageElement.question.fullWidth)
  );
}

export const getTranslatedStringRaw = (obj: any, key: string, languageKey: string) => {
  let translationObjectKey = key + '_t';
  return obj[translationObjectKey]?.[languageKey] ?? obj[key];
};

export interface IQuestionnaireInterpolationString {
  key: string;
  label: string;
}
export const QUESTIONNAIRE_INTERPOLATION_STRINGS_PATIENT = [
  { key: 'pat_vorname', label: 'Pat. Vorname' },
  { key: 'pat_nachname', label: 'Pat. Nachname' },
  { key: 'pat_title', label: 'Pat. Titel' },
  { key: 'pat_anrede', label: 'Pat. Anrede' },
  { key: 'pat_full_name', label: 'Pat. Name Gesamt' },
  { key: 'pat_street', label: 'Pat. Straße' },
  { key: 'pat_zip', label: 'Pat. PLZ' },
  { key: 'pat_city', label: 'Pat. Stadt' },
  { key: 'pat_dob', label: 'Pat. Geb. Datum' },
  { key: 'pat_gender', label: 'Pat. Geschlecht' },
  { key: 'pat_gender_r', label: 'Pat. nachgestelltes r bei männlich' },
] as const;
export const QUESTIONNAIRE_INTERPOLATION_STRINGS_VERSICHERTER = [
  { key: 'vers_vorname', label: 'Versich. Vorname' },
  { key: 'vers_nachname', label: 'Versich. Nachname' },
  { key: 'vers_title', label: 'Versich. Titel' },
  { key: 'vers_anrede', label: 'Versich. Anrede' },
  { key: 'vers_full_name', label: 'Versich. Name Gesamt' },
  { key: 'vers_street', label: 'Versich. Straße' },
  { key: 'vers_zip', label: 'Versich. PLZ' },
  { key: 'vers_city', label: 'Versich. Stadt' },
  { key: 'vers_dob', label: 'Versich. Geb. Datum' },
  { key: 'vers_gender', label: 'Versich. Geschlecht' },
  { key: 'vers_gender_r', label: 'Versich. nachgestelltes r bei männlich' },
] as const;
export const QUESTIONNAIRE_INTERPOLATION_STRINGS_TIME = [
  { key: 'datum', label: 'Datum' },
  { key: 'datum_2', label: 'Datum (21. Mai 2020)' },
  { key: 'datum_3', label: 'Datum (Donnerstag, 21. Mai 2020)' },
  { key: 'datum_4', label: 'Datum (Do, 21.05.2020)' },
  { key: 'time', label: 'Uhrzeit' },
] as const;
export const QUESTIONNAIRE_INTERPOLATION_STRINGS_OTHER = [
  { key: 'charly_user', label: 'angemeldeter Benutzer' },
] as const;
export const QUESTIONNAIRE_INTERPOLATION_STRINGS_PRAXIS = [
  { key: 'praxis_name', label: 'Praxis Name' },
  { key: 'praxis_stempel', label: 'Praxis Stempel' },
  { key: 'praxis_adresse', label: 'Praxis Adresse' },
  { key: 'praxis_tel', label: 'Praxis Telefon' },
  { key: 'praxis_mail', label: 'Praxis Mail' },
] as const;
export const QUESTIONNAIRE_INTERPOLATION_STRINGS = [
  ...QUESTIONNAIRE_INTERPOLATION_STRINGS_PATIENT,
  ...QUESTIONNAIRE_INTERPOLATION_STRINGS_VERSICHERTER,
  ...QUESTIONNAIRE_INTERPOLATION_STRINGS_TIME,
  ...QUESTIONNAIRE_INTERPOLATION_STRINGS_OTHER,
  ...QUESTIONNAIRE_INTERPOLATION_STRINGS_PRAXIS,
  // { key: 'not-found-test', label: 'Unbekannt Test' },
] as const;

export type QuestionnaireInterpolationKey = typeof QUESTIONNAIRE_INTERPOLATION_STRINGS[number]['key'];
export type QuestionnaireInterpolationMap = Record<QuestionnaireInterpolationKey, string>;

// needs to run in client and server therefore no moment oder dayjs
function formatDate(dateStr?: string) {
  const d = dateStr ? new Date(dateStr) : new Date();

  const year = d.getFullYear();
  const date = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  return `${date}.${month}.${year}`;
}

export function invoiceListSummaryCollectInvoiceItems({
  questionnaireQuestions,
  showTotalItems,
  summaryElement,
  getTranslatedString,
  getAnswerForQuestion,
}: {
  questionnaireQuestions: IQuestionnaireQuestion[];
  showTotalItems: boolean;
  summaryElement: IQuestionnaireQuestion;
  getTranslatedString: CallableFunction;
  getAnswerForQuestion: (questionId: number) => any;
}) {
  let invoiceListQuestions = [];
  for (let question of questionnaireQuestions) {
    if (question === summaryElement) {
      break;
    }

    if (question.valueType === CustomAnamneseQuestionTypes.INVOICE_LIST) {
      invoiceListQuestions.push(question);
    }
    if (question.valueType === CustomAnamneseQuestionTypes.INVOICE_LIST_SUMMARY && !showTotalItems) {
      invoiceListQuestions = [];
    }
  }

  let invoiceLists = invoiceListQuestions.map(invoiceListQuestion => {
    let items = getAnswerForQuestion(invoiceListQuestion.questionID);
    return {
      name: getTranslatedString(invoiceListQuestion, 'questionLong'),
      sum: invoiceListSumForItems(items),
      items,
    };
  });
  let totalSum = invoiceListSumForItems(invoiceLists.map(l => l.items).flat());

  return {
    invoiceLists,
    totalSum,
  };
}

export function invoiceListSumForItems(items: { quantity: number; price: number }[]): string {
  let sum: number = items.reduce((subSum: number, item: any) => subSum + Number(item.quantity) * Number(item.price), 0);
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(sum);
}

export function createQuestionnaireInterpolationMap({
  patient,
  charlyUser,
  praxisInfos,
}: {
  patient?: IFactoringPatientKomplett;
  charlyUser?: string;
  praxisInfos: IFactoringPraxisInfo;
}): QuestionnaireInterpolationMap {
  let emptyPatient = {
    vorname: '',
    name: '',
    namenszusatz: '',
    strasse: '',
    plz: '',
    ort: '',
    geburtsdatum: '',
    geschlecht: '',
  };

  let usePatient = patient || emptyPatient;
  let versicherter = patient?.rechnungsempfaenger || usePatient;

  let map: QuestionnaireInterpolationMap = {
    // Patient
    pat_vorname: usePatient.vorname,
    pat_nachname: usePatient.name,
    pat_title: usePatient.namenszusatz || '',
    pat_anrede: usePatient.geschlecht === 'M' ? 'Herr' : usePatient.geschlecht === 'W' ? 'Frau' : '',
    pat_full_name: `${usePatient.namenszusatz ? usePatient.namenszusatz + ' ' : ''}${usePatient.vorname} ${
      usePatient.name
    }`,
    pat_street: usePatient.strasse,
    pat_zip: usePatient.plz,
    pat_city: usePatient.ort,
    pat_dob: formatDate(usePatient.geburtsdatum),
    pat_gender: FactoringGeschlechtToText[usePatient.geschlecht as keyof typeof FactoringGeschlechtToText] || '-',
    pat_gender_r: usePatient.geschlecht === 'M' ? 'r' : '',
    // Versicherter
    vers_vorname: versicherter.vorname,
    vers_nachname: versicherter.name,
    vers_title: versicherter.namenszusatz || '',
    vers_anrede: versicherter.geschlecht === 'M' ? 'Herr' : versicherter.geschlecht === 'W' ? 'Frau' : '',
    vers_full_name: `${versicherter.namenszusatz ? versicherter.namenszusatz + ' ' : ''}${versicherter.vorname} ${
      versicherter.name
    }`,
    vers_street: versicherter.strasse,
    vers_zip: versicherter.plz,
    vers_city: versicherter.ort,
    vers_dob: formatDate(versicherter.geburtsdatum),
    vers_gender: FactoringGeschlechtToText[versicherter.geschlecht as keyof typeof FactoringGeschlechtToText] || '-',
    vers_gender_r: versicherter.geschlecht === 'M' ? 'r' : '',

    // other
    charly_user: charlyUser || '-',

    // Dates
    datum: formatDate(),
    datum_2: new Date().toLocaleDateString('de-DE', { day: '2-digit', month: 'long', year: 'numeric' }),
    datum_3: new Date().toLocaleDateString('de-DE', {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }),
    datum_4: new Date().toLocaleDateString('de-DE', {
      weekday: 'short',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }),
    time: new Date().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }),

    // praxis
    praxis_name: praxisInfos.praxis,
    praxis_stempel: `${praxisInfos.stempelname1} ${praxisInfos.stempelname2}`,
    praxis_adresse: `${praxisInfos.strasse} ${praxisInfos.plz} ${praxisInfos.ort}`,
    praxis_tel: praxisInfos.telefon,
    praxis_mail: praxisInfos.email,
  };
  return map;
}

export const createExampleQuestionnaireInterpolationMap = () =>
  createQuestionnaireInterpolationMap({
    patient: exampleCharlyPatient,
    charlyUser: 'charly-user',
    praxisInfos: examplePraxisInfos,
  });

export const exampleCharlyPatientDetails: ICharlyPatientDetails = {
  zip: '41189',
  city: 'Musterstadt',
  title: 'Arzt',
  gender: 'UNKNOWN',
  street: 'Musterstraße 1',
  country: 'Deutschland',
  peerage: 'Graf',
  birthday: '2016-01-25',
  lastName: 'Musterfrau',
  firstName: 'Max',
  patientId: 7001,
  birthplace: 'Bonn',
  contacts: [
    {
      id: 77131,
      type: 'EMAIL',
      state: 'PRIVATE',
      value: 'musterman@email.de',
    },
    {
      id: 77132,
      type: 'PHONE',
      state: 'PRIVATE',
      value: '0049-0000000',
    },
  ],
};

export const exampleCharlyPatient: IFactoringPatientKomplett = {
  extid: '7001',
  vorname: 'Max',
  name: 'Mustermann',
  namenszusatz: 'Graf',
  titel: 'Dr.',
  strasse: 'Musterstraße 999',
  plz: '41189',
  ort: 'Musterstadt',
  geburtsdatum: '2016-01-21',
  email: 'musterman@mail.de',
  geschlecht: 'U',
  patientseit: '2050-01-01',
  beruf: 'Müllfrau XXX',
  arbeitgeber: 'Müllwerke Stadtwerke',
  versichertbei: 'AOK Rheinland/Hamburg',
  inland: true,
  land: 'D',
  rechnungsempfaenger: {
    extid: '91158',
    vorname: 'Erika',
    name: 'Mustermann',
    strasse: 'Musterstraße 3',
    plz: '41189',
    ort: 'Musterstadt',
    geburtsdatum: '2000-01-25',
    email: '',
    geschlecht: 'M',
    land: 'D',
  },
  hkps: [],
  erbrachteLeistungen: 0,
  rgan: 1,
  rganid: '91158',
  rginland: true,
  ref_p_simple: '7001$8719512615f3079c27e106d16e37523c',
  ref_p_full: '7001$63e849c3efb91bb95d03b6ce96c394f0',
  ref_re_simple: '7001$46a85f4a915fec87c7478de0e22025df',
  ref_re_full: '7001$8a6e8f3298a648a5f1ae4d8d0fe55428',
  ref_p_re_simple: '7001$7394c287d0ad5428fe0274d940777e05',
  ref_p_re_full: '7001$0ff8e1edb69b15a0c0810936621968a3',
  fieldhashes: {
    patient: {
      name: '7001$8c08e6a4d60aba3ae3ad42b85d798f85',
      vorname: '7001$6ca5baf72585b8aca2b4b0dc144551af',
      geburtsdatum: '7001$a6229d045e54e74dcb44b38448ffd8b7',
      strasse: '7001$89a67b0dd63657a8e809a1602abb2b98',
      plz: '7001$c2b574794f632f76ad0a916f01306288',
      ort: '7001$5f65ad92c670ca847828e007ccf6dc32',
    },
    re: {
      name: '7001$c78ffea6df9eaec0524413a16f962333',
      vorname: '7001$79593c7f944ba0bd5e7edce23f10a04f',
      geburtsdatum: '7001$25917b86beacc0eb40301e74ab0b50e4',
      strasse: '7001$068353397893b4bcd3c71b5b16a78e5e',
      plz: '7001$c2b574794f632f76ad0a916f01306288',
      ort: '7001$5f65ad92c670ca847828e007ccf6dc32',
    },
  },
  stammbehandler: '1',
  zuletztAbgerechnetGoz: new Date(),
  zuletztNichtAbgerechnetGoz: new Date(),
};

export const examplePraxisInfos: IFactoringPraxisInfo = {
  aktivepatienten: 19055,
  anzahlpatienten: 34685,
  appmode: 'DEMO',
  behandler: 43,
  email: 'info@demo-praxis.de',
  bank1: 'Apotheker- und Ärztebank',
  bic1: '',
  fax: '',
  iban1: '',
  kzvidnr: '',
  kzvname: '',
  kzvnummer: '',
  license: '',
  pvsversion: '',
  maxid: '',
  ort: 'Musterort',
  plz: '41189',
  praxis: 'Demo Praxis',
  praxis1: 'Demo Praxis 1',
  praxis2: 'Demo Praxis 2',
  stempelname1: 'Demo Praxis Stempel 1',
  stempelname2: 'Demo Praxis Stempel 2',
  stempelort: 'Musterort',
  stempelplz: '41189',
  stempelstrasse: 'Musterstraße 123',
  strasse: 'Musterstraße 123',
  telefon: '0012 3456789',
  terminbehandler: 68,
  rz: [
    {
      id: '6',
      nummer: '3',
      name: 'Health AG',
      kundennr: '2448',
      umsatz: 0,
    },
  ],
  termineprojahr: [{ jahr: 2020, anzahl: 12 }],
  umsatzprojahr: [{ jahr: 2020, anzahl: 26 }],
  stempel: [
    {
      id: '1',
      bank: 'Apotheker- und Ärztebank',
      bic: '',
      iban: '',
      kzvnummer: '',
      fax: '',
      stempelname1: 'Demo Praxis Stempel 1',
      stempelname2: 'Demo Praxis Stempel 2',
      ort: 'Musterort',
      plz: '41189',
      strasse: 'Musterstraße 123',
      behandler: ['1'],
    },
  ],
};
