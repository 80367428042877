import {
  CustomAnamneseQuestionTypes,
  IQuestionnaire,
  IQuestionnaireElement,
  QuestionnaireElementType,
} from './anamnese';
import { FEATURES } from './features';

export enum AnamneseCapability {
  EDIT_DESIGN_COLOR = 'EDIT_DESIGN_COLOR',
  EDIT_DESIGN_LOGO = 'EDIT_DESIGN_LOGO',
  CREATE_CUSTOM_QUESTIONNAIRE = 'CREATE_CUSTOM_QUESTIONNAIRE',
  CUSTOM_TEXT_FIELDS = 'CUSTOM_TEXT_FIELDS',
  CUSTOM_QUESTIONS = 'CUSTOM_QUESTIONS',
  CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES = 'CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES',
}

export type IQuestionnaireLicenceExtended = Omit<IQuestionnaire, 'questionnairePages' | 'signaturePage'> & {
  questionnairePages: null;
  signaturePage: null;
  mustBePrefilled: boolean;
  locked: boolean;
  licenceProblems: AnamneseLicenceProblem[];
};
export interface IQuestionnaireGroupLicenceExtended {
  name: string;
  questionnaires: IQuestionnaireLicenceExtended[];
}

export const AnamneseAdvancedQuestionTypes = [
  CustomAnamneseQuestionTypes.RATING,
  CustomAnamneseQuestionTypes.RANGE,
  CustomAnamneseQuestionTypes.INVOICE_LIST,
];

export interface IAnamneseCapabilityStatement {
  includedQuestionnaires: number;
  includedTaskFlows: number;
  capabilities: AnamneseCapability[];
}

export function getAnamneseCapabilityStatement(features: FEATURES[]): IAnamneseCapabilityStatement {
  const featureOrder = [
    FEATURES.ANAMNESE_WHITE,
    FEATURES.ANAMNESE_RED,
    FEATURES.ANAMNESE_BLACK,
    FEATURES.ANAMNESE_DIAMOND,
  ];

  let highestFeature: FEATURES | undefined;
  for (const feature of featureOrder) {
    if (features.includes(feature)) {
      highestFeature = feature;
    }
  }

  if (!highestFeature) {
    return {
      includedQuestionnaires: 0,
      includedTaskFlows: 0,
      capabilities: [],
    };
  }

  const capabilityMapping: Record<string, AnamneseCapability[]> = {
    [FEATURES.ANAMNESE_WHITE]: [],
    [FEATURES.ANAMNESE_RED]: [
      AnamneseCapability.EDIT_DESIGN_COLOR,
      AnamneseCapability.EDIT_DESIGN_LOGO,
      AnamneseCapability.CREATE_CUSTOM_QUESTIONNAIRE,
      AnamneseCapability.CUSTOM_TEXT_FIELDS,
      AnamneseCapability.CUSTOM_QUESTIONS,
    ],
    [FEATURES.ANAMNESE_BLACK]: [AnamneseCapability.CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES],
    [FEATURES.ANAMNESE_DIAMOND]: [],
  };

  const includedQuestionnairesMapping: Record<string, number> = {
    [FEATURES.ANAMNESE_WHITE]: 1,
    [FEATURES.ANAMNESE_RED]: 2,
    [FEATURES.ANAMNESE_BLACK]: Infinity,
    [FEATURES.ANAMNESE_DIAMOND]: Infinity,
  };

  const includedTaskFlowsMapping: Record<string, number> = {
    [FEATURES.ANAMNESE_WHITE]: 10,
    [FEATURES.ANAMNESE_RED]: 100,
    [FEATURES.ANAMNESE_BLACK]: Infinity,
    [FEATURES.ANAMNESE_DIAMOND]: Infinity,
  };

  // collect all capabilities upto to the highest feature
  const capabilities: AnamneseCapability[] = [];
  for (const feature of featureOrder) {
    capabilities.push(...capabilityMapping[feature]);
    if (feature === highestFeature) {
      break;
    }
  }

  let statement = {
    includedQuestionnaires: includedQuestionnairesMapping[highestFeature],
    includedTaskFlows: includedTaskFlowsMapping[highestFeature],
    capabilities,
  };

  const extraQuestionnaires = features.filter(f => f === FEATURES.ANAMNESE_EXTRA_QUESTIONNAIRE).length;
  statement.includedQuestionnaires += extraQuestionnaires;

  return statement;
}

// duplicated from base
function simpleHashCode(inputString: string) {
  let hash = 0;
  if (inputString.length === 0) {
    return hash;
  }
  for (let i = 0; i < inputString.length; i++) {
    let char = inputString.charCodeAt(i);
    // tslint:disable-next-line
    hash = (hash << 5) - hash + char;
    // tslint:disable-next-line
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
export function stableHashQuestionnaire(questionnaire: IQuestionnaire): string {
  let hashableSubset = {
    // dont include, hash itself, id, name, title, since they are allowed to be changed
    recallMonths: questionnaire.recallMonths,
    questionnairePages: questionnaire.questionnairePages,
    signaturePage: questionnaire.signaturePage,
    deactivated: questionnaire.deactivated,
    prefillPatientDetails: questionnaire.prefillPatientDetails,
    prefillQuestions: questionnaire.prefillQuestions,
  };

  // deep sort hashableSubset
  let sortedHashableSubset = JSON.parse(JSON.stringify(hashableSubset), (outerKey, value) => {
    if (value && typeof value === 'object') {
      return Object.keys(value)
        .sort()
        .reduce((obj: any, key) => {
          obj[key as keyof typeof obj] = value[key];
          return obj;
        }, {});
    }
    return value;
  });

  return 'v1' + simpleHashCode(JSON.stringify(sortedHashableSubset));
}

// a anamnese problem is either a missing capability or a extra questionnaire
export type AnamneseLicenceProblem = AnamneseCapability | FEATURES.ANAMNESE_EXTRA_QUESTIONNAIRE;

function featureCheck(
  cap: AnamneseCapability,
  problemCheck: CallableFunction,
  collectProblems: AnamneseLicenceProblem[],
  statement: IAnamneseCapabilityStatement | null | undefined,
) {
  if (statement?.capabilities.includes(cap)) {
    return;
  }

  if (problemCheck()) {
    collectProblems.push(cap);
  }
}

// feature checks for questionnaires, required by server and client
export const anamneseLicenceProblemChecks = {
  questionnaireInGroupProblems(
    questionnaires: IQuestionnaire[],
    questionnaireIndex: number,
    collectProblems: AnamneseLicenceProblem[],
    statement?: IAnamneseCapabilityStatement,
  ) {
    let questionnaire = questionnaires[questionnaireIndex];
    if (!questionnaire) {
      console.warn('questionnaire not found in anamneseSettings', questionnaireIndex, questionnaires);
      return;
    }
    let quesitonnaireMaxCount = statement?.includedQuestionnaires || 0;

    let precedingQuestionnaires = questionnaires.slice(0, questionnaireIndex).filter(q => !q.deactivated).length;
    if (precedingQuestionnaires + 1 > quesitonnaireMaxCount) {
      collectProblems.push(FEATURES.ANAMNESE_EXTRA_QUESTIONNAIRE);
    }

    this.questionnaireProblems(questionnaire, collectProblems, statement);
  },
  questionnaireProblems(
    questionnaire: IQuestionnaire,
    collectProblems: AnamneseLicenceProblem[],
    statement?: IAnamneseCapabilityStatement,
  ) {
    featureCheck(
      AnamneseCapability.CREATE_CUSTOM_QUESTIONNAIRE,
      () => stableHashQuestionnaire(questionnaire) !== questionnaire.initialGeneratedHash,
      collectProblems,
      statement,
    );

    let usedElms = questionnaire.questionnairePages.flatMap(p => p.pageElements);
    usedElms.forEach(elm => this.elementProblems(elm, collectProblems, statement));
  },
  elementProblems(
    elm: IQuestionnaireElement,
    collectProblems: AnamneseLicenceProblem[],
    statement?: IAnamneseCapabilityStatement,
  ) {
    // initialGenerated elements are always valid, eg. the initial text field, and textfields on the signature page
    if (elm.initialGenerated) {
      return collectProblems;
    }

    this.customQuestion(elm, collectProblems, statement);
    this.customQuestionAdvancesType(elm, collectProblems, statement);
    this.customText(elm, collectProblems, statement);
  },
  customQuestion(
    elm: IQuestionnaireElement,
    collectProblems: AnamneseLicenceProblem[],
    statement?: IAnamneseCapabilityStatement,
  ) {
    featureCheck(
      AnamneseCapability.CUSTOM_QUESTIONS,
      () => elm.type === QuestionnaireElementType.QUESTION && !elm.question.isCharlyQuestion,
      collectProblems,
      statement,
    );
  },
  customQuestionAdvancesType(
    elm: IQuestionnaireElement,
    collectProblems: AnamneseLicenceProblem[],
    statement?: IAnamneseCapabilityStatement,
  ) {
    featureCheck(
      AnamneseCapability.CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES,
      () =>
        elm.type === QuestionnaireElementType.QUESTION &&
        AnamneseAdvancedQuestionTypes.includes(elm.question.valueType),
      collectProblems,
      statement,
    );
  },
  customText(
    elm: IQuestionnaireElement,
    collectProblems: AnamneseLicenceProblem[],
    statement?: IAnamneseCapabilityStatement,
  ) {
    featureCheck(
      AnamneseCapability.CUSTOM_TEXT_FIELDS,
      () => elm.type === QuestionnaireElementType.RICH_TEXT,
      collectProblems,
      statement,
    );
  },
};
