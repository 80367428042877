"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    isEditable: function isEditable() {
      var _this$params$context;

      return !!((_this$params$context = this.params.context) !== null && _this$params$context !== void 0 && _this$params$context.isEditable);
    },
    icons: function icons() {
      return this.params.value;
    }
  },
  methods: {
    changedIconTitle: function changedIconTitle(title) {
      console.log('changedIconTitle', title);
      this.params.context.itemChanged({
        id: this.params.data.id,
        title: title
      });
    }
  }
});