import {
  HKPArtType,
  HKPPARPhaseType,
  HKPStatusType,
  PAGradType,
  PAREreignisFehlerLevel,
  PAREreignisFehlerTyp,
  PAREreignisGruppeTyp,
  PAREreignisPhaseTyp,
  PAREreignisTyp,
  PAType,
  TerminStatusType,
  UPTEventType,
} from './enums';
import { IPatientNameContainer } from './patient';

export const UPTIntervalMap = [
  PAREreignisGruppeTyp.UPT_I,
  PAREreignisGruppeTyp.UPT_II,
  PAREreignisGruppeTyp.UPT_III,
  PAREreignisGruppeTyp.UPT_IV,
  PAREreignisGruppeTyp.UPT_V,
  PAREreignisGruppeTyp.UPT_VI,
  PAREreignisGruppeTyp.UPT_VII,
  PAREreignisGruppeTyp.UPT_VIII,
  PAREreignisGruppeTyp.UPT_IX,
  PAREreignisGruppeTyp.UPT_X,
];

export const gruppenNamen = {
  [PAREreignisGruppeTyp.VORBEHANDLUNG]: 'Vorbehandlung',
  [PAREreignisGruppeTyp.HAUPTBEHANDLUNG]: 'Hauptbehandlung',
  [PAREreignisGruppeTyp.NACHBEHANDLUNG_BEVA]: 'Nachbehandlung / BEVa',
  [PAREreignisGruppeTyp.REEVALUATION_AIT]: 'Reevaluation',
  [PAREreignisGruppeTyp.CPT]: 'Chirurgische-Therapie',
  [PAREreignisGruppeTyp.REEVALUATION_CPT]: 'CPT-Nachbehandlung / BEVb',
};

export interface IPARLeistung {
  tag: Date;
  abrechnungsnummer: string;
  hkp: string;
  anzahl: number;
  betrag: number;
  abgerechnet: boolean;
}

export interface IPARTermin {
  extid: string;
  art: string;
  artbezeichnung: string;
  patyp: PAType;
  patypbezeichnung: string;
  status: TerminStatusType;
  dauer: number; // minutes
}

export interface IRohHKPPARStatusBase<T = Date> {
  extid: string;
  hkp: string;
  hkpstatus: HKPStatusType;
  grad: PAGradType;
  stadium: number;
  vier: number;
  aita: number;
  aitb: number;
  atg: number;
  mhu: number;
  upt: number;
  cpta: number;
  cptb: number;
  beva: number;
  diabetes: boolean;
  uebernommen: boolean;
  genehmigtam?: T;
  kaindexgrad: PAGradType;
  rauchengrad: PAGradType;
  diabetesgrad: PAGradType;
  gesamtbetrag: number;
  parodontitis: boolean;
  andereparodont: boolean;
  gutachterdatum?: T;
  letztesparoentgen?: T;
  parosysterkrankung: boolean;
  reichtroentgendiag: boolean;
  zahnverluststadium: number;
}

export interface IRohHKPPARStatus extends IPARPARStatus {
  patid: string;
  tabak: boolean;
  grad: PAGradType;
  kastadium: number;
  calstadium: number;
  kplxfaktorstadium: number;
  lokalisiert: boolean;
  generalisiert: boolean;
  molarinzisivmuster: boolean;
  kkkostenuebernahme: boolean;
  gutachter: string;
  genehmigtam: Date;
}

export interface IPARLeistungsInfos {
  leistungen: {
    tag: Date;
    abrechnungsnummer: string;
    anzahl: number;
  }[];
}

export interface IHKPPARStatus extends IRohHKPPARStatus, IPARLeistungsInfos {
  phase: HKPPARPhaseType;
}

export interface IPARPARStatus<T = Date> extends IRohHKPPARStatusBase<T> {
  phase: HKPPARPhaseType;
}

export interface IPARPSIAbrechnung {
  behandler: string;
  betrag: number;
  leistungen: string[];
}

export interface IPARPSIBefund {
  s1: string;
  s2: string;
  s3: string;
  s4: string;
  s5: string;
  s6: string;
  bad: boolean;
}

export interface IPARRoentgen<T = Date> {
  tag?: T;
  abrechnungsnummer: string;
  anzahl: number;
}

export interface IPARVorschlag {
  regel: string;
  anzahlplus: number;
  umsatzpotential: number;
}

export interface IPAREvent<T = Date> extends IPARLeistungsInfos {
  id: number;
  patid: string;
  tag: T;
  leistungen: IPARLeistung[];
  termin: IPARTermin;
  hkp: IPARHKP<T>;
  parstatusid: string;
  parstatus: IPARPARStatus<T>;
  psiabrechnungen: IPARPSIAbrechnung[];
  psibefunde: IPARPSIBefund[];
  roentgen: IPARRoentgen<T>[];
  vorschlaege: IPARVorschlag[];
}

export interface IPAREventExtended<T = Date> extends IPAREvent<T> {
  uptTyp: UPTEventType;
  uptNr?: number; // upt nummer
}

export interface IPARPatient extends IPatientNameContainer {
  patid: string;
}

export interface IPARResult {
  patienten: IPARPatient[];
}

export const PAREreignisGruppenNichtUPT = [
  PAREreignisGruppeTyp.VORBEHANDLUNG,
  PAREreignisGruppeTyp.HAUPTBEHANDLUNG,
  PAREreignisGruppeTyp.REEVALUATION_AIT,
  PAREreignisGruppeTyp.NACHBEHANDLUNG_BEVA,
  PAREreignisGruppeTyp.CPT,
  PAREreignisGruppeTyp.REEVALUATION_CPT,
];

export const PAREreignisGruppenUPT = [
  PAREreignisGruppeTyp.UPT_I,
  PAREreignisGruppeTyp.UPT_II,
  PAREreignisGruppeTyp.UPT_III,
  PAREreignisGruppeTyp.UPT_IV,
  PAREreignisGruppeTyp.UPT_V,
  PAREreignisGruppeTyp.UPT_VI,
  PAREreignisGruppeTyp.UPT_VII,
  PAREreignisGruppeTyp.UPT_VIII,
  PAREreignisGruppeTyp.UPT_IX,
  PAREreignisGruppeTyp.UPT_X,
];

export const PAREreignisGruppenCPT = [PAREreignisGruppeTyp.CPT, PAREreignisGruppeTyp.REEVALUATION_CPT];

export enum PAREreignisGruppeZeitTyp {
  VERGANGEN = 'VERGANGEN',
  AKTUELL = 'AKTUELL',
  ZUKUNFT = 'ZUKUNFT',
}

export interface IPAREreignisFehlerBase {
  text: string;
  level: PAREreignisFehlerLevel;
  countValue: number;
  typ: PAREreignisFehlerTyp;
  phase?: PAREreignisPhaseTyp;
  debugInfo?: string;
}

export interface IPAREreignisFehler<T = Date> extends IPAREreignisFehlerBase {
  tag?: T; // TODO: macht das sinn? 1. der tag an sich und 2. dass er null sein kann?
}

export interface IPAREreignisLeistungen {
  [nummer: string]: {
    anzahl: number;
    betrag: number;
  };
}

export interface IPAREreignis<T = Date> {
  tag: T | undefined;
  typ: PAREreignisTyp;
  beschreibung: string | undefined;
  termin: IPARTermin | undefined; // bester termin
  leistungen: IPAREreignisLeistungen;
  vorschlaege: IPARVorschlag[] | undefined;
  betragabgerechnet: number | undefined;
  betragoffen: number | undefined;
  hinweis: string;
  fehler: IPAREreignisFehlerBase | undefined;
  offset: number; // should be show before or after ereignis with same tag
  ignoreBetraege: boolean;
  uptTyp: UPTEventType;
  uptNr?: number; // upt nummer
  meta?: any;
}

export interface IPARHKP<T = Date> {
  extid: string;
  status: HKPStatusType;
  art: HKPArtType;
  tag?: T;
  von?: T;
  bis?: T;
  verlaengertbis?: T;
  datumgenehmigt?: T;
  honorar: number;
  betragleistungengeplant: number;
  betragleistungenerbracht: number;
  betragleistungenabgerechnet: number;
  best?: boolean;
}

export interface IPAREreignisGruppe<T = Date> {
  group: PAREreignisGruppeTyp;
  zeit?: PAREreignisGruppeZeitTyp;
  name: string;
  start?: T;
  ende?: T;
  startCalendar?: T;
  endeCalendar?: T;
  ereignisse: IPAREreignis<T>[];
  showTerminPlaceholder: boolean;
}

export interface IPAREreignisGruppeExtended<T = Date> extends IPAREreignisGruppe<T> {
  hide: boolean;
}

export interface IPARCaseHonorarInfoEntry {
  gesamt: number;
  erbracht: number;
  abgerechnet: number;
  nichtAbgerechnet: number;
}

export interface IPARCaseHonorarInfo {
  par: IPARCaseHonorarInfoEntry;
  cpt: IPARCaseHonorarInfoEntry;
  upt: IPARCaseHonorarInfoEntry;
  gesamt: IPARCaseHonorarInfoEntry;
}

export interface IPARCaseInfoGruppe<T = Date> {
  [gt: string]: IPAREreignisGruppe<T>;
}

export enum CPTPlanStatus {
  KEINE_CPT_GEPLANT = 'KEINE_CPT_GEPLANT',
  NUR_ANTRAG = 'NUR_ANTRAG',
  KAPUTT_WEGEN_UPT_LEISTUNGEN = 'KAPUTT_WEGEN_UPT_LEISTUNGEN',
  CPT_GEPLANT = 'CPT_GEPLANT',
  CPT_DURCHGEFUEHRT = 'CPT_DURCHGEFUEHRT',
}

export interface IPARCaseInfoBasis<T = Date> {
  patid: string;
  von?: T;
  bis?: T;
  parstatusid: string;
  parstatus: IPARPARStatus<T>;
  hkp: IPARHKP<T> | undefined;
  fehler: IPAREreignisFehler<T>[];
  uptparams: IUPTIntervalParamsEntry;
  problemEreignisse: IPAREreignis<T>[];
  honorare: IPARCaseHonorarInfo;
  cptPlanStatus: CPTPlanStatus; // wie ist der status des cpt
  endOfUPT?: T;
  finished: boolean;
}

export interface IPARCaseInfoTemp<T = Date> extends IPARCaseInfoBasis<T> {
  gruppen: IPARCaseInfoGruppe<T>;
}

export interface IPARCaseInfo<T = Date> extends IPARCaseInfoBasis<T> {
  gruppen: IPAREreignisGruppe<T>[];
  currentview: PAREreignisPhaseTyp;
  disableupt: boolean;
  showcpt: boolean;
  fehleranzahl: {
    all: number;
    par: number;
    cpt: number;
    upt: number;
  };
  compliance: {
    uptCountGKV: number;
    uptCountPKV: number;
  };
}

export interface IUPTIntervalParamsEntry {
  grad: PAGradType;
  minDistInMonths: number;
  intervalLength: number;
  uptCount: number;
  intervalName: string;
}

export interface IUPTIntervalParams {
  [grad: string]: IUPTIntervalParamsEntry;
}

export interface IPARMiniInfo {
  patid: string;
  von: Date;
  bis: Date;
  parstatusid: string;
  hkpid: string;
  grad: PAGradType;
}
