import { PvsType } from './settings';

export interface IBenchmarkHKPStatusDetails {
  eingabe: IBenchmarkHKPDetail;
  genehmigt: IBenchmarkHKPDetail;
  durchgefuehrt: IBenchmarkHKPDetail;
  verworfen: IBenchmarkHKPDetail;
  abgelehnt: IBenchmarkHKPDetail;
}

export interface IBenchmarkHKPDetail {
  count: number;
  betrag: number;
  ids: string[];
}

export interface IBenchmark {
  tag?: Date;
  m?: Date;
  behandler?: string;
  behandlerIds?: string[];
  team?: string;
  anzahlbehandler?: number;
}

export interface IBenchmarkResult {
  baseSet?: IBenchmark;
  selfCompareSet?: IBenchmark;
  compareSets?: IBenchmark[];
  historySet?: any;
}

export interface IBenchmarkDetails {
  behandler: string;
  team: string;
  patienten: number;
  termine: number;
  honorar: number;
  relativeBehandlungszeit: number;
  angeboteneBehandlungszeit: number;
  stattgefundeneBehandlungszeit: number;
  konfiguriert: boolean;
  terminBehandler: string[];
  isTeam: boolean;
  teamColor?: string;
  teamSize?: number;
  teamName?: string;
}

export interface IBenchmarkDetailsResult {
  za: IBenchmarkDetails[];
  pzr: IBenchmarkDetails[];
  none: IBenchmarkDetails[];
  zaCount?: number;
  pzrCount?: number;
  noneCount?: number;
}

export enum IBenchmarkConfigTrendType {
  smallerisbetter = 'smallerisbetter',
  biggerisbetter = 'biggerisbetter',
  none = 'none',
  nonebutabsolut = 'nonebutabsolut',
}

export enum BenchmarkConfigProLevelType {
  NEWBIE = 0,
  APPRENTICE = 10,
  MASTER = 20,
}

export interface IBenchmarkConfigItemBase {
  name: string;
  shortName?: string;
  trendType?: IBenchmarkConfigTrendType; // if this is true smaller values are better and therefore trend to the negative will be green
  value?: string; // this must be a simple string of an existing property of IBenchmark
  virtual?: boolean; // if this is true eval the value
  unit?: string;
  unitString?: string;
  smartValue?: string; // this is js that will be evaled!!!!! use variablename b to refer to the current IBenchmark object!! use self to refer to to the value of the current item
  smartUnit?: string; // just plain string used for smart values unit
  formatHint?: string; // this is numeral.js format string
  help?: string;
  isHeader?: boolean;
  noAvg?: boolean; // do not show average for those values
  collapsed?: boolean; // is this collapsed
  visible?: boolean; // is this visible, nutzen wir für neue tabs
  isTab?: boolean;
  classes?: string[]; // add this class to row
  dividedClasses?: string[]; // classes for divided value field
  debug?: string[];
  hideWhenFiltered?: boolean;
  canFilterByPatid?: boolean;
  color?: string;
  noHistory?: boolean; // kpi kann keine history haben (NOCH NICHT...)
  beta?: boolean; // kpi nur bei beta kunden anzeigen
  proLevel?: BenchmarkConfigProLevelType;
  excludeForPVS?: PvsType[];
}

export interface IBenchmarkConfigItemRaw extends IBenchmarkConfigItemBase {
  childs?: { [key: string]: IBenchmarkConfigItemRaw };
  disabled?: string[]; // environments where this is disabled
  isRootNode?: boolean;
}

export interface IBenchmarkConfigItem extends IBenchmarkConfigItemBase {
  key: string;
  childs: IBenchmarkConfigItem[];
  parent?: IBenchmarkConfigItem;
  path?: string[];
  filterInclude?: boolean;
  filterExclude?: boolean;
  favorite?: boolean;
  isRootNode?: boolean;
  idx?: number; // for row coloring
  level?: number; // for row indenation
  tab?: string; // tab name
}

export function emptyBenchmark(): any {
  let emptyHkpDetail = {
    gesamt: { ids: [], count: 0, betrag: 0 },
    eingabe: { ids: [], count: 0, betrag: 0 },
    erstellt: { ids: [], count: 0, betrag: 0 },
    abgelehnt: { ids: [], count: 0, betrag: 0 },
    genehmigt: { ids: [], count: 0, betrag: 0 },
    verworfen: { ids: [], count: 0, betrag: 0 },
    durchgefuehrt: { ids: [], count: 0, betrag: 0 },
  };
  return {
    createdAt: new Date(),
    tag: new Date(),
    monat: 0,
    jahr: 0,
    behandler: '',
    behandlerIds: [],
    team: '',
    anzahlbehandler: 0,
    // terminzeiten
    tzAvgBehandlung: 0,
    tzAvgBehandlungszimmer: 0,
    tzAvgTermin: 0,
    tzAvgWartezimmer: 0,
    tzMedianBehandlung: 0,
    tzMedianBehandlungszimmer: 0,
    tzMedianTermin: 0,
    tzMedianWartezimmer: 0,
    tzAvgDiffPlanRealStart: 0,
    tzMedianDiffPlanRealStart: 0,
    // umsatz
    honorar: 0,
    honorarbema: 0,
    honorarerstattung: 0,
    honorargoz: 0,
    honorargozkasse: 0,
    honorargozprivat: 0,
    material: 0,
    eigenlaborleistungen: 0,
    eigenlabormaterial: 0,
    fremdlabor: 0,
    // leistungen
    leistungenGOZA: 0,
    leistungenGOZB: 0,
    leistungenGOZC: 0,
    leistungenGOZD: 0,
    leistungenGOZE: 0,
    leistungenGOZF: 0,
    leistungenGOZG: 0,
    leistungenGOZH: 0,
    leistungenGOZJ: 0,
    leistungenGOZK: 0,
    leistungenGOZL: 0,
    leistungenGOZGAE: 0,
    leistungenBEMAGesamt: 0,
    leistungenGOZGesamt: 0,
    leistungenGesamt: 0,
    // patienten
    alter: 0,
    alterNull: 0,
    altersPyramide: {},
    patienten: 0,
    patientenMann: 0,
    patientenFrau: 0,
    patientenDivers: 0,
    patientenGeschlechtNull: 0,
    patientenKind: 0,
    patientenJugendlich: 0,
    patientenErwachsen: 0,
    patientenNeu: 0,
    patientenPrivat: 0,
    patientenKasse: 0,
    patientenKassePrivatNull: 0,
    patientenPa: 0,
    patientenPaLetzte24Monate: 0,
    patientenPaJemals: 0,
    patientenPzrErwachsen: 0,
    patientenPzrKinder: 0,
    patientenPzrQuoteErwachsen: 0,
    patientenPzrQuoteKinder: 0,
    patientenMitRecall: 0,
    patientenMitPsiBefund: 0,
    patientenMitPsiBefundKinder: 0,
    patientenMitPsiAbrechnung: 0,
    patientenMitPsiAbrechnungKinder: 0,
    patientenMitSchlechtemPsiBefund: 0,
    patientenCompliance: 0,
    alterNullIds: [],
    patientenIds: [],
    patientenMannIds: [],
    patientenFrauIds: [],
    patientenDiversIds: [],
    patientenGeschlechtNullIds: [],
    patientenKindIds: [],
    patientenJugendlichIds: [],
    patientenErwachsenIds: [],
    patientenNeuIds: [],
    patientenPrivatIds: [],
    patientenKasseIds: [],
    patientenKassePrivatNullIds: [],
    patientenPaIds: [],
    patientenPaLetzte24MonateIds: [],
    patientenPaJemalsIds: [],
    patientenPzrErwachsenIds: [],
    patientenPzrKinderIds: [],
    patientenPzrQuoteErwachsenIds: [],
    patientenPzrQuoteKinderIds: [],
    patientenMitRecallIds: [],
    patientenOhneTerminEntlassenIds: [],
    patientenMitPsiBefundIds: [],
    patientenMitPsiBefundKinderIds: [],
    patientenMitSchlechtemPsiBefundIds: [],
    patientenMitPsiAbrechnungIds: [],
    patientenMitPsiAbrechnungKinderIds: [],
    // behandlungs zeiten: behandler
    bzB1: 0,
    bzB2a: 0,
    bzB2b: 0,
    bzB2c: 0,
    bzB2d: 0,
    bzB3a: 0,
    bzB3b: 0,
    bzB3c: 0,
    bzB3d: 0,
    bzB3e: 0,
    bzB3f: 0,
    bzB4a: 0,
    bzB4b: 0,
    bzB4c: 0,
    bzB4d: 0,
    // behandlungs zeiten: terminbehandler
    bzTB6a: 0,
    bzTB6b: 0,
    bzTB6c: 0,
    bzTB6d: 0,
    bzTB6e: 0,
    // termine
    termine: 0,
    termineNichtErschienen: 0,
    termineWahrgenommen: 0,
    termineAbgesagt: 0,
    termineNichtErschienenUndAbgesagt: 0,
    termineOK: 0,
    termineOhneStatus: 0,
    termineStatusFehler: 0,
    termineKontrolliertBehandler: 0,
    termineKontrolliertAbrechnung: 0,
    termineKontrolliertAlle: 0,
    // hkp
    hkp: emptyHkpDetail,
    hkpPrivat: emptyHkpDetail,
    hkpPrivatAllgemein: emptyHkpDetail,
    hkpPrivatKBrFAL: emptyHkpDetail,
    hkpPrivatPA: emptyHkpDetail,
    hkpPrivatImpla: emptyHkpDetail,
    hkpPrivatZE: emptyHkpDetail,
    hkpPrivatKFO: emptyHkpDetail,
    hkpKasse: emptyHkpDetail,
    hkpKasseZE: emptyHkpDetail,
    hkpKasseKFO: emptyHkpDetail,
    hkpKassePA: emptyHkpDetail,
    hkpKasseKBrFAL: emptyHkpDetail,
    hkpTageBisGenehmigt: 0,
    hkpTageBisDurchgefuehrt: 0,
    hkpTageErstelltBisDurchgefuehrt: 0,
    tbAngebotGesamt: 0,
    tbAngebotGesamtStattgefunden: 0,
  };
}

export enum BenchmarkParts {
  PATIENTEN = 'patientenTab',
  TERMINE = 'termineTab',
  UMSATZ = 'umsatzTab',
  HKPS = 'hkpTab',
  BEHANDLUNGEN = 'behandlungenTab',

  DETAILS = 'details',
}

export interface IBenchmarkGlobalGroup {
  id: any;
  name: string;
  memberships: string[]; // list if clientids
}

export enum BenchmarkAnalysisType {
  ENTWICKLUNG = 'entwicklung',
  BENCHMARK = 'benchmark',
}

export enum BenchmarkEntwicklungType {
  MM = 'mm',
  MJ = 'mj',
}

export enum BenchmarkBenchmarkType {
  PRAXIS = 'praxis',
  EXTERN = 'extern',
}

export enum BenchmarkMonatsType {
  YEARPARTLY = 'y',
  YEAR = 'Y',
  QUARTER = 'q',
  MONTH = 'm',
}

export interface IBenchmarkMonatInfo {
  name: string;
  mtyp: BenchmarkMonatsType;
  m?: number;
}

export interface IBenchmarkQueryTypeInfo {
  atyp: BenchmarkAnalysisType;
  etyp: BenchmarkEntwicklungType;
  btyp: BenchmarkBenchmarkType;
  freerange: boolean;
}

export interface IBenchmarkBaseTimeInfo extends IBenchmarkMonatInfo, IBenchmarkQueryTypeInfo {
  jahr: number;
}

export interface IBenchmarkColumnInfoBase {
  ids: string[];
  jahr: number | undefined;
  quartal: number | undefined;
  monat: number | undefined;
  mtyp: BenchmarkMonatsType | undefined;
  from: string | undefined; // YYYY-MM-DD
  to: string | undefined; // YYYY-MM-DD
}

export interface IBenchmarkColumnInfo extends IBenchmarkColumnInfoBase {
  header: string;
}

export interface IBenchmarkColumnInfoParams extends IBenchmarkColumnInfoBase, IBenchmarkQueryTypeInfo {
  parts: BenchmarkParts[];
}

export interface IBenchmarkQueryFilterConfig {
  include?: boolean;
  exclude?: boolean;
  idKeys: string[];
  tab: BenchmarkParts;
}
