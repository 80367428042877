import { IBenchmarkConfigItemRaw } from '../../../types';

export function benchmarkConfigRezepteHmvAuKt(): IBenchmarkConfigItemRaw {
  return {
    name: 'Rezepte, Verordnungen & AU',
    help: 'Im ausgewählten Zeitraum ausgestellte Rezepte, Heilmittelverordnungen, Krankentransporte und Arbeitsunfähigkeitsbescheinigungen.',
    isHeader: true,
    value: 'b.rezeptegesamt',
    virtual: true,
    childs: {
      _rezepte: {
        name: 'Ausgestellte Rezepte',
        value: 'b.rezeptegesamt',
        virtual: true,
        help: 'Augestellte Rezepte.',
        collapsed: false,
        childs: {
          rezeptegesamt: {
            name: 'Gesamt',
            smartValue: '100',
            smartUnit: '%',
            help: 'Im ausgewählten Zeitraum ausgestellte Rezepte',
            debug: ['rezeptegesamtIds'],
            canFilterByPatid: true,
          },
          rezepteprivat: {
            name: 'Privat',
            smartValue: 's / b.rezeptegesamt * 100',
            smartUnit: '%',
            help: 'Im ausgewählten Zeitraum ausgestellte Privat-Rezepte',
            debug: ['rezepteprivatIds'],
            canFilterByPatid: true,
          },
          rezeptekasse: {
            name: 'Kasse',
            smartValue: 's / b.rezeptegesamt * 100',
            smartUnit: '%',
            help: 'Im ausgewählten Zeitraum ausgestellte Kassen-Rezepte',
            debug: ['rezeptekasseIds'],
            canFilterByPatid: true,
          },
        },
      },
      hmvgesamt: {
        name: 'Heilmittelverordnungen',
        help: 'Im ausgewählten Zeitraum ausgestellte Heilmittelverordnungen',
        debug: ['hmvgesamtIds'],
        canFilterByPatid: true,
      },
      augesamt: {
        name: 'Arbeitsunfähigkeitsbescheinigungen',
        help: 'Im ausgewählten Zeitraum ausgestellte Arbeitsunfähigkeitsbescheinigungen',
        debug: ['augesamtIds'],
        canFilterByPatid: true,
      },
      krankentransportegesamt: {
        name: 'Krankentransporte',
        help: 'Im ausgewählten Zeitraum ausgestellte Krankentransporte',
        debug: ['krankentransportegesamtIds'],
        canFilterByPatid: true,
      },
    },
  };
}
