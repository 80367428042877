import { IPatientNameContainer } from './patient';

export interface ILocation {
  lat: number;
  lon: number;
  osmid: number;
}

export interface INominatimSearchResult {
  osm_id: number;
  importance: number;
  category: string;
  type: string;
  lat: number;
  lon: number;
  rawData?: any;
}

export interface ILocationResult extends ILocation {
  hash: string;
}

export interface IPatientLocation extends ILocation {
  patid: string;
  hash: string;
  privat: boolean;
  age: number;
  anzahlTermine: number;
  letzterTermin: Date;
  umsatz: number;
  distance?: number;
}

export interface IPatientLocationWithDetails extends IPatientLocation, IPatientNameContainer {}

export interface IPatientenLocationsQueryParams {
  from?: string;
  to?: string;
  filter?: LocationsSearchDateFilter;
  versicherung?: LocationsSearchVersicherungFilter;
  team?: string;
}

export enum LocationsSearchDateFilter {
  Alle = 'Alle',
  LetzterTermin = 'LetzterTermin',
  Neupatienten = 'Neupatienten',
}

export enum LocationsSearchVersicherungFilter {
  Alle = 'Alle',
  Privat = 'Privat',
  Kasse = 'Kasse',
}
