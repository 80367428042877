import { IBenchmarkConfigItemRaw, IStichwortParam } from '../../../types';

export function benchmarkConfigStichworte(topStichworte: IStichwortParam[]): IBenchmarkConfigItemRaw {
  const childs: { [key: string]: IBenchmarkConfigItemRaw } = {};
  for (const s of topStichworte.sort((a, b) => a.name.localeCompare(b.name))) {
    const n = s.name || s.kuerzel || '??';
    childs[n] = {
      name: n,
      unit: 'patient',
      virtual: true,
      value: `b.patientenStichworte['${n}']?.anzahl`,
      help: `Patienten, die ${n} als Stichwort haben`,
      debug: [`patientenStichworte['${n}'].ids`],
      canFilterByPatid: true,
      color: s.farbe,
    };
  }
  return {
    name: 'Stichworte',
    isHeader: true,
    childs,
  };
}
