"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.some.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var rechnungenStore_1 = require("@/state/rechnungenStore");

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: null
    };
  },
  computed: {
    showExtendedSummaryRow: function showExtendedSummaryRow() {
      return rechnungenStore_1.rechnungenStore.getters.showExtendedSummaryRow;
    },
    filterActive: function filterActive() {
      var _this$params, _this$params$data;

      if (!((_this$params = this.params) !== null && _this$params !== void 0 && (_this$params$data = _this$params.data) !== null && _this$params$data !== void 0 && _this$params$data.spezialFilter)) {
        return false;
      }

      var activeFilterKeys = rechnungenStore_1.rechnungenStore.getters.activeFilterKeys;
      return this.params.data.spezialFilter.some(function (filterKey) {
        return activeFilterKeys.includes(filterKey);
      });
    }
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    // this.cellValue = this.getValueToDisplay(this.params);
    this.setValues(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues(params);
      return true;
    },
    setValues: function setValues(params) {
      this.params = params;
    }
  }
});