import { FEATURES } from '../../types';
import { ViewID } from './views';

export const DEFAULTVIEWS = [ViewID.PROFIL, ViewID.NEWS];

// these views depend on admin rights, not on selection
export const UNSELECTABLEVIEWS = [ViewID.EINSTELLUNGEN_OLD, ViewID.EINSTELLUNGEN];

export const FEATUREVIEWS: { [x: string]: ViewID[] } = {
  [FEATURES.METRICS]: [
    ViewID.HEUTE,
    ViewID.BENCHMARK_OLD,
    ViewID.UMSATZ_TABELLE,
    ViewID.UMSATZ_MATRIX,
    ViewID.UMSATZ_CHART,
    ViewID.TERMIN_QUALITAET,
    ViewID.HVM,
    ViewID.LEISTUNGEN_VUI,
    ViewID.PATIENTENUMSAETZE,
    ViewID.RECHNUNGEN,
    ViewID.HKP_MANAGER,
    ViewID.PA_MANAGER,
    ViewID.ABRECHNUNG,
    ViewID.PATIENTEN,
    ViewID.EXTENDEDQUERY,
    ViewID.PATIENTEN_PORTAL,
    ViewID.PATIENTEN_MAP,
    ...DEFAULTVIEWS,
  ],
  [FEATURES.BETA]: [ViewID.BENCHMARK, ViewID.COMPLIANCE, ViewID.ZIMMERBELEGUNG],
  [FEATURES.DOCTOSYNC]: [ViewID.DOCTOSYNC],
  [FEATURES.DSO]: [ViewID.DSO],
  [FEATURES.FACTORING]: [ViewID.FACTORING, ViewID.PATIENTEN_PORTAL],
  [FEATURES.DOCUMENTS_SMALL]: [ViewID.PATIENTEN_PORTAL],
  [FEATURES.DOCUMENTS_BIG]: [ViewID.PATIENTEN_PORTAL],
  [FEATURES.DOCUMENTS_FLAT]: [ViewID.PATIENTEN_PORTAL],
  [FEATURES.ANAMNESE_WHITE]: [ViewID.PATIENTEN_PORTAL],
  [FEATURES.ANAMNESE_RED]: [ViewID.PATIENTEN_PORTAL],
  [FEATURES.ANAMNESE_BLACK]: [ViewID.PATIENTEN_PORTAL],
  [FEATURES.ANAMNESE_DIAMOND]: [ViewID.PATIENTEN_PORTAL],
  [FEATURES.PRAXISREPORT]: [ViewID.PRAXIS_KPI],
  [FEATURES.DEBUG]: [ViewID.DEBUG, ViewID.EINSTELLUNGEN_OLD, ViewID.DESIGNSYSTEM],
  [FEATURES.DEPRECATED]: [ViewID.RECHNUNGEN_OLD, ViewID.LEISTUNGEN_OLD],
};
