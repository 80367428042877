// credit https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export function isValidEmail(email: string) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isInvalidEmail(email: string) {
  return !isValidEmail(email);
}

export function validateKeysSet(object: object, keys: string[]) {
  keys.forEach(key => {
    if (!(key in object)) {
      throw new Error(`key ${key} is missing`);
    }
  });
}
