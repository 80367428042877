import { HKPPARPhaseType, PAType } from '../../../types';

export function translatePAType(t: PAType) {
  switch (t) {
    case PAType.PROPHYLAXE:
      return 'PROPHYLAXE';
    case PAType.PAV:
      return 'PAV';
    case PAType.PA:
      return 'PA';
    case PAType.PAN:
      return 'PAN';
    case PAType.UPT:
      return 'UPT';
    default:
      return '-';
  }
}

export function translateHKPParPhase(phase: HKPPARPhaseType): string {
  switch (phase) {
    case HKPPARPhaseType.BEANTRAGT:
      return 'Beantragt';
    case HKPPARPhaseType.GENEHMIGT:
      return 'Genehmigt';
    case HKPPARPhaseType.PAR_BEGONNEN:
      return 'PAR begonnen';
    case HKPPARPhaseType.PAR_ABGESCHLOSSEN:
      return 'AIT abgeschlossen';
    case HKPPARPhaseType.PAR_BEFUNDEVALUATION:
      return 'PAR Befundevaluation';
    case HKPPARPhaseType.PAR_CHIRURGIE:
      return 'Chirurgie';
    case HKPPARPhaseType.PAR_CHIRURGIE_BEFUNDEVALUATION:
      return 'Chirurgie Befundevaluation';
    case HKPPARPhaseType.UPT:
      return 'UPT';
    case HKPPARPhaseType.BEENDET:
      return 'Beendet';
    case HKPPARPhaseType.UNBEKANNT:
      return 'Unbekannt';
    default:
      return `${phase}`;
  }
}
