"use strict";

var _createForOfIteratorHelper = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js")["default"];

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.map.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.regexp.constructor.js");

require("core-js/modules/es.regexp.dot-all.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.sticky.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.regexp.test.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var DeleteButtonCell_vue_1 = require("@/components/cells/DeleteButtonCell.vue");

var common_ui_1 = require("@rose/common-ui");

var leistungen_1 = require("@/components/columns/settings/leistungen");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

var lodash_1 = require("lodash");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    title: {
      type: String,
      required: true
    },
    // liste der
    items: {
      type: Array,
      required: true
    },
    // leistungen, die noch verfügbar sind, also ohne die bereits vergebenen
    itemsAvailable: {
      type: Array,
      required: true
    },
    leistungKey: {
      type: String,
      required: true
    },
    infoText: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: [],
      rowData: [],
      selectedItemsToAdd: [],
      itemsSelected: [],
      components: {
        actionButtonsRenderer: DeleteButtonCell_vue_1["default"]
      },
      showDeleteAllSelected: false,
      idMap: new Map()
    };
  },
  computed: {
    newSettings: function newSettings() {
      return editSettingsStore_1.editSettingsStore.state.editSettings;
    }
  },
  created: function created() {
    this.columnDefs = (0, leistungen_1.settingsLeistungenColumnsDefs)(this.leistungKey);
    this.gridOptions = {
      rowHeight: 40,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        sortable: true,
        resizable: true
      },
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      suppressCellFocus: true
    };

    var _iterator = _createForOfIteratorHelper(this.itemsAvailable),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var i = _step.value;
        this.idMap.set(i.extid, i.abrechnungsnummer);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  },
  methods: {
    onGridReady: function onGridReady(event) {
      this.gridApi = event.api;
      this.gridColumnApi = event.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    getRowClass: function getRowClass(row) {
      if (row.data.veraltet) {
        return 'veraltet';
      }
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    },
    onSelectionChanged: function onSelectionChanged() {
      this.showDeleteAllSelected = !!this.gridApi.getSelectedRows().length;
    },
    addLeistung: function addLeistung() {
      var _this = this;

      var items = this.selectedItemsToAdd.map(function (v) {
        return _this.idMap.get(v) || v;
      });
      console.log('addLeistung', this.leistungKey, this.idMap, (0, lodash_1.map)(this.items, function (i) {
        return i.abrechnungsnummer;
      }), this.selectedItemsToAdd, items);
      editSettingsStore_1.editSettingsStore.commit.addLeistung({
        key: this.leistungKey,
        items: items
      });
      this.selectedItemsToAdd = [];
      this.showDeleteAllSelected = false;
    },
    deleteLeistung: function deleteLeistung() {
      var selectedRows = this.gridApi.getSelectedRows();
      var items = selectedRows.map(function (v) {
        return v.nummer.toLowerCase();
      });
      editSettingsStore_1.editSettingsStore.commit.deleteLeistung({
        key: this.leistungKey,
        items: items
      });
      this.showDeleteAllSelected = false;
      this.gridApi.deselectAll();
    },
    filterWithGroups: function filterWithGroups(item, queryText, itemText) {
      var rx = RegExp(queryText, 'i');
      return !!(0, lodash_1.get)(item, 'header') || rx.test(itemText) || rx.test(item.text) || rx.test(item.nummer) || rx.test(item.abrechnungsnummer);
    }
  }
});