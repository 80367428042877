"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var types_1 = require("@rose/types");

var OpenInCharly_vue_1 = require("@/components/OpenInCharly.vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    OpenInCharly: OpenInCharly_vue_1["default"]
  },
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      GeschlechtType: types_1.GeschlechtType
    };
  },
  methods: {
    goToPatient: function goToPatient(termin) {
      void this.$router.push({
        name: 'patient',
        params: {
          patid: termin.patid
        }
      });
    },
    logEntry: function logEntry(termin) {
      console.log(termin);
    },
    onOpenPatient: function onOpenPatient() {
      this.$emit('open-patient');
    }
  }
});