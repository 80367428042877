export interface IFreshDeskCompany {
  id?: number;
  name: string;
  custom_fields: {
    cid: string;
  };
}

export interface IFreshDeskErrorResponseBody {
  description: string;
  errors: IFreshDeskError[];
}

interface IFreshDeskError {
  field: string;
  additional_info: { [key: string]: string | number };
  message: string;
  code: string;
}

export interface IFreshDeskContact {
  id?: number;
  name: string;
  job_title?: JobTitles;
  description?: string;
  email: string;
  phone: string;
  company_id: number;
  address: string;
  mobile?: string;
}

// change also in admin/ContactPositionCell.vue
export enum JobTitles {
  IT = 'IT',
  Praxismanager = 'Praxismanager',
  Arzt = 'Arzt',
  Inhaber = 'Inhaber',
  Sammelkontakt = 'Sammelkontakt',
  Verwaltung = 'Verwaltung',
  Empfang = 'Empfang',
}

export enum FreshdeskTicketStatus {
  Open = 2,
  Pending = 3,
  Resolved = 4,
  Closed = 5,
  WaitingOnCustomer = 6,
  WaitingOnThirdParty = 7,
  CallBack = 8,
  InternalClarification = 9,
}

export enum FreshdeskTicketPriority {
  Low = 1,
  Medium = 2,
  High = 3,
  Urgent = 4,
}

export enum FreshdeskEingang {
  Callcenter = 'Callcenter',
  TelefonRose = 'Telefon rose',
  Email = 'Email',
  FeedbackWidget = 'Feedback Widget',
  API = 'API',
  Schulung = 'Schulung',
  Demo = 'Demo',
  Uebergabe = 'Übergabe',
}

export enum FreshdeskLevel {
  FirstLevel = 'First Level',
  SecondLevel = 'Second Level',
}

export interface IFreshDeskTicket {
  id?: number;
  requester_id?: number;
  email?: string;
  subject: string;
  type?: string;
  description: string;
  status: FreshdeskTicketStatus;
  priority: FreshdeskTicketPriority;
  tags?: string[];
  custom_fields: {
    cf_eingang: FreshdeskEingang;
    cf_level: FreshdeskLevel;
  };
}
