import { INews, NewsTags } from '../../types';

export let news: INews[] = [
  {
    name: 'Herzlich Willkommen bei rose-metrics',
    tags: [NewsTags.METRICS],
    nameCaOverride: 'Herzlich Willkommen bei charly-analytics',
    description: 'Schön, dass Du da bist',
    fileName: 'welcome.md',
    fileNameCaOverride: 'welcome_ca.md',
    date: new Date('2000-01-01'),
    draft: true,
  },
  {
    name: 'Updates 2019, August',
    tags: [NewsTags.METRICS],
    description: 'Es gibt jetzt News!',
    fileName: '20190807.md',
    date: new Date('2019-08-07'),
  },
  {
    name: 'Updates 2019, Oktober',
    tags: [NewsTags.METRICS],
    description: 'Rakete und mehr...',
    fileName: '20191004.md',
    date: new Date('2019-10-04'),
  },
  {
    name: 'Updates 2020, Januar',
    tags: [NewsTags.METRICS],
    description: 'HKP, Umsatz, Heute und mehr...',
    fileName: '20200123.md',
    date: new Date('2020-01-23'),
  },
  {
    name: 'Updates 2020, Februar',
    tags: [NewsTags.METRICS],
    description: 'Webinar, HKP, Heute und mehr...',
    fileName: '20200225.md',
    date: new Date('2020-02-25'),
  },
  {
    name: 'Updates 2020, März',
    tags: [NewsTags.METRICS],
    description: 'Webinar, Videos, Benchmark, HKP und mehr...',
    fileName: '20200320.md',
    date: new Date('2020-03-20'),
  },
  {
    name: 'Webinar 2 vom März online ansehen',
    tags: [NewsTags.METRICS],
    description: 'Webinar...',
    fileName: '20200403.md',
    date: new Date('2020-04-03'),
  },
  {
    name: 'Updates 2020, April',
    tags: [NewsTags.METRICS],
    description: 'Webinar, Heute-Labels, HKP-Manager und mehr...',
    fileName: '20200427.md',
    date: new Date('2020-04-27'),
  },
  {
    name: 'Abrechnungs-Unterstützung-Preview',
    tags: [NewsTags.METRICS],
    description: 'Corona-Hygiene-Pauschale 3010a',
    fileName: '20200504.md',
    date: new Date('2020-05-11'),
  },
  {
    name: 'BEMA-Abrechnung und Webinar 3 online',
    tags: [NewsTags.METRICS],
    description: 'rose Abrechnung - BEMA Vorschläge und Webinar 3 online ansehen',
    fileName: '20200522.md',
    date: new Date('2020-05-22'),
  },
  {
    name: 'rose4charly ist da!',
    tags: [NewsTags.METRICS],
    description: 'rose4charly und rose-metrics',
    fileName: '20200930.md',
    date: new Date('2020-09-30'),
  },
  {
    name: 'Updates 2020, Oktober',
    tags: [NewsTags.METRICS],
    description: 'Umsatztabelle, Patientensuche und QR-Codes',
    fileName: '20201001.md',
    date: new Date('2020-10-28'),
  },
  {
    name: 'Webinar Abrechnung',
    tags: [NewsTags.METRICS],
    description: 'Wie hole ich das Meiste aus meiner Abrechnung raus?',
    fileName: '20201102.md',
    date: new Date('2020-11-12'),
  },
  {
    name: 'Webinar Abrechnung: Mitschnitt',
    tags: [NewsTags.METRICS],
    description: 'Der Mitschnitt vom Webinar ist online!',
    fileName: '20201203.md',
    date: new Date('2020-12-03'),
  },
  {
    name: 'Update 2020, Dezember',
    tags: [NewsTags.METRICS],
    description: 'Heute, Abrechnung, HKP-Manager und Benchmark',
    fileName: '20201212.md',
    date: new Date('2020-12-12'),
  },
  {
    name: 'Update 2021, Januar',
    tags: [NewsTags.METRICS],
    description: 'Verbesserungen',
    fileName: '20210201.md',
    date: new Date('2021-02-01'),
  },
  {
    name: 'Update 2021, März',
    tags: [NewsTags.METRICS],
    description: 'Nachtrag: Verbesserungen',
    fileName: '20210408.md',
    date: new Date('2021-04-08'),
  },
  {
    name: 'Update 2021, Mai',
    tags: [NewsTags.METRICS],
    description: 'Nachtrag: Verbesserungen',
    fileName: '20210531.md',
    date: new Date('2021-05-31'),
  },
  {
    name: 'Update 2021, September',
    tags: [NewsTags.METRICS],
    description: 'Viele Änderungen in Heute',
    fileName: '20211021.md',
    date: new Date('2021-10-21'),
  },
  {
    name: 'Update 2021, Oktober',
    tags: [NewsTags.METRICS],
    description: 'Verwaltung, Abrechnung, HKP-Manager, Leistungen & Rechnungen',
    fileName: '20211101.md',
    date: new Date('2021-11-01'),
  },
  {
    name: 'News zur PAR-Richtlinie',
    tags: [NewsTags.METRICS],
    description: 'Erstes Update zur PAR-Richtlinie',
    fileName: '20211102.md',
    date: new Date('2021-11-02'),
  },
  {
    name: 'Update 2021, November',
    tags: [NewsTags.METRICS],
    description: 'Patientensuche, Profil, Login, Connector Check',
    fileName: '20211115.md',
    date: new Date('2021-11-15'),
  },
  {
    name: 'charly analytics',
    tags: [NewsTags.METRICS],
    description: 'solutio und rose - Das perfekte Team für die charly-Praxis',
    fileName: 'ca.md',
    date: new Date('2021-11-23'),
  },
  {
    name: 'Update 2021, Dezember',
    tags: [NewsTags.METRICS],
    description: 'Ganz viel Heute, Rechnungen im neuen Kleid und Sneak Peak in Patientenumsätze',
    fileName: '20211229.md',
    date: new Date('2021-12-29'),
  },
  {
    name: 'Update 2022, Januar',
    tags: [NewsTags.METRICS],
    description: 'PAR-Richtlinie und Benchmark',
    fileName: '20220115.md',
    date: new Date('2022-01-15'),
  },
  {
    name: 'Update 2022, Februar',
    tags: [NewsTags.METRICS],
    description: 'Eine neue Runde PAR, News, Support und neue Views: Kartenansicht und Erweiterte Abfragen',
    fileName: '20220301.md',
    date: new Date('2022-03-01'),
  },
  {
    name: 'rose4charly documents ist da',
    tags: [NewsTags.METRICS],
    description: 'Alle Dokumente mit rose4charly documents direkt auf dem iPad aus charly heraus unterschreiben!',
    fileName: '20220516.md',
    date: new Date('2022-05-16'),
  },
  {
    name: 'Update 2022, Mai',
    tags: [NewsTags.METRICS],
    description: 'QR-Code für Patienten Termine und erweiterte Abfragen',
    fileName: '20220529.md',
    date: new Date('2022-05-29'),
  },
  {
    name: 'Update 2022, August',
    tags: [NewsTags.METRICS],
    description: 'PAR-Monitor, Heute und Benchmark',
    fileName: '20220827.md',
    date: new Date('2022-08-27'),
  },
  {
    name: 'Update 2022, September',
    tags: [NewsTags.METRICS],
    description: 'Heute, Benchmark und QR-Code',
    fileName: '20220909.md',
    date: new Date('2022-09-09'),
  },
  {
    name: 'Neujahrswünsche 2023',
    tags: [NewsTags.METRICS],
    description: 'Benchmark, Terminbuch, CPT-Behandlung, rose4charly',
    fileName: '20221231.md',
    date: new Date('2022-12-31'),
  },
  {
    name: 'Update 2023, Februar',
    tags: [NewsTags.METRICS],
    description: 'Neuer View: Terminqualität',
    fileName: '20230201.md',
    date: new Date('2023-02-01'),
  },
  {
    name: 'Bahar - rose specialist',
    tags: [NewsTags.METRICS],
    description: 'Neue rose im Team: Bahar - rose specialist für mehr Praxiserfolgt',
    fileName: '20230325.md',
    date: new Date('2023-02-20'),
  },
  {
    name: 'Update 2023, März',
    tags: [NewsTags.METRICS],
    description: 'IDS 2023, Einstellungen im neuen Look und HVM - Budget in Echtzeit',
    fileName: '20230303.md',
    date: new Date('2023-03-03'),
  },
  {
    name: 'E-Mails mit rose4charly',
    tags: [NewsTags.METRICS],
    description: 'Verbesserung des Datenschutzes bei E-Mail-Versand durch rose',
    fileName: '20230310.md',
    date: new Date('2023-03-10'),
  },
  {
    name: 'News für r4c!!!',
    tags: [NewsTags.R4C],
    description: 'Endlich gibt es auch News für r4c!!!',
    teaser: 'Endlich gibt es auch News für r4c!!!',
    fileName: '20230419_r4c.md',
    date: new Date('2023-05-05'),
    draft: true,
  },
  {
    name: 'rose-community Meeting',
    tags: [NewsTags.R4C, NewsTags.METRICS],
    description: 'rose-community Meeting am 27.05.23',
    teaser: 'rose-community Meeting am 27.05.23',
    fileName: '20230504.md',
    date: new Date('2023-05-15'),
  },
  {
    name: 'HVM-Trilogy: Die Rückkehr der Budgetierung!',
    tags: [NewsTags.METRICS],
    description: 'Deep-Dive in den Honorarverteilungsmasstab',
    teaser: 'HVM-Trilogy: Die Rückkehr der Budgetierung!',
    fileName: '20230612_hvm-trilogy.md',
    date: new Date('2023-06-12'),
  },
  {
    name: 'NEU: Anamnese@Home',
    tags: [NewsTags.R4C],
    description: 'Anamnese@Home und die HVM-Trilogy',
    teaser: 'NEU: Anamnese@Home',
    fileName: '20230625_ana_at_home.md',
    date: new Date('2023-06-25'),
    draft: true,
  },
  {
    name: 'Update 2023, Juli',
    tags: [NewsTags.METRICS],
    description: 'rose4charly Anamnese und Weiterentwicklung HVM',
    fileName: '20230728.md',
    date: new Date('2023-07-28'),
  },
  {
    name: 'HVM Trilogy: Teil 1',
    tags: [NewsTags.METRICS],
    description: 'HVM-Trilogy Teil 1: Die Rückkehr der Budgetierung! Das Video',
    fileName: '20230822_hvm-trilogy-teil1.md',
    date: new Date('2023-08-22'),
  },
  {
    name: 'Update 2023, September',
    tags: [NewsTags.METRICS],
    description: 'HVM Westfalen-Lippe',
    fileName: '20230918_hvm-wl.md',
    date: new Date('2023-09-18'),
  },
  {
    name: 'rose auf der Fachdental Südwest',
    tags: [NewsTags.METRICS, NewsTags.BLOG],
    description: 'Fachdental Südwest',
    fileName: '20230920_fachdental.md',
    date: new Date('2023-09-20'),
  },
  {
    name: 'HVM Trilogy: Teil 2',
    tags: [NewsTags.METRICS],
    description: 'HVM-Trilogy Teil 2: Die Parodontitis Behandlung und das Budget. Das Video',
    fileName: '20231023_hvm-trilogy-teil2.md',
    date: new Date('2023-10-23'),
  },
  {
    name: 'rose4charly News 2023, Oktober',
    tags: [NewsTags.R4C, NewsTags.METRICS, NewsTags.BLOG],
    description: 'rose4charly News: Ein Haufen neuer Funktionen',
    teaser: 'Ein Haufen neuer Funktionen',
    fileName: '20231030_r4c_news.md',
    date: new Date('2023-10-30'),
  },
  {
    name: 'rose4charly News 2023, Dezember',
    tags: [NewsTags.R4C, NewsTags.METRICS, NewsTags.BLOG],
    description: 'rose4charly Updates im Dezember',
    teaser: 'Wieder viele neue Funktionen',
    fileName: '20231212_r4c_news.md',
    date: new Date('2023-12-12'),
  },
  {
    name: 'rose4charly News 2024, Januar',
    tags: [NewsTags.R4C, NewsTags.METRICS, NewsTags.BLOG],
    description: 'rose4charly Updates im Januar',
    teaser: '2 neue Workflows!',
    fileName: '20240110_r4c_news.md',
    date: new Date('2024-01-10'),
  },
  {
    name: 'Katrin - rose Specialist',
    tags: [NewsTags.METRICS, NewsTags.BLOG],
    description: 'Neue rose im Team: Katrin - rose Specialist für mehr Praxis erfolg!',
    teaser: 'Neue rose im Team: Katrin',
    fileName: '20240617_katrin.md',
    date: new Date('2024-06-17'),
  },
  {
    name: 'Update 2024, Juli',
    tags: [NewsTags.METRICS, NewsTags.BLOG],
    description: 'Update 2024, Juli',
    teaser: 'Update 2024, Juli',
    fileName: '20240701_update_juli.md',
    date: new Date('2024-07-01'),
  },
  {
    name: 'Webinar-Termine und rose-Community Meetings',
    tags: [NewsTags.METRICS, NewsTags.BLOG, NewsTags.R4C],
    description: 'Webinar-Termine und rose-Community Meetings',
    teaser: 'Webinare und rose-Community',
    fileName: '20240809_termine.md',
    date: new Date('2024-08-09'),
  },
  {
    name: 'rose4charly News 2024, September',
    tags: [NewsTags.METRICS, NewsTags.BLOG, NewsTags.R4C],
    description: 'rose4charly News 2024, September',
    teaser: 'Praxis-Fragen und mehr',
    fileName: '20240901_r4c_september.md',
    date: new Date('2024-09-01'),
  },
  {
    name: 'Update 2024, September',
    tags: [NewsTags.METRICS, NewsTags.BLOG],
    description: 'Neue Features für Terminaqualität, Umsatztabelle, HKP-Manager und mehr',
    teaser: 'Neues Features für mehrere Dialoge',
    fileName: '20240901_update_september.md',
    date: new Date('2024-09-16'),
  },
  {
    name: 'rose4charly News 2024, September',
    tags: [NewsTags.BLOG, NewsTags.R4C],
    description: 'rose4charly News 2024, September',
    teaser: 'Praxis-Portal Sicherheit',
    fileName: '20240910_r4c_september.md',
    date: new Date('2024-09-17'),
  },
];
