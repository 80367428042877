"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var BehandlerChip_vue_1 = require("@/components/BehandlerChip.vue");

exports["default"] = (0, vue_1.defineComponent)({
  name: 'BehandlerCell',
  components: {
    BehandlerChip: BehandlerChip_vue_1["default"]
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    behandler: function behandler() {
      return this.params.value;
    }
  }
});