import { AblageType } from './charly';
import { IDBModel } from './db';

export interface IDocumentSignSignaturePosition {
  cid: string;
  filename: string;
  signatures: DOCUMENT_SIGNATURE_CONFIG[];
}

export type DOCUMENT_SIGNATURE_CONFIG =
  | {
      type: Exclude<SIGNATURE_TYPE, SIGNATURE_TYPE.PREDEFINED>;
      position: Rectangle;
      pageIndex: number;
    }
  | {
      type: SIGNATURE_TYPE.PREDEFINED;
      predefinedId: number;
      position: Rectangle;
      pageIndex: number;
    };

export interface IDocumentFileWithSignatures {
  ablage: AblageType;
  filename: string;
  pdfFileName: string;
  signatures: DOCUMENT_SIGNATURE_CONFIG[];
}

export interface IDocumentPreviewMeta {
  failedReferencesDownloads: {
    fileName: string;
    error: any;
  }[];
  fontSubstitutions: {
    originalFont: string;
    substituteFont: string;
  }[];
}

export interface IDocumentPredefinedSignature extends IDBModel {
  cid: string;
  descriptor: string;
  pngString: string;
  strokeData: string;
}

export enum SIGNATURE_TYPE {
  PATIENT = 'PATIENT',
  ADDITIONAL = 'ADDITIONAL',
  PREDEFINED = 'PREDEFINED',
}

export interface Rectangle {
  x: number;
  y: number;
  scaleX?: number;
  scaleY?: number;
  width: number;
  height: number;
}
