import { IPercentage } from './interfaces';
import { IHVMWestfalenLippeSettings } from './settings';

export enum FallgruppeGenType {
  NORMAL = 'NORMAL', // nr, bayern
  GROUPBYKASSENKEY = 'GROUPBYKASSENKEY', // wl
}

export interface ITempTableConfigItemFallgruppe {
  fg: string;
  fallwert: number | null;
}

export interface ITempTableConfigItem {
  l: (t: string, fallgruppe: string) => string;
  fallgruppen?: ITempTableConfigItemFallgruppe[];
  quarterly: boolean;
  groupBy: string;
  indexes: string[][];
}

export interface ITempTableConfig {
  [teil: string]: ITempTableConfigItem;
}

export interface IHVMQueryConfig {
  fallgruppenGenType: FallgruppeGenType;
  tempTables: ITempTableConfig;
  cteFallgruppen?: string[];
}

export enum FallWertType {
  PUNKTE = 'PUNKTE',
  EURO = 'EURO',
}

// QUERY PARAMS
// QUERY PARAMS
export interface IHVMQueryParams {
  [key: string]: string | boolean | number | undefined;
  von?: string | undefined;
  bis?: string | undefined;
}

export interface IHVMWestfalenLippeParams {
  von: string;
  bis: string;
  refType?: string;
  altervon: number;
  alterbis: number;
  ignorekzv: boolean;
  behandlerzeiten: IHVMWestfalenLippeSettings['behandlerzeiten'];
}

// DETAILS
// DETAILS
export interface IHVMPatientDetailsLeistung {
  typ: 'leistung' | 'faelle';
  extid: string;
  tag: Date;
  patid: string;
  abrechnungsnummer: string;
  anzahl: number;
  pz: number; // punktzahl
  betrag: number;
  abgerechnet: boolean;
  kzvabrdatum: Date;
  behandler: string;
  faelle: number;
  allebehandler: string[];
}

export interface IHVMPatientDetailsLeistungStats {
  abrechnungsnummer: string;
  anzahl: number;
  betrag: number;
  patienten: number;
  gesamt: number; // punkte
  pz: number; // punktzahl
}

export interface IHVMPatientDetails {
  byPatient: { patid: string; leistungen: IHVMPatientDetailsLeistung[]; faelle: number; allebehandler: string[] }[];
  byNummer: IHVMPatientDetailsLeistungStats[];
}

// BASE TYPES
// BASE TYPES

export interface IHVMQueryParts {
  cases: {
    [teil: string]: (t?: string, k?: string, w?: string) => string;
  };
  points: {
    [teil: string]: string;
  };
}

// probably deprecated
export interface IHVMResultRaw {
  t: string;
  krankenkasse?: string;
  fallzahl: number;
  punkte: number;
  profall: number;
  patids: string[];
}

export interface IHVMVerlaufResultItem {
  key: string;
  quartal: number;
  jahr: number;
  tag: Date;
  cases: number;
  betrag: number;
  punkte: number;
  tagImQuartalIdx: number;
}

export interface IHVMVerlaufResult {
  [quartal: string]: IHVMVerlaufResultItem[];
}

// probably deprecated
export interface IHVMResultItem {
  profall: number;
  fallzahl: number; // anzahl fälle wie sie aus der db kommt
  punkte: number;
  betrag: number;
  faktor?: number;
}

export interface IHVMResultBase {
  kzv: string;
}

// BAYERN
// BAYERN
export interface IHVMResultItemBayern {
  budget: number;
  budgetgesamt?: number;
  fallzahl: number; // anzahl fälle wie sie aus der db kommt
  punkte: number;
  betrag: number;
}

// das ist der basis typ wie er aus dem sql query rauskommt
export interface IHVMResultRowBase {
  cases: number;
  budgeteuro: number;
  budgetpunkte: number;
  performanceeuro: number;
  performancepunkte: number;
  avgcaseperformanceeuro: number;
  avgcaseperformancepunkte: number;
  fallwert: number;
  punktwert: number; // euros pro punkt
  faktor: number;
}

export interface IHVMResultRow extends IHVMResultRowBase {
  t: string;
  fg: string;
  s?: string;
  b?: string;
}

export interface IHVMResultBayernPart<T = IHVMResultRowBase> {
  kch: {
    total: T;
    u: T;
    b: T;
    k: T;
  };
  ait: {
    aok: T;
    ek: T;
    fk: T;
  };
  upt: {
    aok: T;
    ek: T;
    fk: T;
  };
  kbkg: {
    total: T;
  };
  ukps: {
    total: T;
  };
  kfo: {
    total: T;
  };
}

export interface IHVMResultBayernBehandlerItem {
  id: string;
  punktwerte: IHVMResultBayernPart<IHVMResultItemBayern>;
}

export interface IHVMResult2BehandlerItem<TPart> {
  id: string;
  verbrauch: TPart;
}

export interface IHVMResultBayern extends IHVMResultBase {
  praxis: IHVMResultBayernPart<IHVMResultItemBayern>;
  budget: IHVMResultBayernPart<IHVMResultItemBayern>;
  differenz: IHVMResultBayernPart<IHVMResultItemBayern>;
  behandler?: IHVMResultBayernBehandlerItem[];
}

export interface IHVMResultBaseBase<T> extends IHVMResultBase {
  verbrauch: T;
  budget: T;
  uebermittelt: T;
  uebermitteltverbrauch: T;
  nichtuebermittelt: T;
  seitletzterabrechnung: T;
  behandler: IHVMResult2BehandlerItem<T>[];
}

export interface IHVMResultBayern2Base extends IHVMResultBaseBase<IHVMResultBayernPart<IHVMResultRowBase>> {}

export interface IHVMResultNordrhein2Base extends IHVMResultBaseBase<IHVMResultNordrheinPart<IHVMResultRowBase>> {}

export interface IHVMResultBayern2 extends IHVMResultBayern2Base {
  differenz: IHVMResultBayernPart<IHVMResultRowBase>;
}

export interface IHVMResultBayernBehandler extends IHVMResultBase {
  behandler: IHVMResultBayernBehandlerItem[];
}

// WESTFALEN-LIPPE 2
// WESTFALEN-LIPPE 2

export interface IHVMWestfalenLippeBracketConfig2 {
  id: number;
  lower: number;
  upper?: number;
  faktorRaw: number;
  hasRest?: boolean;
  includeLower?: boolean;
}

export interface IHVMWestfalenLippeBracketBase2 extends IHVMWestfalenLippeBracketConfig2 {
  casesPerDoc: number; // normalisierte menge an fällen auf der pro arzt ebene
  faktor: number;
}

export interface IHVMWestfalenLippeBracket2 extends IHVMWestfalenLippeBracketBase2 {
  cases: number;
  casesTotal: number;
}

export interface IHVMResultBracketKasse2 {
  id: number;
  cases: number;
  casesTotal: number;
  casesPerDoc: number;

  punktbudget: number;
  punkte: number;
  betrag: number;
}

export interface IHVMResultBracket2 {
  [kasse: string]: IHVMResultBracketKasse2[];
}

export interface IHVMResultBracketKasseWrapper2 {
  mainBrackets: IHVMWestfalenLippeBracketBase2[];
  brackets: IHVMResultBracket2;
  kassenAnteile: { [kasse: string]: { percentage: IPercentage; casesPerDoc: number } };
  cases: number;
}

export interface IHVMWestfalenLippeDifferenz2 {
  cases: number;
  punkte: number;
  betrag: number;
  fallwert: number;
}

export interface IHVMResultWestfalenLippeReferenzTotal2 {
  cases: number;
  abweichungprozent: number;
}

export interface IHVMResultWestfalenLippeDifferenzBase2 {
  differenz: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMWestfalenLippeDifferenz2>>;
}

export interface IHVMResultWestfalenLippeQuartaleAkkumuliert2 extends IHVMResultWestfalenLippeDifferenzBase2 {
  quartal: IHVMWestfalenLippeQuarterBase;
  verbrauch: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>;
  budget: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>;
}

export interface IHVMResultWestfalenLippeQuartalProDaten2Teil {
  uebermittelt: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>;
  nichtuebermittelt: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>;
  seitletzterabrechnung: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>;
}

export interface IHVMResultWestfalenLippeQuartalProDaten2 {
  budget: IHVMResultWestfalenLippeQuartalProDaten2Teil;
  verbrauch: IHVMResultWestfalenLippeQuartalProDaten2Teil;
}

export interface IHVMResultWestfalenLippeQuartalDaten2 extends IHVMResultWestfalenLippeDifferenzBase2 {
  kassen: IHVMWestfalenLippeKassenInfoItem[];
  praxis: IHVMWestfalenLippeTeile2<IHVMResultBracketKasseWrapper2>;
  praxisRaw: any;
  arztanzahl: number;
  quartal: IHVMWestfalenLippeQuarterBase;
  budget: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>;
  budgetTotal: IHVMKassenWrapper<IHVMResultWestfalenLippeReferenzTotal2>;
  verbrauch: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>;
  pro: IHVMResultWestfalenLippeQuartalProDaten2;
  behandler: IHVMResult2BehandlerItem<IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>>[];
}

export interface IHVMResultWestfalenLippe2Base
  extends IHVMResultBaseBase<IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>> {}

// export interface IHVMResultWestfalenLippe2 extends IHVMResultWestfalenLippe2Base {
//   differenz: IHVMWestfalenLippeTeile<IHVMResultRowBase>;
// }

export interface IHVMResultWestfalenLippe2 extends IHVMResultBase {
  brackets: IHVMWestfalenLippeBracketConfig2[];
  quartale: IHVMResultWestfalenLippeQuartalDaten2[];
  quartaleAkkumuliert: IHVMResultWestfalenLippeQuartaleAkkumuliert2[];
  verbrauch: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>;
  budget: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMResultRowBase>>;
  budgetTotal: IHVMKassenWrapper<IHVMResultWestfalenLippeReferenzTotal2>;
  differenz: IHVMWestfalenLippeTeile2<IHVMKassenWrapper<IHVMWestfalenLippeDifferenz2>>;
}

// WESTFALEN-LIPPE
// WESTFALEN-LIPPE
export interface IHVMResultKasseWrapper<T = IHVMResultItem> {
  [kasse: string]: T;
}

export interface IKassenBasisPunktInfo {
  name: string;
  basispunktwert: number;
}

export type KassenBasisWerte = IHVMKassenWrapper<IKassenBasisPunktInfo>;

export interface IHVMResultBracketKasse {
  lower: number;
  upper?: number;
  faktor: number;
  anteil: number;
  punktbudget: number;
  faelle: number;
  fallzahl: number;
  punkte: number;
  betrag: number;
  inBracket: number;
}

export interface IHVMResultBracket {
  [kasse: string]: IHVMResultBracketKasse[];
}

export interface IHVMResultBracketKasseWrapper {
  brackets: IHVMResultBracket;
  kassenAnteile: { [kasse: string]: IPercentage };
  faelle: number;
}

export interface IHVMResultWestfalenLippeReferenzTotal {
  faelle: number;
  abweichungprozent: number;
}

export interface IHVMWestfalenLippeKassenInfoItem {
  key: string;
  name: string;
  basispunktwert: number;
  unknown?: boolean;
}

export interface IHVMWestfalenLippeQuarterBase<T = string> {
  von: T;
  bis: T;
  key: string;
  jahr: string;
  quartal: string;
  arztanzahl: number;
  show: boolean;
  showDetails: boolean;
}

export interface IHVMResultWestfalenLippeQuartalDaten {
  kassen: IHVMWestfalenLippeKassenInfoItem[];
  praxis: IHVMWestfalenLippeTeile<IHVMResultBracketKasseWrapper>;
  praxisRaw: any;
  arztanzahl: number;
  quartal: IHVMWestfalenLippeQuarterBase;
  referenz: IHVMWestfalenLippeTeile<IHVMResultKasseWrapper<IHVMWestfalenLippeReferenzVerbrauch>>;
  referenzTotal: IHVMResultKasseWrapper<IHVMResultWestfalenLippeReferenzTotal>;
  verbrauch: IHVMWestfalenLippeTeile<IHVMResultKasseWrapper<IHVMWestfalenLippeReferenzVerbrauch>>;
  differenz: IHVMWestfalenLippeTeile<IHVMResultKasseWrapper<IHVMWestfalenLippeDifferenz>>;
}

export interface IHVMResultWestfalenLippeQuartaleAkkumuliert {
  quartal: IHVMWestfalenLippeQuarterBase;
  verbrauch: IHVMWestfalenLippeTeile<IHVMResultKasseWrapper<IHVMWestfalenLippeReferenzVerbrauch>>;
  referenz: IHVMWestfalenLippeTeile<IHVMResultKasseWrapper<IHVMWestfalenLippeReferenzVerbrauch>>;
  differenz: IHVMWestfalenLippeTeile<IHVMResultKasseWrapper<IHVMWestfalenLippeDifferenz>>;
}

export interface IHVMResultWestfalenLippe extends IHVMResultBase {
  brackets: IHVMWestfalenLippeBracketConfig[];
  quartale: IHVMResultWestfalenLippeQuartalDaten[];
  quartaleAkkumuliert: IHVMResultWestfalenLippeQuartaleAkkumuliert[];
  verbrauch: IHVMWestfalenLippeTeile<IHVMResultKasseWrapper<IHVMWestfalenLippeReferenzVerbrauch>>;
  referenz: IHVMWestfalenLippeTeile<IHVMResultKasseWrapper<IHVMWestfalenLippeReferenzVerbrauch>>;
  referenzTotal: IHVMResultKasseWrapper<IHVMResultWestfalenLippeReferenzTotal>;
  differenz: IHVMWestfalenLippeTeile<IHVMResultKasseWrapper<IHVMWestfalenLippeDifferenz>>;
}

export interface IHVMWestfalenLippeBracketConfig {
  lower: number;
  upper?: number;
  faktorRaw: number;
  hasRest?: boolean;
  includeLower?: boolean;
}

export interface IHVMWestfalenLippeBracket extends IHVMWestfalenLippeBracketConfig {
  inBracket: number;
  anteil: number;
  faelle: number;
  faktor: number;
}

export interface IHVMWestfalenLippeDifferenz {
  punkte: number;
  betrag: number;
  fallzahl: number;
}

export interface IHVMWestfalenLippeReferenzVerbrauch extends IHVMWestfalenLippeDifferenz {
  faelle: number;
}

export interface IHVMKassenWrapper<T = IHVMResultRowBase> {
  aok: T;
  ikk: T;
  bkk: T;
  kn: T; // (bundes-)knappschaft
  lkk: T; // svlfg
  barmer: T;
  dak: T;
  kkh: T;
  hek: T;
  hkk: T;
  tk: T;
  total?: T;
}

export interface IHVMWestfalenLippeTeile<T = IHVMResultItem> {
  kch: T;
  kchohneip: T;
  ip: T;
  par: T;
  kbr: T;
  kfo: T;
  total: T;
}

export interface IHVMWestfalenLippeTeile2<T = IHVMResultItem> {
  kch: T;
  kchohneip: T;
  kchohneipkbr: T;
  ip: T;
  par: T;
  kbr: T;
  kfo: T;
  total: T;
}

// NORDRHEIN
// NORDRHEIN
export interface IHVMNordRheinResultPart<T = IHVMResultItem> {
  kch: T;
  kchohneip: T;
  ip: T;
  kbkg: T;
  ukps: T;
  kfo: T;
  ait: T;
  cpt: T;
  upt: T;
}

export interface IHVMResultNordrhein extends IHVMResultBase {
  praxis: IHVMNordRheinResultPart;
  referenz: IHVMNordRheinResultPart;
  differenz: IHVMNordRheinResultPart;
  behandler?: { id: string; punktwerte: IHVMNordRheinResultPart }[];
  verlauf?: IHVMVerlaufResult;
}

export interface IHVMResultNordrheinBehandler extends IHVMResultBase {
  behandler: { id: string; punktwerte: IHVMNordRheinResultPart }[];
}

export interface IHVMResultNordrheinVerlauf extends IHVMResultBase {
  verlauf: IHVMVerlaufResult;
}

export interface IHVMResultNordrheinPart<T = IHVMResultRowBase> {
  kch: {
    total: T;
  };
  kchohneip: {
    total: T;
  };
  ip: {
    total: T;
  };
  ait: {
    total: T;
  };
  cpt: {
    total: T;
  };
  upt: {
    total: T;
  };
  kbkg: {
    total: T;
  };
  ukps: {
    total: T;
  };
  kfo: {
    total: T;
  };
}

export interface IHVMResultNordrhein2 extends IHVMResultNordrhein2Base {
  differenz: IHVMResultNordrheinPart<IHVMResultRowBase>;
}
