import { IImportStep, IMPORT_STEPS } from './interfaces';

/**
 * SYNC ORDER WITH IMPORT_STEPS ENUM AND DERIVEDDATACALC !!!!!!!!!!
 */
export let ImportSteps: IImportStep[] = [
  { step: IMPORT_STEPS.STARTED, key: convertStepToKey(IMPORT_STEPS.STARTED), name: 'Gestartet' },
  {
    step: IMPORT_STEPS.RAW_DATA_RETRIVAL,
    key: convertStepToKey(IMPORT_STEPS.RAW_DATA_RETRIVAL),
    name: 'Rohdaten laden',
  },
  {
    step: IMPORT_STEPS.DOCTOSYNC_DATA_RETRIVAL,
    key: convertStepToKey(IMPORT_STEPS.DOCTOSYNC_DATA_RETRIVAL),
    name: 'Doctosync-Daten laden',
  },
  {
    step: IMPORT_STEPS.RAW_DATA_INDEXING,
    key: convertStepToKey(IMPORT_STEPS.RAW_DATA_INDEXING),
    name: 'Rohdaten indizieren',
  },
  {
    step: IMPORT_STEPS.RAW_DATA_TRANSFORMATION,
    key: convertStepToKey(IMPORT_STEPS.RAW_DATA_TRANSFORMATION),
    name: 'Rohdaten transformieren',
  },
  {
    step: IMPORT_STEPS.DELETIONS,
    key: convertStepToKey(IMPORT_STEPS.DELETIONS),
    name: 'Gelöschte Datensätze synchronisieren',
  },
  {
    step: IMPORT_STEPS.LEISTUNGSBLOECKE,
    key: convertStepToKey(IMPORT_STEPS.LEISTUNGSBLOECKE),
    name: 'Leistungsblöcke erstellen',
  },
  { step: IMPORT_STEPS.KONTROLLIERT, key: convertStepToKey(IMPORT_STEPS.KONTROLLIERT), name: 'Kontrolliert erstellen' },
  { step: IMPORT_STEPS.PSI, key: convertStepToKey(IMPORT_STEPS.PSI), name: 'PSI erstellen' },
  { step: IMPORT_STEPS.TERMINE, key: convertStepToKey(IMPORT_STEPS.TERMINE), name: 'Termine erstellen' },
  { step: IMPORT_STEPS.HKP, key: convertStepToKey(IMPORT_STEPS.HKP), name: 'HKPs erstellen' },
  { step: IMPORT_STEPS.PATIENTEN, key: convertStepToKey(IMPORT_STEPS.PATIENTEN), name: 'Patienten erstellen' },
  { step: IMPORT_STEPS.RECHNUNGEN, key: convertStepToKey(IMPORT_STEPS.RECHNUNGEN), name: 'Rechnungen erstellen' },
  { step: IMPORT_STEPS.UMSATZ, key: convertStepToKey(IMPORT_STEPS.UMSATZ), name: 'Umsatz erstellen' },
  {
    step: IMPORT_STEPS.ABRECHNUNG,
    key: convertStepToKey(IMPORT_STEPS.ABRECHNUNG),
    name: 'Abrechnungsvorschläge erstellen',
  },
  {
    step: IMPORT_STEPS.KZVABRECHNUNG,
    key: convertStepToKey(IMPORT_STEPS.KZVABRECHNUNG),
    name: 'KZV-Abrechnung erstellen',
  },
  { step: IMPORT_STEPS.PA, key: convertStepToKey(IMPORT_STEPS.PA), name: 'PAs erstellen' },
  { step: IMPORT_STEPS.HEUTE, key: convertStepToKey(IMPORT_STEPS.HEUTE), name: 'Heute erstellen' },
  { step: IMPORT_STEPS.TERMINBUCH, key: convertStepToKey(IMPORT_STEPS.TERMINBUCH), name: 'Terminbuch erstellen' },
  { step: IMPORT_STEPS.BENCHMARK, key: convertStepToKey(IMPORT_STEPS.BENCHMARK), name: 'Benchmark erstellen' },
  { step: IMPORT_STEPS.FINISHED, key: convertStepToKey(IMPORT_STEPS.FINISHED), name: 'Fertig' },
];

export function getImportStepName(step: IMPORT_STEPS) {
  let ss = ImportSteps.find(s => s.step === step);
  return ss ? ss.name : step;
}

export function convertStepToKey(step: IMPORT_STEPS) {
  switch (step) {
    case IMPORT_STEPS.NOT_RUNNING:
      return 'NOT_RUNNING';
    case IMPORT_STEPS.STARTED:
      return 'STARTED';
    case IMPORT_STEPS.RAW_DATA_RETRIVAL:
      return 'RAW_DATA_RETRIVAL';
    case IMPORT_STEPS.DOCTOSYNC_DATA_RETRIVAL:
      return 'DOCTOSYNC_DATA_RETRIVAL';
    case IMPORT_STEPS.RAW_DATA_INDEXING:
      return 'RAW_DATA_INDEXING';
    case IMPORT_STEPS.RAW_DATA_TRANSFORMATION:
      return 'RAW_DATA_TRANSFORMATION';
    case IMPORT_STEPS.DELETIONS:
      return 'DELETIONS';
    case IMPORT_STEPS.LEISTUNGSBLOECKE:
      return 'LEISTUNGSBLOECKE';
    case IMPORT_STEPS.KONTROLLIERT:
      return 'KONTROLLIERT';
    case IMPORT_STEPS.PSI:
      return 'PSI';
    case IMPORT_STEPS.TERMINE:
      return 'TERMINE';
    case IMPORT_STEPS.HKP:
      return 'HKP';
    case IMPORT_STEPS.PA:
      return 'PA';
    case IMPORT_STEPS.PATIENTEN:
      return 'PATIENTEN';
    case IMPORT_STEPS.RECHNUNGEN:
      return 'RECHNUNGEN';
    case IMPORT_STEPS.HEUTE:
      return 'HEUTE';
    case IMPORT_STEPS.UMSATZ:
      return 'UMSATZ';
    case IMPORT_STEPS.ABRECHNUNG:
      return 'ABRECHNUNG';
    case IMPORT_STEPS.KZVABRECHNUNG:
      return 'KZVABRECHNUNG';
    case IMPORT_STEPS.TERMINBUCH:
      return 'TERMINBUCH';
    case IMPORT_STEPS.BENCHMARK:
      return 'BENCHMARK';
    case IMPORT_STEPS.FINISHED:
      return 'FINISHED';
  }
  return 'UNKNOWN';
}
