import { IBenchmarkConfigItemRaw, IStichwortParam } from '../../../types';
import { slugifyStichwort } from '../utils/util';

export function benchmarkConfigStichworte(topStichworte: IStichwortParam[]): IBenchmarkConfigItemRaw {
  const childs: { [key: string]: IBenchmarkConfigItemRaw } = {};
  for (const s of topStichworte.sort((a, b) => a.name.localeCompare(b.name))) {
    const n = s.name || s.kuerzel || '??';
    const swSlugified = slugifyStichwort(s.name);
    childs[n] = {
      name: n,
      unit: 'patient',
      virtual: true,
      value: `b.patientenStichworte${swSlugified}`,
      smartValue: 's / b.patienten * 100',
      smartUnit: '%',
      help: `Patienten, die ${n} als Stichwort haben`,
      debug: [`patientenStichworte${swSlugified}Ids`],
      canFilterByPatid: true,
      color: s.farbe,
    };
  }
  return {
    name: 'Stichworte',
    isHeader: true,
    childs,
  };
}
