"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var HelpTooltip_vue_1 = require("@/components/HelpTooltip.vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    HelpTooltip: HelpTooltip_vue_1["default"]
  },
  data: function data() {
    return {
      params: null,
      text: ''
    };
  },
  mounted: function mounted() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh() {
      // set value into cell again
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      this.text = this.params.valueFormatted;
    }
  }
});