import { IDBModel } from './db';

export type ReplacePropertyType<T, O, N> = {
  [P in keyof T]: T[P] extends O ? N : T[P];
};

const TypesBufferSymbol = Symbol('TypesBuffer');
export type TypesBufferPlaceholder = typeof TypesBufferSymbol;

export interface IImageUpload extends IDBModel {
  cid: string;
  image: TypesBufferPlaceholder;
  imageOriginal: TypesBufferPlaceholder;
  purpose: string;
  source: string;
}

export type ReplaceRoseNodeBuffer<T, NodeBuffer> = {
  [P in keyof T]: T[P] extends TypesBufferPlaceholder ? NodeBuffer : T[P];
};
