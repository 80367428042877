import { IOnboardingDaten, IOnboardingEntry } from '../../types';

export function mapOnboardingDbEntryToOnboardingDaten(
  odb: IOnboardingEntry,
  rechenzentrenData?: any,
): IOnboardingDaten {
  return {
    paket: odb.paket,
    kommentar: odb.kommentar,
    praxisDaten: {
      praxis: {
        plz: odb.praxisplz,
        tel: odb.praxistel,
        name: odb.praxisname,
        stadt: odb.praxisstadt,
        straße: odb.praxisstraße,
        nameKurz: odb.praxisnamekurz,
      },
      auftraggeber: {
        plz: odb.auftraggeberplz,
        name: odb.auftraggebername,
        email: odb.auftraggeberemail,
        stadt: odb.auftraggeberstadt,
        straße: odb.auftraggeberstraße,
        vorname: odb.auftraggebervorname,
      },
      empfehlung: odb.empfehlung,
    },
    adminBenutzer: {
      name: odb.adminbenutzername,
      email: odb.adminbenutzeremail,
      vorname: odb.adminbenutzervorname,
    },
    zahlungsdaten: {
      kontoinhaber: odb.kontoinhaber,
      kontoinhaberEmail: odb.kontoinhaberEmail,
      payment_method_id: odb.payment_method_id,
    },
    ansprechpartner: {
      tel: odb.ansprechpartnertel,
      name: odb.ansprechpartnername,
      email: odb.ansprechpartneremail,
      vorname: odb.ansprechpartnervorname,
    },
    rechenzentrenData,
  };
}
