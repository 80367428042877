"use strict";

var _objectSpread = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2.js")["default"];

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var importProtocol_1 = require("@/components/columns/settings/importProtocol");

var lodash_1 = require("lodash");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    importProtocolComplete: {
      type: Object,
      required: true
    },
    tableType: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: [],
      rowData: [],
      protocolInfo: {},
      from: {},
      to: {},
      size: undefined
    };
  },
  computed: {
    duration: function duration() {
      return common_ui_1.roseDayjs.duration(this.to.diff(this.from)).asSeconds();
    }
  },
  created: function created() {
    this.gridOptions = {
      rowHeight: 40,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        sortable: true
      },
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };

    if (this.tableType === 'bot') {
      this.columnDefs = (0, importProtocol_1.settingsImportBotProtocolColumnsDefs)();
      this.protocolInfo = this.importProtocolComplete.botProtocol;
    } else {
      this.columnDefs = (0, importProtocol_1.settingsImportCollectorProtocolColumnsDefs)();
      this.protocolInfo = this.importProtocolComplete.collectorProtocol;
    }

    this.rowData = this.getModelInfoArray(this.protocolInfo.modelInfo);
    this.from = (0, common_ui_1.roseDayjs)((0, lodash_1.chain)(this.protocolInfo.modelInfo).map(function (m) {
      return m.started;
    }).compact().min().value());
    this.to = (0, common_ui_1.roseDayjs)((0, lodash_1.chain)(this.protocolInfo.modelInfo).map(function (m) {
      return m.ended;
    }).compact().max().value());
    this.size = (0, common_ui_1.formatBytes)((0, lodash_1.chain)(this.protocolInfo.modelInfo).map(function (m) {
      return m.size;
    }).compact().sum().value());
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('cell clicked:', event);
    },
    getModelInfoArray: function getModelInfoArray(modelInfos) {
      return (0, lodash_1.chain)(modelInfos).pickBy(function (modelInfo) {
        return modelInfo.size !== 0 || !(0, lodash_1.isEmpty)(modelInfo.patids);
      }).map(function (value, key) {
        if (Object.prototype.hasOwnProperty.call(modelInfos, key)) {
          return _objectSpread({
            name: key
          }, modelInfos[key]);
        }
      }).value();
    }
  }
});