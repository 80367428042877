"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    link: function link() {
      var _this$params$data;

      return (_this$params$data = this.params.data) === null || _this$params$data === void 0 ? void 0 : _this$params$data.downloadLink;
    }
  }
});