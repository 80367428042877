import { IBenchmarkConfigItemRaw, IBenchmarkConfigTrendType } from '../../../types';

export function benchmarkConfigPaPzr(): IBenchmarkConfigItemRaw {
  return {
    name: 'PZR- und PA',
    value: '+b.patientenPzrErwachsen + +b.patientenPzrKinder',
    smartValue: '100',
    smartUnit: '%',
    unit: 'patient',
    virtual: true,
    isHeader: true,
    childs: {
      _paPzrErwachsene: {
        name: 'Behandelte Erwachsene',
        value: 'b.patientenPzrErwachsen',
        smartValue: '100',
        smartUnit: '%',
        unit: 'patient',
        virtual: true,
        isHeader: true,
        childs: {
          patientenPzrErwachsen: {
            name: 'PZR behandelt, im Betrachtungszeitraum',
            unit: 'patient',
            smartValue: 's / b.patientenErwachsen * 100',
            smartUnit: '%',
            help: 'Anzahl der Erwachsenen (>18J), bei denen Prophylaxe-Leistungen im Betrachtungszeitraum (laut Leistungs-Konfiguration) erbracht wurden',
            debug: ['patientenPzrErwachsenIds'],
            canFilterByPatid: true,
          },
          patientenPzrQuoteErwachsen: {
            name: 'PZR-Patienten / -Quote',
            unit: 'patient',
            smartValue: 's / b.patientenErwachsen * 100',
            smartUnit: '%',
            help: 'Bei der Ermittlung der PZR-Quote werden alle erwachsenen Patienten (>18J) berücksichtigt, die entweder in den vergangenen 6 Monaten PZR-Leistungen erhalten oder in den nächsten 6 Monaten einen PZR-Termin haben',
            debug: ['patientenPzrQuoteErwachsenIds'],
            canFilterByPatid: true,
          },
          patientenErwachsenMitPzrUptTerminInDerZukunft: {
            name: 'Mit PZR/UPT-Termin in der Zukunft',
            unit: 'patient',
            smartValue: 's / b.patientenErwachsen * 100',
            smartUnit: '%',
            help: 'Patienten im Alter >= 18 Jahren mit PZR/UPT-Termin in der Zukunft',
            debug: ['patientenErwachsenMitPzrUptTerminInDerZukunftIds'],
            canFilterByPatid: true,
          },
          patientenErwachsenMitPavParPanTerminInDerZukunft: {
            name: 'Mit PAV/PAR/PAN-Termin in der Zukunft',
            unit: 'patient',
            smartValue: 's / b.patientenErwachsen * 100',
            smartUnit: '%',
            help: 'Patienten im Alter >= 18 Jahren mit PAV/PAR/PAN-Termin in der Zukunft',
            debug: ['patientenErwachsenMitPavParPanTerminInDerZukunftIds'],
            canFilterByPatid: true,
          },
          patientenErwachsenMitPaTerminInDerZukunft: {
            name: 'Mit PZR/PAV/PAR/PAN/UPT-Termin in der Zukunft',
            unit: 'patient',
            smartValue: 's / b.patientenErwachsen * 100',
            smartUnit: '%',
            help: 'Patienten im Alter >= 18 Jahren mit einem PA-Termin (PZR, PAV, PAR, PAN, UPT) in der Zukunft',
            debug: ['patientenErwachsenMitPaTerminInDerZukunftIds'],
            canFilterByPatid: true,
          },
          patientenMitRecall: {
            name: 'Mit gesetztem Recall-Vermerk',
            unit: 'patient',
            smartValue: 's / b.patientenErwachsen * 100',
            smartUnit: '%',
            help: 'Anzahl der behandelten erwachsenen Patienten, bei denen ein Recall vereinbart wurde.',
            debug: ['patientenMitRecallIds'],
            canFilterByPatid: true,
          },
          patientenMitPsiAbrechnung: {
            name: 'Mit PSI Abrechnung, in den letzten 24 Monaten',
            unit: 'patient',
            smartValue: 's / b.patientenErwachsen * 100',
            smartUnit: '%',
            help: 'Anzahl der behandelten erwachsenen Patienten, bei denen innerhalb der letzten 24 Monate ein PSI abgerechnet wurde.',
            debug: ['patientenMitPsiAbrechnungIds'],
            canFilterByPatid: true,
          },
          patientenMitPsiBefund: {
            name: 'Mit PSI-Befund, in den letzten 24 Monaten',
            unit: 'patient',
            smartValue: 's / b.patientenErwachsen * 100',
            smartUnit: '%',
            help: 'Anzahl der behandelten erwachsenen Patienten, bei denen innerhalb der letzten 24 Monate ein PSI-Befund eingetragen wurde.',
            debug: ['patientenMitPsiBefundIds'],
            canFilterByPatid: true,
          },
          patientenMitSchlechtemPsiBefund: {
            name: 'Mit therapiebedürftigem PSI Befund',
            unit: 'patient',
            smartValue: 's / b.patientenErwachsen * 100',
            smartUnit: '%',
            help: 'Anzahl der behandelten erwachsenen Patienten, bei denen innerhalb der letzten 24 Monate ein therapiebedürftiger PSI-Befund (PSI-Code 3 und 4 in mind. einem Sextanten) eingetragen wurde.',
            debug: ['patientenMitSchlechtemPsiBefundIds'],
            canFilterByPatid: true,
          },
          _patientenPa: {
            name: 'PA-Behandlung',
            virtual: true,
            isHeader: true,
            childs: {
              patientenPa: {
                name: 'Im Betrachtungszeitraum',
                unit: 'patient',
                smartValue: 's / b.patientenErwachsen * 100',
                smartUnit: '%',
                help: 'Anzahl der behandelten erwachsenen Patienten, bei denen Parodontitis-Leistungen (Konfiguration) erbracht wurden.',
                debug: ['patientenPaIds'],
                canFilterByPatid: true,
              },
              patientenPaLetzte24Monate: {
                name: 'In den letzten 24 Monaten',
                unit: 'patient',
                smartValue: 's / b.patientenErwachsen * 100',
                smartUnit: '%',
                help: 'Anzahl der behandelten erwachsenen Patienten, bei denen Parodontitis-Leistungen (Konfiguration) in den letzten 24 Monaten erbracht wurden.',
                debug: ['patientenPaLetzte24MonateIds'],
                canFilterByPatid: true,
              },
              patientenPaJemals: {
                name: 'Jemals',
                unit: 'patient',
                smartValue: 's / b.patientenErwachsen * 100',
                smartUnit: '%',
                help: 'Anzahl der behandelten erwachsenen Patienten, bei denen jemals Parodontitis-Leistungen (Konfiguration) erbracht wurden.',
                debug: ['patientenPaJemalsIds'],
                canFilterByPatid: true,
              },
            },
          },
        },
      },
      _paPzrKinder: {
        name: 'Behandelte Kinder und Jugendliche (6-17J)',
        value: 'b.patientenPzrKinder',
        smartValue: '100',
        smartUnit: '%',
        unit: 'patient',
        isHeader: true,
        virtual: true,
        childs: {
          patientenPzrKinder: {
            name: 'PZR behandelt, im Betrachtungszeitraum',
            unit: 'patient',
            smartValue: 's / b.patientenJugendlich * 100',
            smartUnit: '%',
            help: 'Anzahl der Kinder (6-18J), bei denen Prophylaxe-Leistungen im Betrachtungszeitraum (laut Leistungs-Konfiguration) erbracht wurden',
            debug: ['patientenPzrKinderIds'],
            canFilterByPatid: true,
          },
          patientenPzrQuoteKinder: {
            name: 'PZR-Patienten / -Quote',
            unit: 'patient',
            smartValue: 's / b.patientenJugendlich * 100',
            smartUnit: '%',
            noAvg: true,
            trendType: IBenchmarkConfigTrendType.nonebutabsolut,
            help: 'Bei der Ermittlung der PZR-Quote werden alle Patienten im Alter zwischen 6 und 18 Jahren berücksichtigt, die entweder in den vergangenen 6 Monaten PZR-Leistungen erhalten oder in den nächsten 6 Monaten einen PZR-Termin haben',
            debug: ['patientenPzrQuoteKinderIds'],
            canFilterByPatid: true,
          },
          patientenKindMitPaTerminInDerZukunft: {
            name: 'Mit PZR/PAV/PAR/PAN/UPT-Termin in der Zukunft',
            unit: 'patient',
            smartValue: 's / b.patientenJugendlich * 100',
            smartUnit: '%',
            noAvg: true,
            trendType: IBenchmarkConfigTrendType.nonebutabsolut,
            help: 'Patienten im Alter < 18 Jahren mit einem PA-Termin (PZR, PAV, PAR, PAN, UPT) in der Zukunft',
            debug: ['patientenKindMitPaTerminInDerZukunftIds'],
            canFilterByPatid: true,
          },
          patientenMitPsiAbrechnungKinder: {
            name: 'Mit PSI Abrechnung, in den letzten 24 Monaten',
            unit: 'patient',
            smartValue: 's / b.patientenJugendlich * 100',
            smartUnit: '%',
            help: 'Anzahl der behandelten jugendlichen Patienten, bei denen innerhalb der letzten 24 Monate ein PSI abgerechnet wurde.',
            debug: ['patientenMitPsiAbrechnungKinderIds'],
            canFilterByPatid: true,
          },
          patientenMitPsiBefundKinder: {
            name: 'Mit PSI-Befund, in den letzten 24 Monaten',
            unit: 'patient',
            smartValue: 's / b.patientenJugendlich * 100',
            smartUnit: '%',
            help: 'Anzahl der behandelten jugendlichen Patienten, bei denen innerhalb der letzten 24 Monate ein PSI-Befund eingetragen wurde.',
            debug: ['patientenMitPsiBefundKinderIds'],
            canFilterByPatid: true,
          },
        },
      },
    },
  };
}
