import { IAnamneseSettings } from './anamnese';
import { IWorkflowSettings } from './workflow';
import { IBehandlerInfo, IBehandler } from './behandler';
import { AbrechnungsArt, HKPTerminType, PAType } from './enums';
import { IFactoringPraxisStempelInfo, rzkeys, IFactoringVertragOderMandant } from './factoring';
import { IHeuteAlarme, IHeuteAlarmConfig } from './heute';
import { ITerminart, IStichwortParam } from './diverse';
import { IDBModelBase } from './db';

export enum BehandlerType {
  ZA = 'za',
  PZR = 'pzr',
  TECHNIKER = 'techniker',
}

export enum TeamTypes {
  ALLE = 'alle',
  PRAXIS = 'praxis',
  UNUSED = 'unused', // unused = alle - praxis
  SINGLE = 'single',
  MITARBEITER = 'mitarbeiter',
  TEAM = 'team',
  BUNDLE = 'bundle',
  AUTOTEAM = 'autoteam',
  INAKTIV = 'inaktiv',
  TERMINGRUPPE = 'termingruppe',
}

export interface IWartezeitenDetail {
  warteZimmer: number;
  behandlungsZimmer: number;
  termin: number;
}

export interface IWartezeiten {
  warnung: IWartezeitenDetail;
  maximum: IWartezeitenDetail;
  minimumVerspaetungInMinuten: number;
}

export interface IZeitenBase {
  von: string;
  bis: string;
}

export interface IZeiten extends IZeitenBase {
  id?: string;
  tag: keyof IOeffnungsZeiten;
  tagName?: string;
  tagNr?: number;
  stunden?: number;
}

export interface IOeffnungsZeiten {
  mo: IZeitenBase[];
  di: IZeitenBase[];
  mi: IZeitenBase[];
  do: IZeitenBase[];
  fr: IZeitenBase[];
  sa: IZeitenBase[];
  so: IZeitenBase[];
}

export interface IBaseDBSettings {
  host: string;
  port: number;
  user: string;
  pass: string;
  db: string; // if pvs = evident -> root path for db files
  mandanten?: number[]; // z1
  praxis?: string; // evident
  ignoreTimer?: boolean;
  disableEncryption?: boolean;
}

export interface IDBSettings extends IBaseDBSettings {
  termineDB?: IBaseDBSettings;
  importCutoffDate?: Date;
}

export interface IHeuteGruppenFilterSettingDetailInfo {
  anzeigen: boolean;
  index: number;
}

export interface IHeuteGruppenFilterSettingDetail {
  termingruppen: IHeuteGruppenFilterSettingDetailInfo;
  za: IHeuteGruppenFilterSettingDetailInfo;
  pzr: IHeuteGruppenFilterSettingDetailInfo;
}

export interface IHeuteGruppenFilterSetting {
  empfang: IHeuteGruppenFilterSettingDetail;
  prophylaxe: IHeuteGruppenFilterSettingDetail;
  zahnarzt: IHeuteGruppenFilterSettingDetail;
  abrechnung: IHeuteGruppenFilterSettingDetail;
}

export interface IHeuteDisabledAlarms {
  stichworte: string[];
  terminbehandler: string[];
  terminarten: string[];
  ueberweiser: boolean;
  terminstatus: { abgesagt: boolean; nichtErschienen: boolean };
}

export type TerminstatusThemeType = 'none' | 'z1timer';

export interface IHeuteSettingsHideFields {
  empfang: {
    [key: string]: boolean;
  };
  prophylaxe: {
    [key: string]: boolean;
  };
  zahnarzt: {
    [key: string]: boolean;
  };
  abrechnung: {
    [key: string]: boolean;
  };
}

export interface IHeuteSettings {
  keinePZRInMonaten: number;
  gruppenfilter: IHeuteGruppenFilterSetting;
  klassifizierungBeiPatientAnzeigen: boolean;
  gueltigkeitHKPOhneGesetzlicheVorgabeInMonaten: number;
  hideFields?: IHeuteSettingsHideFields;
  ignorierteAlarme: IHeuteDisabledAlarms;
  terminstatusTheme: TerminstatusThemeType;
  weiteAnreiseInKm: number;
}

export interface ILiveView {
  updateIntervallServerInSeconds: number;
  updateIntervallClientInSeconds: number;
  active: boolean;
  lookbackInMinutes: number; // how many minutes of lookback the view shows
}

export interface ISettingsBehandlerKonfiguration {
  [behandlerId: string]: IBehandlerInfo;
}

export interface IExtraseitenStichworteSammlungStichworte {
  [stichwort: string]: string; // stichwort kann übersetzt werden
}

export interface IExtraSeitenStichwortSammlung {
  typ: ExtraSeitenStichwortSammlung;
  stichworte?: IExtraseitenStichworteSammlungStichworte;
  extraseiten?: string;
}

export type ExtraSeitenStichwortSammlung = 'stichworte' | 'extraseiten';

export enum ExtraSeitenStichwortSammlungTypValues {
  Stichworte = 'stichworte',
  Extraseiten = 'extraseiten',
}

export interface IRecallSammlung {
  recallIgnorieren: IExtraSeitenStichwortSammlung;
}

export interface IDatenSammlungConfig {
  rechenzentrum: IRechenzentrumSammlung;
  bewertung: IBewertungSammlung;
  datenschutz: IDatenschutzSammlung;
  zusatzversicherung: IExtraSeitenStichwortSammlung;
  recall?: IRecallSammlung;
  deactivateFullImport?: boolean;
  deactivateIncImport?: boolean;
  zimmerbelegung?: IZimmerbelegungSammlung;
}

export interface IZimmerbelegungSammlung {
  excludedRooms: string[];
}

export interface IDatenschutzSammlung {
  typ: DatenschutzSammlungTypValues;
  extraseite?: string;
  stichworte?: string;
}

export enum DatenschutzSammlungTypValues {
  None = 'none',
  Stichworte = 'stichworte',
  Extraseiten = 'extraseiten',
}

export interface IBewertungSammlung {
  typ: BewertungSammlungTypValues;
  extraseite?: string;
  stichWorteRegex?: string;
}

export interface IZVStichwort extends IStichwortParam {
  text: string;
}

export enum BewertungSammlungTypValues {
  None = 'none',
  Klassifizierung = 'klassifizierung',
  Stichworte = 'stichworte',
  Extraseiten = 'extraseiten',
}

export interface IRechenzentrumSammlungextrapage {
  name: string;
  avaDatumFieldName?: string;
  avaErgebnisFieldName?: string;
  eweFieldname?: string;
  avaErgebnisPositiv?: string;
  noRzFieldname?: string;
}

export interface IRechenzentrumSammlungPatextrapage {
  avaDatumFieldName: string;
  avaErgebnisFieldName: string;
  eweFieldname: string;
  avaErgebnisPositiv: string;
  noRzFieldname: string;
}

export interface IRechenzentrumSammlungStichworte {
  ava?: Date;
  ewe?: Date;
  avaOK?: boolean;
  spezialFall?: boolean;
  spezialFallAnzeige?: string;
  spezialFallAlarm?: boolean;
}

export interface IRechenzentrumSammlungStichworteExtended extends IRechenzentrumSammlungStichworte {
  stichwort: string;
}

export interface IRechenzentrumSammlung {
  reihenfolge: string[];
  avaTageGueltig: number;
  eweMonateGueltig: number;
  extraseiten?: IRechenzentrumSammlungextrapage[];
  stichworte?: {
    [stichwort: string]: IRechenzentrumSammlungStichworte;
  };
  minderjaehrigeIgnorieren: boolean;
}

export interface IFactoringSetting {
  eweMonateGueltig: number;
  anfrageWertAbweichenderStandard: number;
  anfrageWertAbweichenderStandardAktiv: boolean;
  anfrageWertMaximum: number;
  aufschlagWertProzent: number;
  aufschlagWertMinimum: number;
  erneuterAufschlagWertProzent: number;
  erneuterAufschlagWertMinimum: number;
  sofortAnfragen: boolean; // betrageingabe bei anfrage überspringen
  eweVerlaengerungDurchRechnung: boolean;
  strategyGueltigkeit: { erste: 'manuell' | 'automatisch'; erneuerung: 'manuell' | 'automatisch' };
  budgetUeberwachung: { erste: 'manuell' | 'automatisch'; erneuerung: 'manuell' | 'automatisch' };
  aktiveMandantenReihenfolge?: string[];
  avaLaeuftBaldAbInWochen: number;
}

export interface IFactoringRZSettings {
  type: rzkeys;
  credentials: any;
  credentialsDecrypted: boolean;
  vertraege: IFactoringVertragOderMandant[];
  standardanfragebetrag?: number;
  auslanderlauben: boolean;
}

export interface ITeam {
  id: string;
  name: string;
  mitglieder: string[];
  size: number;
  // string that can be parsed by: https://www.npmjs.com/package/color
  farbe: string | undefined;
  // bundle: Mitglieder werden nicht mehr separat angezeigt (in Konfig und Benchmark) und durch Gruppe ersetzt z.B. Behandler an verschiedenen Standorten
  // team: Mitglieder bleiben als eigenständige Behandler erhalten z.B.Prophylaxe, Assistenzzahnärzte
  typ: TeamTypes;
  sortKey?: string;
  hilfe?: string;
  singleTyp?: BehandlerType;
}

export interface IInPzrBehandlung {
  monateInDieVergangeheit: number; // wie weit zurück vom aktuellen termin nach termin mit pzr leistungen suchen
  inDieBekannteZukunftSuchen: boolean; // soll auch in die zukunft gesucht werden? wenn ja wird geguckt ob es stattgefundene termine in der zukunft gibt mit pzr leistungen, die vor dem aktuellen termin
}

export interface IS3Credentials {
  accessKeyId: string;
  secretAccessKey: string;
}

export interface IPersonalPlanerSettings {
  kundenNummer: string;
  soapUser: string;
  soapPassword: string;
  mitarbeiterMapping: { [ppposition: string]: string[] }; // personalplaner position matches multiple rose behandler ids
  termineMergeDistInMinutes: number;
}

export interface IPraxisKPIReportSettings {
  userToExecuteAs: string; // this user should be invited to all necessary clients of this is a multi client situation
  emailRecipients: string; // could be comma separated list of emails
}

export interface ISettingsTerminBehandlerZuordnung {
  [terminbehandler: string]: string;
}

export interface ISettingsTerminBehandlerZuordnungMetaAutoItem {
  datum: Date;
  grund: string;
}

export interface ISettingsTerminBehandlerZuordnungMetaAuto {
  [terminebehandler: string]: ISettingsTerminBehandlerZuordnungMetaAutoItem;
}

export interface ISettingsTerminBehandlerZuordnungMeta {
  manuellEntfernt?: string[];
  manuellZugewiesen?: string[];
  auto?: ISettingsTerminBehandlerZuordnungMetaAuto;
}

export type PvsType = 'charly' | 'z1' | 'z1v2' | 'evident' | 'unknown';

export interface ILeistungenSettings {
  implantat: string[];
  pa: string[];
  prophylaxe: string[];
  nullEins: string[];
}

export interface IHKPManagerViewSettings {
  kommentarFavoriten: string[];
}

export interface IViewSettings {
  hkpmanager: IHKPManagerViewSettings;
}

// TODO: Convert this to enum -> enum.ts/WoAnbietenTyp (and use it in all places)
export type IAARegelAnbieten = 'GKV_PKV' | 'PKV' | 'GKV';

export interface IAbrechnungsRegelSettings {
  deaktiviert?: boolean;
  ignorierteStichworte?: string[];
  fehlendeLeistung?: string;
  minAlter?: number;
  woAnbieten?: IAARegelAnbieten;
  triggerLeistungen?: string[];
  ignoreUeberweiser?: boolean;
}

export interface IAbrechnungsRegelSettingsType {
  [regelid: string]: IAbrechnungsRegelSettings;
}

export interface IAbrechnungsSettings {
  ignoriereTerminArten: ITerminart['extid'][];
  ignoriereTerminBehandler: IBehandler['extid'][];
  regelSettings: IAbrechnungsRegelSettingsType;
}

export interface ILabelConfig {
  title: string;
  colorId: number;
  gesetztDatum?: Date;
  ersteller?: Date;
}

export interface IExternalApiSettings {
  quentn?: {
    baseUrl: string;
    apiKey: string;
    supportedTermIds: number[]; // aka tags
  };
}

export interface IAbrechnungsArtTypZuordnung {
  [terminart: string]: AbrechnungsArt;
}

export interface ITerminArtPATypZuordnung {
  [terminart: string]: PAType;
}

export interface ITerminArtHkpTypZuordnung {
  [terminart: string]: HKPTerminType;
}

export interface IBaseSettings extends IDBModelBase {
  db: IDBSettings;
  pvs: PvsType;
}

export interface IHVMSettings {
  [kzv: string]: any;
  '37': IHVMWestfalenLippeSettings;
}

export interface IHVMWestfalenLippeSettingsBehandlerzeiten {
  name: string;
  anteil: number;
  von: string;
  bis: string;
  bisTyp: 'heute' | 'date';
}

export interface IHVMWestfalenLippeSettings {
  behandlerzeiten: IHVMWestfalenLippeSettingsBehandlerzeiten[];
}

export interface ISettings extends IBaseSettings {
  cid: string;
  abrechnung: IAbrechnungsSettings;
  alarme: IHeuteAlarme<IHeuteAlarmConfig>;
  behandler: ISettingsBehandlerKonfiguration | undefined;
  datensammlung: IDatenSammlungConfig;
  factoring: { [rzkey: string]: IFactoringSetting };
  alternativeStempelInfo: {
    aktiv: boolean;
    stempelInfo: IFactoringPraxisStempelInfo;
  };
  heuteSettings: IHeuteSettings;
  inPzrBehandlung: IInPzrBehandlung;
  leistungen: ILeistungenSettings;
  oeffnungszeiten: IOeffnungsZeiten;
  personalPlanerSettings?: IPersonalPlanerSettings;
  praxisKPIReport?: IPraxisKPIReportSettings;
  teams: ITeam[];
  terminArtZuAbrechnungsArt: IAbrechnungsArtTypZuordnung;
  terminArtZuPaType: ITerminArtPATypZuordnung;
  terminArtZuHkpType: ITerminArtHkpTypZuordnung;
  terminArtRegresse: string[];
  terminBehandlerZuordnung: ISettingsTerminBehandlerZuordnung | undefined;
  terminBehandlerZuordnungMeta: ISettingsTerminBehandlerZuordnungMeta | undefined;
  terminLabelConfig: ILabelConfig[];
  abrechnungsLabelConfig: ILabelConfig[];
  hkpLabelConfig: ILabelConfig[];
  terminBuchRaster: number; // in minutes
  views: IViewSettings;
  wartezeiten: IWartezeiten;
  zimmerzahl: number;
  externalapicredentials?: IExternalApiSettings;
  anamneseSettings: IAnamneseSettings;
  workflowSettings: IWorkflowSettings;
  hvm: IHVMSettings;
  praxisBranding: IPraxisBranding;
  doctolib: IDoctolibSettings;
}

export interface IDoctolibSettings {
  active: boolean;
  test: boolean; // use doctolib testhost
  key: string;
  secretKey: string;
}

export interface IPraxisBranding {
  logo: string | null;
  primaryColor: string | null;
  secondaryColor: string | null;
}

export interface IAlarmeSetting extends IHeuteAlarme<IHeuteAlarmConfig> {
  feldName: string;
  feldKey: string;
}

export interface IManuellBehandlerZuweisungsInfo {
  id: string;
  where: string;
}

export interface IHeuteAlarmeUI {
  name: string;
  noAlarms?: boolean;
  alarme: IAlarmeSetting[];
}
