import { IChangeInfo, IPoJo } from './db';
import { RechnungsArtType, ZahlungsArtType } from './enums';
import { IPercentage } from './interfaces';
import { ITerminDetails } from './termin';

export interface IRechnungRoh extends IChangeInfo {
  extid: string;
  patid: string;
  behandler: string;
  tag: Date;
  faellig: Date;
  rgnr: string;
  rgart: RechnungsArtType;
  status: number;
  bezahlt: boolean;
  honorar: number;
  material: number;
  labor: number;
  erstattung: number;
  gesamt: number;
  rest: number;
  mahnbetrag: number;
  mwstnetto: number;
  auslagen: number;
  hkpid: string;
  hkpart: number;
  teilzahlung: boolean;
  ratenzahlung: boolean;
  zahlungsziel: number;
  mahnstufe: number;
  rechenzentrum: boolean;
  bankeinzug: boolean;
  erlassen: number;
  erlasstext: string;
  lastchange: number;
  zahlungsart: ZahlungsArtType;
  alter: number;
  alterinmonaten: number;
}

export interface IRechnungenHonorarDetails {
  behandlerverteilung: IRechnungenLaborDetailsBehandlerMitRestVerteilung[];
  mwstverteilung: IRechnungenDetailsMwStVerteilung[];
}

export interface IRechnungenMaterialDetails {
  behandlerverteilung: IRechnungenLaborDetailsBehandlerVerteilung;
  mwstverteilung: IRechnungenDetailsMwStVerteilung[];
}

export interface IRechnungenLeistungen {
  nummer: string;
  anzahl: number;
  betrag: number;
}

export interface IRechnung extends IRechnungRoh {
  privat: boolean;
  name?: string;
  vorname?: string;
  // derived fields
  behandlerdetails: IRechnungenBehandlerDetails[]; // spezial handling
  bezahltdetails: IRechnungBezahlt[]; // special handling
  honorardetails: IRechnungenHonorarDetails;
  labordetails: IRechnungenLaborDetails;
  materialdetails: IRechnungenMaterialDetails;
  bezahltdatum: Date;
  folgetermine: ITerminDetails[];
  hkpGesamt: number;
  wochenueberfaellig: number;
  von: Date;
  bis: Date;
  eigenlabor: number;
  eigenlabormwst: number;
  fremdlabor: number;
  fremdlabormwst: number;
  leistungen: IRechnungenLeistungen[];
}

export interface IRechnungenResult {
  rechnungen: IRechnungExtended[];
  stornos: IRechnungRoh[];
}

export interface IRechnungExtended extends IRechnung {
  mahnstoppKommentar: string;
  mahnstoppDatum: Date;
  mahnstoppErsteller: string;
  mahnungen: any;
  ertrag: number;
  ertragprozent: number;
}

// ui
export interface IRechnungExtendedWithVeraltet extends IRechnungExtended {
  veraltet: boolean;
}

export interface IRechnungExtendedWithVeraltetLeistungenFiltered extends IRechnungExtendedWithVeraltet {
  filteredLeistungen?: IRechnungenLeistungen[];
}

export enum RechnungSearchStatusFilter {
  Alle = 'Alle',
  Bezahlt = 'Bezahlt',
  Offen = 'Offen',
  Mahnung = 'Mahnung',
  Mahnung1 = 'Mahnung1',
  Mahnung2 = 'Mahnung2',
  Mahnung3 = 'Mahnung3',
  Mahnung4 = 'Mahnung4',
}

export enum RechnungSearchRZFilter {
  Alle = 'Alle',
  RZ = 'RZ',
  KeinRZ = 'KeinRZ',
}

export enum RechnungSearchDateFilter {
  Alle = 'Alle',
  Erstellt = 'Erstellt',
  Faellig = 'Faellig',
}

export interface IRechnungenBehandlerDetails {
  behandler: string;
  honorarAnteil: IPercentage;
}

export interface IRechnungenLaborDetailsBehandlerVerteilung {
  behandler: string;
  rgid: string;
  bis: Date;
  von: Date;
  betrag: number;
  mwstbetrag: number;
}

export interface IRechnungenLaborDetailsBehandlerMitRestVerteilung extends IRechnungenLaborDetailsBehandlerVerteilung {
  rest: number;
}

export interface IRechnungenDetailsMwStVerteilung {
  betrag: number;
  mwstsatz: number;
  rechnung: string;
  mwstbetrag: number;
}

export interface IRechnungenLaborDetails {
  fremdlaborbehandlerverteilung: IRechnungenLaborDetailsBehandlerVerteilung[];
  eigenlaborbehandlerverteilung: IRechnungenLaborDetailsBehandlerVerteilung[];
  eigenlabormaterialbehandlerverteilung: IRechnungenLaborDetailsBehandlerVerteilung[];
  eigenlabormwstverteilung: IRechnungenDetailsMwStVerteilung[];
  eigenlabormaterialmwstverteilung: IRechnungenDetailsMwStVerteilung[];
}

export interface IRechnungBezahlt extends IPoJo {
  extid: string;
  rgid: string;
  tag: Date;
  fibubuchdatum: Date;
  betrag: number;
  zahlungsart: ZahlungsArtType;
  rechenzentrum: boolean;
}

export interface IMahnung extends IPoJo {
  extid: string;
  rgid: string;
  rgnr: string;
  patid: string;
  behandler: string;
  tag: Date;
  erstemahnung: Date;
  zweitemahnung: Date;
  drittemahnung: Date;
  anwalt: string;
  rest: number;
  faelligseit: Date;
  rechnungsbetrag: number;
  behandlerdetails: { [behandlerid: string]: number };
  lastchange: number;
}

export interface IRechungKommentar {
  cid: string;
  rechnungid: string;
  datum: Date;
  kommentar: string;
  ersteller: string;
}

export interface IRechnungMahnstopp {
  rechnung: string;
  kommentar: string;
  datum: string;
  ersteller: string;
}

export interface IRechnungSummaryRow {
  isSummary: boolean;
  isExtended: boolean;
  doFilter: any;
  spezialFilter: string[] | null;
  isExtendedSummaryRow: boolean;
  title: string;
  count: number;
  gesamt: number;
  honorar: number;
  labor: number;
  mahnbetrag: number;
  material: number;
  materialmwst: number;
  mwstnetto: number;
  erstattung: number;
  rest: number;
  honorardetails: any;
}

export interface IRechnungRequestParams {
  bagatellschwelle?: number;
  erstelltFrom?: string;
  erstelltTo?: string;
  faelligFrom?: string;
  faelligTo?: string;
  team?: string;
  leistungserbringer?: string;
  status: RechnungSearchStatusFilter;
  rz?: RechnungSearchRZFilter;
}
