"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var LeistungenTable_vue_1 = require("@/components/tables/settings/Leistungen/LeistungenTable.vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    LeistungenTable: LeistungenTable_vue_1["default"]
  },
  data: function data() {
    return {};
  },
  computed: {
    nullEins: function nullEins() {
      return editSettingsStore_1.editSettingsStore.getters.nullEins;
    },
    prophylaxe: function prophylaxe() {
      return editSettingsStore_1.editSettingsStore.getters.prophylaxe;
    },
    implantat: function implantat() {
      return editSettingsStore_1.editSettingsStore.getters.implantat;
    },
    pa: function pa() {
      return editSettingsStore_1.editSettingsStore.getters.pa;
    },
    availableLeistungen: function availableLeistungen() {
      return editSettingsStore_1.editSettingsStore.getters.availableLeistungen;
    },
    availableProphylaxe: function availableProphylaxe() {
      return editSettingsStore_1.editSettingsStore.getters.availableProphylaxe;
    },
    availableImplantat: function availableImplantat() {
      return editSettingsStore_1.editSettingsStore.getters.availableImplantat;
    },
    availablePa: function availablePa() {
      return editSettingsStore_1.editSettingsStore.getters.availablePa;
    }
  }
});