"use strict";

var _createForOfIteratorHelper = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js")["default"];

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.join.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var behandlerService_1 = require("@/services/behandlerService");

var vue_1 = require("vue");

var lodash_1 = require("lodash");

var PieChart_vue_1 = require("../charts/PieChart.vue");

var filters_1 = require("../../../../common-ui/src/filters");

var colorScales_1 = require("../../../../common-ui/src/colorScales");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    PieChart: PieChart_vue_1["default"]
  },
  mixins: [filters_1.euroFilter],
  data: function data() {
    return {
      params: null,
      behandler: [],
      behandlerText: '',
      behandlerCount: 0,
      chartVals: [],
      chartLabels: [],
      valueColumns: [],
      totals: null,
      maxBehandlerTooltipCount: 12,
      chartColors: colorScales_1.chartColors,
      chartBackgrounds: null,
      chartStyles: {
        position: 'relative',
        width: '150px',
        height: '150px'
      },
      behandlerService: new behandlerService_1.BehandlerService(),
      typ: '-',
      colNames: {
        honorar: 'Honorar',
        material: 'Material',
        eigenlabor: 'Eigenlabor',
        eigenlabormaterial: 'Eigenlabor-Material',
        fremdlabor: 'Fremdlabor',
        betrag: 'Betrag',
        gesamt: 'Gesamt'
      }
    };
  },
  mounted: function mounted() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh() {
      // set value into cell again
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      var _this = this;

      if (!this.params.value) {
        return;
      }

      this.typ = this.params.typ;
      var totalColumn = 'gesamt';
      var chartColumn = 'honorar';

      if (this.typ === 'offeneleistungen') {
        this.valueColumns = ['honorar', 'material', 'eigenlabor', 'eigenlabormaterial', 'fremdlabor', 'gesamt'];
        totalColumn = 'gesamt';
        chartColumn = 'honorar';
      } else if (this.typ === 'rechnungen') {
        this.valueColumns = ['betrag'];
        totalColumn = 'betrag';
        chartColumn = 'betrag';
      }

      var honorardetails = this.params.value; // console.log('leb-cell', this.params, this.typ, honorardetails);

      this.behandlerCount = honorardetails.length; // calc totals

      this.totals = {};

      var _iterator = _createForOfIteratorHelper(this.valueColumns),
          _step;

      try {
        var _loop = function _loop() {
          var col = _step.value;
          _this.totals[col] = (0, lodash_1.sumBy)(honorardetails, function (hd) {
            return hd[col];
          });
        };

        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        } // only show maxBehandlerTooltipCount largest

      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this.behandler = (0, lodash_1.chain)(honorardetails).orderBy(totalColumn, 'desc').take(this.maxBehandlerTooltipCount).value();
      this.behandler.forEach(function (entry) {
        return entry.displayName = _this.behandlerService.getProperty(entry.behandler);
      });
      this.behandlerText = this.leistungserbringer(this.behandler);
      this.chartVals = this.behandler.map(function (behandler) {
        return behandler[chartColumn];
      });
      this.chartLabels = this.behandler.map(function (behandler) {
        return behandler.displayName;
      });
      this.chartBackgrounds = this.behandler.map(function (behandler, index) {
        return behandler.farbe || _this.chartColors[index];
      });
    },
    leistungserbringer: function leistungserbringer(erbringer) {
      if (!erbringer) {
        return '';
      }

      if (this.behandlerCount === 0) {
        return '-';
      }

      var bcString = this.behandlerCount !== 1 ? "(".concat(this.behandlerCount, ") ") : '';
      var names = (0, lodash_1.chain)(erbringer).map(function (entry) {
        return entry.displayName;
      }).join(', ').value();
      return bcString + names;
    }
  }
});