declare global {
  interface ErrorConstructor {
    captureStackTrace(thisArg: any, constructorOpt?: Function): void;
  }
}
export class RoseLoginError extends Error {
  mfaTokenRequired = undefined;
  mfaTokenInvalid = undefined;
  mfaMailSent = undefined;
  wrongCredentials = undefined;
  unknownUser = undefined;
  externalAccessNotAllowed = undefined;

  constructor(message: string) {
    super(message);
    this.name = 'RoseLoginError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RoseLoginError);
    }
  }

  setFlag(flag: keyof RoseLoginError, value = true) {
    (this as any)[flag] = value;
    return this;
  }

  static isRoseLoginError(err: any): err is RoseLoginError {
    return err?.name === 'RoseLoginError';
  }
}
