import { BehandlerService } from '@/services/behandlerService';
import { leistungenStore } from '@/state/leistungenStore';
import {
  behandlerArrayFormatter,
  behandlerFormatter,
  classZeroValue,
  dateFormatter,
  euroFormatter,
  folgeTermineComparer,
  folgeTermineFormatter,
  patientComparer,
  patientDisplayText,
  dateRawFormatter,
  folgeTermineRawDateFormatter,
  IRoseAGGridColumn,
  decimalFormatter,
} from '@rose/common-ui';
import { Units, IOffeneGOZLeistungenExtended } from '@rose/types';
import { sumBy } from 'lodash';

const behandlerService = new BehandlerService();
export const leistungenColumnDefs: IRoseAGGridColumn<IOffeneGOZLeistungenExtended>[] = [
  {
    headerName: 'Patient',
    sortable: true,
    sort: 'asc',
    pinned: 'left',
    width: 300,
    comparator: patientComparer,
    valueFormatter: params => params && patientDisplayText(params.data),
    cellRendererSelector: (params: any) => {
      if (params.data.isSummary) {
        return { component: 'LeistungenSummary' };
      }
      return { component: 'PatientCell' };
    },
    suppressMovable: true,
    exportWidth: 180,
    noexport: true,
  },
  {
    headerName: 'Termin',
    field: 'folgetermine',
    cellClass: 'ag-cell-with-component',
    width: 30,
    sortable: true,
    resizable: true,
    comparator: folgeTermineComparer,
    valueFormatter: folgeTermineFormatter,
    cellRendererSelector: (params: any) => {
      if (params.data.isSummary) {
        return { component: null };
      }
      return { component: 'TermineCell' };
    },
    exportWidth: 80,
    exportUnit: Units.DATUM,
    excelValueFormatter: folgeTermineRawDateFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Info',
    field: 'hasComments',
    cellRenderer: 'KommentarCell',
    width: 48,
    sortable: true,
    resizable: true,
    exportWidth: 150,
  },
  {
    headerName: 'Wiedervorlage',
    field: 'wiedervorlage',
    editable: true,
    cellEditor: 'DateRangeCell',
    valueFormatter: dateFormatter,
    onCellValueChanged: (v: any) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      leistungenStore.dispatch.saveWiedervorlage(v).then(() => console.log('saved'));
    },
    width: 125,
    resizable: true,
    sortable: true,
    suppressMovable: true,
    cellClassRules: {
      // 'cell-warning': params => {
      //   let wiedervorlage = params.data?.wiedervorlage;
      //   let ueberFaellig = wiedervorlage && roseDayjs(wiedervorlage).isBefore(roseDayjs());
      //   return ueberFaellig;
      // },
    },
  },
  {
    headerName: 'Zeitraum',
    sortable: true,
    field: 'abrechnungszeitraumoffengoz',
    headerTooltip: 'Durchschnittlicher Abrechnungszeitraum gewichtet nach Honorarumsatz',
    width: 150,
    resizable: true,
    exportUnit: Units.DAYSROUNDED,
    useFormatterForExport: false,
    cellStyle: { 'text-align': 'right' },
    valueFormatter: decimalFormatter,
  },
  {
    headerName: 'Datum von',
    sortable: true,
    field: 'tagMin',
    valueFormatter: dateFormatter,
    width: 90,
    resizable: true,
    exportUnit: Units.DATUM,
    excelValueFormatter: dateRawFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Datum bis',
    sortable: true,
    field: 'tagMax',
    valueFormatter: dateFormatter,
    width: 90,
    resizable: true,
    exportUnit: Units.DATUM,
    excelValueFormatter: dateRawFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Honorar GOZ',
    field: 'honorar',
    width: 110,
    sortable: true,
    cellStyle: { 'text-align': 'right' },
    cellClass: params => classZeroValue(params),
    valueFormatter: euroFormatter,
    headerTooltip: 'Vom Honorar GOZ wurde der BEMA-Erstattungsanteil bereits abgezogen',
    resizable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Erstattung BEMA',
    field: 'honorarerstattung',
    width: 140,
    sortable: true,
    valueFormatter: euroFormatter,
    cellStyle: { 'text-align': 'right' },
    cellClass: params => classZeroValue(params),
    resizable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Material',
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    field: 'material',
    valueFormatter: euroFormatter,
    cellClass: params => classZeroValue(params),
    width: 100,
    resizable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Eigenlabor',
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    field: 'eigenlabor',
    valueFormatter: euroFormatter,
    cellClass: params => classZeroValue(params),
    width: 100,
    resizable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Eigenlabor-Material',
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    field: 'eigenlabormaterial',
    valueFormatter: euroFormatter,
    cellClass: params => classZeroValue(params),
    width: 100,
    resizable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Fremdlabor',
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    field: 'fremdlabor',
    valueFormatter: euroFormatter,
    cellClass: params => classZeroValue(params),
    width: 100,
    resizable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Gesamt',
    field: 'gesamt',
    sortable: true,
    cellStyle: { 'text-align': 'right' },
    valueFormatter: euroFormatter,
    cellClass: params => classZeroValue(params),
    width: 100,
    resizable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Stammbehandler',
    field: 'hauptbehandler',
    sortable: true,
    width: 150,
    resizable: true,
    valueFormatter: behandlerFormatter(behandlerService),
  },
  {
    headerName: 'Leistungserbringer',
    field: 'leistungsErbringerVerteilung',
    width: 250,
    cellRendererParams: {
      typ: 'offeneleistungen',
    },
    cellRenderer: 'LeistungserbringerCell',
    valueFormatter: behandlerArrayFormatter(behandlerService, v => v.behandler),
    resizable: true,
    exportWidth: 400,
  },
  {
    headerName: 'Honorar-Leistungserbringer',
    field: 'Honorar-Leistungserbringer',
    width: 150,
    sortable: true,
    resizable: true,
    get reactiveHideCondition() {
      return !leistungenStore.getters.leistungserbringerSelected;
    },
    noexport: true,
    valueFormatter: euroFormatter,
    valueGetter: p => {
      const leistungsbehandler = leistungenStore.getters.leistungserbringerSelected;

      if (leistungsbehandler && p.data?.leistungsErbringerVerteilung) {
        return sumBy(
          p.data.leistungsErbringerVerteilung.filter((lev: any) => lev.behandler === leistungsbehandler.id),
          (e: any) => e.honorar,
        );
      } else {
        return undefined;
      }
    },
  },
  // pinnedRowCellRenderer: (p: any) => {
  //   if (p.data.honorarLeistungserbringer) {
  //     return '<span>' + `${numeral(p.data.honorarLeistungserbringer, { fractionDigits: 0 })}` + '</span>';
  //   }
  //   return '<span></span>';
  // },
];

export const leistungenColumnDefsPro: IRoseAGGridColumn<IOffeneGOZLeistungenExtended>[] = [
  {
    headerName: 'AZ',
    headerTooltip: 'Abrechungszeitraum',
    field: 'abrechnungszeitraum',
    sortable: true,
    cellStyle: { 'text-align': 'right' },
    valueFormatter: decimalFormatter,
    // cellClass: params => classZeroValue(params),
    // exportUnit: Units.EURO,
    width: 150,
    resizable: true,
  },
  {
    headerName: 'AZ abgerechnet',
    headerTooltip: 'Abrechungszeitraum abgerechnet',
    field: 'abrechnungszeitraumabgerechnet',
    sortable: true,
    cellStyle: { 'text-align': 'right' },
    valueFormatter: decimalFormatter,
    // cellClass: params => classZeroValue(params),
    // exportUnit: Units.EURO,
    width: 150,
    resizable: true,
  },
  {
    headerName: 'AZ offen',
    headerTooltip: 'Abrechungszeitraum offen',
    field: 'abrechnungszeitraumoffen',
    sortable: true,
    cellStyle: { 'text-align': 'right' },
    valueFormatter: decimalFormatter,
    // cellClass: params => classZeroValue(params),
    // exportUnit: Units.EURO,
    width: 150,
    resizable: true,
  },
];
