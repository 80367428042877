"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.date.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("../../../../common-ui");

var DatePicker_vue_1 = require("@rose/common-ui/src/components/DatePicker.vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    DatePicker: DatePicker_vue_1["default"]
  },
  data: function data() {
    return {
      params: {},
      selectedDate: null,
      datePickerOptions: null,
      dateRange: {
        startDate: new Date(),
        endDate: new Date()
      }
    };
  },
  computed: {},
  beforeMount: function beforeMount() {
    console.log('before mount'); // this.params contains the cell & row information and is made available to this component at creation time

    this.setValues(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      this.setValues(params);
      return true;
    },
    setValues: function setValues(params) {
      var date = (0, common_ui_1.roseDayjs)(this.params.value);

      if (!date.isValid()) {
        date = (0, common_ui_1.roseDayjs)();
      }

      this.datePickerOptions = {
        alwaysShowCalendars: false,
        defaultDateRange: {
          start: date,
          end: date
        }
      };
    },
    getValue: function getValue() {
      return this.selectedDate;
    },
    selectDate: function selectDate(date) {
      this.selectedDate = date.startDate;
      this.params.api.stopEditing();
    },
    remove: function remove() {
      this.selectedDate = null;
      this.params.api.stopEditing();
    }
  }
});