"use strict";

var _defineProperty = require("/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/defineProperty.js")["default"];

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var types_1 = require("@rose/types");

var RechenzentrumStichworte_vue_1 = require("@/components/settings/Datensammlung/RechenzentrumStichworte.vue");

var ZusatzversicherungTable_vue_1 = require("@/components/tables/settings/Datensammlung/ZusatzversicherungTable.vue");

var authStore_1 = require("@/state/authStore");

var Rechenzentrum_vue_1 = require("@/components/settings/Datensammlung/Rechenzentrum.vue");

var RechenzentrumExtraSeiten_vue_1 = require("@/components/settings/Datensammlung/RechenzentrumExtraSeiten.vue");

var RechenzentrumFactoring_vue_1 = require("@/components/settings/Datensammlung/RechenzentrumFactoring.vue");

var StichwortChip_vue_1 = require("@/components/StichwortChip.vue");

var common_ui_1 = require("@rose/common-ui");

var editSettingsRootStore_1 = require("@/state/settings/editSettingsRootStore");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

var staticDataMetricsStore_1 = require("@/state/staticDataMetricsStore");

var Zimmerbelegung_vue_1 = require("@/components/settings/Datensammlung/Zimmerbelegung.vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    Zimmerbelegung: Zimmerbelegung_vue_1["default"],
    RechenzentrumStichworte: RechenzentrumStichworte_vue_1["default"],
    ZusatzversicherungTable: ZusatzversicherungTable_vue_1["default"],
    Rechenzentrum: Rechenzentrum_vue_1["default"],
    RechenzentrumExtraSeiten: RechenzentrumExtraSeiten_vue_1["default"],
    RechenzentrumFactoring: RechenzentrumFactoring_vue_1["default"],
    StichwortChip: StichwortChip_vue_1["default"]
  },
  data: function data() {
    var _bewertungTypOptions, _datenshutzTypOptions, _zvTypOptions;

    return {
      BewertungTyp: types_1.BewertungSammlungTypValues,
      bewertungTypOptions: (_bewertungTypOptions = {}, _defineProperty(_bewertungTypOptions, types_1.BewertungSammlungTypValues.Klassifizierung, 'Aus Klassifizierung'), _defineProperty(_bewertungTypOptions, types_1.BewertungSammlungTypValues.Stichworte, 'Aus Stichworten'), _defineProperty(_bewertungTypOptions, types_1.BewertungSammlungTypValues.Extraseiten, 'Aus Extra-Seiten'), _defineProperty(_bewertungTypOptions, types_1.BewertungSammlungTypValues.None, 'Keine Bewertung'), _bewertungTypOptions),
      DatenschutzTyp: types_1.DatenschutzSammlungTypValues,
      datenshutzTypOptions: (_datenshutzTypOptions = {}, _defineProperty(_datenshutzTypOptions, types_1.DatenschutzSammlungTypValues.Stichworte, 'Aus Stichworten'), _defineProperty(_datenshutzTypOptions, types_1.DatenschutzSammlungTypValues.Extraseiten, 'Aus Extra-Seiten'), _defineProperty(_datenshutzTypOptions, types_1.DatenschutzSammlungTypValues.None, 'Keine Datenschutzerklärung'), _datenshutzTypOptions),
      ZvTyp: types_1.ExtraSeitenStichwortSammlungTypValues,
      zvTypOptions: (_zvTypOptions = {}, _defineProperty(_zvTypOptions, types_1.ExtraSeitenStichwortSammlungTypValues.Stichworte, 'Aus Stichworten'), _defineProperty(_zvTypOptions, types_1.ExtraSeitenStichwortSammlungTypValues.Extraseiten, 'Aus Extra-Seiten'), _zvTypOptions),
      rechenzentrumTab: null,
      rules: common_ui_1.rules
    };
  },
  computed: {
    zvClass: function zvClass() {
      return this.hasPraxisReport ? 'zv-half-span' : 'zv-full-span';
    },
    userToExecuteAs: {
      get: function get() {
        var _editSettingsStore_1$, _editSettingsStore_1$2;

        return (_editSettingsStore_1$ = editSettingsStore_1.editSettingsStore.getters) === null || _editSettingsStore_1$ === void 0 ? void 0 : (_editSettingsStore_1$2 = _editSettingsStore_1$.praxisKPIReport) === null || _editSettingsStore_1$2 === void 0 ? void 0 : _editSettingsStore_1$2.userToExecuteAs;
      },
      set: function set(value) {
        if (this.rules.email(value) === true) {
          editSettingsRootStore_1.editSettingsRootStore.commit.deleteError({
            tabName: 'Datensammlung',
            key: 'userToExecuteAs'
          });
        } else {
          editSettingsRootStore_1.editSettingsRootStore.commit.addError({
            tabName: 'Datensammlung',
            key: 'userToExecuteAs',
            error: 'E-Mail-Adresse in Praxis-KPI-Report falsch.'
          });
          this.$log.debug('Keine gültige E-Mail-Adresse!');
        }

        editSettingsStore_1.editSettingsStore.commit.setPraxisKpiReportUser(value);
      }
    },
    emailRecipients: {
      get: function get() {
        var _editSettingsStore_1$3;

        return (_editSettingsStore_1$3 = editSettingsStore_1.editSettingsStore.getters.praxisKPIReport) === null || _editSettingsStore_1$3 === void 0 ? void 0 : _editSettingsStore_1$3.emailRecipients;
      },
      set: function set(value) {
        if (this.rules.emailDelimited(value) === true) {
          editSettingsRootStore_1.editSettingsRootStore.commit.deleteError({
            tabName: 'Datensammlung',
            key: 'emailRecipients'
          });
        } else {
          editSettingsRootStore_1.editSettingsRootStore.commit.addError({
            tabName: 'Datensammlung',
            key: 'emailRecipients',
            error: 'Liste der E-Mail-Adressen in Praxis-KPI-Report falsch.'
          });
          this.$log.debug('Keine gültige E-Mail-Adresse!');
        }

        editSettingsStore_1.editSettingsStore.commit.setPraxisKpiReportMailRecipient(value);
      }
    },
    zvTyp: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.zvTyp;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setZusatzversicherungTyp(value);
      }
    },
    zvExtraSeiten: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.zvExtraSeiten;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setZusatzversicherungExtraseite(value);
      }
    },
    bewertungTyp: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.bewertungTyp;
      },
      set: function set(value) {
        if (value) {
          editSettingsStore_1.editSettingsStore.commit.setBewertungTyp(value);
        }
      }
    },
    bewertungStichworte: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.bewertungStichworte;
      },
      set: function set(value) {
        if (value) {
          if (this.rules.regex(value) === true) {
            editSettingsRootStore_1.editSettingsRootStore.commit.deleteError({
              tabName: 'Datensammlung',
              key: 'bewertungStichworte'
            });
            editSettingsStore_1.editSettingsStore.commit.setBewertungStichWorteRegex(value);
          } else {
            editSettingsRootStore_1.editSettingsRootStore.commit.addError({
              tabName: 'Datensammlung',
              key: 'bewertungStichworte',
              error: 'Regulärer Ausdruck in Bewertung falsch.'
            });
            this.$log.debug('Kein gültiger Regex!');
          }
        }
      }
    },
    bewertungExtraseite: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.bewertungExtraseite;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setBewertungExtraseite(value);
      }
    },
    patextrapagefields: function patextrapagefields() {
      return staticDataMetricsStore_1.staticDataMetricsStore.state.params.patextrapagefields;
    },
    datenschutzTyp: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.datenschutzTyp;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setDatenschutzTyp(value);
      }
    },
    datenschutzStichworte: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.datenschutzStichworte;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setDatenschutzStichWorteRegex(value);
      }
    },
    datenschutzExtraseite: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.datenschutzExtraseite;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setDatenschutzExtraseite(value);
      }
    },
    doctolibActive: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.state.editSettings.doctolib.active;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setDoctolibActive(value);
      }
    },
    doctolibKey: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.state.editSettings.doctolib.key;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setDoctolibKey(value);
      }
    },
    doctolibSecretKey: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.state.editSettings.doctolib.secretKey;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setDoctolibSecretKey(value);
      }
    },
    doctolibActiveLabel: function doctolibActiveLabel() {
      return this.doctolibActive ? 'Aktiv' : 'Inaktiv';
    },
    stichworte: function stichworte() {
      return staticDataMetricsStore_1.staticDataMetricsStore.state.params.stichworte;
    },
    hasPraxisReport: function hasPraxisReport() {
      return authStore_1.authStore.getters.hasPraxisReport;
    },
    hasFactoring: function hasFactoring() {
      return authStore_1.authStore.getters.hasFactoring;
    },
    hasDoctosync: function hasDoctosync() {
      return authStore_1.authStore.getters.hasDoctosync;
    },
    isBetaCustomer: function isBetaCustomer() {
      return authStore_1.authStore.getters.isBetaCustomer;
    },
    isCharly: function isCharly() {
      return editSettingsStore_1.editSettingsStore.getters.isCharly;
    },
    rechenzentrumTabs: function rechenzentrumTabs() {
      if (this.isCharly && this.hasFactoring) {
        return [{
          name: 'Aus Stichworten',
          component: 'RechenzentrumStichworte'
        }, {
          name: 'Aus Extra-Seiten',
          component: 'RechenzentrumExtraSeiten'
        }, {
          name: 'Aus Factoring',
          component: 'RechenzentrumFactoring'
        }];
      } else if (this.isCharly && !this.hasFactoring) {
        return [{
          name: 'Aus Stichworten',
          component: 'RechenzentrumStichworte'
        }, {
          name: 'Aus Extra-Seiten',
          component: 'RechenzentrumExtraSeiten'
        }];
      } else if (!this.isCharly && this.hasFactoring) {
        return [{
          name: 'Aus Stichworten',
          component: 'RechenzentrumStichworte'
        }, {
          name: 'Aus Factoring',
          component: 'RechenzentrumFactoring'
        }];
      } else {
        return [{
          name: 'Aus Stichworten',
          component: 'RechenzentrumStichworte'
        }];
      }
    }
  }
});