"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var StichwortChip_vue_1 = require("@/components/StichwortChip.vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    StichwortChip: StichwortChip_vue_1["default"]
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    stichwort: function stichwort() {
      return this.params.value;
    }
  }
});