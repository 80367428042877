import { TerminStatusType, RecallType } from './enums';

// some utils
export function statusIsOffen(status: TerminStatusType) {
  return status === TerminStatusType.OFFEN;
}

export function statusIsVirtuell(status: TerminStatusType) {
  return status === TerminStatusType.VIRTUELL;
}

export function statusIsOk(status: TerminStatusType) {
  return status > 0 && (status + '').endsWith('1');
}

export function statusIsNichtErschienen(status: TerminStatusType) {
  return (status + '').endsWith('2');
}

export function statusIsKurzfristigAbgesagt(status: TerminStatusType) {
  return (status + '').endsWith('3');
}

export function statusIsFristgerechtAbgesagt(status: TerminStatusType) {
  return (status + '').endsWith('4');
}

export function statusIsFristgerechtAbgesagtPraxis(status: TerminStatusType) {
  return (status + '').endsWith('5');
}

export function statusIsNotKontrolliert(status: TerminStatusType) {
  return status < TerminStatusType.KONTROLLIERT_BEHANDLER_OK;
}

export function statusIsKontrolliertBehandler(status: TerminStatusType) {
  return (
    (status >= TerminStatusType.KONTROLLIERT_BEHANDLER_OK &&
      status <= TerminStatusType.KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT) ||
    (status >= TerminStatusType.KONTROLLIERT_ALLE_OK &&
      status <= TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT)
  );
}

export function statusIsKontrolliertAbrechnung(status: TerminStatusType) {
  return (
    (status >= TerminStatusType.KONTROLLIERT_ABRECHNUNG_OK &&
      status <= TerminStatusType.KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT) ||
    (status >= TerminStatusType.KONTROLLIERT_ALLE_OK &&
      status <= TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT)
  );
}

export function statusIsKontrolliertAlle(status: TerminStatusType) {
  return (
    status >= TerminStatusType.KONTROLLIERT_ALLE_OK && status <= TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT
  );
}

export function translateRecallType(rt: RecallType) {
  let rs: string;
  switch (rt) {
    case RecallType.KEIN_RECALL:
      rs = 'Kein Recall';
      break;
    case RecallType.NAECHSTER_RECALLTERMIN:
      rs = 'Nächster Recalltermin';
      break;
    case RecallType.RECALLTERMIN_ERINNERT:
      rs = 'Recalltermin erinnert';
      break;
    case RecallType.RECALLTERMIN_VEREINBART:
      rs = 'Recalltermin vereinbart';
      break;
    case RecallType.RECALLTERMIN_ERSCHIENEN:
      rs = 'Recalltermin erschienen';
      break;
  }
  return rs;
}
