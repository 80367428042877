import { CompressionType, EncryptionType } from './bot';
import { IDBModelBase } from './db';
import { TabletTaskType } from './enums';
import { ITabletTaskWrapped, ReducedPatientForTabletTasks } from './tablet';

export interface IPapoSharedEntryBase extends IDBModelBase {
  id: string;
  cid: string;
  shareId: string;
  emailSendId: string;
  recipientEmail: string;
  source: string;
  recipientPassword: string;
  failedLoginAttempts: number;
  expires: Date;
  patient: ReducedPatientForTabletTasks;
  patid: string;
}

export interface ISharedSecuredFiles extends IPapoSharedEntryBase {
  fileName: string;
  md5Hash: string;
  encryptedFileBuffer: any; // Buffer;
  compressionType: CompressionType;
  encryptionType: EncryptionType;

  downloadCount: number;
}

export interface ISharedTasks extends IPapoSharedEntryBase {
  taskWrapped: ITabletTaskWrapped;
  isDone: boolean;
  taskType: TabletTaskType;
}

export type IPapoSharedEntry = ISharedSecuredFiles | ISharedTasks;

export function formatSharedTaskToForUser(task: ISharedTasks): string {
  switch (task.taskType) {
    case TabletTaskType.ANAMNESE_TASK:
      return 'Anamnese';
    case TabletTaskType.EWE_TASK:
      return 'EWE';
    case TabletTaskType.DOCUMENT_TASK:
      return 'Dokument';
    case TabletTaskType.QR_CODE_TASK:
      return 'QR Code';
    case TabletTaskType.UPLOAD_TASK:
      return 'Upload';
    default:
      return 'Unbekannt';
  }
}
