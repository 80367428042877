import { IBenchmarkConfigItemRaw, IBenchmarkConfigTrendType } from '../../../types';

function createAltersPyramide() {
  let ap: any = {};
  for (let i = 0; i < 10; i++) {
    ap[`_alter${i}`] = {
      name: `${i * 10}-${(i + 1) * 10 - 1} J`,
      unit: 'patient',
      trendType: IBenchmarkConfigTrendType.biggerisbetter,
      virtual: true,
      smartValue: 's / b.patienten * 100',
      smartUnit: '%',
      value: `b.altersPyramide['${i}']`,
      debug: [`altersPyramide['${i}patids']`],
      canFilterByPatid: true,
    };
  }
  ap._alter100 = {
    name: `>= 100 J`,
    unit: 'patient',
    trendType: IBenchmarkConfigTrendType.biggerisbetter,
    virtual: true,
    smartValue: 's / b.patienten * 100',
    smartUnit: '%',
    value: `b.altersPyramide['100']`,
    debug: [`altersPyramide['100patids']`],
    canFilterByPatid: true,
  };
  return ap;
}

export function benchmarkConfigPatienten(): IBenchmarkConfigItemRaw {
  return {
    name: 'Behandelte Patienten',
    isHeader: true,
    value: 'b.patienten',
    virtual: true,
    smartValue: '100',
    smartUnit: '%',
    help: 'Als **behandelter Patienten** gilt jeder Patient, an dem im ausgewählten Zeitraum Honorarleistungen erbracht wurden.',
    childs: {
      patienten: {
        name: 'Insgesamt',
        unit: 'patient',
        smartValue: '100',
        smartUnit: '%',
        help: 'Als **behandelter Patienten** gilt jeder Patient, an dem im ausgewählten Zeitraum Honorarleistungen erbracht wurden.',
        collapsed: true,
        debug: ['patientenIds'],
        canFilterByPatid: true,
        childs: {
          patientenAktiv: {
            name: 'Aktiv',
            unit: 'patient',
            smartValue: 's / b.patienten * 100',
            smartUnit: '%',
            help: 'Als **aktiver** behandelter Patienten gilt jeder Patient, an dem im ausgewählten Zeitraum Honorarleistungen erbracht wurden und der **nicht** veraltet wurde.',
            debug: ['patientenAktivIds'],
            canFilterByPatid: true,
          },
          patientenVeraltet: {
            name: 'Inaktiv (veraltet)',
            unit: 'patient',
            smartValue: 's / b.patienten * 100',
            smartUnit: '%',
            help: 'Als **inaktiver** behandelter Patienten gilt jeder Patient, an dem im ausgewählten Zeitraum Honorarleistungen erbracht wurden und der veraltet wurde.',
            debug: ['patientenVeraltetIds'],
            canFilterByPatid: true,
          },
          patientenKeinNotfall: {
            name: 'Nicht Notfall-Patienten',
            unit: 'patient',
            smartValue: 's / b.patienten * 100',
            smartUnit: '%',
            help: 'Ein Patient, in dessen erstem Termin kein **Notdienst-Zuschlag** abgerechnet wurde, wird als **Nicht-Notfall-Patient** eingestuft. Dies sind in der Regel die meisten Patienten. Die Einteilung ist für die Unterscheidung von "Notfall-" und "Nicht-Notfall-Patienten" wichtig.',
            debug: ['patientenKeinNotfallIds'],
            canFilterByPatid: true,
          },
          patientenNotfall: {
            name: 'Notfall-Patienten',
            unit: 'patient',
            smartValue: 's / b.patienten * 100',
            smartUnit: '%',
            help: 'Ein Patient, in dessen erstem Termin ein **Notdienst-Zuschlag** abgerechnet wurde, wird als **Notfall-Patient** eingestuft. Er gilt damit dauerhaft als **Notfall-Patient**, der im Notdienst zur Praxis gefunden hat. Es spielt somit keine Rolle ob der Notdienst im Betrachtungszeitraum oder zuvor stattgefunden hat.',
            debug: ['patientenNotfallIds'],
            canFilterByPatid: true,
          },
        },
      },
      patientenPrivat: {
        name: 'Privat',
        unit: 'patient',
        smartValue: 's / b.patienten * 100',
        smartUnit: '%',
        help: 'Patienten mit der Abrechnungsart privat und Selbstzahler',
        debug: ['patientenPrivatIds'],
        canFilterByPatid: true,
      },
      patientenKasse: {
        name: 'Kasse',
        unit: 'patient',
        smartValue: 's / b.patienten * 100',
        smartUnit: '%',
        help: 'Patienten mit der Abrechnungsart Kasse',
        debug: ['patientenKasseIds'],
        canFilterByPatid: true,
      },
      patientenNeu: {
        name: 'Neupatienten',
        unit: 'patient',
        smartValue: 's / b.patienten * 100',
        smartUnit: '%',
        help: 'Ein Patient, der im ausgewählten Zeitraum erstmalig einen Termin hatte, an dem Leistungen erbracht wurden, wird als **Neupatient** gezählt.',
        debug: ['patientenNeuIds'],
        canFilterByPatid: true,
      },
      patientenFrau: {
        name: 'Frauen',
        unit: 'patient',
        smartValue: 's / b.patienten * 100',
        smartUnit: '%',
        help: 'Es werden nur Mann/Frau gezählt. Neutral bleibt unberücksichtigt.',
        debug: ['patientenFrauIds'],
        canFilterByPatid: true,
      },
      patientenMann: {
        name: 'Männer',
        unit: 'patient',
        smartValue: 's / b.patienten * 100',
        smartUnit: '%',
        help: 'Es werden nur Mann/Frau gezählt. Neutral bleibt unberücksichtigt.',
        debug: ['patientenMannIds'],
        canFilterByPatid: true,
      },
      patientenErwachsen: {
        name: 'Erwachsene >= 18J',
        unit: 'patient',
        smartValue: 's / b.patienten * 100',
        smartUnit: '%',
        debug: ['patientenErwachsenIds'],
        canFilterByPatid: true,
      },
      _patientenKinderUndJugendliche: {
        name: 'Kinder und Jugendliche',
        unit: 'patient',
        virtual: true,
        value: '+b.patientenJugendlich + +b.patientenKind',
        smartValue: 's / b.patienten * 100',
        smartUnit: '%',
        debug: ['patientenJugendlichIds', 'patientenKindIds'],
        canFilterByPatid: true,

        childs: {
          patientenJugendlich: {
            name: '6-17J',
            smartValue: 's / b.patienten * 100',
            smartUnit: '%',
            unit: 'patient',
            debug: ['patientenJugendlichIds'],
            canFilterByPatid: true,
          },
          patientenKind: {
            name: '< 6J',
            unit: 'patient',
            smartValue: 's / b.patienten * 100',
            smartUnit: '%',
            debug: ['patientenKindIds'],
            canFilterByPatid: true,
          },
        },
      },
      alter: {
        name: '⌀ Alter',
        unit: 'jahre',
        trendType: IBenchmarkConfigTrendType.nonebutabsolut,
        noAvg: true,
        collapsed: true,
        childs: createAltersPyramide(),
      },
    },
  };
}
