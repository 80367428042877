import { IViewUI } from '../../types';

export enum ViewID {
  HEUTE = '001',
  UMSATZ_TABELLE = '740',
  UMSATZ_MATRIX = '741',
  UMSATZ_CHART = '742',
  HVM = '750',
  HKP_MANAGER = '1210',
  RECALL_MANAGER = '1220',
  PA_MANAGER = '1230',
  BENCHMARK_OLD = '666',
  BENCHMARK = '6666',
  RECHNUNGEN_OLD = '801',
  RECHNUNGEN = '800',
  LEISTUNGEN_OLD = '900_OLD',
  LEISTUNGEN_VUI = '900',
  PATIENTENUMSAETZE = '902',
  TERMIN_QUALITAET = '903',
  DEBUG = '920',
  FACTORING = '3000',
  EXTENDEDQUERY = '7000',
  PRAXIS_KPI = '777',
  PATIENTEN = '4000',
  ABRECHNUNG = '5000',
  COMPLIANCE = '6000',
  PATIENTEN_PORTAL = '7100',
  PATIENTEN_MAP = '7700',
  PROFIL = '8888',
  EINSTELLUNGEN_OLD = '9999',
  EINSTELLUNGEN = '99999',
  NEWS = '7777',
  DESIGNSYSTEM = '11111',
  ZIMMERBELEGUNG = '22222',
  DOCTOSYNC = '22224',
  DSO = '22226',
  // this view can be selcted if no other view should be used
  PLACEHOLDERVIEW = '0000',
}

export let views: IViewUI[] = [
  {
    id: ViewID.HEUTE,
    path: 'heute',
    name: 'Heute',
    helpVideos: {
      videos: [
        { name: 'Übersicht', id: 'EmVgy6vuwys' },
        { name: 'Status und Termin', id: 'pH10yIGWAAQ' },
        { name: 'Behandler und Compliance', id: 'hIPwPXEjj-o' },
        { name: 'RZ, HKPs und Rechnungen', id: 'WKL_ZUnLwjU' },
        { name: 'PZR, Recall, 01 und Folgetermine', id: 'fUTdUKu6Pac' },
        { name: 'Label', id: '9z_pFCSLq4Y' },
        { name: 'PA, Zimmer und Abrechnung', id: 'iAzlE2sAByg' },
        { name: 'Vorschläge', id: 'O27ayCSrWyg' },
        { name: 'Score', id: 'B9AVMXldjvw' },
      ],
      playlist: 'https://youtube.com/playlist?list=PLCdXB6_pH-cWpiiIKQGIRSTbkMgSFxLGN',
    },
  },
  {
    id: ViewID.UMSATZ_TABELLE,
    path: 'umsatz-tabelle',
    name: 'Umsatz-Tabelle',
    specificBehandlerAccessControl: true,
    helpVideos: {
      videos: [{ name: 'Einführung', id: '1c9x1KxQ-I4' }],
    },
  },
  {
    id: ViewID.UMSATZ_MATRIX,
    path: 'umsatz-matrix',
    name: 'Umsatz-Matrix',
    helpVideos: {
      videos: [{ name: 'Einführung', id: 'vucKq72_gYE' }],
    },
  },
  {
    id: ViewID.UMSATZ_CHART,
    path: 'umsatz-chart',
    name: 'Umsatz-Chart',
    helpVideos: {
      videos: [{ name: 'Einführung', id: 'jDooskgRhek' }],
    },
  },
  {
    id: ViewID.HVM,
    name: 'HVM',
    path: 'hvm',
    helpVideos: {
      videos: [
        { name: 'Monitor und Budget', id: 'jRQTTYzBjm4' },
        { name: 'Einstellungsleiste', id: 'X-UhJ9R3S-o' },
        { name: 'Praxisbudget', id: 'qiKMlyBEDQI' },
        { name: 'Pro-Ansicht', id: 'MoKcWiHjpXw' },
        { name: 'Behandlerbudget', id: 'LqxJCRxzZAM' },
        { name: 'Detailansicht', id: 'v6PHS98h2Dg' },
      ],
      playlist: 'https://www.youtube.com/playlist?list=PLCdXB6_pH-cWFjfVhO3TBDozV5mwYiLuF',
    },
  },
  {
    id: ViewID.HKP_MANAGER,
    path: 'hkp',
    name: 'HKP-Manager',
    helpVideos: {
      videos: [
        { name: 'Übersicht', id: 'PoE46EJzP1E' },
        { name: 'Filterzeile', id: 'pJogKa2i6Gw' },
        { name: 'Statistik', id: 'RR6dTh5PXL0' },
        { name: 'Spezialfilter', id: 'cLdxA5q8cnY' },
        { name: 'Tabelle', id: 'KfIMXVFNdH0' },
        { name: 'Details', id: 'sAD9S3br3DE' },
        { name: 'Favoriten', id: 'nDtSD7xh9KE' },
      ],
      playlist: 'https://youtube.com/playlist?list=PLCdXB6_pH-cWSBUw-BBGGXCeY2eZ7HSXl',
    },
  },
  {
    id: ViewID.RECALL_MANAGER,
    path: 'recall',
    name: 'Recall-Manager',
  },
  {
    id: ViewID.TERMIN_QUALITAET,
    path: 'terminqualitaet',
    name: 'Termin-Qualität',
    helpVideos: {
      videos: [
        { name: 'Die Terminqualität', id: 'BbSghgfq8mI' },
        { name: 'Die Terminbeschreibung', id: 'UP_LAOLG85k' },
        { name: 'Details zum zeitlichen Terminablauf', id: 'ExLMlOk_oSg' },
        { name: 'Honorarumsatz Patienten und Leistungskontrolle', id: 'XDEIlCrqF_c' },
        { name: 'Die Vorschau', id: 'ON1e_V__S_g' },
        { name: 'Hinweise zu Konfiguration und Nutzung von Terminarten in der Praxis', id: 'wgu0OnMPHhY' },
      ],
      playlist: 'https://www.youtube.com/playlist?list=PLCdXB6_pH-cXiNUhc0drxG1k4ToxUqLNs',
    },
  },
  // {
  //   id: ViewID.PA_MANAGER,
  //   path: 'pa',
  //   name: 'PA-Manager',
  // },
  {
    id: ViewID.BENCHMARK_OLD,
    path: 'benchmark',
    name: 'Benchmark',
    specificBehandlerAccessControl: true,
  },
  {
    id: ViewID.BENCHMARK,
    path: 'benchmarkneu',
    name: 'Benchmark NEW',
    specificBehandlerAccessControl: true,
  },
  {
    id: ViewID.LEISTUNGEN_OLD,
    path: 'leistungenold',
    name: 'Offene GOZ-Leistungen NG OLD',
  },
  {
    id: ViewID.LEISTUNGEN_VUI,
    path: 'leistungen',
    name: 'Offene GOZ-Leistungen',
    helpVideos: {
      videos: [
        { name: 'Die Filterleiste', id: 'esvjK4yvV5w' },
        { name: 'Die Hauptansicht', id: '6o0Mm0iOn_U' },
        { name: 'Die Summenzeile', id: 'vlMZCXKoIqQ' },
      ],
      playlist: 'https://youtube.com/playlist?list=PLCdXB6_pH-cVSzNamZoWZ_QuL3SscUHTf',
    },
  },
  {
    id: ViewID.PATIENTENUMSAETZE,
    path: 'patientenumsaetze',
    name: 'Patientenumsätze',
  },
  {
    id: ViewID.RECHNUNGEN_OLD,
    path: 'rechnungendeprecated',
    name: 'Rechnungen ng',
  },
  {
    id: ViewID.RECHNUNGEN,
    path: 'rechnungen',
    name: 'Rechnungen',
  },
  {
    id: ViewID.DEBUG,
    name: 'Debug',
    path: 'debug',
  },
  {
    id: ViewID.FACTORING,
    path: 'factoring',
    name: 'Factoring',
  },
  {
    id: ViewID.EXTENDEDQUERY,
    name: 'Erweiterte Abfragen',
    path: 'extendedquery',
  },
  {
    id: ViewID.PRAXIS_KPI,
    path: 'praxiskpi',
    name: 'Praxis-KPI',
  },
  {
    id: ViewID.PATIENTEN,
    path: 'patienten',
    name: 'Patienten-Suche',
    helpVideos: {
      videos: [{ name: 'Die Patientensuche', id: 'UXp2B-wMZbE' }],
    },
  },
  {
    id: ViewID.PATIENTEN_PORTAL,
    name: 'Patienten-Portal',
    path: 'papo',
  },
  {
    id: ViewID.PATIENTEN_MAP,
    path: 'patientenkarte',
    name: 'Patienten-Landkarte',
    beta: true,
  },
  {
    id: ViewID.ABRECHNUNG,
    path: 'abrechnung',
    name: 'Abrechnung',
    helpVideos: {
      videos: [
        { name: 'Filterleiste', id: 'v87CeIHV03A' },
        { name: 'Abrechnungsregeln', id: 'Qu0wc5wUsR4' },
        { name: 'Spezialfilter', id: 'Ze4b4_e8630' },
        { name: 'Hauptansicht', id: '4meHFbjbRGM' },
      ],
      playlist: 'https://youtube.com/playlist?list=PLCdXB6_pH-cUtzLlPbNWeIl2Gp5O8G5gy',
    },
  },
  {
    id: ViewID.COMPLIANCE,
    path: 'compliance',
    name: 'Compliance',
  },
  {
    id: ViewID.PROFIL,
    path: 'profil',
    name: 'Profil',
  },
  {
    id: ViewID.EINSTELLUNGEN_OLD,
    path: 'einstellungenalt',
    name: 'Einstellungen (Alt)',
    helpVideos: {
      videos: [
        { name: 'Benutzer', id: 'LnFZ7nFIUCw' },
        { name: 'Heute', id: 'g0P0BaM-77o' },
      ],
    },
  },
  {
    id: ViewID.EINSTELLUNGEN,
    path: 'einstellungen',
    name: 'Einstellungen',
  },
  {
    id: ViewID.NEWS,
    path: 'news',
    name: 'News',
  },
  {
    id: ViewID.DESIGNSYSTEM,
    path: 'designsystem',
    name: 'Designsystem',
  },
  {
    id: ViewID.ZIMMERBELEGUNG,
    path: 'zimmerbelegung',
    name: 'Zimmerbelegung',
  },
  {
    id: ViewID.DOCTOSYNC,
    path: 'doctosync',
    name: 'Doctosync',
  },
  {
    id: ViewID.DSO,
    path: 'dso',
    name: 'DSO',
  },
  {
    id: ViewID.PLACEHOLDERVIEW,
    path: '',
    name: 'Keine anderen Views',
  },
];
