import { IAnamneseCharlyQuestion, IAnamneseInfos, ICharlyPatientDetails, IFactoringPatientKomplett } from '.';

export interface IWorkflowSettings {
  workflows: IWorkflow[];
}

export interface ICreateWorkflowRequest {
  workflowId: string;
  patient: IFactoringPatientKomplett;
  anamneseInfos: IAnamneseInfos;
  eweInfos: {
    benutzer: string;
    betreuungsfall: boolean;
  };
}

export interface IWorkflow {
  id: string;
  name: string;
  elements: IWorkflowElement[];
}

export enum WorkflowElementType {
  Questionnaire = 'questionnaire',
  Ewe = 'ewe',
  Document = 'document',
}
export interface IWorkflowElementBase {
  id: string;
  type: WorkflowElementType;
}
export interface IWorkflowElementEwe extends IWorkflowElementBase {
  type: WorkflowElementType.Ewe;
  rzid: number;
}

export interface IWorkflowElementQuestionnaire extends IWorkflowElementBase {
  type: WorkflowElementType.Questionnaire;
  questionnaireId: string;
}

export interface IWorkflowElementDocument extends IWorkflowElementBase {
  type: WorkflowElementType.Document;
  filename: string;
}

export type IWorkflowElement = IWorkflowElementEwe | IWorkflowElementQuestionnaire | IWorkflowElementDocument;
