import { IBenchmarkConfigItemRaw } from '../../../types';

interface BenchmarkChildsType {
  [key: string]: IBenchmarkConfigItemRaw;
}

interface IMKVNodeConf {
  key: string;
  name: string;
  help?: string;
  anzahl?: boolean;
  betrag?: boolean;
  durchschnitt?: boolean;
  privatSpezial?: boolean;
  children?: IMKVNodeConf[];
}
type GKVPKVType = 'gkv' | 'pkv';

function u(s: string): string {
  return s ? s[0].toLocaleUpperCase() + s.substring(1) : '';
}

const flaechigkeit = [
  { key: '1flaechig', name: '1-flächig' },
  { key: '2flaechig', name: '2-flächig' },
  { key: '3flaechig', name: '3-flächig' },
  { key: '4flaechig', name: '4-flächig' },
];

const leistungen: { gkv: IMKVNodeConf[]; pkv: IMKVNodeConf[] } = {
  gkv: [
    {
      key: 'mehrkosten',
      name: 'Mehrkosten-Füllungen (mit Zuzahlung)',
      anzahl: true,
      help: 'Anzahl der Füllungsleistungen bei GKV-Patienten nach den Nummern BEMA 13a-d, mit fiktiver Verrechnung und korrespondierender GOZ Leistung.',
    },
    {
      key: 'mehrkosten',
      name: 'Mehrkosten-Füllungen (mit Zuzahlung)',
      durchschnitt: true,
      help: 'Durchschnittlicher Betrag der Füllungsleistungen bei GKV-Patienten nach den Nummern GOZ 2060, 2080, 2100, 2120 abzüglich der jeweiligen fiktiven BEMA Verrechnung.',
    },
    {
      key: 'kassenfuellung',
      name: 'Kassenfüllungen (ohne Zuzahlung)',
      children: [
        {
          key: 'dentinadhaesiv',
          name: 'Dentinadhäsiv',
          anzahl: true,
          help: 'Anzahl der Füllungsleistungen bei GKV-Patienten nach den Nummern BEMA 13e-h (Bei Kindern bis 14 Jahre und Amalgam Kontraindikation).',
        },
        {
          key: 'komposit', // plastisch
          name: 'Komposit',
          anzahl: true,
          help: 'Anzahl der Füllungsleistungen bei GKV-Patienten nach den Nummern BEMA 13a-d.',
        },
        {
          key: 'amalgam',
          name: 'Amalgam',
          anzahl: true,
          help: 'Anzahl der Füllungsleistungen bei GKV-Patienten nach den Nummern BEMA 13aA-dA mit der Kennzeichnung Amalgam (A).',
        },
      ],
    },
  ],
  pkv: [
    {
      key: 'komposit',
      name: 'Komposit',
      anzahl: true,
      help: 'Anzahl der Füllungsleistungen bei PKV-Patienten nach den Nummern GOZ 2060, 2080, 2100, 2120.',
    },
    {
      key: 'zement',
      name: 'Zement',
      anzahl: true,
      help: 'Anzahl der Füllungsleistungen bei PKV-Patienten nach den Nummern GOZ 2050, 2070, 2090, 2110.',
    },
  ],
};

function createFuellungenFlaechigkeitChilds() {
  const flaechigkeitChilds: BenchmarkChildsType = {};
  for (const f of flaechigkeit) {
    flaechigkeitChilds[`_${f.key}`] = {
      name: f.name,
      isHeader: true,
      value: `b.behandlungen${f.key}Anzahl`,
      unit: 'F',
      smartValue: `b.behandlungen${f.key}Anzahl / b.behandlungenAnzahl * 100`,
      smartUnit: '%',
      virtual: true,
      childs: createFuellungenVersicherungsartChilds(f.key),
      collapsed: true,
    };
  }
  return flaechigkeitChilds;
}

function createFuellungenVersicherungsartChilds(fkey: string, mitGesamt = true) {
  const vChilds: BenchmarkChildsType = {};
  const gkv: { key: GKVPKVType; name: string } = { key: 'gkv', name: 'Bei GKV' };
  const g = `b.behandlungen${u(fkey)}${u(gkv.key)}`;
  const pkv: { key: GKVPKVType; name: string } = { key: 'pkv', name: 'Bei PKV' };
  const p = `b.behandlungen${u(fkey)}${u(pkv.key)}`;

  if (mitGesamt) {
    vChilds[`behandlungen${u(fkey)}Anzahl`] = {
      name: 'Insgesamt',
      unit: 'F',
      smartValue: `s / b.behandlungenAnzahl * 100`,
      smartUnit: '%',
      help: 'Anzahl der Füllungsleistungen nach den Nummern BEMA 13a-h und GOZ 2050-2120 (Abrechnungsleistungen je nach Fläche).',
    };
  }

  // bei GKV
  vChilds[`_behandlungen${u(fkey)}${u(gkv.key)}Anzahl`] = {
    name: gkv.name,
    unit: 'F',
    virtual: true,
    value: `${g}MehrkostenAnzahl + ${g}AmalgamAnzahl + ${g}DentinadhaesivAnzahl + ${g}KompositAnzahl`,
    smartValue: `s / b.behandlungen${u(fkey)}Anzahl * 100`,
    smartUnit: '%',
    childs: createLChilds(fkey, gkv.key),
  };

  // bei PKV
  vChilds[`_behandlungen${u(fkey)}${u(pkv.key)}Anzahl`] = {
    name: pkv.name,
    unit: 'F',
    virtual: true,
    value: `${p}ZementAnzahl + ${p}KompositAnzahl`,
    smartValue: `s / b.behandlungen${u(fkey)}Anzahl * 100`,
    smartUnit: '%',
    childs: createLChilds(fkey, pkv.key),
  };
  return vChilds;
}

function createLChilds(fkey: string, vkey: GKVPKVType) {
  const lChilds: BenchmarkChildsType = {};
  for (const l of leistungen[vkey]) {
    if (!l.privatSpezial) {
      if (l.anzahl) {
        lChilds[`behandlungen${u(fkey)}${u(vkey)}${u(l.key)}Anzahl`] = {
          name: l.name,
          unit: 'F',
          smartValue: `s / b.behandlungen${u(fkey)}Anzahl * 100`,
          smartUnit: '%',
          help: l.help,
        };
      }
      if (l.betrag) {
        lChilds[`behandlungen${u(fkey)}${u(vkey)}${u(l.key)}Betrag`] = {
          name: `${l.name} €`,
          unit: 'eur',
          smartValue: `s / b.behandlungen${u(fkey)}Betrag * 100`,
          smartUnit: '%',
          help: l.help,
        };
      }
      if (l.durchschnitt) {
        lChilds[`_behandlungen${u(fkey)}${u(vkey)}${u(l.key)}Durchschnitt`] = {
          name: `${l.name} ø€`,
          virtual: true,
          value: `Math.abs(b.behandlungen${u(fkey)}${u(vkey)}${u(l.key)}Betrag) / b.behandlungen${u(fkey)}${u(vkey)}${u(
            l.key,
          )}Anzahl`,
          unit: 'eur',
          help: l.help,
        };
      }
      if (l.children) {
        const key = `_behandlungen${u(fkey)}${u(vkey)}${u(l.key)}`;
        lChilds[key] = {
          name: l.name,
          unit: 'F',
          smartValue: `s / b.behandlungen${u(fkey)}Anzahl  * 100`,
          smartUnit: '%',
          value: `b.behandlungen${u(fkey)}${u(vkey)}KompositAnzahl + b.behandlungen${u(fkey)}${u(
            vkey,
          )}AmalgamAnzahl + b.behandlungen${u(fkey)}${u(vkey)}DentinadhaesivAnzahl`,
          virtual: true,
          isHeader: true,
          collapsed: true,
          childs: {},
        };
        for (const c of l.children) {
          const childs = lChilds[key].childs;
          if (childs) {
            childs[`behandlungen${u(fkey)}${u(vkey)}${u(c.key)}Anzahl`] = {
              name: c.name,
              unit: 'F',
              smartValue: `s / (${lChilds[key].value}) * 100`,
              smartUnit: '%',
              help: c.help,
            };
          }
        }
      }
    } else {
      lChilds[`_behandlungen${u(fkey)}${u(vkey)}${u(l.key)}Anzahl`] = {
        name: `${l.name}`,
        virtual: true,
        value: `b.behandlungen${u(fkey)}${u(vkey)}${u(l.key)}Anzahl - b.behandlungen${u(fkey)}${u(
          vkey,
        )}MehrkostenAnzahl`,
        unit: 'F',
        smartValue: `s / b.behandlungen${u(fkey)}Anzahl * 100`,
        smartUnit: '%',
        help: l.help,
      };
    }
  }
  return lChilds;
}

function createImplChilds(implantatLeistungen: string[]) {
  const ret: BenchmarkChildsType = {
    behandlungenImplantateGesamtAnzahl: {
      name: 'Anzahl gesetzte Implantate',
      unit: 'I',
    },
    behandlungenImplantateGesamtBetrag: {
      name: 'Honorar gesetzte Implantate',
      unit: 'eur',
    },
    behandlungenImplantateGesamtPatienten: {
      name: 'Patienten mit gesetztem Implantat',
      unit: 'P',
    },
    _behandlungenImplantateGesamtImplantateJePatient: {
      name: 'Implantate je Patient mit Implantat',
      unit: 'I',
      formatHint: '0.0',
      virtual: true,
      value: `b.behandlungenImplantateGesamtAnzahl / b.behandlungenImplantateGesamtPatienten`,
    },
    _behandlungenImplantateGesamtHonorarJePatient: {
      name: 'Honorar je Patient mit Implantat',
      unit: 'eur',
      virtual: true,
      value: `b.behandlungenImplantateGesamtBetrag / b.behandlungenImplantateGesamtPatienten`,
    },
    _behandlungenImplantateGesamtHonorarJeImplantat: {
      name: 'Honorar je Implantat',
      unit: 'eur',
      virtual: true,
      value: `b.behandlungenImplantateGesamtBetrag / b.behandlungenImplantateGesamtAnzahl`,
    },
  };
  for (const impla of implantatLeistungen) {
    ret[`_behandlungenImplantate${impla}`] = {
      name: impla,
      value: `b.behandlungenImplantate['${impla}'].gesamtBetrag`,
      virtual: true,
      smartValue: `s / b.behandlungenImplantateGesamtBetrag * 100`,
      smartUnit: '%',
      childs: {
        [`_behandlungenImplantate${impla}GesamtAnzahl`]: {
          name: 'Anzahl gesetzte Implantate',
          value: `b.behandlungenImplantate['${impla}'].gesamtAnzahl`,
          virtual: true,
          unit: 'I',
        },
        [`_behandlungenImplantate${impla}GesamtBetrag`]: {
          name: 'Honorar gesetzte Implantate',
          value: `b.behandlungenImplantate['${impla}'].gesamtBetrag`,
          virtual: true,
          unit: 'eur',
        },
        [`_behandlungenImplantate${impla}GesamtPatienten`]: {
          name: 'Patienten mit gesetztem Implantat',
          value: `b.behandlungenImplantate['${impla}'].gesamtPatienten`,
          virtual: true,
          unit: 'P',
        },
        [`_behandlungenImplantate${impla}Kasse`]: {
          name: 'Bei GKV',
          isHeader: true,
          childs: {
            [`_behandlungenImplantate${impla}KasseAnzahl`]: {
              name: 'Anzahl gesetzte Implantate',
              value: `b.behandlungenImplantate['${impla}'].kasseAnzahl`,
              virtual: true,
              unit: 'I',
            },
            [`_behandlungenImplantate${impla}KasseBetrag`]: {
              name: 'Honorar gesetzte Implantate',
              value: `b.behandlungenImplantate['${impla}'].kasseBetrag`,
              virtual: true,
              unit: 'eur',
            },
            [`_behandlungenImplantate${impla}KassePatienten`]: {
              name: 'Patienten mit gesetztem Implantat',
              value: `b.behandlungenImplantate['${impla}'].kassePatienten`,
              virtual: true,
              unit: 'P',
            },
          },
        },
        [`_behandlungenImplantate${impla}Privat`]: {
          name: 'Bei PKV',
          isHeader: true,
          childs: {
            [`_behandlungenImplantate${impla}PrivatAnzahl`]: {
              name: 'Anzahl gesetzte Implantate',
              value: `b.behandlungenImplantate['${impla}'].privatAnzahl`,
              virtual: true,
              unit: 'I',
            },
            [`_behandlungenImplantate${impla}PrivatBetrag`]: {
              name: 'Honorar gesetzte Implantate',
              value: `b.behandlungenImplantate['${impla}'].privatBetrag`,
              virtual: true,
              unit: 'eur',
            },
            [`_behandlungenImplantate${impla}PrivatPatienten`]: {
              name: 'Patienten mit gesetztem Implantat',
              value: `b.behandlungenImplantate['${impla}'].privatPatienten`,
              virtual: true,
              unit: 'P',
            },
          },
        },
      },
    };
  }
  return ret;
}

export function benchmarkConfigBehandlungen(implantatLeistungen: string[]): IBenchmarkConfigItemRaw {
  return {
    name: 'Behandlungen',
    unit: 'eur',
    help: 'Im ausgewählten Zeitraum erbrachte Behandlungen.',
    smartValue: '100',
    smartUnit: '%',
    value: 'b.behandlungenAnzahl',
    isHeader: true,
    virtual: true,
    childs: {
      _konsBehandlungen: {
        name: 'Konservierende Behandlungen',
        isHeader: true,
        virtual: true,
        value: 'b.behandlungenAnzahl',
        childs: {
          _fuellungenBehandlungen: {
            name: 'Füllungstherapie',
            isHeader: true,
            virtual: true,
            value: 'b.behandlungenFuellungenGesamtAnzahl',
            childs: {
              behandlungenFuellungenGesamtAnzahl: {
                name: 'Anzahl Füllungen insgesamt',
                unit: 'F',
                help: 'Zur Ermittlung der Anzahl der gelegten Füllungen im Betrachtungszeitraum werden die erbrachten Leistungen nach den Nummern BEMA 13a-h und GOZ 2050-2120 ausgewertet.',
              },
              behandlungenFuellungenGesamtBetrag: {
                name: 'Honorar Füllungen insgesamt',
                unit: 'eur',
                help: 'Das erwirtschaftete Honorar der gelegten Füllungen bezieht sich lediglich auf die eigentlich erbrachten Füllungsleistungen BEMA 13a-h und GOZ 2050-2120, ohne Begleitleistungen.',
              },
              // behandlungenTermineAnzahl: {
              //   name: 'Termine mit Füllungen',
              //   unit: 'T',
              // },
              behandlungenPatientenAnzahl: {
                name: 'Patienten mit Füllungen',
                unit: 'P',
                help: 'Anzahl der Patienten, bei denen Füllungsleistungen im Betrachtungszeitraum erbracht wurden.',
                smartValue: 'b.behandlungenPatientenAnzahl / b.patienten * 100',
                smartUnit: '%',
              },
              // _fuellungenJeTermin: {
              //   name: 'Füllungen je Termin mit Füllung',
              //   virtual: true,
              //   value: 'b.behandlungenFuellungenGesamtAnzahl / b.behandlungenTermineAnzahl',
              //   unit: 'F',
              // },
              _fuellungenJePatient: {
                name: 'Füllungen je Patient',
                virtual: true,
                value: 'b.behandlungenFuellungenGesamtAnzahl / b.behandlungenPatientenAnzahl',
                unit: 'F/P',
                formatHint: '0,0.0',
                help: 'Anzahl der gelegten Füllungen, je behandelten Patient mit Füllungsleistungen, im Betrachtungszeitraum.',
              },
              ...createFuellungenVersicherungsartChilds('FuellungenGesamt', false),
              ...createFuellungenFlaechigkeitChilds(),
            },
          },
        },
      },
      _implaBehandlungen: {
        name: 'Implantate',
        isHeader: true,
        virtual: true,
        value: 'b.behandlungenImplantateGesamtAnzahl',
        help: 'Welche Implantatsleistungen hier gezählt werden, kann in den Einstellungen unter Leistungen konfiguriert werden',
        childs: createImplChilds(implantatLeistungen),
      },
    },
  };
}
