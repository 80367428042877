import { IFactoringSetting, IFactoringRZSettings } from './settings';
import { HKPStatusType } from './enums';
import { ITablet } from './tablet';
import { IDBModel, IPostgresVersion } from './db';
import { ICharlyAnamneseConfig } from './anamnese';
import { INews } from './interfaces';

// ========================== magic variables

export enum EWE_FORMULARE_SPEZIAL {
  MANUELLES_EWE_FORMULAR = 'MANUELLES_EWE_FORMULAR',
  KEINRZANBIETEN_EWE_FORMULAR = 'KEINRZANBIETEN_EWE_FORMULAR',
  ABLEHNUNGS_EWE_FORMULAR = 'ABLEHNUNGS_EWE_FORMULAR',
  MITGEGEBEN_EWE_FORMULAR = 'MITGEGEBEN_EWE_FORMULAR',
  EWE_WIDERRUF = 'EWE_WIDERRUF',
}

// ========================== enums

export enum rzkeys {
  test = 'test',
  demo = 'demo',
  bfs = 'bfs',
  za = 'za',
  dzr = 'dzr',
  abz = 'abz',
  health = 'health',
  pvsdental = 'pvsdental',
  pvsreiss = 'pvsreiss',
  arc = 'arc',
  teamfaktor = 'teamfaktor',
  carecapital = 'carecapital',
  mediserv = 'mediserv',
  zab = 'zab',
  nelly = 'nelly',
  fabius = 'fabius',
}

export enum EWE_ERGEBNIS_TYPE {
  ANGENOMMEN,
  ABGELEHNT_NICHT_MEHR_NACHFRAGEN,
  KEIN_RZ_ANBIETEN,
  EWE_MITGEGEBEN,
  WIDERRUFEN,
}

export enum ANFRAGE_TRIGGER_TYPE {
  UNKNOWN = -1, // da wir anfrage infos manchmal nur vom rz bekommen wissen wir nicht mehr wer angefragt hat
  AUTOMATISCH = 0,
  MANUELL = 1,
  CACHE_PLACEHOLDER = 2,
}

export enum CREDENTIALS_TYPE {
  ROSETOKEN = 0, // rose has some kind of one token for all clients
  CLIENT_WITH_CERT = 1, // every client has its own credentials which have to be encrypted
}

export enum EWE_ABLAUF_TYPE {
  WIRD_16 = 0,
  WIRD_18 = 1,
  // WIDERRUFEN = 2,
  RZ_WECHSEL = 3,
  FIRMIERUNGSWECHSEL = 4,
  LOESCHUNG = 5, // eine ewe wird gelöscht
  GUELTIGKEITSERWEITERT = 6,
  MANUELL_INVALIDIERT = 7,
}

export enum STATS_EWE_RESULT {
  KEINE = 'KEINE',
  GUELTIG = 'GUELTIGE',
  UNGUELTIG = 'UNGUELTIGE',
}

export enum STATS_AVA_RESULT {
  KEINE = 'KEINE',
  GUELTIG = 'GUELTIGE',
  ABGELAUFEN = 'ABGELAUFENE',
  AUFGEBRAUCHT = 'AUFGEBRAUCHT',
  ABGELEHNT = 'ABGELEHNT',
}

export enum R4CRegisterState {
  TOKENINVALID = 'TOKENINVALID',
  NOTREGISTERED = 'NOTREGISTERED',
  ONBOARDING = 'ONBOARDING',
  REGISTERED = 'REGISTERED',
}

export interface IFactoringPatientRefHashes {
  ref_p_simple: string;
  ref_p_full: string;
  ref_re_simple: string;
  ref_re_full: string;
  ref_p_re_simple: string;
  ref_p_re_full: string;
}

export interface IFactoringPatientFieldHashes {
  fieldhashes: {
    patient: IFactoringPatientHashFields | undefined;
    re: IFactoringPatientHashFields | undefined;
  };
}

// ========================== DB

export interface IFactoringPraxis extends IDBModel {
  cid: string;
  infos: IFactoringPraxisInfo;
  charlyTemplates?: CharlyGroupedTemplates;
  charlyAnamneseConfig?: ICharlyAnamneseConfig;
  sharedAblageFiles: string[];
}

export interface IFactoringClientRechenzentrumMini {
  id: number;
  cid: string;
  alias?: string;
  rzkey: rzkeys;
}

export interface IFactoringClientRechenzentrumDBBase extends IDBModel, IFactoringClientRechenzentrumMini {
  id: number;
  name: string; // usually name from IFactoringRechenzentrum
  prio: number;
  veraltet: boolean;
  standardanfragebetrag: number;
  vertragsnummernodermandanten: IFactoringVertragOderMandant[];
  auslanderlauben: boolean;
}

export interface IFactoringClientRechenzentrumDB<T = any> extends IFactoringClientRechenzentrumDBBase {
  credentials: T;
}

export interface IFactoringClientRechenzentrumDBExtended extends IFactoringClientRechenzentrumDB {
  meta?: { [key: string]: any };
}

export interface IFactoringAnfrage
  extends IFactoringRZPatientenInfosBase,
    IFactoringPatientRefHashes,
    IFactoringPatientFieldHashes,
    IDBModel {
  extid?: string;
  cid: string;
  patid: string;
  anfragedatum?: Date;
  trigger: ANFRAGE_TRIGGER_TYPE;
  triggerbenutzer?: string;
  anfrage: IFactoringAnfrageErgebnis | null;
  ewe?: number; // referenz
  clientrz: number; // referenz
  cachelebensdauer: Date;
  error?: string;
}

export interface IFactoringAnfrageHistory extends IFactoringAnfrage {
  oldid: number;
  oldCreatedAt: number;
}

export interface IFactoringAnfrageMitErsteller extends IFactoringAnfrage {
  ersteller?: string;
  erstelltam?: Date;
}

export interface IFactoringAnfrageErsteller extends IFactoringPatientRefHashes, IFactoringPatientFieldHashes, IDBModel {
  extid: string;
  cid: string;
  patid: string;
  trigger: ANFRAGE_TRIGGER_TYPE;
  triggerbenutzer: string;
  anfrage: IFactoringAnfrageErgebnis;
  ewe?: number; // referenz
  clientrz: number; // referenz
}

export interface IFactoringEWEDB extends IFactoringPatientRefHashes, IFactoringPatientFieldHashes, IDBModel {
  cid: string;
  patid: string;
  benutzer: string;
  ewe: Date; // erfassungsdatum
  formular: string; // referenz
  clientrz: number; // referenz
  ablaufdatum?: Date;
  ablaufgrund?: EWE_ABLAUF_TYPE;
  zusatzinfos?: any;
  signaturestrokedata?: string;
  ergebnis: EWE_ERGEBNIS_TYPE;
  betreuungsfall: boolean;
  gueltigkeitserweiterungvon?: number;
}

export interface IFactoringEWEFormular {
  id: string;
  dateiname: string;
  gueltigvon?: Date;
  gueltigbis?: Date;
  sprache: string;
  pdfPageNum?: number;
  rz: string; // referenz zu statischen rz in rechenzentren.ts
  kommentar: string;
  positionen: IFactoringPDFPositionen;
  deactivated: boolean;
}

export interface IFactoringStats extends IDBModel {
  cid: string;
  patid: string;
  tag: Date;
  statusewe: STATS_EWE_RESULT;
  statusava: STATS_AVA_RESULT;
  quelle: ANFRAGEQUELLE_TYPE;
  lockwait: number;
  rzdatawait: number;
  rid: string; // requestid
}

export interface IFactoringHeuteInfoEntry {
  patid: string;
  ref: string;
  ewe?: {
    id: number;
    gueltigBis: Date;
    ergebnis: EWE_ERGEBNIS_TYPE;
  };
  ava?: {
    id: number | undefined;
    tag: Date | undefined;
    gueltigBis: Date | undefined;
    status: FACTORING_ANFRAGE_STATUS | undefined;
  };
}

export interface IFactoringHeutePatientInfo {
  [crz: string]: IFactoringHeuteInfoEntry;
}

export interface IFactoringHeuteInfo {
  crzs: IFactoringClientRechenzentrumDB[];
  patienten: {
    [patid: string]: IFactoringHeutePatientInfo;
  };
}

export interface IFactoringClientRechenzentren<T = IFactoringClientRechenzentrumDBBase> {
  rechenzentren: T[];
  decrypted: boolean;
  credentialsKey?: string;
}

// ========================== patient interfaces
// ========================== patient interfaces
// ========================== patient interfaces

export interface IFactoringPatientHashFields {
  name: string;
  vorname: string;
  geburtsdatum: string;
  strasse: string;
  plz: string;
  ort: string;
}

export interface IFactoringCharlyPatient extends IFactoringPatientBase {
  inland: boolean;
  rginland: boolean;
  hkps: IFactoringHKP[];
  erbrachteLeistungen: number;
  rechnungsempfaenger: IFactoringPatientBase;
  rgan: RECHNUNGSEMPFAENGER_TYPE;
  rganid: string;
  patientseit: string;
  beruf: string;
  arbeitgeber: string;
  versichertbei: string;
  stammbehandler: string;
  zuletztAbgerechnetGoz: Date;
  zuletztNichtAbgerechnetGoz: Date;
}

export interface IFactoringRosePatient {
  extid: string;
  rzdata?: IFactoringPatientRZData[];
  fromCache?: boolean;
}

export type IFactoringGeschlechtType = 'M' | 'W' | 'U';

export const FactoringGeschlechtToText = {
  M: 'Männlich',
  W: 'Weiblich',
  U: 'Unbekannt',
};

/**
 * base patient, used as rechnungsenmpfänger
 */
export interface IFactoringPatientBase {
  extid: string;
  name: string;
  vorname: string;
  namenszusatz?: string;
  titel?: string;
  geschlecht: IFactoringGeschlechtType;
  geburtsdatum: string; // DD.MM.YYYY
  strasse: string;
  plz: string;
  ort: string;
  land: string; // kürzel
  email: string;
}

/**
 * this is used in metrics for prefetcher, we dont have names etc here
 */
export interface IFactoringPatientMetrics extends IFactoringPatientRefHashes {
  extid: string;
  rgan: RECHNUNGSEMPFAENGER_TYPE;
  rganid: string;
}

export interface IFactoringPatient extends IFactoringPatientFieldHashes, IFactoringPatientMetrics {}

export interface IFactoringPatientKomplett
  extends IFactoringCharlyPatient,
    IFactoringPatientFieldHashes,
    IFactoringPatientMetrics {}

export type PATREFTYPE =
  | 'ref_p_simple'
  | 'ref_p_full'
  | 'ref_re_simple'
  | 'ref_re_full'
  | 'ref_p_re_simple'
  | 'ref_p_re_full';

export interface IFactoringRechenzentrumFeatures {
  restbetrag: boolean;
  letztesRechnungsDatum: boolean;
  nachrichten: boolean;
  ausfallschutzBeiBlindankauf: boolean;
  fristLetzteLeistung: boolean;
  landMandatory: boolean;
  vertragsDatenUpdate: boolean;
}

// ========================== more interfaces
// ========================== more interfaces
// ========================== more interfaces

export interface IFactoringRechenzentrum {
  key: rzkeys;
  backendkey: rzkeys;
  name: string;
  langname: string;
  patreffieldEWE: PATREFTYPE;
  patreffieldAVA: PATREFTYPE;
  credentialstype: CREDENTIALS_TYPE;
  localisation: {
    // wie nennt das rz die dinger
    ewe: string;
    ava: string;
    mandant: string;
  };
  standardanfragebetrag: number;
  standardLaufzeitEweInMonaten: number;
  maximalbetrag: number;
  multipleanfragen: boolean; // kann es mehrere anfragen geben?
  features: IFactoringRechenzentrumFeatures;
  binaryCredentialFields?: string[];
  numberCredentialFields?: string[];
  credentialsFieldsForMeta?: string[];
}

export interface IFactoringRechenzentrumInfo {
  key: rzkeys;
  name: string;
  aktiv: boolean;
}

export interface IFactoringEWE extends IFactoringEWEDB {
  gueltigbis?: Date; // wird vom server berechnet: eingabedatum + zeitraum aus settings + ggf. verlängerung durch rz
  formularDeactivated?: boolean; // wenn das formular deaktiviert wurde
}

export interface IFactoringEWEResult {
  valid: IFactoringEWE | null; // this is a valid ewe which allow access to rz
  last: IFactoringEWE | null; // last ewe may be invalid
  previousEwes: IFactoringEWEDB[]; // list of previous ewes, possibly with different refs
  timingInMs: {
    get: number;
    save: number;
    redate: number;
  };
}

export interface IFactoringVertragsInfos {
  mandantenOderVertraege: IFactoringVertragOderMandant[];
}

export interface IFactoringNeueAnfrage {
  anfrage?: IFactoringAnfrageErgebnis;
  letzterechnung?: Date;
  rohdaten: any;
  error?: string;
}

export interface IFactoringBackend<TCredentials, TService> {
  key: string;
  enabled: boolean;

  clearCaches: () => Promise<void>;

  ping: (crz: IFactoringClientRechenzentrumDB<TCredentials>, credentialsKey?: string) => Promise<any>;

  vertragsInfos: (
    crz: IFactoringClientRechenzentrumDB<TCredentials>,
    credentialsKey?: string,
  ) => Promise<IFactoringVertragsInfos | undefined>;

  neueAnfrage: (
    crz: IFactoringClientRechenzentrumDB<TCredentials>,
    patient: IFactoringPatientKomplett,
    betrag: number,
    mandantOderVertrag: IFactoringVertragOderMandant | undefined,
    credentialsKey: string | undefined,
    uuid: string | undefined,
  ) => Promise<IFactoringNeueAnfrage | undefined>;

  patientenInfos: (
    crz: IFactoringClientRechenzentrumDB<TCredentials>,
    patient: IFactoringPatientKomplett,
    von?: Date,
    bis?: Date,
    credentialsKey?: string,
    uuid?: string,
  ) => Promise<IFactoringRZPatientenInfos | undefined>;

  letztesRechnungsDatum: (
    crz: IFactoringClientRechenzentrumDB<TCredentials>,
    patient: IFactoringPatientKomplett,
    credentialsKey?: string,
    uuid?: string,
  ) => Promise<Date | undefined>;

  nachrichten: (
    crz: IFactoringClientRechenzentrumDB<TCredentials>,
    patient: IFactoringPatientKomplett | undefined,
    credentialsKey: string,
    uuid: string,
  ) => Promise<IFactoringNachrichten | undefined>;

  createClientRechenzentrum: (
    cid: string,
    credentialsSecret: string,
    prio: number,
    credentials: TCredentials,
    transaction: any,
  ) => Promise<IFactoringClientRechenzentrumDB<TCredentials>>;

  updateClientRechenzentrum: (
    crz: IFactoringClientRechenzentrumDB<TCredentials>,
    credentialsSecret: string,
  ) => Promise<void>;

  getClientRechenzentrum: (
    cid: string,
    clientRzId: number,
    credentialsSecret?: string,
  ) => Promise<IFactoringClientRechenzentrumDB<TCredentials>>;
}

export enum FACTORING_ANFRAGE_STATUS {
  OFFEN = 'offen',
  GUELTIG_MIT_AUSFALLSCHUTZ = 'gueltig_as',
  GUELTIG_50_PROZENT_AUSFALLSCHUTZ = 'gueltig_50_as',
  GUELTIG_KEIN_AUSFALLSCHUTZ = 'gueltig_kein_as',
  RUECKSPRACHE = 'ruecksprache',
  VERBRAUCHT = 'verbraucht',
  ERSETZT = 'ersetzt',
  UNGUELTIG = 'ungueltig',
  ABGELAUFEN = 'abgelaufen',
  UNBEKANNT = '-',
}

export interface IFactoringRZPatientenInfos extends IFactoringRZPatientenInfosBase {
  anfragen: IFactoringAnfrageErgebnis[];
}

export interface IFactoringAnfrageErgebnis {
  id: string;
  patid: string;
  mandantOderVertrag?: string;
  datum?: Date;
  gueltigBis?: Date;
  anfrageStatus: FACTORING_ANFRAGE_STATUS;
  betragAngefragt?: number;
  betragZugesagt?: number;
  betragVerbleibend?: number;
  bemerkung?: string;
  createdAt?: Date;
}

export interface IFactoringPraxisStempelInfo {
  stempelname1: string;
  stempelname2: string;
  stempelstrasse: string;
  stempelplz: string;
  stempelort: string;
  fax: string;
  bic1: string;
  iban1: string;
  bank1: string;
  kzvnummer: string;
}

export interface IFactoringPraxisStempelInfoNew {
  id: string;
  stempelname1: string;
  stempelname2: string;
  strasse: string;
  plz: string;
  ort: string;
  fax: string;
  bic: string;
  iban: string;
  bank: string;
  behandler: string[];
  kzvnummer: string;
}

export interface IFactoringPraxisInfoBase extends IFactoringPraxisStempelInfo {
  aktivepatienten: number;
  anzahlpatienten: number;
  appmode: string;
  behandler: number;
  email: string;
  fax: string;
  license: string;
  maxid: string;
  ort: string;
  plz: string;
  strasse: string;
  telefon: string;
  terminbehandler: number;
  praxis: string;
  praxis1: string;
  praxis2: string;
  kzvidnr: string;
  kzvname: string;
  stempel: IFactoringPraxisStempelInfoNew[];
}

// this is what we get from db
export interface IFactoringPraxisInfoRaw extends IFactoringPraxisInfoBase {
  rzumsatz: string; // JSON
  rz: string; // JSON
  termineprojahr: string; // JSON
  umsatzprojahr: string; // JSON
}

// which will be converted to
export interface IFactoringPraxisInfo extends IFactoringPraxisInfoBase {
  rz: {
    id?: string;
    nummer?: string;
    name?: string;
    kundennr?: string;
    umsatz?: number;
  }[];
  termineprojahr: {
    jahr?: number;
    anzahl?: number;
  }[];
  umsatzprojahr: {
    jahr?: number;
    anzahl?: number;
  }[];
  dbversion?: IPostgresVersion;
  pvsversion?: string;
}

export type CharlyGroupedTemplates = {
  group: string;
  entries: string[];
}[];

export interface IFactoringPDFPosition {
  x: number;
  y: number;
}

export interface IFactoringPDFAreaPosition {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IFactoringPDFMultiLinePosition {
  x: number;
  y: number;
  fontSize: number;
  lineHeight: number;
}

export interface IFactoringPDFPositionen {
  offset: IFactoringPDFPosition;
  allgemein: {
    unterschrift: IFactoringPDFAreaPosition;
    datum?: IFactoringPDFPosition;
  };
  praxis: {
    stempel: IFactoringPDFMultiLinePosition;
    ort?: IFactoringPDFPosition;
    telefon?: IFactoringPDFPosition;
    email?: IFactoringPDFPosition;
  };
  patient: {
    firstname: IFactoringPDFPosition;
    lastname: IFactoringPDFPosition;
    dateofbirth: IFactoringPDFPosition;
    street: IFactoringPDFPosition;
    city: IFactoringPDFPosition;
  };
  rechnungsempfaenger: {
    firstname: IFactoringPDFPosition;
    lastname: IFactoringPDFPosition;
    dateofbirth: IFactoringPDFPosition;
    street: IFactoringPDFPosition;
    city: IFactoringPDFPosition;
  };
  extras?: {
    elternEinwilligungCheckbox?: IFactoringPDFPosition;
  };
  stellungVertreterZuPatient?: {
    elternteil: IFactoringPDFPosition;
    betreuer: IFactoringPDFPosition;
    ehegatte: IFactoringPDFPosition;
    sonstig: IFactoringPDFPosition;
    sonstigText: IFactoringPDFPosition;
  };
  // vielleicht noch irgendwelche checkbox?
}

export interface IFactoringBackendInitData {
  clientrechenzentren: IFactoringClientRechenzentrumDBExtended[];
}

export interface IUserNewsInfo {
  newsRootUrl: string;
  key: string;
  url: string;
  wann?: Date;
  news: INews;
}

export interface IFactoringHKP {
  id: string;
  datum: Date;
  status: HKPStatusType;
  gesamt: number;
  versichertenanteil: number;
  rest: number;
  leistungen: number;
}

export enum RECHNUNGSEMPFAENGER_TYPE {
  VERSICHERTER = 1,
  PATIENT = 2,
  RECHNUNGSEMPFAENGER = 3,
  UNFALLVERSICHERUNG = 4,
}

export interface IFactoringCharlyBenutzer {
  loginname: string;
  benutzername: string;
  veraltet?: string;
}

export enum ANFRAGEQUELLE_TYPE {
  NONE = 'NONE',
  CACHE = 'CACHE',
  RZ = 'RZ',
  HISTORY = 'HISTORY',
}

export interface IFactoringVertragOderMandant {
  id1?: string;
  id2?: string;
  name: string; // bfs: nr, dzr: vertragsnummer
  typ: string;
  aktiv: boolean;
  standardAnfrageBetrag?: number;
  bezeichnung?: string;
}

export interface IFactoringRZPatientenInfosBase {
  ausfallschutz?: boolean; // ist nur für blindankauf ausschlaggebend!!!
  ausfallschutzbemerkung?: string;
  letzterechnung?: Date;
  rohdaten: any;
  error?: any;
}

export interface IFactoringPatientRZDataBase extends IFactoringRZPatientenInfosBase {
  ewe: IFactoringEWE | null;
  previousEwes: IFactoringEWEDB[];
  anfrage: IFactoringAnfrage | null;
  anfrageQuelle: ANFRAGEQUELLE_TYPE;
  anfrageAutomatikDetails?: IFactoringAnfrageAutomatik;
  error?: string;
}

export interface IFactoringPatientRZData extends IFactoringPatientRZDataBase {
  crz: IFactoringClientRechenzentrumMini;
  rz: IFactoringRechenzentrum;
  prio: number;
  standardanfragebetrag: number;
  maximalbetrag: number;
  vertragsnummernOderMandanten: IFactoringVertragOderMandant[];
  auslanderlauben: boolean;
}

export interface R4CPatient
  extends IFactoringRosePatient,
    IFactoringCharlyPatient,
    IFactoringPatientRefHashes,
    IFactoringPatientFieldHashes {
  selectedRechenzentrumDaten: IFactoringPatientRZData | undefined;
}

export interface IFactoringMandant {
  nr: number;
  type: 'PRAXIS' | 'LABOR';
  aktiv: boolean;
}

export type IFactoringSettingsToEdit = IFactoringSetting & IFactoringRZSettings;

// tablets

export interface IFactoringTabletInfo {
  ok: boolean;
  error?: string;
  tablets?: ITablet[];
}

// socketserver

export interface IFactoringMessenger {
  isR4CConnectedForClient: (cid: string) => boolean;
  requestClientDataFromR4C: (cid: string) => Promise<IRoseClientData | undefined> | undefined;
  requestCharlyPatientInfoFromR4C: (
    cid: string,
    patientId: string,
    timeoutInSec: number,
    doNotThrow: boolean,
  ) => Promise<IFactoringCharlyPatient | undefined> | undefined;
  patientInfoUpdated: (cid: string, patientId: string) => void;
  sendCharlyInfoEntry: (cid: string, patientId: string, filename: string, info: string, date: Date) => void;
}

// misc
export interface IRoseClientInfoForOnboarding {
  authToken: string;
  isApikey?: boolean; // when started from r4c
  cid: string;
  r4cSecret?: string;
}

export interface IFactoringOnboardingStart {
  apikey: string;
  secret: string;
  praxisData: IFactoringPraxisInfo;
  showTestRz?: boolean;
  url: string;
}

export interface IRoseClientData {
  apikey: string;
  secret: string;
}

export interface IFactoringEWEMiniStats {
  cid: string;
  formular: string;
  anzahl: number;
  latest: Date;
}

export interface IFactoringAnfrageAutomatik {
  cid: string;
  patid: string;
  clientrz: number;
  triggeranfrage: IFactoringAnfrageErgebnis | null;
  triggeranfrageextid?: string;
  neueanfrage: IFactoringAnfrageErgebnis | null;
  neueanfrageextid?: string;
  grund?: {
    text: string;
    parameter?: any;
  };
}

/* RZ Credentials */

export interface ICredentialsBase {
  [key: string]: any;
}

export interface IMitGutscheinCode {
  gutschein?: string;
}

export interface IBFSCredentials extends ICredentialsBase {
  benutzer: string;
  passwort: string;
  zertifikattoken?: string;
  zertifikatpasswort: string;
  zertifikat?: any; // string or BUFFER
}

export interface IDZRCredentials {
  org_unit: number;
  contract_number: number;
  username: string;
  password: string;
}

export interface IZACredentials {
  customerNumber: string;
  username: string;
  password: string;
}

export interface IHealthCredentials {
  customerNumber: string;
  apiKey: string;
}

export interface IPvsReissCredentials {
  username: string;
  password: string;
}

export interface IArcCredentials {
  kunde: string;
  hash: string;
}

export interface ICareCapitalCredentials {
  username: string;
  password: string;
}

export interface IMediservCredentials {
  kunde: string;
}

export interface IZABCredentials {
  username: string;
  password: string;
}

export interface INellyCredentials {
  username: string;
  password: string;
  organization_id: string;
  customer_number: string;
}

export interface IFabiusCredentials {
  key: string;
}

export interface ITeamfaktorCredentials {
  benutzer: string;
  passwort: string;
}

export interface IPvsDentalCredentials {
  token: string;
}

export interface IR4cUsageStats {
  cid: string;
  monat: string;
  lastewe: Date;
  lastava: Date;
  lastsigneddocument: Date;
  name: string;
  clicks: number;
  ewecount: number;
  avacount: number;
  signeddocumentcount: number;
}

export interface IR4cUsageStatsExtended extends IR4cUsageStats {
  clicksDiff: number;
  ewecountDiff: number;
  avacountDiff: number;
  signeddocumentcountDiff: number;
}

export interface IR4cRZSignupStats {
  tag: Date;
  bfs: number;
  za: number;
  dzr: number;
  health: number;
  arc: number;
  teamfaktor: number;
  pvsreiss: number;
  pvsdental: number;
}

export interface IR4cStats {
  usage: IR4cUsageStatsExtended[];
  signup?: IR4cRZSignupStats[];
  signupCsv?: string;
}

export interface IFactoringNachricht {
  text: string;
  patient?: {
    id?: string;
    avaid?: string;
    name?: string;
    vorname?: string;
  };
}

export interface IFactoringNachrichten {
  nachrichten: IFactoringNachricht[];
}
