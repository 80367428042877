"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var BehandlerChip_vue_1 = require("@/components/BehandlerChip.vue");

var lodash_1 = require("lodash");

var behandlerStore_1 = require("@/state/behandlerStore");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  name: 'TeamMitgliederCell',
  components: {
    BehandlerChip: BehandlerChip_vue_1["default"]
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    mitglieder: function mitglieder() {
      var _this$params$data;

      return (0, lodash_1.compact)((0, lodash_1.map)((_this$params$data = this.params.data) === null || _this$params$data === void 0 ? void 0 : _this$params$data.mitglieder, function (m) {
        return behandlerStore_1.behandlerStore.getters.map[m];
      }));
    },
    addableBehandler: function addableBehandler() {
      var _this = this;

      return (0, lodash_1.filter)(behandlerStore_1.behandlerStore.getters.values, function (b) {
        var _this$params$data2;

        return !((_this$params$data2 = _this.params.data) !== null && _this$params$data2 !== void 0 && _this$params$data2.mitglieder.includes(b.extid));
      });
    },
    isCustom: function isCustom() {
      var _this$params$context;

      return !!((_this$params$context = this.params.context) !== null && _this$params$context !== void 0 && _this$params$context.isCustom);
    }
  },
  methods: {
    removeTeamMitglied: function removeTeamMitglied(item) {
      var _this$params$data3;

      console.log('removeTeamMitglied', (_this$params$data3 = this.params.data) === null || _this$params$data3 === void 0 ? void 0 : _this$params$data3.id, item.extid);

      if (this.params.data) {
        editSettingsStore_1.editSettingsStore.commit.removeTeamMitglied({
          teamId: this.params.data.id,
          behandlerId: item.extid
        });
      }
    },
    addTeamMitglied: function addTeamMitglied(item) {
      if (this.params.data) {
        editSettingsStore_1.editSettingsStore.commit.addTeamMitglied({
          teamId: this.params.data.id,
          behandlerId: item.extid
        });
      }
    }
  }
});