"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var diversRechenzentrum_1 = require("@/components/columns/settings/diversRechenzentrum");

var StichwortChip_vue_1 = require("@/components/StichwortChip.vue");

var lodash_1 = require("lodash");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  components: {
    StichwortChip: StichwortChip_vue_1["default"]
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: null,
      gridApi: null,
      gridColumnApi: null,
      overlayLoadingTemplate: null,
      overlayNoRowsTemplate: null,
      columnDefs: (0, diversRechenzentrum_1.settingsDiversRechenzentrumColumnsDefs)(),
      stichwortSelected: ''
    };
  },
  computed: {
    rzStichworte: function rzStichworte() {
      return editSettingsStore_1.editSettingsStore.getters.rzStichworte;
    },
    stichworteAvailable: function stichworteAvailable() {
      return editSettingsStore_1.editSettingsStore.getters.rzStichworteAvailable;
    },
    isInactive: function isInactive() {
      return !!(0, lodash_1.find)(editSettingsStore_1.editSettingsStore.getters.rechenzentrumReihenfolgeInactive, function (x) {
        return x.name === 'stichworte';
      });
    }
  },
  beforeMount: function beforeMount() {
    this.gridOptions = {
      rowHeight: 50,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        resizable: true
      },
      rowSelection: 'single',
      suppressCellFocus: true
    };
  },
  mounted: function mounted() {
    if (this.gridOptions.api) {
      this.gridApi = this.gridOptions.api;
    }

    this.gridColumnApi = this.gridOptions.columnApi;
    this.gridOptions.api.setDomLayout('autoHeight');
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      console.log('onCellClicked', event);
    },
    addRZStichwort: function addRZStichwort() {
      if (this.stichwortSelected) {
        editSettingsStore_1.editSettingsStore.commit.addRZStichwort(this.stichwortSelected);
        this.stichwortSelected = null;
      }
    }
  }
});